import { useRecordLoader } from "./useRecordLoader";
import { SpecialTagTypeEnum } from "libs/schema";

export function useThreadLabelIds(props: { threadId?: string | null }) {
  return useRecordLoader({
    name: "useThreadLabels",
    load({ loader, limit, deps: [threadId] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"thread_tag">();
      }

      return loader.observeGetThreadTags({
        thread_id: threadId,
        tag_type: SpecialTagTypeEnum.LABEL,
        limit,
      });
    },
    initialLimit: 50,
    limitStep: 50,
    deps: [props.threadId],
    map: (records) => records.map((record) => record.tag_id),
  });
}
