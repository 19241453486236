import { CSSProperties, memo } from "react";
import { IListOnEntryActionEvent, List } from "../list";
import { isEqual } from "libs/predicates";
import { EntryActions, entryCSSClasses, OtherCommandEntryAction } from "./layout";
import { PointerWithRecord } from "libs/schema";
import { isModKeyActive } from "~/environment/command.service";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { cx } from "@emotion/css";
import { ParentComponent } from "~/utils/type-helpers";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useLabel } from "~/hooks/useLabel";
import { Tooltip } from "~/components/Tooltip";
import { MdLabelOutline } from "react-icons/md";

/* -------------------------------------------------------------------------------------------------
 * onLabelSelect
 * -----------------------------------------------------------------------------------------------*/

export async function onLabelSelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent;
    label: PointerWithRecord<"tag">;
  },
) {
  if (isModKeyActive(props.event)) {
    return environment.router.navigate(`/labels/${props.label.id}`, {
      openInNewTab: true,
    });
  }

  return environment.router.navigate(`/labels/${props.label.id}`);
}

/* -------------------------------------------------------------------------------------------------
 * LabelChipEntry
 * -----------------------------------------------------------------------------------------------*/

export const LabelChipEntry: ParentComponent<{
  labelId: string;
  relativeOrder: number;
  onEntryAction?: (props: IListOnEntryActionEvent<PointerWithRecord<"tag">>) => void;
  isActive?: boolean;
  style?: CSSProperties;
}> = memo((props) => {
  const { labelId, relativeOrder, onEntryAction, style, isActive } = props;

  const [label] = useLabel(labelId);

  const entryData = useAsPointerWithRecord("tag", label);

  if (!label || !entryData) return null;

  return (
    <Tooltip side="bottom" content={label.name}>
      <span>
        <List.Entry<PointerWithRecord<"tag">>
          id={labelId}
          data={entryData}
          relativeOrder={relativeOrder}
          onEntryAction={onEntryAction}
        >
          <div
            role="listitem"
            className={cx(
              "LabelEntry",
              "flex items-center hover:cursor-pointer outline-none",
              `border rounded text-xs truncate max-w-40 pt-[3px] px-[6px] pb-[2px] font-medium`,
              isActive ?
                "bg-slateA-11 border-slateA-11 text-white hover:bg-slateA-9 focus:bg-slateA-9"
              : "bg-transparent border-black text-black hover:bg-slateA-4 focus:bg-slateA-4",
            )}
            style={style}
          >
            <MdLabelOutline className="flex-shrink-0" /> <span className="pl-1 truncate">{label.name}</span>
          </div>
        </List.Entry>
      </span>
    </Tooltip>
  );
}, isEqual);

/* -------------------------------------------------------------------------------------------------
 * LabelEntry
 * -----------------------------------------------------------------------------------------------*/

export const LabelEntry: ParentComponent<{
  labelId: string;
  relativeOrder: number;
  onEntryAction?: (props: IListOnEntryActionEvent<PointerWithRecord<"tag">>) => void;
  style?: CSSProperties;
}> = memo((props) => {
  const { labelId, relativeOrder, onEntryAction } = props;

  const [label] = useLabel(labelId);

  const entryData = useAsPointerWithRecord("tag", label);

  if (!label || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"tag">>
      id={labelId}
      data={entryData}
      relativeOrder={relativeOrder}
      onEntryAction={onEntryAction}
    >
      <div role="listitem" className={cx(entryCSSClasses, "LabelEntry")} style={props.style}>
        <div className="w-full flex items-center">
          <div className="flex items-center pr-6">
            <MdLabelOutline className="text-black flex-shrink-0" /> <span className="pl-1 truncate">{label.name}</span>{" "}
          </div>

          <div className="flex-1" />

          <EntryActions>
            <OtherCommandEntryAction />
          </EntryActions>
        </div>
      </div>
    </List.Entry>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/
