import { css, cx } from "@emotion/css";
import { isEqual } from "libs/predicates";
import { forwardRef, memo, ReactNode, RefObject, useEffect, useRef } from "react";
import {
  IEditorMention,
  IMessageEditorControl,
  IRichTextEditorRef,
  MessageEditor,
  MessageEditorErrors,
  useSyncDraftContentChangesFromOtherWindowToEditor,
} from "~/components/forms/message-editor";
import { observable, useControlState } from "~/components/forms/utils";
import { PLATFORM_MODIFIER_KEY } from "~/environment/command.service";
import { addAttachmentCommand, deleteDraftCommand, sendMessageCommand } from "~/utils/common-commands";
import { Tooltip } from "~/components/Tooltip";
import { MdAttachment, MdOutlineDelete } from "react-icons/md";
import { OutlineButton } from "~/components/OutlineButtons";
import { IComposeMessageForm } from "./ComposeMessageContext";
import { IFormControl } from "solid-forms-react";
import { IRecipientOption } from "~/components/forms/ThreadRecipients";
import { startWith } from "libs/rxjs-operators";
import { filter, map, pairwise, switchMap } from "rxjs";
import { isNonNullable } from "libs/predicates";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import { observeMentionableUsers } from "~/observables/observeMentionableUsers";
import { observeMentionableGroups } from "~/observables/observeMentionableGroups";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { AttachmentsContainer, DraftAttachment } from "./Attachment";
import { ParentComponent } from "~/utils/type-helpers";

/* -------------------------------------------------------------------------------------------------
 * ComposePostReplyBase
 * -----------------------------------------------------------------------------------------------*/

export interface IComposeMessageReplyBaseProps {
  control: IComposeMessageForm;
  formRef: RefObject<HTMLFormElement>;
  header: ReactNode;
  saveDraftFn: () => Promise<void>;
  className?: string;
  focusOnInit?: boolean;
  draftActions: ReactNode;
  wrapperRef: RefObject<HTMLDivElement>;
  onEditorStartOverflow?: () => void;
  onEditorEndOverflow?: () => void;
}

export const ComposeMessageReplyBase = memo(
  forwardRef<IRichTextEditorRef, IComposeMessageReplyBaseProps>((props, ref) => {
    const { control } = props;
    const editorRef = useRef<IRichTextEditorRef>(null);

    const composedEditorRefs = useComposedRefs(ref, editorRef);

    useFocusDraftOnMount({
      focusOnInit: !!props.focusOnInit,
      control,
      editorRef,
    });

    const isReadonly = useControlState(() => control.isDisabled || control.isReadonly, [control]);

    useSyncDraftContentChangesFromOtherWindowToEditor(props.control, editorRef);

    const controlBorderCSS = useControlState(() => {
      if (control.isDisabled) return "border-slate-9";

      if (control.isPending) {
        return "border-blue-5 focus-within:border-blue-9";
      }

      if (control.errors) return "border-red-5 focus-within:border-red-9";

      return "border-green-5 focus-within:border-green-9";
    }, [control]);

    return (
      <div
        ref={props.wrapperRef}
        className={cx(
          "DraftEntry",
          "bg-white shadow-lg border-l-[.4rem]",
          "focus:outline-none transition-colors relative",
          controlBorderCSS,
          props.className,
        )}
      >
        {props.header}

        <form ref={props.formRef} onSubmit={(e) => e.preventDefault()}>
          <div
            className={cx(
              "flex flex-col flex-1 overflow-y-auto px-4 sm-w:px-8",
              !isReadonly && "min-h-32",
              isReadonly && "hover:cursor-not-allowed",
            )}
          >
            <MessageEditor
              ref={composedEditorRefs}
              onEditorStartOverflow={props.onEditorStartOverflow}
              onEditorEndOverflow={props.onEditorEndOverflow}
              initialTabIndex={0}
              control={control}
              saveDraftFn={props.saveDraftFn}
            />

            <Attachments control={control} />
            {!isReadonly && <MessageEditorErrors control={control} />}
          </div>

          <div className="flex px-4 sm-w:px-8 pt-2 pb-4 *:mr-3 last:mr-0 overflow-x-auto">{props.draftActions}</div>
        </form>
      </div>
    );
  }),
  isEqual,
);

/* -------------------------------------------------------------------------------------------------
 * ComposeReplyHint
 * -----------------------------------------------------------------------------------------------*/

export const ComposeReplyHint: ParentComponent<{}> = () => {
  return (
    <div className={cx(composeWrapperCSS, "mt-8 mx-10 prose text-slate-9")}>
      <p>
        <strong>Hint:</strong> To include additional recipients, <code>@mention</code> (or{" "}
        <code>@@request-response</code>, etc) them.
      </p>
    </div>
  );
};

// the bottom margin is equal to 50vh - the header height.
const composeWrapperCSS = css`
  margin-bottom: calc(50vh - 4.75rem);
`;

/* -------------------------------------------------------------------------------------------------
 * SendDraftButton
 * -----------------------------------------------------------------------------------------------*/

export const SendDraftButton: ParentComponent<{ label?: string }> = (props) => {
  const label = props.label || "Send";

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          {label}
          <code className="flex items-center">
            (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" /> + Enter)
          </code>
        </>
      }
    >
      <OutlineButton
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault();
          sendMessageCommand.trigger();
        }}
      >
        <small>{label}</small>
      </OutlineButton>
    </Tooltip>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Attachments
 * -----------------------------------------------------------------------------------------------*/

const Attachments: ParentComponent<{ control: IMessageEditorControl }> = (props) => {
  const { control } = props;

  const attachments = useControlState(
    () => control.controls.attachments.rawValue.filter((a) => a.contentDisposition !== "inline"),
    [control],
  );

  if (attachments.length === 0) return null;

  return (
    <div className="mb-3">
      <AttachmentsContainer>
        {attachments.map((attachment) => (
          <DraftAttachment key={attachment.id} control={control} attachment={attachment} />
        ))}
      </AttachmentsContainer>
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * AttachFileButton
 * -----------------------------------------------------------------------------------------------*/

/** When clicked, this button activates the "add attachment" command. */
export const AttachFileButton: ParentComponent<{}> = () => {
  return (
    <Tooltip
      side="left"
      content={
        <>
          Attach file
          <code className="flex items-center">
            (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" /> + Shift + A)
          </code>
        </>
      }
    >
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          addAttachmentCommand.trigger();
        }}
        className="inline-flex justify-center items-center w-[29px] text-slate-9 hover:text-slate-12"
      >
        <MdAttachment size={24} />
      </button>
    </Tooltip>
  );
};

/* -------------------------------------------------------------------------------------------------
 * DeleteDraftButton
 * -----------------------------------------------------------------------------------------------*/

/** When clicked, this button activates the "delete draft" command. */
export const DeleteDraftButton: ParentComponent<{ label?: string }> = (props) => {
  const label = props.label || "Delete draft";

  return (
    <Tooltip
      side="left"
      content={
        <>
          {label}
          <code className="flex items-center">
            (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" /> + Shift + ,)
          </code>
        </>
      }
    >
      <button
        type="button"
        tabIndex={-1}
        className="inline-flex justify-center items-center w-[29px] text-slate-9 hover:text-slate-12"
        onClick={(e) => {
          e.preventDefault();

          const isSure = confirm(`Are you sure you want to ${label.toLowerCase()}?`);

          if (!isSure) return;

          deleteDraftCommand.trigger();
        }}
      >
        <MdOutlineDelete size={24} />
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useFocusDraftOnMount(args: {
  focusOnInit: boolean;
  control: IComposeMessageForm;
  editorRef: RefObject<IRichTextEditorRef>;
}) {
  useEffect(() => {
    if (!args.focusOnInit) return;

    let isMounted = true;

    args.editorRef.current?.addOnCreate(() => {
      if (!isMounted) return;
      focusDraft(args.control, args.editorRef);
    });

    return () => {
      isMounted = false;
    };
  }, []);
}

/* -------------------------------------------------------------------------------------------------
 * focusDraft
 * -----------------------------------------------------------------------------------------------*/

export function focusDraft(control: IComposeMessageForm, editorRef: RefObject<IRichTextEditorRef>) {
  const isBranch = !!control.rawValue.branchedFrom;

  if (isBranch) {
    if (control.rawValue.recipients.to.length === 0) {
      control.controls.recipients.controls.to.data.focus.next();
      return;
    }

    if (!control.rawValue.subject) {
      control.controls.subject.data.focus.next();
      return;
    }
  }

  // If the thread is deleted editorRef.current will be null
  editorRef.current?.focus("end", { scrollIntoView: true });
}

/* -------------------------------------------------------------------------------------------------
 * useAddNewUserMentionsAsRecipients
 * -----------------------------------------------------------------------------------------------*/

export function useAddNewUserMentionsAsRecipients(
  userMentionsControl: IFormControl<IEditorMention[]>,
  recipientsControl: IFormControl<IRecipientOption[]>,
  isReadyToSync = true,
) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useEffect(() => {
    if (!isReadyToSync) return;

    const sub = observable(() => userMentionsControl.value)
      .pipe(
        startWith(() => []),
        pairwise(),
        filter(([prevMentions, currMentions]) => prevMentions.length < currMentions.length),
        map(([prevMentions, currMentions]) => {
          return currMentions.filter((curr) => !prevMentions.some((prev) => prev.id === curr.id));
        }),
        switchMap((addedMentions) =>
          observeMentionableUsers(environment, { currentUserId }).pipe(
            map(([mentionalUsers]) => mentionalUsers.filter((m) => addedMentions.some((added) => added.id === m.id))),
          ),
        ),
      )
      .subscribe((possiblyAddedUserMentions) => {
        const currentRecipients = recipientsControl.rawValue;

        // Since we've already compared the current mentions to the previous mentions
        // above when calculating the possiblyAddedUserMentions, you might wonder why we're
        // grabbing the currentRecipients and (again) checking to see if these mentions are new
        // or not. We're doing this because it's theoretically possible that
        // subscriptions trigger out of order causing the possiblyAddedUserMentions to be stale
        // by the time this subscribe handler is called. So again we filter out any mentions
        // that are already recipients here.
        const addedUserMentions = possiblyAddedUserMentions.filter(
          (mention) => !currentRecipients.some((recipient) => recipient.value === mention.id),
        );

        if (addedUserMentions.length === 0) return;

        recipientsControl.setValue([
          ...currentRecipients,
          ...addedUserMentions.map((mention) => {
            return {
              type: "user" as const,
              value: mention.id,
              label: mention.profile.name,
              email: mention.contact ? mention.contact.email_address : null,
            };
          }),
        ]);
      });

    return () => sub.unsubscribe();
  }, [userMentionsControl, recipientsControl, isReadyToSync, currentUserId, environment]);
}

/* -------------------------------------------------------------------------------------------------
 * useAddNewGroupMentionsAsRecipients
 * -----------------------------------------------------------------------------------------------*/

export function useAddNewGroupMentionsAsRecipients(
  groupMentionsControl: IFormControl<IEditorMention[]>,
  recipientsControl: IFormControl<IRecipientOption[]>,
  isReadyToSync = true,
) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useEffect(() => {
    if (!isReadyToSync) return;

    const sub = observable(() => groupMentionsControl.value)
      .pipe(
        startWith(() => []),
        pairwise(),
        filter(([prevMentions, currMentions]) => prevMentions.length < currMentions.length),
        map(([prevMentions, currMentions]) => {
          return currMentions.filter((curr) => !prevMentions.some((prev) => prev.id === curr.id));
        }),
        switchMap((addedMentions) =>
          observeMentionableGroups(environment, { currentUserId }).pipe(
            map(([records]) => records.filter((m) => addedMentions.some((added) => added.id === m.id))),
          ),
        ),
        filter(isNonNullable),
      )
      .subscribe((possiblyAddedGroupMentions) => {
        const currentRecipients = recipientsControl.rawValue;

        // Since we've already compared the current mentions to the previous mentions
        // above when calculating the possiblyAddedGroupMentions, you might wonder why we're
        // grabbing the currentRecipients and (again) checking to see if these mentions are new
        // or not. We're doing this because it's theoretically possible that
        // subscriptions trigger out of order causing the possiblyAddedGroupMentions to be stale
        // by the time this subscribe handler is called. So again we filter out any mentions
        // that are already recipients here.
        const addedGroupMentions = possiblyAddedGroupMentions.filter(
          (mention) => !currentRecipients.some((recipient) => recipient.value === mention.id),
        );

        if (addedGroupMentions.length === 0) return;

        recipientsControl.setValue([
          ...currentRecipients,
          ...addedGroupMentions.map((mention) => {
            return {
              type: "group" as const,
              value: mention.id,
              icon: mention.record.icon,
              label: mention.record.archived_at ? `${mention.record.name} (Archived)` : mention.record.name,
              isPrivate: mention.isPrivate,
              folderPaths: mention.folderPaths.map((p) => p.map((folder) => folder.name).join(" > ")),
            };
          }),
        ]);
      });

    return () => sub.unsubscribe();
  }, [groupMentionsControl, recipientsControl, isReadyToSync, currentUserId, environment]);
}

/* -----------------------------------------------------------------------------------------------*/
