import { memo, useState, CSSProperties } from "react";
import { List, useListContext } from "../list";
import { isEqual } from "libs/predicates";
import {
  Recipients,
  Summary,
  DisplayDate,
  entryCSSClasses,
  useShouldShowChannelLabels,
  PrivateEntryIcon,
  StarredEntryIcon,
  EntryActions,
  MarkDoneEntryAction,
  SetReminderEntryAction,
  OtherCommandEntryAction,
  MarkNotDoneEntryAction,
} from "./layout";
import { cx } from "@emotion/css";
import { Avatar } from "../Avatar";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useNotification } from "~/hooks/useNotification";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { useMessageSender } from "~/hooks/useMessageSender";
import { LabelContainer, PermittedGroupChips, ThreadLabelsCountChip, ThreadResolvedLabel } from "../LabelChip";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { useDoesThreadHaveDraft } from "~/hooks/useDoesThreadHaveDraft";
import { ParentComponent } from "~/utils/type-helpers";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { getThreadRoutePrefix } from "~/environment/router";
import { onMessageSelectNavigateToThread } from "./MessageEntry";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import {
  ClientRecordLoaderObserveGetRecordResult,
  ClientRecordLoaderObserveQueryResult,
  ObserveOptions,
} from "~/environment/RecordLoader";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

/**
 * Because we're using a virtual list, entries are positioned based on their index.
 * If one of the list entry components renders `null`, then it will create a gap in
 * the list since the next entry will have a higher index. For this reason, we need
 * to filter out any notifications that will not be rendered from the list. See the
 * `NotificationEntry` component for specifics on what notifications are rendered.
 */
export function observeFilteredNotificationEntriesForVirtualList(
  environment: Pick<ClientEnvironment, "recordLoader">,
  observable: Observable<ClientRecordLoaderObserveQueryResult<"notification">>,
  options?: ObserveOptions,
) {
  const loader = environment.recordLoader;

  return observable.pipe(
    switchMap(([notifications, meta]) => {
      const observable: Observable<
        Array<
          [
            RecordValue<"notification">,
            ClientRecordLoaderObserveGetRecordResult<"message">,
            ClientRecordLoaderObserveGetRecordResult<"thread">,
          ]
        >
      > =
        notifications.length === 0 ?
          of([])
        : combineLatest(
            notifications.map((n) =>
              combineLatest([
                of(n),
                loader.observeGetRecord("message", n.message_id, options),
                loader.observeGetRecord("thread", n.thread_id, options),
              ]),
            ),
          );

      return observable.pipe(
        map((results) => {
          const notifications = results
            .filter(([_, [message], [thread]]) => !!message && !!thread)
            .map(([notification]) => notification);

          return [notifications, meta] as const;
        }),
      );
    }),
  );
}

export const NotificationEntry: ParentComponent<{
  notificationId: string;
  relativeOrder: number;
  style?: CSSProperties;
}> = memo((props) => {
  const environment = useClientEnvironment();
  const [notification] = useNotification({
    notificationId: props.notificationId,
  });

  const [thread] = useThread(notification?.thread_id);
  const [message] = useMessage(notification?.message_id);
  const sender = useMessageSender(notification?.message_id);

  const [hasDraft] = useDoesThreadHaveDraft({
    threadId: notification?.thread_id,
    // In the environment we create and maintain a subscription to all of the user's drafts.
    // For this reason, we can use a cache-only fetch strategy here. Note that the environment
    // creates a subscription to the user's drafts after a delay, so if the user is initially
    // loading Comms' inbox they won't see these drafts appear until that subscription is created.
    // But in this case we're deciding that that is ok.
    fetchStrategy: "cache",
  });

  const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");

  const senderPhotoURL = sender?.photoUrl;

  const showLabels = useShouldShowChannelLabels();

  const [isChecked, setIsChecked] = useState(false);

  const listContext = useListContext();

  const entryData = useAsPointerWithRecord("notification", notification);

  if (!notification || !thread || !message || !entryData) return null;

  const isPrivateThread = thread.visibility === "PRIVATE";

  return (
    <List.Entry<PointerWithRecord<"notification">>
      key={notification.id}
      id={notification.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntrySelectionChange={(event) => {
        setIsChecked(event.isSelected);
      }}
    >
      <div
        role="listitem"
        className={cx(entryCSSClasses, isChecked && "is-checked", "NotificationEntry")}
        style={props.style}
      >
        <div className="pr-2 sm-w:pr-3">
          {isChecked ?
            <MdOutlineCheckBox
              size={30}
              className="p-1"
              onClick={(e) => {
                e.stopPropagation();
                listContext.deselect(notification.id);
              }}
            />
          : <>
              <Avatar label={senderLabel} photoURL={senderPhotoURL} width="30px" className="group-hover:hidden" />

              <MdOutlineCheckBoxOutlineBlank
                size={30}
                className="hidden group-hover:block p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  listContext.select(notification.id);
                }}
              />
            </>
          }
        </div>

        <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
          <Recipients
            nonTruncatedSuffix={
              <>
                {hasDraft && <span className="text-green-9 ml-2 shrink-0">(+ draft)</span>}

                {isPrivateThread && <PrivateEntryIcon />}
                {notification.is_starred && <StarredEntryIcon />}
              </>
            }
          >
            <span className="truncate">{senderLabel}</span>
          </Recipients>

          <Summary
            subject={thread.subject}
            formatAsReply={message.is_reply && message.type === "COMMS"}
            details={isPrivateThread ? "private message" : message.body_text}
          />
        </div>

        <LabelContainer>
          {showLabels && <PermittedGroupChips threadId={message.thread_id} />}
          {showLabels && <ThreadLabelsCountChip threadId={message.thread_id} />}

          <ThreadResolvedLabel
            threadId={notification.thread_id}
            onClick={({ event, threadTag }) => {
              event.preventDefault();

              const location = environment.router.location();

              onMessageSelectNavigateToThread(environment, {
                event,
                threadRoutePrefix: getThreadRoutePrefix(location),
                threadId: threadTag.thread_id,
                messageId: threadTag.data.message_id,
              });
            }}
          />
        </LabelContainer>

        <EntryActions
          defaultComponent={
            <div className="ml-2">
              <NotificationTimestamp notification={notification} sentAt={message.sent_at} showReminder />
            </div>
          }
        >
          {notification.is_done ?
            <MarkNotDoneEntryAction />
          : <MarkDoneEntryAction />}
          <SetReminderEntryAction />
          <OtherCommandEntryAction />
        </EntryActions>
      </div>
    </List.Entry>
  );
}, isEqual);

export const NotificationTimestamp: ParentComponent<{
  notification: Pick<RecordValue<"notification">, "done_last_modified_by" | "remind_at">;
  sentAt: string;
  showReminder?: boolean;
}> = (props) => {
  const wrapperCSS = "flex items-center text-sm";

  if (props.showReminder && props.notification.remind_at) {
    return (
      <div className={wrapperCSS}>
        <span className="text-plumA-8">
          Remind me:{" "}
          <span className="uppercase">
            <DisplayDate date={props.notification.remind_at} />
          </span>
        </span>
      </div>
    );
  }

  const isInInboxDueToReminder = props.showReminder && props.notification.done_last_modified_by === "reminder";

  return (
    <div className={wrapperCSS}>
      <span className={cx("uppercase", isInInboxDueToReminder ? "text-plumA-8" : "text-slateA-9")}>
        <DisplayDate date={props.sentAt} />
      </span>
    </div>
  );
};
