import { shareReplay, distinctUntilChanged, map, interval, firstValueFrom, filter } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import Cookies from "js-cookie";
import { firebaseSignOut, isEmulatingAuth } from "~/firebase";
import { apiRoot } from "./api.service";
import { onlyCallFnOnceWhilePreviousCallIsPending, wait } from "libs/promise-utils";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "./config";
import { ClientEnvironment } from "./ClientEnvironment";
import { Simplify } from "type-fest";
import { datadogLogs } from "@datadog/browser-logs";

/* -------------------------------------------------------------------------------------------------
 * AuthService
 * -------------------------------------------------------------------------------------------------
 */

export type AuthServiceApi = Simplify<AuthService>;

export class AuthService {
  static readonly isLoadingKeySignout = "auth-signout";

  currentUserId$ = interval(1000).pipe(
    startWith(() => null),
    map(() => this.getCurrentUserId()),
    distinctUntilChanged(),
    shareReplay(1),
  );

  currentUserOwnerOrganizationId$ = interval(1000).pipe(
    startWith(() => null),
    map(() => this.getCurrentUserOwnerOrganizationId()),
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor(private env: Pick<ClientEnvironment, "logger" | "isLoading" | "isPendingUpdate">) {}

  getCurrentUserId(): string | null {
    return Cookies.get("userId") || null;
  }

  getAndAssertCurrentUserId(): string {
    const currentUserId = this.getCurrentUserId();

    if (!currentUserId) {
      throw new Error("Expected current user to be signed in but they are not.");
    }

    return currentUserId;
  }

  getCurrentUserOwnerOrganizationId(): string | null {
    return Cookies.get("userOwnerOrganizationId") || null;
  }

  getAndAssertCurrentUserOwnerOrganizationId(): string {
    const ownerOrganizationId = this.getCurrentUserOwnerOrganizationId();

    if (!ownerOrganizationId) {
      throw new Error("Expected current user to be signed in but they are not.");
    }

    return ownerOrganizationId;
  }

  signout = onlyCallFnOnceWhilePreviousCallIsPending(
    async (
      options: {
        /**
         * Normally when attempting to close the current tab we'll show the user a warning if there
         * are any pending transactions. Pass `true` to this function to force signout without
         * showing the warning.
         */
        force?: boolean;
      } = {},
    ) => {
      this.env.logger.trace("Signing out");

      using disposable = this.env.isLoading.add({ key: AuthService.isLoadingKeySignout });

      if (this.env.isPendingUpdate.isUpdating()) {
        const shouldWait = confirm(
          "There are pending updates. Wait for the pending updates to complete before signing out?",
        );

        if (shouldWait) {
          await firstValueFrom(this.env.isPendingUpdate.isUpdating$.pipe(filter((v) => !v)));
        } else {
          this.env.isPendingUpdate.showPendingUpdatesWarning(false);
        }
      }

      if (isEmulatingAuth()) {
        await logoutRequest();
      } else {
        await Promise.all([firebaseSignOut(), logoutRequest()]);
      }

      // If we're running the client locally but connecting to a production server
      // then we need to manually remove these cookies which we set on localhost but
      // which the server won't be able to remove.
      Cookies.remove("userId");
      Cookies.remove("userOwnerOrganizationId");

      // Note that we also clear the user context via a subscription in the createEnvironment function, but we do it here
      // as well because it's possible that our subscription doesn't run before the page reloads. In that case, when
      // the user opened up Comms again there might briefly be a stale user context in datadog.
      datadogLogs.clearUser();
      datadogRum.clearUser();

      if (options.force) {
        this.env.isPendingUpdate.showPendingUpdatesWarning(false);
      }

      // Note that we navigate using location.href so that the current memory is cleared.
      // Important as a security precaution.
      window.location.href = "/login";

      // Wait a few seconds to give time for the page to reload
      await wait(3000);
    },
  );
}

/* -----------------------------------------------------------------------------------------------*/

// Note that this is a *rare* exception where we aren't using the api service to make an
// api request! We're making an exception here because we potentially want the ability to
// logout before the API service has been initialized. In general, we want all requests to
// go through the API service.
async function logoutRequest() {
  const response = await fetch(apiRoot + "logout", {
    method: "post",
    credentials: "include",
    body: null,
  });

  if (!response.ok) {
    throw new Error("Failed to log out");
  }
}

/* -----------------------------------------------------------------------------------------------*/
