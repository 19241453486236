import { CreateRecord, Operation, op } from "libs/transaction";
import { SpecialTagTypeEnum, ThreadResolvedThreadTagRecord, generateRecordId, getPointer } from "libs/schema";

export type MarkThreadResolvedProps = {
  threadId: string;
  resolvedByMessageId: string;
  markedResolvedByUserId: string;
  ownerOrganizationId: string;
};

export function markThreadResolved(props: MarkThreadResolvedProps): Operation[] {
  const tagId = generateRecordId("singleton_tag", { name: "resolved" });

  return [
    op.set("thread_tag", {
      id: generateRecordId("thread_tag", {
        thread_id: props.threadId,
        tag_id: tagId,
      }),
      thread_id: props.threadId,
      tag_id: tagId,
      tag_type: SpecialTagTypeEnum.SINGLETON,
      creator_user_id: props.markedResolvedByUserId,
      data: {
        message_id: props.resolvedByMessageId,
      },
      owner_organization_id: props.ownerOrganizationId,
    } satisfies CreateRecord<ThreadResolvedThreadTagRecord>),
  ];
}

export type MarkThreadNotResolvedProps = {
  threadId: string;
};

export function markThreadNotResolved(props: MarkThreadNotResolvedProps): Operation[] {
  const pointer = getPointer("thread_tag", {
    thread_id: props.threadId,
    tag_id: generateRecordId("singleton_tag", { name: "resolved" }),
  });

  return [op.delete(pointer)];
}

export type DeleteThreadProps = {
  threadId: string;
};

export function deleteThread(props: DeleteThreadProps): Operation[] {
  const pointer = getPointer("thread", props.threadId);
  return [op.delete(pointer)];
}

export type RestoreThreadProps = {
  threadId: string;
};

export function restoreThread(props: RestoreThreadProps): Operation[] {
  const pointer = getPointer("thread", props.threadId);
  return [op.update(pointer, { deleted_at: null, deleted_by_user_id: null })];
}
