export function removeOneFromArray<T>(array: T[], fn: (item: T) => boolean): T[] {
  const newArray = array.slice();

  const index = array.findIndex(fn);

  if (index < 0) return newArray;

  newArray.splice(index, 1);

  return newArray;
}

export function arrayChange<T>(newArray: T[], oldArray: T[], comparer: (a: T, b: T) => boolean = (a, b) => a === b) {
  // Copy newArray so we can remove items as we find matches
  const newItems = newArray.slice();
  const removed: T[] = [];

  // For each item in oldArray, attempt to find and remove one match in newItems
  for (const oldItem of oldArray) {
    const matchIndex = newItems.findIndex((newItem) => comparer(oldItem, newItem));

    if (matchIndex === -1) {
      // No match found in newItems, so this oldItem was removed
      removed.push(oldItem);
    } else {
      // Remove one matched item so duplicates are correctly tracked
      newItems.splice(matchIndex, 1);
    }
  }

  // Whatever remains in newItems after removal are new additions
  const added = newItems;

  return { added, removed };
}
