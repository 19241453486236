import { Operation, op } from "libs/transaction";
import { TagSubscriptionPreference, generateRecordId } from "libs/schema";

export type SetTagSubscriptionProps = {
  tagId: string;
  tagType: string;
  userId: string;
  creatorUserId: string;
  preference: TagSubscriptionPreference;
  ownerOrganizationId: string;
};

export function setTagSubscription(props: SetTagSubscriptionProps) {
  const operations: Operation[] = [
    op.set("tag_subscription", {
      id: generateRecordId("tag_subscription", {
        tag_id: props.tagId,
        user_id: props.userId,
      }),
      creator_user_id: props.creatorUserId,
      owner_organization_id: props.ownerOrganizationId,
      preference: props.preference,
      tag_id: props.tagId,
      tag_type: props.tagType,
      user_id: props.userId,
    }),
  ];

  return operations;
}
