import { from } from "rxjs";
import { useEffect, useState } from "react";
import { UnreachableCaseError } from "libs/errors";
import { memoize } from "lodash-comms";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useRouteParams } from "~/hooks/useRouteParams";

/* -------------------------------------------------------------------------------------------------
 * useGetSearchQueryFromURL
 * -----------------------------------------------------------------------------------------------*/

export function useGetSearchQueryFromURL() {
  const environment = useClientEnvironment();
  const [params] = useRouteParams();
  const searchQueryParam = params.query || "";
  const [queryTextAndHTML, setQueryTextAndHTML] = useState<{
    queryAsPlainText: string | null;
    queryAsHTML: string | null;
  }>({
    queryAsPlainText: null,
    queryAsHTML: null,
  });

  // We want to set the input's current value to the
  // queryText on initial page load. Important to run this
  // effect before we focus and select the input.
  useEffect(() => {
    const sub = from(mapPlainTextSearchQueryToHTMLQuery(environment, searchQueryParam)).subscribe(setQueryTextAndHTML);

    return () => sub.unsubscribe();
  }, [environment, searchQueryParam]);

  return queryTextAndHTML;
}

/* -----------------------------------------------------------------------------------------------*/

export const mapPlainTextSearchQueryToHTMLQuery = memoize(
  async (environment: Pick<ClientEnvironment, "recordLoader">, searchQueryParam: string) => {
    if (!searchQueryParam) {
      return { queryAsHTML: "", queryAsPlainText: "" };
    }

    const queryAsHTMLPromise = mapDocIdsInQuery({
      environment,
      searchQueryParam,
      mapFn({ subject, priority, subjectId, label }) {
        const priorityNumber =
          priority?.length === 3 ? 100
          : priority?.length === 2 ? 200
          : 300;

        const html =
          `<span data-type="mention" class="search-filter" data-id="` +
          subjectId +
          `" data-label="${label}" data-subject="` +
          subject +
          `" data-priority="` +
          priorityNumber +
          `" contenteditable="false">${priority}${label}</span>`;

        return html;
      },
    }).then((values) => values.map((text) => `<p>${text}</p>`).join(""));

    const queryAsPlainTextPromise = mapDocIdsInQuery({
      environment,
      searchQueryParam,
      mapFn({ priority, label }) {
        return `${priority}${label}`;
      },
    }).then((values) => values.join(""));

    const [queryAsHTML, queryAsPlainText] = await Promise.all([queryAsHTMLPromise, queryAsPlainTextPromise]);

    mapPlainTextSearchQueryToHTMLQuery.cache.delete(searchQueryParam);

    return { queryAsHTML, queryAsPlainText };
  },
  (_, searchQueryParam) => searchQueryParam,
);

/* -----------------------------------------------------------------------------------------------*/

function mapDocIdsInQuery(props: {
  environment: Pick<ClientEnvironment, "recordLoader">;
  searchQueryParam: string;
  mapFn(props: { subject: string; priority: string; subjectId: string; label: string }): string;
}) {
  const { searchQueryParam, mapFn, environment } = props;
  const { recordLoader } = environment;

  return Promise.all(
    searchQueryParam.split("\n").map(async (text) => {
      // match `<#`, then match any character until `::`,
      // then match `@` or `@@` or `@@@` (or `#` or `##` or `###`),
      // then match `::`, then match any character until `>`.
      const regex = /<#[^:]*::(?:@@?@?|##?#?)::[^>]*>/g;

      const replacementPromises = Array.from(text.matchAll(regex)).map(async (match) => {
        const [subject, priority, subjectId] = match[0].slice(2, -1).split("::");

        const originalString = `<#${subject}::${priority}::${subjectId}>`;

        if (!subject || !priority || !subjectId) {
          return {
            label: null,
            originalString,
          };
        }

        if (subject === "user") {
          const [profile] = await recordLoader.getRecord("user_profile", subjectId);

          return {
            label: profile?.name || "unknown",
            subject,
            priority,
            subjectId,
            originalString,
          };
        } else if (subject === "group") {
          const [tag] = await recordLoader.getRecord("tag", subjectId);

          return {
            label: tag?.name || "unknown",
            subject,
            priority,
            subjectId,
            originalString,
          };
        } else {
          throw new UnreachableCaseError(subject as never);
        }
      });

      const resplacements = await Promise.all(replacementPromises);

      for (const replacement of resplacements) {
        text = text.replace(replacement.originalString, replacement.label === null ? "unknown" : mapFn(replacement));
      }

      return text;
    }),
  );
}

/* -----------------------------------------------------------------------------------------------*/
