import { observeFilteredMessageEntriesForVirtualList } from "~/components/content-list/MessageEntry";
import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { useRecordLoader } from "./useRecordLoader";
import { uniqBy } from "lodash-es";

export function useDistinctSentMessageIdsByThread() {
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useDistinctSentMessageIdsByThread",
    load({ loader, limit, currentUserId }) {
      const observable = loader.observeGetSentMessages({
        userId: currentUserId,
        limit,
      });

      return observeFilteredMessageEntriesForVirtualList({ recordLoader: loader }, observable);
    },
    initialLimit,
    limitStep: initialLimit,
    map: (records) => uniqBy(records, "thread_id").map((record) => record.id),
  });
}
