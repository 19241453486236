import { useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useDistinctSentMessageIdsByThread } from "~/hooks/useDistinctSentMessageIdsByThread";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { MessageEntry } from "~/components/content-list/MessageEntry";
import { useVirtualList } from "~/hooks/useVirtualList";
import { ParentComponent } from "~/utils/type-helpers";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { onSentEntrySelect, TSentEntry, useSentViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";
import { EndOfListMsg, LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";

export const SentView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const listRef = useRef<IListRef<TSentEntry>>(null);
  const threadViewContext = useSentViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/sent/threads/$threadId" });

  const [messageIds, { fetchMore, nextId, isLoading }] = useDistinctSentMessageIdsByThread();

  const hasNextPage = !!nextId;

  const virtualMessageIds = useVirtualList({
    scrollboxRef,
    count: messageIds.length,
    getEntryKey: (index) => messageIds[index] || "",
    fetchMore,
    hasNextPage,
    isFetchingNextPage: isLoading,
  });

  const messageCount = virtualMessageIds.entries.length;

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the 
              Sent page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the 
              Sent page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Sent page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Sent page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Sent page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Sent page.
            `);
          },
        }),
      ];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <Helmet>
          <title>Sent | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} className={cx(isThreadOpen && "invisible")}>
          <h1 className="text-3xl">Sent</h1>
        </MainLayout.Header>

        {messageCount === 0 && !isLoading ?
          <EmptyListMessage text="No sent messages." className={cx(isThreadOpen && "invisible")} />
        : <ContentList<TSentEntry>
            listRef={listRef}
            mode={isThreadOpen ? "active-descendent" : "focus"}
            onEntryAction={(event) => onSentEntrySelect(environment, { event })}
            className={cx(isThreadOpen && "invisible")}
            autoFocus
            allEntryIdsForVirtualizedList={messageIds}
            style={virtualMessageIds.containerStyles()}
          >
            {virtualMessageIds.entries.map((virtualEntry, index) => {
              const messageId = virtualEntry.key as string;
              if (!messageId) return null;

              return (
                <MessageEntry
                  key={messageId}
                  messageId={messageId}
                  relativeOrder={index}
                  showRecipientNames
                  showScheduledToBeSentIconFor="threadId"
                  style={virtualMessageIds.entryStyles(virtualEntry)}
                />
              );
            })}
          </ContentList>
        }

        {messageCount > 0 && !hasNextPage && !isLoading && <EndOfListMsg className={cx(isThreadOpen && "invisible")} />}

        {(hasNextPage || isLoading) && <LoadingMoreListEntriesMsg isThreadOpen={isThreadOpen} />}

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
};
