import { isDefined } from "libs/predicates";
import { Simplify } from "type-fest";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import {
  getMapRecords,
  iterateRecordMap,
  PointerWithRecord,
  RecordMap,
  RecordPointer,
  RecordTable,
  RecordValue,
  SpecialTagTypeEnum,
  tableFilterKeys,
  TableHasFilterKeys,
  TagSubscriptionPreference,
  TagSubscriptionPreferenceEnum,
  virtualTables,
} from "libs/schema";
import { Logger } from "libs/logger";
import {
  parseNamespacedDatabaseRows,
  parseDatabaseRows,
  getNamespacedTableColumnsForSelect as getNamespacedTableColumnsForSelect,
  Sql,
  sql,
  Statement,
  RawResult,
  ParsedResult,
} from "libs/sql-statement";
import { ValidationError } from "libs/errors";
import type PgBoss from "pg-boss";
import type { ServerDatabaseAdapterApi } from "./server/ServerDatabaseAdapterApi";

/* -------------------------------------------------------------------------------------------------
 *  SharedQueryApi
 * -------------------------------------------------------------------------------------------------
 *
 * All the SharedQueryApi methods. There needs to be an API endpoint of the same
 * name for each of these methods.
 *
 * Use this list, combined with the vscode "sort lines ascending" command,
 * to keep the methods sorted.
 *
 * - - - - - - - - - - - -
 * Non standard methods
 * - - - - - - - - - - - -
 * getDrafts
 * getGroupsUserHasAccessTo
 * getGroupsUserIsSubscribedTo
 * getGroupsWhichNewUsersOfOrgShouldBeSubscribedTo
 * getGroupViewThreads
 * getInboxDraftEntries
 * getInboxEntries
 * getInboxNotificationEntries
 * getInboxSections
 * getInboxSubsections
 * getLastMessageInThread
 * getNotificationsDeliveredOnOrBefore
 * getNotificationsWithDueReminders
 * getOrganizationByControlledDomain
 * getOrganizations
 * getOrganizationUsers
 * getPendingSentMessages
 * getPersonalLabels
 * getSentMessages
 * getSentMessagesForSearchIndex
 * getTagFolderMembersUserHasAccessTo
 * getTagSubscriberUsers
 * getTagViewThreads
 * getThreadsWithUndeliveredMessages
 * getThreadTimelineEntries
 * getTrashViewThreads
 * getUndeliveredMessageJobRecords
 * getUndeliveredMessagesForThread
 * getUndeliveredNotifications
 * getUserAuthTokens
 * getUserByEmail
 * getUserByFirebaseAuthId
 * getUserOrganizationProfiles
 * getUserProfiles
 *
 * - - - - - - - - - - - -
 * Standard methods
 * - - - - - - - - - - - -
 * getMessageReactions
 * getMessages
 * getNotifications
 * getOrganizationControlledDomains
 * getOrganizationUserInvitations
 * getOrganizationUserMembers
 * getTagFolderMembers
 * getTagGroupMembers
 * getTagSubscriptions
 * getTagUserMembers
 * getThreadGroupPermissions
 * getThreadsGroupPermissions
 * getThreadSubscriptions
 * getThreadTags
 * getThreadUserParticipants
 * getThreadUserPermissions
 * getUserLessons
 * getUserOAuths
 * getUserPushNotificationSubscriptions
 */

// Note that, where appropriate, the SharedQueryApi uses native private methods
// (i.e. beginning with "#") instead of typescript private/protected methods
// so that the private methods are not enumerable. See the `serverDatabaseProps`
// constant defined in ServerDatabase.ts.
export class SharedQueryApi {
  constructor(
    private props: {
      logger: Logger;
      engine: "POSTGRES" | "SQLITE";
      namespaceAllTableColumnsWithNullValues: <T extends RecordTable>(
        /**
         * An array of the table names in the result. If a table in the
         * result has been renamed, the entry here will be an object like
         * `{ table: T, as: string }`
         */
        ...tables: Array<T | { table: T; as: string }>
      ) => Sql;
      decodeRecord: <T extends RecordTable>(table: T, row: Record<string, any>) => RecordValue<T>;
    },
  ) {}

  ////////
  // Non standard methods
  //

  getDrafts(params: GetDraftsParams): Query<"draft"> {
    return {
      primaryTable: "draft",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        const statement = sql`
          SELECT 
            *
          FROM
            "draft"
          WHERE
            "draft"."user_id" = ${params.currentUserId}
          ${params.threadId ? sql`AND "draft"."thread_id" = ${params.threadId}` : sql.EMPTY}
          ${params.branchedFromMessageId ? sql`AND "draft"."branched_from_message_id" = ${params.branchedFromMessageId}` : sql.EMPTY}
          AND
            "draft"."is_edit" = false
          AND
            "draft"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "draft",
            sortOn: "created_at",
            sortDirection: "ASC",
            startAt: params.startAt,
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("draft", result)],
      subscriptionKeys: (recordMap) => [
        `draft:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  /**
   * Note that this query is too complex to be easily subscribed to on the client.
   * In order to create a decent subscription, we need access to all the groups that
   * the current user has access to. Because of this, we don't allow a `LIMIT` to be
   * applied to this query. Since this query returns all the groups that the user has
   * access to, and because a user must be
   * either a direct member of a group or a direct member of an ancestor of that group
   * in order to have access to a group, we can subscribe to all the direct user
   * and group members of each of these returned groups (and maintain a subscription to
   * them) in order to maintain a subscription to this list.
   *
   * This solution is a temporary placeholder. It sucks that we need to subscribe to
   * everything, but at the moment "everything" isn't that big of a list (<300 groups)
   * at time of writing.
   */
  getGroupsUserHasAccessTo(params: GetGroupsUserHasAccessToParams): Query<"tag"> {
    return {
      primaryTable: "tag",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        const statements: Statement[] = [];

        if (this.props.engine === "POSTGRES") {
          // We need to return all results from postgres in order to properly create the
          // subscriptionKeys for this query (which sucks). But at the moment "all results"
          // isn't a huge list (<300) so it's not the end of the world.
          statements.push(
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}
              
              SELECT
                "tag".*
              FROM
                "distinct_tag_ids_of_user"
              JOIN
                "tag" ON "tag"."id" = "distinct_tag_ids_of_user"."tag_id"
              WHERE
                "tag"."type" = ${SpecialTagTypeEnum.GROUP}
              AND
                "tag"."deleted_at" IS NULL;
            `,
          );
        } else if (this.props.engine === "SQLITE") {
          statements.push(
            sql`
              SELECT
                *
              FROM
                "tag"
              WHERE
                "tag"."type" = ${SpecialTagTypeEnum.GROUP}
              AND
                "tag"."deleted_at" IS NULL
              ${getOrderByWithStartAndEndAndLimitSql({
                table: "tag",
                sortOn: params.orderBy || "name",
                sortDirection: "ASC",
              })};`,
          );
        } else {
          throw new UnreachableCaseError(this.props.engine);
        }

        return statements;
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("tag", result)],
      subscriptionKeys: (recordMap) => {
        // We need to use the query results to determine the full subscription keys
        if (!recordMap) {
          return [`tag_user_member:user_id:${params.currentUserId}`];
        }

        const recordSubscriptionKeys = this.#getSubscriptionKeysForRecords(recordMap);

        const groups = getMapRecords(recordMap, "tag");

        // For each group we want to subscribe to all direct tag_group_members
        // of this group. Below, we also subscribe to the tag_user_member records
        // for the current user.
        const querySubscriptionKeys = groups.map((group) => {
          return `tag_group_member:group_id:${group.id}`;
        });

        return [`tag_user_member:user_id:${params.currentUserId}`, ...querySubscriptionKeys, ...recordSubscriptionKeys];
      },
    };
  }

  getGroupsUserIsSubscribedTo(params: GetGroupsUserIsSubscribedToParams): Query<"tag"> {
    return {
      primaryTable: "tag",
      statements: () => {
        const statement = sql`
          SELECT 
            ${getNamespacedTableColumnsForSelect("tag", "tag_subscription")}
          FROM
            "tag"
          JOIN
            "tag_subscription" ON "tag_subscription"."tag_id" = "tag"."id"
          WHERE
            "tag"."type" = ${SpecialTagTypeEnum.GROUP}
          AND
            "tag"."deleted_at" IS NULL
          ${params.includeArchived ? sql.EMPTY : sql`AND "tag"."archived_at" IS NULL`}
          AND
            "tag_subscription"."preference" IN (
              ${TagSubscriptionPreferenceEnum.ALL},
              ${TagSubscriptionPreferenceEnum.ALL_NEW}
            )
          AND
            "tag_subscription"."user_id" = ${params.userId}
          AND
            "tag_subscription"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "tag",
            sortOn: "name",
            sortDirection: "ASC",
            startAt: params.startAt,
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "tag", "tag_subscription")],
      subscriptionKeys: (recordMap) => [
        `tag_subscription:user_id:${params.userId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getGroupsWhichNewUsersOfOrgShouldBeSubscribedTo(
    params: GetGroupsWhichNewUsersOfOrgShouldBeSubscribedToParams,
  ): Query<"tag"> {
    return {
      primaryTable: "tag",
      statements: () => {
        switch (this.props.engine) {
          case "POSTGRES": {
            return [
              sql`
                SELECT 
                  *
                FROM
                  "tag"
                WHERE
                  "tag"."type" = ${SpecialTagTypeEnum.GROUP}
                AND
                  "tag"."deleted_at" IS NULL
                AND EXISTS (
                  SELECT
                    1
                  FROM
                    jsonb_array_elements_text("tag"."data"->'subscribe_new_users_of_orgs') as "org_id"
                  WHERE
                    "org_id" = ${params.organizationId}
                );
              `,
            ];
          }
          case "SQLITE": {
            // At time of writing, this query is only used on the server. SQLITE doesn't
            // implement a `jsonb_array_elements_text` sql function, though there is another
            // way to do this in SQLITE (I think something like
            // `json_each("tag"."data"->'subscribe_new_users_of_orgs') as "org_id"`). Not
            // bothering to look it up now though. -- John 5/31/24
            throw new Error("getGroupsWhichNewUsersOfOrgShouldBeSubscribedTo: not supported in sqlite");
          }
          default: {
            throw new UnreachableCaseError(this.props.engine);
          }
        }
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("tag", result)],
      subscriptionKeys: () => {
        // only currently used on the server so not bothering with this.
        throw new Error("getGroupsWhichNewUsersOfOrgShouldBeSubscribedTo: subscriptions not supported");
      },
    };
  }

  getGroupViewThreads(params: GetGroupViewThreadsParams): Query<"thread"> {
    return {
      primaryTable: "thread",
      statements: () => {
        // As a performance optimization on the server, we want the query to fast-fail if the current user doesn't
        // have permission to access the group. We do this by first checking if the user has access
        // to the group and then only running the main query if they do.
        //
        // We only run this code on the server because withDistinctTagIdsOfUserStatement is
        // only supported in postgres. See the note in the function for more details. We
        // also only need to run this authorization filter on the server. On the client we
        // can trust that we only have access to valid records.
        const authCheck =
          this.props.engine === "POSTGRES" ?
            sql`WITH
              ${this.#withDistinctTagIdsOfUserStatement(true, params)},
              "does_user_have_access_to_group"("answer") AS (
                SELECT EXISTS (
                  SELECT
                    1
                  FROM
                    "distinct_tag_ids_of_user"
                  WHERE
                    "tag_id" = ${params.groupId}
                ) as "answer"
              )`
          : sql.EMPTY;

        const statement = sql`
          ${authCheck}
          SELECT 
            ${getNamespacedTableColumnsForSelect("thread", "thread_group_permission", "message", "user_profile")}
          FROM
            "thread_group_permission"
          ${
            this.props.engine === "POSTGRES" ?
              sql`JOIN "does_user_have_access_to_group" ON "does_user_have_access_to_group"."answer"`
            : sql.EMPTY
          }
          JOIN 
            "thread" ON "thread"."id" = "thread_group_permission"."thread_id" 
          JOIN
            "message" ON "message"."id" = "thread"."last_message_id"
          LEFT JOIN
            "user_profile" ON "user_profile"."id" = "message"."sender_user_id"
          WHERE
            "thread_group_permission"."group_id" = ${params.groupId}
          AND
            "thread_group_permission"."deleted_at" IS NULL
          AND
            "thread"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "thread",
            sortOn: "last_message_timeline_order",
            sortDirection: params.sortDirection,
            startAt: params.startAt,
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(result, "thread", "thread_group_permission", "message", {
          table: "user_profile",
          canAuthorizationFail: true,
        }),
      ],
      subscriptionKeys: (recordMap) => [
        `thread_group_permission:group_id:${params.groupId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  // IMPORTANT!! When fetching this query we don't apply row level security rules
  // so we must only return records which the user has permission to view.
  #inboxDraftEntriesStatement(isNested: boolean, params: GetInboxDraftEntriesParams): Statement {
    const uuidCast = this.props.engine === "POSTGRES" ? sql.raw("UUID") : sql.raw("Text");

    const statement = sql`
      SELECT 
        "draft"."id" as "inbox_entry__id",
        'draft' as "inbox_entry__type",
        "draft"."user_id" as "inbox_entry__user_id",
        CAST(${params.inboxSectionId} as ${uuidCast}) as "inbox_entry__inbox_section_id",
        "draft"."thread_id" as "inbox_entry__thread_id",
        "draft"."id" as "inbox_entry__draft_id",
        "draft"."is_reply" as "inbox_entry__draft_is_reply",
        CAST(null as ${uuidCast}) as "inbox_entry__notification_id",
        CAST(null as ${uuidCast}) as "inbox_entry__inbox_subsection_id",
        CAST(null as Integer) as "inbox_entry__inbox_subsection_order",
        CAST("draft"."created_at" as Text) as "inbox_entry__order",
        CAST(null as Integer) as "inbox_entry__priority",
        "draft"."owner_organization_id" as "inbox_entry__owner_organization_id",
        "draft"."version" as "inbox_entry__version",
        "draft"."created_at" as "inbox_entry__created_at",
        "draft"."updated_at" as "inbox_entry__updated_at",
        "draft"."server_updated_at" as "inbox_entry__server_updated_at",
        "draft"."deleted_at" as "inbox_entry__deleted_at",
        "draft"."deleted_by_user_id" as "inbox_entry__deleted_by_user_id",
        ${getNamespacedTableColumnsForSelect("notification", "thread", "draft")}
        ${isNested ? sql`,` : sql.EMPTY}
        ${
          isNested ?
            this.props.namespaceAllTableColumnsWithNullValues(
              {
                table: "tag",
                as: "inbox_subsection",
              },
              "message",
            )
          : sql.EMPTY
        }
      FROM 
        "draft"
      LEFT JOIN
        "notification" ON
          "notification"."thread_id" = "draft"."thread_id"
          AND "notification"."user_id" = ${params.currentUserId}
          ${
            !params.lastScheduledDeliveryAt ?
              sql.EMPTY
            : sql`
                AND (
                  "notification"."priority" <= 100
                  OR "notification"."done_last_modified_by" != 'delivery'
                  OR "notification"."oldest_message_not_marked_done_sent_at" <= ${params.lastScheduledDeliveryAt}
                )
              `
          }
      LEFT JOIN
        "thread" ON "thread"."id" = "draft"."thread_id"
      WHERE
        "draft"."user_id" = ${params.currentUserId}
      AND
        "draft"."is_edit" = false
      AND
        "draft"."deleted_at" IS NULL
      AND (
        "notification"."id" IS NULL
        OR "notification"."deleted_at" IS NOT NULL
        OR "notification"."is_done" = true
        OR (
          "notification"."is_done" = false AND NOT
          ${
            this.props.engine === "POSTGRES" ? sql`"notification"."tag_ids" ? ${params.inboxSectionId}`
            : this.props.engine === "SQLITE" ?
              // strategy borrowed from https://stackoverflow.com/a/56135885/5490505
              sql`"notification"."tag_ids" LIKE ${`%"${params.inboxSectionId}"%`}`
            : throwUnreachableCaseError(this.props.engine)
          }
        )
        OR "thread"."deleted_at" IS NOT NULL
      )
      ${getOrderByWithStartAndEndAndLimitSql({
        table: "draft",
        sortOn: "created_at",
        sortDirection: params.sortDirection,
        startAt: params.startAt,
        limit: isNested ? undefined : params.limit,
      })}
    `;

    return statement;
  }

  // IMPORTANT!! When fetching this query we don't apply row level security rules
  // so we must only return records which the user has permission to view.
  getInboxDraftEntries(params: GetInboxDraftEntriesParams): Query<"inbox_entry"> {
    return {
      primaryTable: "inbox_entry",
      statements: () => {
        const statement = this.#inboxDraftEntriesStatement(false, params);
        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(
          result,
          "inbox_entry",
          "notification",
          { table: "thread", canAuthorizationFail: true },
          "draft",
        ),
      ],
      subscriptionKeys: (recordMap) => [
        `draft:user_id:${params.currentUserId}`,
        `notification:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  /**
   * The inbox includes
   * 1. First it shows drafts that aren't associated with a notification in the
   *    current inbox section. This includes drafts for new threads as well as draft replies
   *    that don't have an associated notification in this inbox section.
   * 2. Next it shows each inbox section and the notifications within it.
   */
  //
  // IMPORTANT!! When fetching this query we don't apply row level security rules
  // so we must only return records which the user has permission to view.
  getInboxEntries(params: GetInboxEntriesParams): Query<"inbox_entry"> {
    return {
      primaryTable: "inbox_entry",
      statements: () => {
        let statement: Statement;

        const draftStatement = this.#inboxDraftEntriesStatement(true, params);

        // If we're filtering inbox notifications to match priorities in the given array and the array
        // is emptry, then there are no matching inbox notifications and we should only return drafts.
        if (params.priorities?.length === 0) {
          statement = sql`
            WITH 
            "draft_entry" AS (
              ${draftStatement}
            )

            SELECT * FROM "draft_entry"
            ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
          `;

          return [statement];
        }

        const notificationStatement = this.#inboxNotificationEntriesStatement(true, params);

        switch (params.startAt?.table) {
          case "draft": {
            if (params.sortDirection === "DESC") {
              statement = sql`
                WITH 
                "draft_entry" AS (
                  ${draftStatement}
                )

                SELECT * FROM "draft_entry"
                ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
              `;
            } else {
              statement = sql`
                WITH 
                "draft_entry" AS (
                  ${draftStatement}
                ),
                "notification_entry" AS (
                  ${notificationStatement}
                ),
                "inbox_entry" AS (
                  SELECT * FROM "draft_entry"
                  UNION ALL
                  SELECT * FROM "notification_entry"
                )

                SELECT * FROM "inbox_entry"
                ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
              `;
            }

            break;
          }
          case "notification": {
            if (params.sortDirection === "DESC") {
              // Note that the UNION ALL section is reversed from the ASC version
              statement = sql`
                WITH 
                "draft_entry" AS (
                  ${draftStatement}
                ),
                "notification_entry" AS (
                  ${notificationStatement}
                ),
                "inbox_entry" AS (
                  SELECT * FROM "notification_entry"
                  UNION ALL
                  SELECT * FROM "draft_entry"
                )

                SELECT * FROM "inbox_entry"
                ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
              `;
            } else {
              statement = sql`
                WITH 
                "notification_entry" AS (
                  ${notificationStatement}
                )

                SELECT * FROM "notification_entry"
                ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
              `;
            }

            break;
          }
          default: {
            if (params.sortDirection === "DESC") {
              // Note that the UNION ALL section is reversed from the ASC version
              statement = sql`
                WITH 
                "draft_entry" AS (
                  ${draftStatement}
                ),
                "notification_entry" AS (
                  ${notificationStatement}
                ),
                "inbox_entry" AS (
                  SELECT * FROM "notification_entry"
                  UNION ALL
                  SELECT * FROM "draft_entry"
                )

                SELECT * FROM "inbox_entry"
                ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
              `;
            } else {
              // Note that it's important both the draft and notification queries
              // filter on the same rows (to the extent that the queries overlap).
              // For example, a previous version of this query filtered drafts to
              // look for drafts that didn't have a notification_tag associated with
              // `params.inboxSectionId` but filtered notifications to look for
              // notifications associated with a notification_tag that linked to
              // an inbox_subsection with `params.inboxSectionId`. This small difference
              // led to edge cases where the client database didn't have the same records
              // available as the server database resulting in different results on
              // the client vs the server.
              statement = sql`
                WITH 
                "draft_entry" AS (
                  ${draftStatement}
                ),
                "notification_entry" AS (
                  ${notificationStatement}
                ),
                "inbox_entry" AS (
                  SELECT * FROM "draft_entry"
                  UNION ALL
                  SELECT * FROM "notification_entry"
                )

                SELECT * FROM "inbox_entry"
                ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
              `;
            }

            break;
          }
        }

        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(
          result,
          "inbox_entry",
          "notification",
          { table: "thread", canAuthorizationFail: true },
          "draft",
          { table: "tag", as: "inbox_subsection" },
          "message",
        ),
      ],
      subscriptionKeys: (recordMap) => [
        `draft:user_id:${params.currentUserId}`,
        `notification:user_id:${params.currentUserId}`,
        `tag:data:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  // IMPORTANT!! When fetching this query we don't apply row level security rules
  // so we must only return records which the user has permission to view.
  #inboxNotificationEntriesStatement(isNested: boolean, params: GetInboxNotificationEntriesParams): Statement {
    const uuidCast = this.props.engine === "POSTGRES" ? sql.raw("UUID") : sql.raw("Text");

    const boolCast = this.props.engine === "POSTGRES" ? sql.raw("Boolean") : sql.raw("Integer");

    let fromNotificationStatement: Sql;
    let notificationTagIdColumn: Sql;

    if (this.props.engine === "POSTGRES") {
      fromNotificationStatement = sql`
        FROM
          "notification"
        CROSS JOIN LATERAL
          jsonb_array_elements_text("notification"."tag_ids") AS "notification_tag_id"
      `;

      notificationTagIdColumn = sql`"notification_tag_id"::UUID`;
    } else if (this.props.engine === "SQLITE") {
      fromNotificationStatement = sql`
        FROM
          "notification",
          json_each("notification"."tag_ids") as "notification_tag_id"
      `;

      notificationTagIdColumn = sql`"notification_tag_id".value`;
    } else {
      throw new UnreachableCaseError(this.props.engine);
    }

    const joinInboxSubsectionStatement = sql`
      JOIN
        "tag" as "inbox_subsection" ON (
          "inbox_subsection"."type" = ${SpecialTagTypeEnum.INBOX_SUBSECTION}
          AND "inbox_subsection"."id" = ${notificationTagIdColumn}
          AND "inbox_subsection"."data" ->> 'inbox_section_id' = ${params.inboxSectionId}
          AND "inbox_subsection"."data" ->> 'user_id' = ${params.currentUserId}
        )
    `;

    const startAtInboxSubsectionOrderStatement =
      params.startAt ?
        sql`
          SELECT
            CAST("inbox_subsection"."data" ->> 'order' as Integer) as "order"
          ${fromNotificationStatement}
          ${joinInboxSubsectionStatement}
          WHERE
            "notification"."id" = ${params.startAt.id}
          ORDER BY
            CAST("inbox_subsection"."data" ->> 'order' as Integer) ${params.sortDirection === "DESC" ? sql.raw("DESC") : sql.raw("ASC")}
          LIMIT
            1
        `
      : sql.EMPTY;

    // Note the importance of using an optional join on the "thread" and "message" tables.
    // This is for several reasons
    // 1. A user might lose access to a thread or message for legitimate reasons after a
    //    notification is delivered. In this case, we choose to still show the notification
    //    in their inbox but with a note explaining that they lost access to the thread or
    //    we cannot find the associated message.
    // 2. The client will subscribe to notification updates for the inbox but doesn't have a
    //    good way of subscribing to the related threads or messages. Instead, we want this
    //    query to return the relevant notifications and then the client will use the
    //    information in each notification to subscribe to the relevant messages/threads.
    //    If we perform a regular `join` then a notification won't be returned on the client
    //    unless the client already has the associated message/thread loaded, which they
    //    are likely to not have.
    const statement = sql`
      SELECT 
        "notification"."id" as "inbox_entry__id",
        'notification' as "inbox_entry__type",
        "notification"."user_id" as "inbox_entry__user_id",
        CAST("inbox_subsection"."data" ->> 'inbox_section_id' as ${uuidCast}) as "inbox_entry__inbox_section_id",
        "notification"."thread_id" as "inbox_entry__thread_id",
        CAST(null as ${uuidCast}) as "inbox_entry__draft_id",
        CAST(null as ${boolCast}) as "inbox_entry__draft_is_reply",
        "notification"."id" as "inbox_entry__notification_id",
        "inbox_subsection"."id" as "inbox_entry__inbox_subsection_id",
        CAST("inbox_subsection"."data" ->> 'order' as Integer) as "inbox_entry__inbox_subsection_order",
        CAST("notification"."oldest_message_not_marked_done_sent_at" as Text) as "inbox_entry__order",
        CAST("notification"."priority" as Integer) as "inbox_entry__priority",
        "notification"."owner_organization_id" as "inbox_entry__owner_organization_id",
        "notification"."version" as "inbox_entry__version",
        "notification"."created_at" as "inbox_entry__created_at",
        "notification"."updated_at" as "inbox_entry__updated_at",
        "notification"."server_updated_at" as "inbox_entry__server_updated_at",
        "notification"."deleted_at" as "inbox_entry__deleted_at",
        "notification"."deleted_by_user_id" as "inbox_entry__deleted_by_user_id",
        ${getNamespacedTableColumnsForSelect("notification", "thread")},
        ${isNested ? sql`${this.props.namespaceAllTableColumnsWithNullValues("draft")},` : sql.EMPTY}
        ${getNamespacedTableColumnsForSelect(
          {
            table: "tag",
            as: "inbox_subsection",
          },
          "message",
        )}
      ${fromNotificationStatement}
      ${joinInboxSubsectionStatement}
      -- Note that the left join here is necessary. The client is just subscribing to notification record
      -- updates. It a new notification comes in the client isn't automatically going to get the thread &
      -- messages associated with that notification. In order to get the thread and messages, the client
      -- needs to try rendering the notification which will involve attempting to lead the thread and
      -- messages. If we do a normal join here, then this query won't return the notification when
      -- that's all we have, causing the client to not attempt rendering the notification, causing the client
      -- to never get the thread & messages.
      LEFT JOIN
        "thread" ON "thread"."id" = "notification"."thread_id"
      LEFT JOIN
        "message" ON "message"."id" = "notification"."message_id"
      LEFT JOIN
        "message" as "oldest_message_not_marked_done" ON "oldest_message_not_marked_done"."id" = "notification"."oldest_message_not_marked_done_message_id"
      WHERE
        "notification"."user_id" = ${params.currentUserId}
      AND
        "notification"."is_done" = false
      AND
        "notification"."deleted_at" IS NULL
      AND
        "thread"."deleted_at" IS NULL
      AND
        "inbox_subsection"."deleted_at" IS NULL
      ${params.inboxSubsectionId ? sql`AND "inbox_subsection"."id" = ${params.inboxSubsectionId}` : sql.EMPTY}
      ${
        !params.lastScheduledDeliveryAt ?
          sql.EMPTY
        : sql`
              AND (
                "notification"."priority" <= 100
                OR "notification"."done_last_modified_by" != 'delivery'
                OR "notification"."oldest_message_not_marked_done_sent_at" <= ${params.lastScheduledDeliveryAt}
              )
            `
      }
      ${
        !params.priorities?.length ?
          sql.EMPTY
        : sql`
            AND "notification"."priority" IN (${sql.join(params.priorities, ", ")})
          `
      }
      ${
        params.startAt?.table === "notification" ?
          sql`
              AND (
                CAST("inbox_subsection"."data" ->> 'order' as Integer) ${
                  params.sortDirection === "DESC" ? sql.raw("<") : sql.raw(">")
                } (${startAtInboxSubsectionOrderStatement})
                OR (
                  CAST("inbox_subsection"."data" ->> 'order' as Integer) = (${startAtInboxSubsectionOrderStatement})
                  AND "notification"."oldest_message_not_marked_done_sent_at" ${
                    params.sortDirection === "DESC" ? sql.raw("<=") : sql.raw(">=")
                  } (
                    SELECT
                      "notification"."oldest_message_not_marked_done_sent_at"
                    FROM
                      "notification"
                    WHERE
                      "notification"."id" = ${params.startAt.id}
                  )
                )
              )
            `
        : sql.EMPTY
      }
      ORDER BY
        CAST("inbox_subsection"."data" ->> 'order' as Integer) ${params.sortDirection === "DESC" ? sql.raw("DESC") : sql.raw("ASC")},
        "notification"."priority" ${params.sortDirection === "DESC" ? sql.raw("DESC") : sql.raw("ASC")},
        "notification"."oldest_message_not_marked_done_sent_at" ${
          params.sortDirection === "DESC" ? sql.raw("DESC") : sql.raw("ASC")
        },
        "oldest_message_not_marked_done"."scheduled_to_be_sent_at" ${params.sortDirection === "DESC" ? sql.raw("DESC") : sql.raw("ASC")}
      ${!isNested && params.limit ? sql`LIMIT ${params.limit};` : sql.EMPTY}
    `;

    return statement;
  }

  // IMPORTANT!! When fetching this query we don't apply row level security rules
  // so we must only return records which the user has permission to view.
  getInboxNotificationEntries(params: GetInboxNotificationEntriesParams): Query<"inbox_entry"> {
    return {
      primaryTable: "inbox_entry",
      statements: () => {
        const statement = this.#inboxNotificationEntriesStatement(false, params);
        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(
          result,
          "inbox_entry",
          "notification",
          { table: "thread", canAuthorizationFail: true },
          "draft",
          { table: "tag", as: "inbox_subsection" },
          "message",
        ),
      ],
      subscriptionKeys: (recordMap) => [
        `draft:user_id:${params.currentUserId}`,
        `notification:user_id:${params.currentUserId}`,
        `tag:data:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getInboxSections(params: GetInboxSectionsParams): Query<"tag"> {
    return {
      primaryTable: "tag",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        const statements: Statement[] = [];

        statements.push(
          sql`
            SELECT
              *
            FROM
              "tag"
            WHERE
              "tag"."type" = ${SpecialTagTypeEnum.INBOX_SECTION}
            AND
              "tag"."deleted_at" IS NULL
            AND
              "tag"."data" ->> 'user_id' = ${params.currentUserId}
            ORDER BY
              CAST("tag"."data" ->> 'order' as Integer) ASC
            ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`,
        );

        return statements;
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("tag", result)],
      subscriptionKeys: (recordMap) => [
        `tag:data:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getInboxSubsections(params: GetInboxSubsectionsParams): Query<"tag"> {
    return {
      primaryTable: "tag",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "tag"
          WHERE
            "tag"."type" = ${SpecialTagTypeEnum.INBOX_SUBSECTION}
          AND
            "tag"."deleted_at" IS NULL
          AND
            "tag"."data" ->> 'user_id' = ${params.currentUserId}
          ${params.inboxSectionId ? sql`AND "tag"."data" ->> 'inbox_section_id' = ${params.inboxSectionId}` : sql.EMPTY}
          ORDER BY
            CAST("tag"."data" ->> 'order' as Integer) ASC
          ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY}
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("tag", result)],
      subscriptionKeys: (recordMap) => [
        `tag:data:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getLastMessageInThread(params: GetLastMessageInThreadParams): Query<"message"> {
    return {
      primaryTable: "message",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "message"
          WHERE
            "message"."thread_id" = ${params.threadId}
          AND
            "message"."deleted_at" IS NULL
          ORDER BY
            "message"."timeline_order" DESC
          LIMIT
            1;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("message", result)],
      subscriptionKeys: (recordMap) => [
        `message:thread_id:${params.threadId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  // Used by the reindexInboxSections job. Since it's used for reindexing
  // notifications, we also want to grab deleted notifications.
  getNotificationsDeliveredOnOrBefore(params: GetNotificationsDeliveredOnOrBeforeParams): Query<"notification"> {
    return {
      primaryTable: "notification",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "notification"
          WHERE
            "notification"."user_id" = ${params.userId}
          AND
            "notification"."is_delivered" = true
          AND
            "notification"."delivered_at" <= ${params.deliveredOnOrBefore}
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "notification",
            sortOn: "delivered_at",
            sortDirection: "DESC",
            startAfter: params.startAfter,
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("notification", result)],
      subscriptionKeys: (recordMap) => [
        `notification:user_id:${params.userId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getNotificationsWithDueReminders(params: GetNotificationsWithDueRemindersParams): Query<"notification"> {
    if (this.props.engine !== "POSTGRES") {
      throw new Error(`getNotificationsWithDueReminders: only supported on server`);
    }

    return {
      primaryTable: "notification",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "notification"
          WHERE
            "notification"."deleted_at" IS NULL
          AND
            "notification"."has_reminder" = true
          AND
            "notification"."remind_at" <= CURRENT_TIMESTAMP
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "notification",
            sortOn: "remind_at",
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("notification", result)],
      subscriptionKeys: () => {
        // We'd need to filter by user to support subscriptions
        throw new Error(`getNotificationsWithDueReminders: subscriptions not supported`);
      },
    };
  }

  getOrganizationByControlledDomain(params: GetOrganizationByControlledDomainParams): Query<"organization_profile"> {
    return {
      primaryTable: "organization_profile",
      statements: () => {
        const statement = sql`
          SELECT
          ${getNamespacedTableColumnsForSelect(
            "organization_controlled_domain",
            "organization",
            "organization_profile",
          )}
          FROM
            "organization_controlled_domain"
          JOIN
            "organization" ON "organization"."id" = "organization_controlled_domain"."organization_id"
          JOIN
            "organization_profile" ON "organization_profile"."id" = "organization_controlled_domain"."organization_id"
          WHERE
            "organization_controlled_domain"."domain" = ${params.domain}
          AND
            "organization_controlled_domain"."deleted_at" IS NULL
          AND
            "organization"."deleted_at" IS NULL
          LIMIT
            1;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(
          result,
          "organization_controlled_domain",
          "organization",
          "organization_profile",
        ),
      ],
      subscriptionKeys: (recordMap) => [
        `organization_controlled_domain:domain:${params.domain}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getOrganizations(_params: {}): Query<"organization"> {
    if (this.props.engine !== "POSTGRES") {
      throw new Error(`getOrganizations is currently only supported on the server`);
    }

    return {
      primaryTable: "organization",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "organization"
          WHERE
            "organization"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "organization",
            sortOn: "created_at",
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("organization", result)],
      subscriptionKeys: () => {
        throw new Error("getOrganizations: subscriptions not supported");
      },
    };
  }

  getOrganizationUsers(params: GetOrganizationUsersParams): Query<"user_profile"> {
    return {
      primaryTable: "user_profile",
      statements: () => {
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("user_profile", "organization_user_member")}
          FROM
            "organization_user_member"
          JOIN
            "user_profile" ON "user_profile"."id" = "organization_user_member"."user_id"
          WHERE
            "organization_user_member"."organization_id" = ${params.organizationId}
          ${
            params.includeSoftDeletes === true ? sql.EMPTY
            : params.isDeleted ?
              sql`
                AND (
                  "organization_user_member"."deleted_at" IS NOT NULL
                  OR "user_profile"."deleted_at" IS NOT NULL
                )
              `
            : sql`
                AND "organization_user_member"."deleted_at" IS NULL
                AND "user_profile"."deleted_at" IS NULL
              `
          }
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "user_profile",
            sortOn: "name",
            limit: params.limit,
          })};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(result, "user_profile", "organization_user_member"),
      ],
      subscriptionKeys: (recordMap) => [
        `organization_user_member:organization_id:${params.organizationId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  /**
   * This is a client-only query to get all the messages which the user has "sent" but which
   * haven't yet been synced to the server.
   */
  getPendingSentMessages(params: {
    // Note that this param isn't actually used by the query. But by requiring a param,
    // we can easily create a decoder which always rejects the query on the server.
    currentUserId: string | null | undefined;
    threadId?: string;
    limit?: number;
  }): Query<"message"> {
    if (this.props.engine !== "SQLITE") {
      throw new Error(`getPendingSentMessages: only supported on client`);
    }

    return {
      primaryTable: "message",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "message"
          WHERE
            "message"."deleted_at" IS NULL
          AND (
            "message"."server_updated_at" IS NULL
            OR "message"."server_updated_at" < "message"."last_edited_at"
          )
          ${params.threadId ? sql`AND "message"."thread_id" = ${params.threadId}` : sql.EMPTY}
          ORDER BY
            "message"."sent_at" DESC,
            "message"."scheduled_to_be_sent_at" DESC,
            "message"."id"
          ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("message", result)],
      // We're only returning messages from the local cache which have pending updates
      // in the transaction queue. Because of this, we don't need to subscribe to any subscription
      // keys. As the transactions sync or are rejected, the client will naturally download the
      // cache updates.
      subscriptionKeys: () => [],
    };
  }

  getPersonalLabels(params: GetPersonalLabelsParams): Query<"tag"> {
    return {
      primaryTable: "tag",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("tag", "tag_user_member")}
          FROM
            "tag_user_member"
          JOIN
            "tag" ON "tag"."id" = "tag_user_member"."tag_id"
          WHERE
            "tag_user_member"."user_id" = ${params.currentUserId}
          AND
            "tag"."type" = ${SpecialTagTypeEnum.LABEL}
          ${params.isDeleted ? sql`AND "tag"."deleted_at" IS NOT NULL` : sql`AND "tag"."deleted_at" IS NULL`}
          ${params.isArchived ? sql`AND "tag"."archived_at" IS NOT NULL` : sql`AND "tag"."archived_at" IS NULL`}
          AND
            "tag_user_member"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "tag",
            sortOn: "name",
          })};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "tag", "tag_user_member")],
      subscriptionKeys: (recordMap) => [
        `tag_user_member:user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getSentMessagesForSearchIndex(params: GetSentMessagesForSearchIndexParams): Query<"message"> {
    if (this.props.engine !== "POSTGRES") {
      throw new Error("getSentMessagesForSearchIndex is only supported on the server");
    }

    return {
      primaryTable: "message",
      statements: () => {
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("message", "thread")}
          FROM
            "message"
          JOIN
            "thread" ON (
              "thread"."id" = "message"."thread_id"
              AND "thread"."deleted_at" IS NULL
            )
          WHERE
            "message"."owner_organization_id" = ${params.organizationId}
          AND
            "message"."sent_at" > ${params.since}
          AND
            "message"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "message",
            sortOn: "sent_at",
            sortDirection: "ASC",
            limit: params.limit,
          })};
        `;
        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "message", "thread")],
      subscriptionKeys: () => [`message:owner_organization_id:${params.organizationId}`],
    };
  }

  getSentMessages(params: GetSentMessagesParams): Query<"message"> {
    return {
      primaryTable: "message",
      statements: () => {
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("message", "thread", "notification")}
          FROM
            "message"
          JOIN
            "thread" ON "thread"."id" = "message"."thread_id"
          LEFT JOIN
            "notification" ON (
              "notification"."thread_id" = "message"."thread_id"
              AND "notification"."user_id" = ${params.userId}
            )
          WHERE
            "message"."sender_user_id" = ${params.userId}
          AND
            "message"."deleted_at" IS NULL
          ${params.threadId ? sql`AND "message"."thread_id" = ${params.threadId}` : sql.EMPTY}
          ${params.sentAfter ? sql`AND "message"."sent_at" > ${params.sentAfter}` : sql.EMPTY}
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "message",
            sortOn: "timeline_order",
            sortDirection: "DESC",
            limit: params.limit,
          })};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "message", "thread", "notification")],
      subscriptionKeys: (recordMap) => [
        `message:sender_user_id:${params.userId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getTagFolderMembersUserHasAccessTo(params: GetTagFolderMembersUserHasAccessToParams): Query<"tag_folder_member"> {
    return {
      primaryTable: "tag_folder_member",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        // Currently, in order to properly create the subscriptionKeys for this query we need to also
        // return all tags which the user has access to. This is unfortunate, but at the moment
        // "all tags" isn't a huge list (~300) so it's not the end of the world. The problem is that,
        // if a new tag_folder_member is created, we don't have a good way of subscribing to that.
        // We can subscribe to tag updates for the current user though. So we get all the tags,
        // subscribe to updates, and also use the tags which the user has access to to subscribe
        // to the tag_folder_members which the user has access to.
        if (this.props.engine === "POSTGRES") {
          return [
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}

              SELECT 
                ${getNamespacedTableColumnsForSelect("tag", "tag_folder_member")}
              FROM 
                "distinct_tag_ids_of_user"
              JOIN
                "tag" ON "tag"."id" = "distinct_tag_ids_of_user"."tag_id"
              JOIN
                "tag_folder_member" ON "tag_folder_member"."tag_id" = "distinct_tag_ids_of_user"."tag_id"
              WHERE
                "tag_folder_member"."deleted_at" IS NULL;
            `,
          ];
        } else if (this.props.engine === "SQLITE") {
          return [
            sql`
              SELECT 
                ${getNamespacedTableColumnsForSelect("tag", "tag_folder_member")}
              FROM 
                "tag_folder_member"
              JOIN
                "tag" ON "tag"."id" = "tag_folder_member"."tag_id"
              WHERE
                "tag_folder_member"."deleted_at" IS NULL;
            `,
          ];
        } else {
          throw new UnreachableCaseError(this.props.engine);
        }
      },
      parseQueryResults: ([results]) => [this.#parseNamespacedDatabaseRows(results, "tag", "tag_folder_member")],
      subscriptionKeys: (recordMap) => {
        const keys: string[] = [`tag_user_member:user_id:${params.currentUserId}`];

        if (!recordMap) return keys;

        const recordSubscriptionKeys = this.#getSubscriptionKeysForRecords(recordMap);

        keys.push(...recordSubscriptionKeys);

        const tags = getMapRecords(recordMap, "tag");

        // For each `tag_folder_member.tag_id` we want to subscribe to all direct
        // tag_group_members of this tag_id. Below, we also subscribe to all
        // tag_user_member updates for the current user.
        tags.forEach((tag) => {
          keys.push(`tag_folder_member:tag_id:${tag.id}`);

          if (tag.type === SpecialTagTypeEnum.GROUP) {
            // This query may update if a group which the user is a member of is added
            // as a member of another group.
            keys.push(`tag_group_member:group_id:${tag.id}`);
          }
        });

        return keys;
      },
    };
  }

  // getTagIndirectGroupMembers(params: { tagId: string; currentUserId: string; limit?: number }): Query<"tag"> {
  //   // A bug in the library we use to parse the sql queries on the client causes an error to be thrown if
  //   // we use `WITH RECURSIVE` with multiple `WITH` clauses (like we do here). Because of this, this query
  //   // is not currently supported on the client.
  //   if (this.props.engine !== "POSTGRES") {
  //     throw new Error("getTagIndirectGroupMembers is only supported on the server");
  //   }

  //   return {
  //     primaryTable: "tag",
  //     statements: () => {
  //       const statement = sql`
  //         WITH RECURSIVE "non_distinct_groups_of_tag"("tag_id", "group_id") AS (
  //           SELECT
  //             "tag_group_member"."tag_id",
  //             "tag_group_member"."group_id"
  //           FROM
  //             "tag_group_member"
  //           WHERE
  //             "tag_group_member"."tag_id" = ${params.tagId}
  //           AND
  //             "tag_group_member"."deleted_at" IS NULL

  //           UNION ALL

  //           SELECT
  //             "tag_group_member"."tag_id",
  //             "tag_group_member"."group_id"
  //           FROM
  //             "non_distinct_groups_of_tag"
  //           JOIN
  //             "tag_group_member" ON "tag_group_member"."tag_id" = "non_distinct_groups_of_tag"."group_id"
  //           WHERE
  //             "tag_group_member"."deleted_at" IS NULL
  //         ), "distinct_group_ids_of_tag"("group_id") AS (
  //           SELECT DISTINCT
  //             "group_id"
  //           FROM
  //             "non_distinct_groups_of_tag"
  //         )

  //         SELECT
  //           ${
  //             // We need to return the tag_group_member records so that the client has the information
  //             // necessary to reconstruct this query locally
  //             getNamespacedTableColumnsForSelect("tag", "tag_group_member")
  //           }
  //         FROM
  //           "distinct_group_ids_of_tag"
  //         JOIN
  //           "tag" ON "tag"."id" = "distinct_group_ids_of_tag"."group_id"
  //         JOIN
  //           "tag_group_member" ON "tag_group_member"."tag_id" = "distinct_group_ids_of_tag"."group_id"
  //         WHERE
  //           "tag"."deleted_at" IS NULL
  //         ${getOrderByWithStartAtEndAtAndLimitSql({
  //           table: "tag",
  //           sortOn: "name",
  //           sortDirection: "ASC",
  //           limit: params.limit,
  //         })};
  //       `;

  //       return [statement];
  //     },
  //     parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "tag", "tag_group_member")],
  //     subscriptionKeys: (recordMap) => {
  //       // We need to use the query results to determine the full subscription keys
  //       if (!recordMap) {
  //         return [`tag_user_member:user_id:${params.currentUserId}`];
  //       }

  //       const recordSubscriptionKeys = this.#getSubscriptionKeysForRecords(recordMap);

  //       const groups = getMapRecords(recordMap, "tag");

  //       // For each group we want to subscribe to all tag_group_members
  //       // of this group. Below, we also subscribe to the tag_user_member records
  //       // for the current user.
  //       const querySubscriptionKeys = groups.map((group) => {
  //         return `tag_group_member:tag_id:${group.id}`;
  //       });

  //       return [`tag_user_member:user_id:${params.currentUserId}`, ...querySubscriptionKeys, ...recordSubscriptionKeys];
  //     },
  //   };
  // }

  // /**
  //  * Includes direct and indirect tag user members.
  //  */
  // getTagIndirectUserMembers(params: { tagId: string; limit?: number }): Query<"user_profile"> {
  //   // A bug in the library we use to parse the sql queries on the client causes an error to be thrown if
  //   // we use `WITH RECURSIVE` with multiple `WITH` clauses (like we do here). Because of this, this query
  //   // is not supported on the client.
  //   if (this.props.engine !== "POSTGRES") {
  //     throw new Error("getTagIndirectUserMembers is only supported on the server");
  //   }

  //   return {
  //     primaryTable: "user_profile",
  //     statements: () => {
  //       const statement = sql`
  //         WITH RECURSIVE "non_distinct_groups_of_tag"("tag_id", "group_id") AS (
  //           SELECT
  //             "tag_group_member"."tag_id",
  //             "tag_group_member"."group_id"
  //           FROM
  //             "tag_group_member"
  //           WHERE
  //             "tag_group_member"."tag_id" = ${params.tagId}
  //           AND
  //             "tag_group_member"."deleted_at" IS NULL

  //           UNION ALL

  //           SELECT
  //             "tag_group_member"."tag_id",
  //             "tag_group_member"."group_id"
  //           FROM
  //             "non_distinct_groups_of_tag"
  //           JOIN
  //             "tag_group_member" ON "tag_group_member"."tag_id" = "non_distinct_groups_of_tag"."group_id"
  //           WHERE
  //             "tag_group_member"."deleted_at" IS NULL
  //         ), "distinct_group_ids_of_tag"("group_id") AS (
  //           SELECT DISTINCT
  //             "group_id"
  //           FROM
  //             "non_distinct_groups_of_tag"
  //         ), "non_distinct_users_of_tag"("user_id") AS (
  //           SELECT
  //             "tag_user_member"."user_id"
  //           FROM
  //             "non_distinct_groups_of_tag"
  //           JOIN
  //             "tag_user_member" ON "tag_user_member"."tag_id" = "non_distinct_groups_of_tag"."group_id"
  //           WHERE
  //             "tag_user_member"."deleted_at" IS NULL

  //           UNION ALL

  //           SELECT
  //             "tag_user_member"."user_id"
  //           FROM
  //             "tag_user_member"
  //           WHERE
  //             "tag_user_member"."tag_id" = ${params.tagId}
  //           AND
  //             "tag_user_member"."deleted_at" IS NULL
  //         ), "distinct_user_ids_of_tag"("user_id") AS (
  //           SELECT DISTINCT
  //             "user_id"
  //           FROM
  //             "non_distinct_users_of_tag"
  //         )

  //         SELECT
  //           "user_profile".*
  //         FROM
  //           "distinct_user_ids_of_tag"
  //         JOIN
  //           "user_profile" ON "user_profile"."id" = "distinct_user_ids_of_tag"."user_id"
  //         WHERE
  //           "user_profile"."deleted_at" IS NULL
  //         ${getOrderByWithStartAtEndAtAndLimitSql({
  //           table: "user_profile",
  //           sortOn: "name",
  //           sortDirection: "ASC",
  //           limit: params.limit,
  //         })};
  //       `;

  //       return [statement];
  //     },
  //     parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "user_profile")],
  //     subscriptionKeys: () => {
  //       throw new Error(
  //         "[getTagIndirectUserMembers] [subscriptionKeys] subscribing to this query isn't currently supported",
  //       );
  //     },
  //   };
  // }

  /**
   * Results are ordered first by subscription preference and then by user name.
   */
  getTagSubscriberUsers(params: GetTagSubscriberUsersParams): Query<"user_profile"> {
    return {
      primaryTable: "user_profile",
      statements: () => {
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("user_profile", "tag_subscription")}
          FROM
            "tag_subscription"
          JOIN
            "user_profile" ON "user_profile"."id" = "tag_subscription"."user_id"
          WHERE
            "tag_subscription"."deleted_at" IS NULL
          AND
            "tag_subscription"."tag_id" = ${params.tagId}
          ${
            params.subscriptionPreference ?
              sql`AND "tag_subscription"."preference" = ${params.subscriptionPreference}`
            : sql.EMPTY
          }
          AND
            "user_profile"."deleted_at" IS NULL
          ORDER BY
            CASE "tag_subscription"."preference"
              WHEN ${TagSubscriptionPreferenceEnum.ALL} THEN 1
              WHEN ${TagSubscriptionPreferenceEnum.ALL_NEW} THEN 2
              WHEN ${TagSubscriptionPreferenceEnum.INVOLVED} THEN 3
              ELSE 4
            END,
            "user_profile"."name" ASC
          ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "user_profile", "tag_subscription")],
      subscriptionKeys: (recordMap) => [
        `tag_subscription:tag_id:${params.tagId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getTagsUserHasAccessTo(params: GetTagsUserHasAccessToParams): Query<"tag"> {
    return {
      primaryTable: "tag",
      resultsAreAlreadyAuthorized: true,
      statements: () => {
        const statements: Statement[] = [];

        if (this.props.engine === "POSTGRES") {
          // We need to return all results from postgres in order to properly create the
          // subscriptionKeys for this query (which sucks). But at the moment "all results"
          // isn't a huge list (<300) so it's not the end of the world.
          statements.push(
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}
              
              SELECT
                "tag".*
              FROM
                "distinct_tag_ids_of_user"
              JOIN
                "tag" ON "tag"."id" = "distinct_tag_ids_of_user"."tag_id"
              WHERE
                "tag"."deleted_at" IS NULL;
            `,
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}
              
              SELECT
                "tag_user_member".*
              FROM
                "distinct_tag_ids_of_user"
              JOIN
                "tag_user_member" ON "tag_user_member"."tag_id" = "distinct_tag_ids_of_user"."tag_id"
              WHERE
                "tag_user_member"."deleted_at" IS NULL;
            `,
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}
              
              SELECT
                "tag_group_member".*
              FROM
                "distinct_tag_ids_of_user"
              JOIN
                "tag_group_member" ON "tag_group_member"."tag_id" = "distinct_tag_ids_of_user"."tag_id"
              WHERE
                "tag_group_member"."deleted_at" IS NULL;
            `,
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}
              
              SELECT
                "tag_folder_member".*
              FROM
                "distinct_tag_ids_of_user"
              JOIN
                "tag_folder_member" ON "tag_folder_member"."tag_id" = "distinct_tag_ids_of_user"."tag_id"
              WHERE
                "tag_folder_member"."deleted_at" IS NULL;
            `,
          );
        } else if (this.props.engine === "SQLITE") {
          statements.push(
            sql`
              SELECT
                *
              FROM
                "tag"
              WHERE
                "tag"."deleted_at" IS NULL
              ${getOrderByWithStartAndEndAndLimitSql({
                table: "tag",
                sortOn: params.orderBy || "name",
                sortDirection: "ASC",
              })};
            `,
            sql`
              SELECT
                *
              FROM
                "tag_user_member"
              WHERE
                "tag_user_member"."deleted_at" IS NULL;
            `,
            sql`
              SELECT
                *
              FROM
                "tag_group_member"
              WHERE
                "tag_group_member"."deleted_at" IS NULL;
            `,
            sql`
              SELECT
                *
              FROM
                "tag_folder_member"
              WHERE
                "tag_folder_member"."deleted_at" IS NULL;
            `,
          );
        } else {
          throw new UnreachableCaseError(this.props.engine);
        }

        return statements;
      },
      parseQueryResults: ([tags, userMembers, groupMembers, folderMembers]) => [
        this.#parseDatabaseRows("tag", tags),
        this.#parseDatabaseRows("tag_user_member", userMembers),
        this.#parseDatabaseRows("tag_group_member", groupMembers),
        this.#parseDatabaseRows("tag_folder_member", folderMembers),
      ],
      subscriptionKeys: (recordMap) => {
        // We need to use the query results to determine the full subscription keys
        if (!recordMap) {
          return [`tag_user_member:user_id:${params.currentUserId}`, `tag:type:${SpecialTagTypeEnum.SINGLETON}`];
        }

        const recordSubscriptionKeys = this.#getSubscriptionKeysForRecords(recordMap);

        const tags = getMapRecords(recordMap, "tag");

        // For each tag we want to subscribe to all direct tag_user_members,
        // tag_group_members, and tag_folder_members of this tag.
        const querySubscriptionKeys = tags.flatMap((tag) => {
          return [
            `tag_user_member:tag_id:${tag.id}`,
            `tag_group_member:tag_id:${tag.id}`,
            `tag_folder_member:tag_id:${tag.id}`,
          ];
        });

        return [
          `tag_user_member:user_id:${params.currentUserId}`,
          `tag:type:${SpecialTagTypeEnum.SINGLETON}`,
          ...querySubscriptionKeys,
          ...recordSubscriptionKeys,
        ];
      },
    };
  }

  getTagViewThreads(params: GetTagViewThreadsParams): Query<"thread"> {
    return {
      primaryTable: "thread",
      statements: () => {
        if (this.props.engine === "POSTGRES") {
          // As a performance optimization, we only return threads which we know that the current user
          // has permission to view. Note that withDistinctTagIdsOfUserStatement can only be run on
          // the server. See the comment in the function for more information.
          return [
            sql`
              ${this.#withDistinctTagIdsOfUserStatement(false, params)}

              SELECT 
                ${getNamespacedTableColumnsForSelect("thread", "thread_tag", "message", "user_profile")}
              FROM
                "distinct_tag_ids_of_user"
              JOIN
                "thread_group_permission" ON (
                  "thread_group_permission"."group_id" = "distinct_tag_ids_of_user"."tag_id"
                  AND "thread_group_permission"."deleted_at" IS NULL
                )
              JOIN
                "thread_tag" ON (
                  "thread_tag"."thread_id" = "thread_group_permission"."thread_id"
                  AND "thread_tag"."deleted_at" IS NULL
                )
              JOIN 
                "thread" ON (
                  "thread"."id" = "thread_group_permission"."thread_id"
                  AND "thread"."deleted_at" IS NULL
                )
              JOIN
                "message" ON "message"."id" = "thread"."last_message_id"
              LEFT JOIN
                "user_profile" ON "user_profile"."id" = "message"."sender_user_id"
              WHERE 
                "thread_tag"."tag_id" = ${params.tagId}
              ${getOrderByWithStartAndEndAndLimitSql({
                table: "thread",
                sortOn: "last_message_timeline_order",
                sortDirection: params.sortDirection,
                startAt: params.startAt,
                limit: params.limit,
              })};
            `,
          ];
        } else {
          return [
            sql`
              SELECT 
                ${getNamespacedTableColumnsForSelect("thread", "thread_tag", "message", "user_profile")}
              FROM
                "thread_tag"
              JOIN 
                "thread" ON "thread"."id" = "thread_tag"."thread_id"
              JOIN
                "message" ON "message"."id" = "thread"."last_message_id"
              LEFT JOIN
                "user_profile" ON "user_profile"."id" = "message"."sender_user_id"
              WHERE 
                "thread_tag"."tag_id" = ${params.tagId}
              AND
                "thread_tag"."deleted_at" IS NULL
              AND
                "thread"."deleted_at" IS NULL
              ${getOrderByWithStartAndEndAndLimitSql({
                table: "thread",
                sortOn: "last_message_timeline_order",
                sortDirection: params.sortDirection,
                startAt: params.startAt,
                limit: params.limit,
              })};
            `,
          ];
        }
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(result, "thread", "thread_tag", "message", {
          table: "user_profile",
          canAuthorizationFail: true,
        }),
      ],
      // TODO:
      // These subscription keys don't account for a user getting access to
      // new tags.
      subscriptionKeys: (recordMap) => [
        `thread_tag:tag_id:${params.tagId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getThreadsGroupPermissions(params: GetThreadsGroupPermissionsParams): Query<"thread_group_permission"> {
    return {
      primaryTable: "thread_group_permission",
      statements: () => {
        if (params.threadIds.length === 0) {
          // Note that we cannot simplly return an empty array as that will cause some downstream
          // issues when we attempt to parseQueryResults.
          throw new Error("[getThreadsGroupPermissions] threadIds are required");
        }

        const statement = sql`
          SELECT
            *
          FROM
            "thread_group_permission"
          WHERE
            "thread_group_permission"."thread_id" IN (${sql.join(params.threadIds)})
          AND
            "thread_group_permission"."deleted_at" IS NULL;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("thread_group_permission", result)],
      subscriptionKeys: (recordMap) => [
        ...params.threadIds.map((id) => `thread_group_permission:thread_id:${id}`),
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getThreadsWithUndeliveredMessages(params: GetThreadsWithUndeliveredMessagesParams): Query<"thread"> {
    if (this.props.engine !== "POSTGRES") {
      // At time of writing, the only use case of this query is on the server.
      throw new Error("getThreadsWithUndeliveredMessages: only supported on the server");
    }

    return {
      primaryTable: "thread",
      statements: () => {
        const statement = sql`
          WITH "message_thread_ids" AS (
            SELECT
              "message"."thread_id"
            FROM
              "message"
            WHERE
              "message"."is_delivered" = false
            AND
              "message"."sent_at" <= CURRENT_TIMESTAMP
            AND
              "message"."deleted_at" IS NULL
            ORDER BY
              "message"."is_delivered" ASC,
              "message"."sent_at" ASC
          ), "distinct_thread_ids" AS (
            SELECT DISTINCT
              "message_thread_ids"."thread_id"
            FROM
              "message_thread_ids"
            ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY}
          )
          
          SELECT
            "thread".*
          FROM
            "distinct_thread_ids"
          JOIN
            "thread" ON "thread"."id" = "distinct_thread_ids"."thread_id"
          WHERE
            "thread"."deleted_at" IS NULL;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("thread", result)],
      subscriptionKeys: () => {
        // currently this is only used on the server
        throw new Error("getThreadsWithUndeliveredMessages: subscriptions not supported");
      },
    };
  }

  getThreadTimelineEntries(params: GetThreadTimelineEntriesParams): Query<"thread_timeline"> {
    return {
      primaryTable: "thread_timeline",
      statements: () => {
        const statement = sql`
          SELECT 
            * 
          FROM
            "thread_timeline"
          WHERE 
            "thread_timeline"."thread_id" = ${params.thread_id}
          AND
            "thread_timeline"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "thread_timeline",
            sortOn: "order",
            sortDirection: "ASC",
            startAt: params.startAt,
            endAt: params.endAt,
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("thread_timeline", result)],
      subscriptionKeys: (recordMap) => [
        `thread_timeline:thread_id:${params.thread_id}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getTrashViewThreads(params: GetTrashViewThreadsParams): Query<"thread"> {
    return {
      primaryTable: "thread",
      statements: () => {
        return [
          sql`
            SELECT 
              ${getNamespacedTableColumnsForSelect("thread", "message", "user_profile")}
            FROM
              "thread"
            JOIN
              "message" ON "message"."id" = "thread"."last_message_id"
            LEFT JOIN
              "user_profile" ON "user_profile"."id" = "message"."sender_user_id"
            WHERE
              "thread"."deleted_by_user_id" = ${params.currentUserId}
            ${getOrderByWithStartAndEndAndLimitSql({
              table: "thread",
              sortOn: "deleted_at",
              sortDirection: params.sortDirection,
              startAt: params.startAt,
              limit: params.limit,
            })};
          `,
        ];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(result, "thread", "message", {
          table: "user_profile",
          canAuthorizationFail: true,
        }),
      ],
      subscriptionKeys: (recordMap) => [
        `thread:deleted_by_user_id:${params.currentUserId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getUndeliveredMessageJobRecords(params: GetUndeliveredMessageJobRecordsParams): Query<"message"> {
    if (this.props.engine !== "POSTGRES") {
      throw new Error("getUndeliveredMessageJobRecords: only supported on server");
    }

    return {
      primaryTable: "message",
      statements: () => {
        const messageSql = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("message", "user_settings")}
          FROM
            "message"
          LEFT JOIN
            "user_settings" ON "user_settings"."id" = "message"."sender_user_id"
          WHERE
            "message"."id" = ${params.messageId}
          AND
            "message"."is_delivered" = false
          AND
            "message"."sent_at" <= CURRENT_TIMESTAMP
          AND
            "message"."deleted_at" IS NULL
          LIMIT
            1;
        `;

        const threadSql = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("thread", {
              table: "message",
              as: "branched_message",
            })}
          FROM
            "thread"
          LEFT JOIN
            "message" as "branched_message" ON "branched_message"."id" = "thread"."branched_from_message_id"
          WHERE
            "thread"."id" = ${params.threadId}
          AND
            "thread"."deleted_at" IS NULL
          LIMIT 1
        `;

        const withDistinctThreadTagIdsSql = sql`
          "thread_tag_ids" AS (
            SELECT
              "thread_group_permission"."group_id" as "tag_id"
            FROM
              "thread_group_permission"
            WHERE
              "thread_group_permission"."thread_id" = ${params.threadId}
            AND
              "thread_group_permission"."deleted_at" IS NULL
            
            UNION ALL

            SELECT
              "message_recipient"."group_id" as "tag_id"
            FROM
              "message",
              jsonb_to_recordset("message"."to") as "message_recipient"(
                id uuid, 
                type text, 
                group_id uuid, 
                priority int, 
                is_implicit boolean, 
                is_mentioned boolean
              )
            WHERE
              "message"."id" = ${params.messageId}
            AND
              "message_recipient"."type" = 'GROUP'        
          ), "distinct_thread_tag_ids" AS (
            SELECT DISTINCT
              "thread_tag_ids"."tag_id"
            FROM
              "thread_tag_ids"
          )
        `;

        const tagsSql = sql`
          WITH ${withDistinctThreadTagIdsSql}

          SELECT
            "tag".*
          FROM
            "distinct_thread_tag_ids"
          JOIN
            "tag" ON "tag"."id" = "distinct_thread_tag_ids"."tag_id"
          WHERE
            "tag"."deleted_at" IS NULL
        `;

        const tagSubscriptionsSql = sql`
          WITH ${withDistinctThreadTagIdsSql}

          SELECT
            "tag_subscription".*
          FROM
            "distinct_thread_tag_ids"
          JOIN
            "tag_subscription" ON "tag_subscription"."tag_id" = "distinct_thread_tag_ids"."tag_id"
          WHERE
            "tag_subscription"."deleted_at" IS NULL
        `;

        const threadSubscriptionsSql = sql`
          SELECT
            *
          FROM
            "thread_subscription"
          WHERE
            "thread_subscription"."thread_id" = ${params.threadId}
          AND
            "thread_subscription"."deleted_at" IS NULL
        `;

        const threadParticipantsSql = sql`
          SELECT
            *
          FROM
            "thread_user_participant"
          WHERE
            "thread_user_participant"."thread_id" = ${params.threadId}
          AND
            "thread_user_participant"."deleted_at" IS NULL
        `;

        const threadUserPermissionsSql = sql`
          SELECT
            *
          FROM
            "thread_user_permission"
          WHERE
            "thread_user_permission"."thread_id" = ${params.threadId}
          AND
            "thread_user_permission"."deleted_at" IS NULL
        `;

        const threadGroupsSql = sql`
          SELECT
            *
          FROM
            "thread_group_permission"
          WHERE
            "thread_group_permission"."thread_id" = ${params.threadId}
          AND
            "thread_group_permission"."deleted_at" IS NULL
        `;

        const existingThreadNotificationsSql = sql`
          SELECT
            *
          FROM
            "notification"
          WHERE
            "notification"."thread_id" = ${params.threadId}
          AND
            "notification"."deleted_at" IS NULL
        `;

        const userProfilesSql = sql`
          WITH ${withDistinctThreadTagIdsSql},
          "user_ids" AS (
            SELECT
              "thread_user_participant"."user_id"
            FROM
              "thread_user_participant"
            WHERE
              "thread_user_participant"."thread_id" = ${params.threadId}
            AND
              "thread_user_participant"."deleted_at" IS NULL

            UNION ALL

            SELECT
              "message_recipient"."user_id"
            FROM
              "message",
              jsonb_to_recordset("message"."to") as "message_recipient"(
                id uuid, 
                type text, 
                user_id uuid, 
                priority int, 
                is_implicit boolean, 
                is_mentioned boolean
              )
            WHERE
              "message"."id" = ${params.messageId}
            AND
              "message"."deleted_at" IS NULL
            AND
              "message_recipient"."type" = 'USER'        

            UNION ALL

            SELECT
              "tag_subscription"."user_id"
            FROM
              "distinct_thread_tag_ids"
            JOIN
              "tag_subscription" ON "tag_subscription"."tag_id" = "distinct_thread_tag_ids"."tag_id"
            WHERE
              "tag_subscription"."deleted_at" IS NULL

            UNION ALL

            SELECT
              "thread_subscription"."user_id"
            FROM
              "thread_subscription"
            WHERE
              "thread_subscription"."thread_id" = ${params.threadId}
            AND
              "thread_subscription"."deleted_at" IS NULL
          )
          
          SELECT
            ${getNamespacedTableColumnsForSelect("user", "user_profile", "user_settings", "organization_user_member")}
          FROM
            "user_profile"
          JOIN
            "user" ON "user"."id" = "user_profile"."id"
          JOIN
            "user_settings" ON "user_settings"."id" = "user_profile"."id"
          LEFT JOIN
            "organization_user_member" ON (
              "organization_user_member"."user_id" = "user_profile"."id"
              AND "organization_user_member"."organization_id" = "user_profile"."owner_organization_id"
            )
          WHERE
            "user"."deleted_at" IS NULL
          AND
            "user_profile"."deleted_at" IS NULL
          AND
            "user_settings"."deleted_at" IS NULL
          AND
            "user_profile"."id" IN (
              SELECT DISTINCT
                "user_ids"."user_id"
              FROM
                "user_ids"
            );
        `;

        return [
          messageSql,
          threadSql,
          tagsSql,
          tagSubscriptionsSql,
          threadSubscriptionsSql,
          threadParticipantsSql,
          threadUserPermissionsSql,
          threadGroupsSql,
          existingThreadNotificationsSql,
          userProfilesSql,
        ];
      },
      parseQueryResults: ([
        messageResult,
        threadResult,
        tagsResult,
        tagSubscriptionsResult,
        threadSubscriptionsResult,
        threadParticipantsResult,
        threadUserPermissionsResult,
        threadGroupsResult,
        existingThreadNotificationsResult,
        userProfilesResult,
      ]) => {
        return [
          this.#parseNamespacedDatabaseRows(messageResult, "message", "user_settings"),
          this.#parseNamespacedDatabaseRows(threadResult, "thread", {
            table: "message",
            as: "branched_message",
            canAuthorizationFail: true,
          }),
          this.#parseDatabaseRows("tag", tagsResult),
          this.#parseDatabaseRows("tag_subscription", tagSubscriptionsResult),
          this.#parseDatabaseRows("thread_subscription", threadSubscriptionsResult),
          this.#parseDatabaseRows("thread_user_participant", threadParticipantsResult),
          this.#parseDatabaseRows("thread_user_permission", threadUserPermissionsResult),
          this.#parseDatabaseRows("thread_group_permission", threadGroupsResult),
          this.#parseDatabaseRows("notification", existingThreadNotificationsResult),
          this.#parseNamespacedDatabaseRows(userProfilesResult, "user", "user_profile", "user_settings", {
            table: "organization_user_member",
            canAuthorizationFail: true,
          }),
        ];
      },
      subscriptionKeys: () => {
        throw new Error("getUndeliveredMessageJobRecords: subscription not supported");
      },
    };
  }

  getUndeliveredMessagesForThread(params: GetUndeliveredMessagesForThreadParams): Query<"message"> {
    return {
      primaryTable: "message",
      statements: () => {
        const messageSql = sql`
          SELECT
            *
          FROM
            "message"
          WHERE
            "message"."thread_id" = ${params.threadId}
          AND
            "message"."is_delivered" = false
          AND
            "message"."deleted_at" IS NULL
          AND
            "message"."sent_at" <= CURRENT_TIMESTAMP
          ORDER BY
            "message"."is_delivered" ASC,
            "message"."sent_at" ASC,
            "message"."scheduled_to_be_sent_at" ASC,
            "message"."id" ASC;
        `;

        const threadSql = sql`
          SELECT
            *
          FROM
            "thread"
          WHERE
            "thread"."id" = ${params.threadId}
          AND
            "thread"."deleted_at" IS NULL
          LIMIT 1
        `;

        return [messageSql, threadSql];
      },
      parseQueryResults: ([messageResult, threadResult]) => {
        return [this.#parseDatabaseRows("message", messageResult), this.#parseDatabaseRows("thread", threadResult)];
      },
      subscriptionKeys: (recordMap) => [
        `message:thread_id:${params.threadId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getUndeliveredNotifications(params: GetUndeliveredNotificationsParams): Query<"notification"> {
    return {
      primaryTable: "notification",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "notification"
          WHERE
            "notification"."deleted_at" IS NULL
          AND
            "notification"."is_delivered" = false
          ORDER BY
            "notification"."is_delivered" ASC,
            "notification"."user_id" ASC
          ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("notification", result)],
      subscriptionKeys: (recordMap) => [
        `notification:is_delivered:false`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getUserAuthTokens(params: GetUserAuthTokensParams): Query<"auth_token"> {
    return {
      primaryTable: "auth_token",
      statements: () => {
        const statement = sql`
          SELECT
            * 
          FROM
            "auth_token"
          WHERE
            "auth_token"."user_id" = ${params.userId}
          AND
            "auth_token"."expires_at" > CURRENT_TIMESTAMP
          AND
            "auth_token"."deleted_at" IS NULL;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("auth_token", result)],
      subscriptionKeys: (recordMap) => [
        `auth_token:user_id:${params.userId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getUserByEmail(params: GetUserByEmailParams): Query<"user_profile"> {
    return {
      primaryTable: "user_profile",
      statements: () => {
        // Note that "user"."email" is type citext
        // so this is case-insensitive
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("user", "user_profile")}
          FROM
            "user"
          JOIN
            "user_profile" ON "user_profile"."id" = "user"."id"
          WHERE
            "user"."email" = ${params.email}
          AND
            "user"."deleted_at" IS NULL
          AND
            "user_profile"."deleted_at" IS NULL
          LIMIT
            1;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseNamespacedDatabaseRows(result, "user", "user_profile")],
      subscriptionKeys: () => {
        // The subscription key for this would be `user:email:${params.email}`
        // but the `params.email` bit should be case-insensitive which Comms'
        // subscription logic currently doesn't support.
        throw new Error(`getUserByEmail: subscriptions not supported`);
      },
    };
  }

  getUserByFirebaseAuthId(params: GetUserByFirebaseAuthIdParams): Query<"user"> {
    return {
      primaryTable: "user",
      statements: () => {
        const statement = sql`
          SELECT
            * 
          FROM
            "user"
          WHERE
            "user"."firebase_auth_id" = ${params.firebaseId}
          AND
            "user"."deleted_at" IS NULL
          LIMIT
            1;
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("user", result)],
      subscriptionKeys: (recordMap) => [
        `user:firebase_auth_id:${params.firebaseId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getUserOrganizationProfiles(params: GetUserOrganizationProfilesParams): Query<"organization_profile"> {
    return {
      primaryTable: "organization_profile",
      statements: () => {
        const statement = sql`
          SELECT
            ${getNamespacedTableColumnsForSelect("organization_profile", "organization_user_member")}
          FROM
            "organization_profile"
          JOIN
            "organization_user_member" ON "organization_user_member"."organization_id" = "organization_profile"."id"
          WHERE
            "organization_user_member"."user_id" = ${params.userId}
          AND
            "organization_user_member"."deleted_at" IS NULL
          AND
            "organization_profile"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "organization_profile",
            sortOn: "name",
            startAt: params.startAt,
            sortDirection: "ASC",
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]) => [
        this.#parseNamespacedDatabaseRows(result, "organization_profile", "organization_user_member"),
      ],
      subscriptionKeys: (recordMap) => [
        `organization_user_member:user_id:${params.userId}`,
        ...this.#getSubscriptionKeysForRecords(recordMap),
      ],
    };
  }

  getUserProfiles(params: GetUserProfilesParams): Query<"user_profile"> {
    // We're not defining this as a "standard query method" because it's currently
    // expected that "standard query methods" filter on a property and this query
    // does not (i.e. there's no "WHERE" clause).
    if (this.props.engine !== "POSTGRES") {
      throw new Error("getUserProfiles: must be used on the server");
    }

    return {
      primaryTable: "user_profile",
      statements: () => {
        const statement = sql`
          SELECT
            *
          FROM
            "user_profile"
          WHERE
            "user_profile"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table: "user_profile",
            sortOn: "id",
            sortDirection: "ASC",
            startAt: params.startAt,
            endAt: params.endAt,
            limit: params.limit,
          })};
        `;

        return [statement];
      },
      parseQueryResults: ([result]) => [this.#parseDatabaseRows("user_profile", result)],
      subscriptionKeys: () => {
        throw new Error("getUserProfiles: subscriptions not supported");
      },
    };
  }

  getMessages(params: GetMessagesParams): Query<"message"> {
    return this.#simpleFilterableTableQuery({
      table: "message",
      params,
    });
  }

  getMessageReactions(params: GetMessageReactionsParams): Query<"message_reactions"> {
    return this.#simpleFilterableTableQuery({
      table: "message_reactions",
      params,
    });
  }

  getNotifications(params: GetNotificationsParams): Query<"notification"> {
    return this.#simpleFilterableTableQuery({
      table: "notification",
      params,
      orderBy: params.orderBy,
      orderDir: params.orderDir,
    });
  }

  getOrganizationControlledDomains(
    params: GetOrganizationControlledDomainsParams,
  ): Query<"organization_controlled_domain"> {
    return this.#simpleFilterableTableQuery({
      table: "organization_controlled_domain",
      params,
    });
  }

  getOrganizationUserInvitations(params: GetOrganizationUserInvitationsParams): Query<"organization_user_invitation"> {
    return this.#simpleFilterableTableQuery({
      table: "organization_user_invitation",
      params,
    });
  }

  getOrganizationUserMembers(params: GetOrganizationUserMembersParams): Query<"organization_user_member"> {
    return this.#simpleFilterableTableQuery({
      table: "organization_user_member",
      params,
    });
  }

  getTagFolderMembers(params: GetTagFolderMembersParams): Query<"tag_folder_member"> {
    return this.#simpleFilterableTableQuery({
      table: "tag_folder_member",
      params,
    });
  }

  getTagGroupMembers(params: GetTagGroupMembersParams): Query<"tag_group_member"> {
    return this.#simpleFilterableTableQuery({
      table: "tag_group_member",
      params,
    });
  }

  getTagSubscriptions(params: GetTagSubscriptionsParams): Query<"tag_subscription"> {
    return this.#simpleFilterableTableQuery({
      table: "tag_subscription",
      params,
    });
  }

  getTagUserMembers(params: GetTagUserMembersParams): Query<"tag_user_member"> {
    return this.#simpleFilterableTableQuery({
      table: "tag_user_member",
      params,
    });
  }

  getThreadGroupPermissions(params: GetThreadGroupPermissionsParams): Query<"thread_group_permission"> {
    return this.#simpleFilterableTableQuery({
      table: "thread_group_permission",
      params,
    });
  }

  getThreadSubscriptions(params: GetThreadSubscriptionsParams): Query<"thread_subscription"> {
    return this.#simpleFilterableTableQuery({
      table: "thread_subscription",
      params,
    });
  }

  getThreadTags(params: GetThreadTagsParams): Query<"thread_tag"> {
    return this.#simpleFilterableTableQuery({
      table: "thread_tag",
      params,
    });
  }

  getThreadUserParticipants(params: GetThreadUserParticipantsParams): Query<"thread_user_participant"> {
    return this.#simpleFilterableTableQuery({
      table: "thread_user_participant",
      params,
    });
  }

  getThreadUserPermissions(params: GetThreadUserPermissionsParams): Query<"thread_user_permission"> {
    return this.#simpleFilterableTableQuery({
      table: "thread_user_permission",
      params,
    });
  }

  getUserLessons(params: GetUserLessonsParams): Query<"user_lesson"> {
    return this.#simpleFilterableTableQuery({
      table: "user_lesson",
      params,
    });
  }

  getUserOAuths(params: GetUserOAuthsParams): Query<"user_oauth"> {
    return this.#simpleFilterableTableQuery({
      table: "user_oauth",
      params,
    });
  }

  getUserPushNotificationSubscriptions(
    params: GetUserPushNotificationSubscriptionsParams,
  ): Query<"user_push_notification_subscriptions"> {
    return this.#simpleFilterableTableQuery({
      table: "user_push_notification_subscriptions",
      params,
    });
  }

  // Note that, where appropriate, the SharedQueryApi uses native private methods
  // (i.e. beginning with "#") instead of typescript private/protected methods
  // so that the private methods are not enumerable. See the `serverDatabaseProps`
  // constant defined in ServerDatabase.ts.
  #simpleFilterableTableQuery<T extends TableHasFilterKeys>(args: {
    table: T;
    params: FilterableTableQueryParams<T>;
    orderBy?: keyof RecordValue<T> & string;
    orderDir?: "ASC" | "DESC";
  }): Query<T> {
    const { table, params, orderBy, orderDir = "ASC" } = args;

    return {
      primaryTable: table,
      statements: () => {
        const filterKeys = tableFilterKeys[table] as unknown as (keyof typeof params)[];

        const definedProps = getAndAssertDefinedParams(params, ...filterKeys);

        const statement = sql`
          SELECT 
            * 
          FROM
            "${sql.raw(table)}"
          WHERE 
            ${sql.join(
              definedProps.map(
                (prop) =>
                  sql`"${sql.raw(table)}"."${sql.raw(prop)}" ${params[prop] === null ? sql`IS NULL` : sql`= ${params[prop]}`}`,
              ),
              " AND ",
            )}
          AND
            "${sql.raw(table)}"."deleted_at" IS NULL
          ${getOrderByWithStartAndEndAndLimitSql({
            table,
            sortOn: orderBy || "id",
            sortDirection: orderDir,
            startAt: params.startAt,
            startAfter: params.startAfter,
            endAt: params.endAt,
            limit: params.limit,
          })};`;

        return [statement];
      },
      parseQueryResults: ([result]: RawResult[]) => [this.#parseDatabaseRows(table, result)],
      subscriptionKeys: (recordMap) => {
        const props = tableFilterKeys[table] as unknown as string[];
        const querySubscriptionKeys = props
          .filter((prop) => (params as any)[prop] !== undefined)
          .map((prop) => `${table}:${prop}:${(params as any)[prop]}`);

        return [...querySubscriptionKeys, ...this.#getSubscriptionKeysForRecords(recordMap)];
      },
    };
  }

  #withDistinctTagIdsOfUserStatement(isNested: boolean, params: { currentUserId: string }) {
    if (this.props.engine !== "POSTGRES") {
      // The `withDistinctTagIdsOfUserStatement` is intended as an authorization check so
      // 1. It doesn't need to be run on the client. Instead, the client can just trust that
      //    whatever records it has access to it has permission to view.
      // 2. On the client reactivity in sqlite is achieved by parsing queries to determine the
      //    affected tables, then rerunning the queries whenever one of the affected tables
      //    changes. The parser we use for this is `pgsql-ast-parser` and there's a longstanding
      //    bug that prevents the user of multiple "WITH" clauses if one of them is RECURSIVE.
      //    Because of this, the below sql causes an error on the client.
      //    See https://github.com/oguimbal/pgsql-ast-parser/issues/44
      throw new Error(`withDistinctTagIdsOfUserStatement only supported on postgres`);
    }

    return sql`
      ${isNested ? sql.EMPTY : sql.raw("WITH")} RECURSIVE "non_distinct_tags_of_user"("tag_id") AS (
        SELECT
          "tag_user_member"."tag_id"
        FROM 
          "tag_user_member"
        WHERE 
          "tag_user_member"."user_id" = ${params.currentUserId}
        AND
          "tag_user_member"."deleted_at" IS NULL
        
        UNION ALL
    
        SELECT 
            "tag_group_member"."tag_id"
        FROM 
            "non_distinct_tags_of_user"
        JOIN 
            "tag_group_member" ON "tag_group_member"."group_id" = "non_distinct_tags_of_user"."tag_id"
        WHERE
            "tag_group_member"."deleted_at" IS NULL
      ), "distinct_tag_ids_of_user"("tag_id") AS (
        SELECT DISTINCT
          "tag_id"
        FROM
          "non_distinct_tags_of_user"

        UNION ALL

        SELECT
          "tag"."id" AS "tag_id"
        FROM
          "tag"
        WHERE
          "tag"."type" = ${SpecialTagTypeEnum.SINGLETON}
      )
    `;
  }

  #parseNamespacedDatabaseRows(
    rows: Record<string, any>[] | undefined,
    ...tables: Array<RecordTable | { table: RecordTable; as?: string; canAuthorizationFail?: boolean }>
  ) {
    // We allow rows to be typed as undefined to improve the DX when destructing
    // query results
    if (!rows) {
      throw new Error("mapNamespacedRowsToRecordMap: rows are required");
    }

    return parseNamespacedDatabaseRows({
      rows,
      tables,
      decodeRecord: this.props.decodeRecord,
    });
  }

  #parseDatabaseRows<Table extends RecordTable>(table: Table, rows: Record<string, any>[] | undefined) {
    // We allow rows to be typed as undefined to improve the DX when destructing
    // query results
    if (!rows) {
      throw new Error(`[parseDatabaseRows] "${table}" rows are required`);
    }

    return parseDatabaseRows({
      rows,
      table,
      decodeRecord: this.props.decodeRecord,
    });
  }

  #getSubscriptionKeysForRecords(recordMap?: RecordMap) {
    if (!recordMap) return [];

    const subscriptionKeys: string[] = [];

    for (const { table, id } of iterateRecordMap(recordMap)) {
      if (virtualTables[table]) continue;

      subscriptionKeys.push(`${table}:${id}`);
    }

    return subscriptionKeys;
  }
}

/* -----------------------------------------------------------------------------------------------*/

function getAndAssertDefinedParams<T, K extends keyof T>(params: T, ...props: K[]) {
  const keyProps = props.filter((p) => !(p === "limit" || p === "startAt" || p === "startAfter"));
  const oneValueIsProvided = keyProps.some((p) => isDefined(params[p]));

  if (keyProps.length === 1 && oneValueIsProvided) {
    return props.filter((p) => isDefined(params[p])) as unknown as [K & string, ...(K & string)[]];
  }

  const everyValueIsProvided = keyProps.every((p) => isDefined(params[p]));

  if (oneValueIsProvided && !everyValueIsProvided) {
    return props.filter((p) => isDefined(params[p])) as unknown as [K & string, ...(K & string)[]];
  }

  if (!oneValueIsProvided) {
    // eslint-disable-next-line quotes
    throw new ValidationError(`Must provide either "${props.join('" or "')}" as a parameter.`);
  }

  // eslint-disable-next-line quotes
  throw new ValidationError(`Must not provide all of "${props.join('" and "')}" as parameters. Use getRecord instead.`);
}

/* -------------------------------------------------------------------------------------------------
 *  ServerOnlyQueryApi
 * -------------------------------------------------------------------------------------------------
 * The SharedQueryApi already includes a number of queries which are only usable by the server so
 * you might wonder, why does this second "ServerOnlyQueryApi" class exist? The SharedQueryApi
 * methods all need to return `Query` objects. This class is more flexible in that you can return
 * anything from it's methods. This being said, queries should be added to the SharedQueryApi
 * unless, for some reason, it is too restrictive.
 */

export class ServerOnlyQueryApi {
  constructor(private props: { logger: Logger; adapter: ServerDatabaseAdapterApi }) {}

  async getReindexInboxSectionsJob(params: GetReindexInboxSectionsJobParams): Promise<PgBoss.JobWithMetadata | null> {
    const statement = sql`
      SELECT
        *
      FROM
        "pgboss"."job"
      WHERE
        "pgboss"."job"."name" = 'oncall.reindex-inbox-sections'
      AND
        "pgboss"."job"."singletonkey" = ${params.userId}
      LIMIT
        1;
    `;

    const {
      rows: [job = null],
    } = await this.props.adapter.query(statement);

    return job as PgBoss.JobWithMetadata | null;
  }

  // Used by the reindexInboxSections job. Since it's used for reindexing
  // notifications, we also want to grab deleted notifications.
  async getNotificationCount(params: GetNotificationCountParams): Promise<number> {
    const statement = sql`
      SELECT
        COUNT(*) as count
      FROM
        "notification"
      WHERE
        "notification"."user_id" = ${params.userId}
      AND
        "notification"."delivered_at" <= ${params.deliveredOnOrBefore}
    `;

    const { rows } = await this.props.adapter.query(statement);

    const count = (rows[0]?.count as number | undefined) ?? 0;

    return count;
  }
}

export interface GetReindexInboxSectionsJobParams {
  userId: string;
}

export interface GetNotificationCountParams {
  userId: string;
  deliveredOnOrBefore: string;
}

/* -------------------------------------------------------------------------------------------------
 *  Types
 * -------------------------------------------------------------------------------------------------
 */

export type SharedQueryApiKey = keyof SharedQueryApi;

export type SharedQueryParams<T extends SharedQueryApiKey> = Parameters<SharedQueryApi[T]>[0];

export interface Query<T extends RecordTable> {
  primaryTable: T;
  // Previously the `statements` property was typed as `Statement[]` instead of `() => Statement[]`.
  // Qualitatively, I perceived Comms behaving slower after updating the SubscriptionManager
  // to use the query object to access the subscription keys. I suspect it was due to the
  // fact that we were eagerly constructing the query statements whenever the subscriptionManager
  // needed to build the subscription keys, even though the subscriptionManager wasn't using those
  // statements. I perceived updating the statements to be lazily evaluated seemed to improve
  // performance. I didn't benchmark this though since creating the benchmark would have taken
  // longer than simply updating the statements to be lazily evaluated.
  statements: () => Statement[];
  parseQueryResults: (results: RawResult[]) => ParsedResult[];
  /**
   * The subscription keys are string values which the client will send to the
   * server to tell the server which records the client is interested in
   * subscribing to. The client will naturally subscribe to all records returned
   * by a query, so the subscription keys are in addition to those subscriptions.
   *
   * For most queries, the subscription keys can be determined by the query
   * params. However, some queries are more complex and the subscription keys
   * can only be fully determined with the results of the query.
   *
   * This function should return the subscription keys for the query. It is
   * optionally called with the results of the query. Usage is to first call
   * it before running the query and get an initial list of subscription keys
   * (if any) which the client can then subscribe to. After the query is run and the
   * results are returned, the client should call this function again with the
   * results to get an updated list of subscription keys. The client is then
   * responsible for subscribing/unsubscribing based on the new keys. Each time
   * the query returns new results the client should call this function again
   * to get the updated list of subscription keys.
   *
   * Using this technique, we leave ourselves open to a race condition: its
   * possible for an update to happen to the query after results are returned but
   * before the client has had a chance to subscribe to the new keys.
   *
   * For subscriptions to specific records, we address this by having the server
   * always return the latest version of the record in response to the initial
   * subscription. However, for more complex queries, we don't currently take
   * any steps to mitigate this risk. In the future, we could update the server
   * to query for all records matching a subscription key that have been updated
   * within the last X seconds (where X is a small window), and then return the
   * IDs and versions of those records to the client. This would help ensure
   * that a client doesn't miss any updates while it's subscribing to a query key.
   */
  subscriptionKeys: (results?: RecordMap) => string[];
  /**
   * This flag indicates that the query is constructed in such a way that we know the
   * current user has permission to view all of the results. This lets us bypass
   * running the authorization logic on these results, even when the current user is
   * not the admin user.
   */
  resultsAreAlreadyAuthorized?: boolean;
}

export type FilterableTableQueryParams<T extends TableHasFilterKeys> = Simplify<
  Partial<Pick<RecordValue<T>, (typeof tableFilterKeys)[T][number] & keyof RecordValue<T>>> & {
    limit?: number;
    startAt?: RecordPointer<T>;
    startAfter?: RecordPointer<T>;
    endAt?: RecordPointer<T>;
  }
>;

export interface GetDraftsParams {
  currentUserId: string;
  branchedFromMessageId?: string;
  threadId?: string;
  limit?: number;
  startAt?: RecordPointer<"draft">;
}

export interface GetGroupsUserHasAccessToParams {
  currentUserId: string;
  // Not currently supported. See query implementation comment for details.
  // ownerOrganizationId?: string;
  orderBy?: "name";
  // Not currently supported. See query implementation comment for details.
  // limit?: number;
}

export interface GetGroupsUserIsDirectMemberOfParams {
  userId: string;
  limit?: number;
  startAt?: RecordPointer<"tag">;
}

export interface GetGroupsUserIsSubscribedToParams {
  userId: string;
  includeArchived?: boolean;
  limit?: number;
  startAt?: RecordPointer<"tag">;
}

export interface GetGroupsWhichNewUsersOfOrgShouldBeSubscribedToParams {
  organizationId: string;
}

export type GetInboxSectionsParams = {
  currentUserId: string;
  limit?: number;
};

export type GetInboxSubsectionsParams = {
  currentUserId: string;
  inboxSectionId?: string;
  limit?: number;
};

export type GetMessagesParams = FilterableTableQueryParams<"message">;

export type GetMessageReactionsParams = FilterableTableQueryParams<"message_reactions">;

export type GetNotificationsParams = Simplify<
  FilterableTableQueryParams<"notification"> & {
    orderBy?: keyof RecordValue<"notification"> & string;
    orderDir?: "ASC" | "DESC";
  }
>;

export type GetOrganizationControlledDomainsParams = FilterableTableQueryParams<"organization_controlled_domain">;

export type GetOrganizationUserInvitationsParams = FilterableTableQueryParams<"organization_user_invitation">;

export type GetOrganizationUserMembersParams = FilterableTableQueryParams<"organization_user_member">;

export type GetTagFolderMembersParams = FilterableTableQueryParams<"tag_folder_member">;

export type GetTagGroupMembersParams = FilterableTableQueryParams<"tag_group_member">;

export type GetTagSubscriptionsParams = FilterableTableQueryParams<"tag_subscription">;

export type GetTagUserMembersParams = FilterableTableQueryParams<"tag_user_member">;

export interface GetGroupViewThreadsParams {
  currentUserId: string;
  groupId: string;
  sortDirection?: "ASC" | "DESC";
  limit?: number;
  startAt?: RecordPointer<"thread">;
}

export interface GetInboxEntriesParams {
  currentUserId: string;
  inboxSectionId: string;
  inboxSubsectionId?: string;
  lastScheduledDeliveryAt?: string | null;
  priorities?: number[];
  limit?: number;
  startAt?: RecordPointer<"draft"> | RecordPointer<"notification">;
  sortDirection?: "ASC" | "DESC";
}

export interface GetInboxDraftEntriesParams {
  currentUserId: string;
  inboxSectionId: string;
  lastScheduledDeliveryAt?: string | null;
  /** If passed a notification record pointer, it will be ignored */
  startAt?: RecordPointer<"draft"> | RecordPointer<"notification">;
  sortDirection?: "ASC" | "DESC";
  limit?: number;
}

export interface GetInboxNotificationEntriesParams {
  currentUserId: string;
  inboxSectionId: string;
  inboxSubsectionId?: string;
  lastScheduledDeliveryAt?: string | null;
  priorities?: number[];
  /** If passed a draft record pointer, it will be ignored */
  startAt?: RecordPointer<"draft"> | RecordPointer<"notification">;
  sortDirection?: "ASC" | "DESC";
  limit?: number;
}

export interface GetOrganizationUsersParams {
  currentUserId: string;
  organizationId: string;
  /**
   * If true, only return users that have been deleted from the organization.
   * If false, only return users that have not been deleted from the organization.
   * @default false
   */
  isDeleted?: boolean;
  /**
   * This can be removed after the next breaking change (current version is `14.14`).
   * @deprecated Use `isDeleted` instead.
   */
  includeSoftDeletes?: boolean;
  limit?: number;
}

export interface GetLastMessageInThreadParams {
  threadId: string;
}

export interface GetNotificationsDeliveredOnOrBeforeParams {
  userId: string;
  deliveredOnOrBefore: string;
  startAfter?: RecordPointer<"notification">;
  limit: number;
}

export interface GetNotificationsWithDueRemindersParams {
  limit: number;
}

export interface GetOrganizationByControlledDomainParams {
  domain: string;
}

export interface GetSentMessagesParams {
  userId: string;
  threadId?: string;
  sentAfter?: string;
  limit?: number;
}

export interface GetPersonalLabelsParams {
  currentUserId: string;
  /**
   * If true, only return labels that have been deleted.
   * If false, only return labels that have not been deleted.
   * @default false
   */
  isDeleted?: boolean;
  /**
   * If true, only return labels that have been archived.
   * If false, only return labels that have not been archived.
   * @default false
   */
  isArchived?: boolean;
}

export interface GetSentMessagesForSearchIndexParams {
  organizationId: string;
  since: string;
  limit?: number;
}

export interface GetTagSubscriberUsersParams {
  tagId: string;
  subscriptionPreference?: TagSubscriptionPreference;
  limit?: number;
}

export interface GetTagsUserHasAccessToParams {
  currentUserId: string;
  // Not currently supported. See query implementation comment for details.
  // ownerOrganizationId?: string;
  orderBy?: "name";
  // Not currently supported. See query implementation comment for details.
  // limit?: number;
}

export interface GetThreadsWithUndeliveredMessagesParams {
  limit?: number;
}

export interface GetTagFolderMembersUserHasAccessToParams {
  currentUserId: string;
}

export interface GetTagViewThreadsParams {
  currentUserId: string;
  tagId: string;
  startAt?: RecordPointer<"thread">;
  limit?: number;
  sortDirection?: "ASC" | "DESC";
}

export type GetThreadGroupPermissionsParams = FilterableTableQueryParams<"thread_group_permission">;

export type GetThreadsGroupPermissionsParams = {
  threadIds: string[];
};

export type GetThreadSubscriptionsParams = FilterableTableQueryParams<"thread_subscription">;

export type GetThreadTagsParams = FilterableTableQueryParams<"thread_tag">;

export interface GetThreadTimelineEntriesParams extends FilterableTableQueryParams<"thread_timeline"> {}

export interface GetTrashViewThreadsParams {
  currentUserId: string;
  startAt?: RecordPointer<"thread">;
  limit?: number;
  sortDirection?: "ASC" | "DESC";
}

export interface GetUndeliveredMessageJobRecordsParams {
  messageId: string;
  threadId: string;
}

export interface GetUndeliveredMessagesForThreadParams {
  threadId: string;
}

export interface GetUndeliveredNotificationsParams {
  limit?: number;
}

export interface GetUserAuthTokensParams {
  userId: string;
}

export interface GetUserByEmailParams {
  email: string;
}

export interface GetUserByFirebaseAuthIdParams {
  firebaseId: string;
}

export type GetThreadUserParticipantsParams = FilterableTableQueryParams<"thread_user_participant">;

export type GetThreadUserPermissionsParams = FilterableTableQueryParams<"thread_user_permission">;

export type GetUserLessonsParams = FilterableTableQueryParams<"user_lesson">;

export type GetUserOAuthsParams = FilterableTableQueryParams<"user_oauth">;

export type GetUserPushNotificationSubscriptionsParams =
  FilterableTableQueryParams<"user_push_notification_subscriptions">;

export interface GetUserOrganizationProfilesParams {
  userId: string;
  startAt?: RecordPointer<"organization_profile">;
  limit?: number;
}

export interface GetUserProfilesParams {
  startAt?: RecordPointer<"user_profile">;
  endAt?: RecordPointer<"user_profile">;
  limit?: number;
}

export interface SearchMessagesParams {
  currentUserId: string;
  query: string;
  limit?: number;
  startAfter?: RecordPointer<"message">;
}

/* -------------------------------------------------------------------------------------------------
 * RecordLoaderApi
 * -------------------------------------------------------------------------------------------------
 */

export type RecordLoaderApi = Simplify<
  {
    [K in SharedQueryApiKey]: (
      params: SharedQueryParams<K>,
    ) => ReturnType<SharedQueryApi[K]> extends Query<infer T> ? Promise<QueryResult<T>> : never;
  } & {
    getRecord: {
      <T extends RecordTable>(
        params: RecordPointer<T>,
        options?: { includeSoftDeletes?: boolean },
      ): Promise<GetRecordResult<T>>;
      <T extends RecordTable>(
        table: T,
        id: string,
        options?: { includeSoftDeletes?: boolean },
      ): Promise<GetRecordResult<T>>;
    };

    getRecords<T extends RecordTable>(
      pointers: RecordPointer<T>[],
      options?: { includeSoftDeletes?: boolean },
    ): Promise<GetRecordsResult<T>>;
  }
>;

export type GetRecordResult<T extends RecordTable = RecordTable> = [RecordValue<T> | null];

export type GetRecordsResult<T extends RecordTable = RecordTable> = [PointerWithRecord<T>[]];

export type QueryResult<T extends RecordTable = RecordTable> = [RecordValue<T>[], { recordMap: RecordMap }];

/* -------------------------------------------------------------------------------------------------
 *  getCacheKey
 * -------------------------------------------------------------------------------------------------
 */

export function getCacheKey(props: {
  type: string;
  params: Record<string, any>;
  /** @default ["limit"] */
  omitParams?: string[];
}): string {
  const { type, params, omitParams = DEFAULT_OMIT } = props;

  const keys = Object.entries(params)
    .filter(([k, v]) => isDefined(v) && !omitParams.includes(k))
    .map(([k, v]) => `${k}:${JSON.stringify(v)}`)
    .sort();

  keys.unshift(type);

  return keys.join(":");
}

const DEFAULT_OMIT = ["limit"];

/* -----------------------------------------------------------------------------------------------*/

function getStartAtFilterSql<T extends RecordTable>(props: {
  startAt?: RecordPointer<T>;
  sortOn: keyof RecordValue<T> & string;
  sortDirection?: "ASC" | "DESC";
}) {
  if (!props.startAt) return sql.EMPTY;

  const { startAt, sortOn, sortDirection = "ASC" } = props;

  const table = sql.raw(startAt.table);
  const operatorOrEqual = sortDirection === "ASC" ? sql.raw(">=") : sql.raw("<=");

  if (sortOn === "id") {
    return sql`AND "${table}"."id" ${operatorOrEqual} ${startAt.id}`;
  }

  const column = sql.raw(sortOn);
  const operator = sortDirection === "ASC" ? sql.raw(">") : sql.raw("<");

  return sql`AND (
    "${table}"."${column}" ${operator} (
      SELECT
        "${table}"."${column}"
      FROM
        "${table}"
      WHERE
        "${table}"."id" = ${startAt.id}
      LIMIT
        1
    )
    OR (
      "${table}"."${column}" = (
        SELECT
          "${table}"."${column}"
        FROM
          "${table}"
        WHERE
          "${table}"."id" = ${startAt.id}
        LIMIT
          1
      )
      AND "${table}"."id" ${operatorOrEqual} ${startAt.id}
    )
  )`;
}

function getStartAfterFilterSql<T extends RecordTable>(props: {
  startAfter?: RecordPointer<T>;
  sortOn: keyof RecordValue<T> & string;
  sortDirection?: "ASC" | "DESC";
}) {
  if (!props.startAfter) return sql.EMPTY;

  const { startAfter, sortOn, sortDirection = "ASC" } = props;

  const table = sql.raw(startAfter.table);
  const operator = sortDirection === "ASC" ? sql.raw(">") : sql.raw("<");

  if (sortOn === "id") {
    return sql`AND "${table}"."id" ${operator} ${startAfter.id}`;
  }

  const column = sql.raw(sortOn);

  return sql`AND (
    "${table}"."${column}" ${operator} (
      SELECT
        "${table}"."${column}"
      FROM
        "${table}"
      WHERE
        "${table}"."id" = ${startAfter.id}
      LIMIT
        1
    )
    OR (
      "${table}"."${column}" = (
        SELECT
          "${table}"."${column}"
        FROM
          "${table}"
        WHERE
          "${table}"."id" = ${startAfter.id}
        LIMIT
          1
      )
      AND "${table}"."id" ${operator} ${startAfter.id}
    )
  )`;
}

function getEndAtFilterSql<T extends RecordTable>(props: {
  endAt?: RecordPointer<T>;
  sortOn: keyof RecordValue<T> & string;
  sortDirection?: "ASC" | "DESC";
}) {
  if (!props.endAt) return sql.EMPTY;

  const sortDirection = props.sortDirection || "ASC";

  const direction =
    sortDirection === "ASC" ? "DESC"
    : sortDirection === "DESC" ? "ASC"
    : throwUnreachableCaseError(sortDirection);

  return getStartAtFilterSql({
    startAt: props.endAt,
    sortOn: props.sortOn,
    sortDirection: direction,
  });
}

function getOrderBySql<T extends RecordTable>(props: {
  table: T;
  sortOn: keyof RecordValue<T> & string;
  sortDirection?: "ASC" | "DESC";
}) {
  const table = sql.raw(props.table);
  const direction = sql.raw(props.sortDirection || "ASC");

  if (props.sortOn === "id") {
    return sql`ORDER BY "${table}"."id" ${direction}`;
  }

  const column = sql.raw(props.sortOn);

  return sql`ORDER BY "${table}"."${column}" ${direction}, "${table}"."id" ${direction}`;
}

export function getOrderByWithStartAndEndAndLimitSql<T extends RecordTable>(props: {
  table: T;
  startAt?: RecordPointer<T>;
  startAfter?: RecordPointer<T>;
  endAt?: RecordPointer<T>;
  sortOn: keyof RecordValue<T> & string;
  sortDirection?: "ASC" | "DESC";
  limit?: number;
}) {
  if (props.startAt && props.startAfter) {
    throw new ValidationError("startAt and startAfter cannot both be provided");
  }

  return sql`
    ${getStartAtFilterSql(props)}
    ${getStartAfterFilterSql(props)}
    ${getEndAtFilterSql(props)}
    ${getOrderBySql(props)}
    ${props.limit ? sql`LIMIT ${props.limit}` : sql.EMPTY}
  `;
}

/* -----------------------------------------------------------------------------------------------*/
