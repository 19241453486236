import { cx } from "@emotion/css";
import { ParentComponent } from "~/utils/type-helpers";

/* -------------------------------------------------------------------------------------------------
 * EndOfListMsg
 * -----------------------------------------------------------------------------------------------*/

export const EndOfListMsg: ParentComponent<{
  className?: string;
}> = (props) => {
  return <div className={cx("text-slate-9 my-8 flex justify-center uppercase", props.className)}>end</div>;
};

/* -------------------------------------------------------------------------------------------------
 * LoadingMoreListEntriesMsg
 * -----------------------------------------------------------------------------------------------*/

export const LoadingMoreListEntriesMsg: ParentComponent<{ isThreadOpen: boolean }> = (props) => {
  return (
    <div className={cx("text-slate-9 my-8 flex justify-center uppercase", props.isThreadOpen && "invisible")}>
      Loading...
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/
