import { InboxSubsectionTagRecord, LabelTagRecord, RecordValue } from "libs/schema";
import { isInParsedToken, parseDocId } from "libs/searchQueryMatcher";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export function renderGroupName(group: RecordValue<"tag">): string {
  let name = [group.icon, group.name].filter(Boolean).join(" ");

  if (group.archived_at) {
    name += " (Archived)";
  }

  return name;
}

export async function canArchiveLabel(environment: ClientEnvironment, tag: LabelTagRecord): Promise<boolean> {
  const currentUserId = environment.auth.getAndAssertCurrentUserId();

  using disposable = environment.isLoading.add();

  const [inboxSections] = await environment.recordLoader.getInboxSections({
    currentUserId,
  });

  const defaultInboxSection = inboxSections.at(0);

  if (!defaultInboxSection) return false;

  const [inboxSubsections] = await environment.recordLoader.getInboxSubsections({
    currentUserId,
    inboxSectionId: defaultInboxSection.id,
  });

  const isLabelUsedInAnyInboxSubsection = inboxSubsections.some((inboxSubsection) => {
    const subsection = inboxSubsection as InboxSubsectionTagRecord;

    return subsection.data.parsed_query.some((rootToken) =>
      isInParsedToken(rootToken, (token) => {
        if (token.type !== "label:") return false;

        return token.value.some((value) => {
          if (value.type !== "DocId") return false;

          const { subject, subjectId } = parseDocId(value);

          return subject === "label" && subjectId === tag.id;
        });
      }),
    );
  });

  return !isLabelUsedInAnyInboxSubsection;
}
