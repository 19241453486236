import { Helmet } from "react-helmet-async";
import { ICommandArgs, isModKeyActive, useRegisterCommands } from "~/environment/command.service";
import { toast } from "~/environment/toast-service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  deleteDraftCommand,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
  threadSubscriptionCommands,
  previousThreadCommand,
  nextThreadCommand,
} from "~/utils/common-commands";
import { CSSProperties, memo, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { IListOnEntryActionEvent, IListRef, ListScrollbox } from "~/components/list";
import { RedirectToDefaultInboxSection, TInboxEntry, useInboxZeroConfetti } from "./utils";
import { Tooltip } from "~/components/Tooltip";
import { cx } from "@emotion/css";
import { NextScheduledDeliveryHeader } from "./NextScheduledDeliveryHeader";
import * as MainLayout from "~/page-layouts/main-layout";
import { NotificationCountBadge } from "~/components/NotificationCountBadge";
import { withNewCommandContext } from "~/environment/command.service";
import { BlockingInboxProgressBar } from "./BlockingInboxProgressBar";
import Confetti from "react-confetti";
import { EmptyInboxMessage } from "./EmptyInboxMessage";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { useInboxSection } from "~/hooks/useInboxSection";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { RecordValue } from "libs/schema";
import { useInboxSectionIds } from "~/hooks/useInboxSectionIds";
import { triageThread } from "~/actions/notification";
import { deleteDraft } from "~/actions/draft";
import { useInboxEntries } from "~/hooks/useInboxEntries";
import { UnreachableCaseError } from "libs/errors";
import { useInboxSubsection } from "~/hooks/useInboxSubsection";
import { onDraftSelect } from "~/components/content-list/DraftEntry";
import { useIsDefaultInboxSection } from "~/hooks/useIsDefaultInboxSection";
import { isUuid } from "libs/uuid";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { InboxDraftEntry, InboxNotificationEntry } from "./InboxEntry";
import { useAttemptRefetchOnError } from "~/hooks/useAttemptRefetchOnError";
import { useInboxGroups } from "~/hooks/useInboxGroups";
import { renderGroupName } from "~/utils/tag-utils";
import { inboxState, TFilteredInboxEntry } from "./state";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import * as Toggle from "@radix-ui/react-toggle";
import { MarkAllDoneEntryAction, SetReminderForAllEntryAction } from "~/components/content-list/layout";
import { useControl, createFormControl, IFormControl } from "solid-forms-react";
import { useControlState } from "~/components/forms/utils";
import { useAddDropShadowWhenSticky } from "~/hooks/useAddDropShadowWhenSticky";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { useDoesThreadHaveDraft } from "~/hooks/useDoesThreadHaveDraft";
import { ParentComponent } from "~/utils/type-helpers";
import { withDepsGuard } from "~/route-guards/withDepsGuard";
import { useIsWindowMdWidth, useVirtualList } from "~/hooks/useVirtualList";
import { Link, Outlet, useParams } from "@tanstack/react-router";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useIsRouteActive } from "~/environment/router/components";
import { IThreadViewContext, ThreadViewContextProvider } from "../thread/context";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useSelectedListEntries } from "~/hooks/useSelectedListEntries";
import { isEqual } from "libs/predicates";
import { observeZustandState } from "~/utils/rxjs-utils";
import { pick } from "lodash-es";
import { combineLatest, filter, map } from "rxjs";
import { observeInboxGroups } from "~/observables/observeInboxGroups";
import { IoMdCloseCircle } from "react-icons/io";
import { LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";
import { useRegisterThreadLabelCommands } from "~/hooks/useRegisterThreadLabelCommands";

/* -------------------------------------------------------------------------------------------------
 * InboxView
 * -----------------------------------------------------------------------------------------------*/

export const InboxView = withOfflineFirstIfSynced(
  withNewCommandContext(
    withDepsGuard<{}>()({
      useDepsFactory() {
        const hasSelectedGroups = inboxState((s) => s.selectedGroups.length > 0);
        const showGroupFiltersControl = useControl(() => createFormControl(hasSelectedGroups));

        if (!showGroupFiltersControl) return null;

        return { showGroupFiltersControl };
      },
      Component: ({ showGroupFiltersControl }) => {
        const { settings } = useCurrentUserSettings();
        const inboxLayout = settings?.inbox_layout;
        const params = useParams({ strict: false });
        const inboxSectionId = params.inboxSectionId && isUuid(params.inboxSectionId) ? params.inboxSectionId : null;
        const isThreadOpen = useIsRouteActive({ path: "/inbox/$inboxSectionId/threads/$threadId" });

        const headerRef = useRef<HTMLDivElement>(null);
        const listRef = useRef<IListRef<TInboxEntry>>(null);
        const inboxScrollboxRef = useRef<HTMLDivElement>(null);

        const threadViewContext = useThreadViewContext({
          listRef,
          inboxSectionId,
          isThreadOpen,
        });

        const [inboxSection, { isLoading: isInboxSectionLoading }] = useInboxSection(inboxSectionId);

        const isListRefSet = !!inboxSection && !inboxSection.data.is_reindexing;

        useTopScrollShadow({
          scrollboxRef: inboxScrollboxRef,
          targetRef: headerRef,
          deps: [inboxSection, threadViewContext],
        });

        if (!inboxSectionId) {
          return <RedirectToDefaultInboxSection />;
        }

        if (!inboxSection) {
          if (isInboxSectionLoading) {
            return <EmptyListMessage text="Loading..." loading={true} />;
          }

          return <RedirectToDefaultInboxSection />;
        }

        if (!threadViewContext) return null;

        return (
          <ListScrollbox ref={inboxScrollboxRef}>
            <div className="h-screen overflow-auto">
              <Helmet>
                <title>Inbox | Comms</title>
              </Helmet>

              <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
                <MainLayout.Header className={cx("sm-max-w:pr-0 flex-col")}>
                  <div className="flex items-center">
                    <InboxHeader currentInboxSectionId={inboxSectionId} />

                    <div className="flex-1" />

                    {settings?.enable_focus_mode && (
                      <Tooltip side="bottom" content="Focus Mode is on">
                        <span className="text-slate-9 mr-4 cursor-help">Focus Mode</span>
                      </Tooltip>
                    )}

                    {settings?.enable_scheduled_delivery && <NextScheduledDeliveryHeader />}
                  </div>

                  {inboxLayout === "blocking-inbox" && (
                    <div className="mt-2">
                      <BlockingInboxProgressBar inboxSectionId={inboxSectionId} />
                    </div>
                  )}
                </MainLayout.Header>

                <InboxActionsBar
                  listRef={listRef}
                  isListRefSet={isListRefSet}
                  inboxSectionId={inboxSectionId}
                  showGroupFiltersControl={showGroupFiltersControl}
                />
              </div>

              {inboxSection.data.is_reindexing ?
                <EmptyListMessage
                  text={`Updating inbox${inboxSection.data.reindexing_total ? ` (${inboxSection.data.reindexing_progress} / ${inboxSection.data.reindexing_total})` : "..."}`}
                  className={cx("flex-col", isThreadOpen && "invisible")}
                >
                  <div className="max-w-[600px] mt-4">
                    <p className="text-slate-9 font-medium">
                      After creating or updating an inbox section, we need to reprocess your notifications to see which
                      of them belong here. Please check back later. This may take a few minutes.
                    </p>
                  </div>
                </EmptyListMessage>
              : <InboxEntries
                  inboxLayout={inboxLayout}
                  inboxSectionId={inboxSectionId}
                  headerRef={headerRef}
                  listRef={listRef}
                  scrollboxRef={inboxScrollboxRef}
                  isThreadOpen={isThreadOpen}
                />
              }

              <ThreadViewContextProvider context={threadViewContext}>
                <Outlet />
              </ThreadViewContextProvider>
            </div>
          </ListScrollbox>
        );
      },
    }),
  ),
);

const InboxActionsBar: ParentComponent<{
  listRef: React.RefObject<IListRef<TInboxEntry>>;
  isListRefSet: boolean;
  inboxSectionId: string;
  showGroupFiltersControl: IFormControl<boolean>;
}> = (props) => {
  const { listRef, isListRefSet, showGroupFiltersControl, inboxSectionId } = props;
  const environment = useClientEnvironment();

  const showFilters = useControlState(() => showGroupFiltersControl.value, [showGroupFiltersControl]);

  const selectedEntryIds = useSelectedListEntries({ listRef, isListRefSet });

  const { currentUserId } = useAuthGuardContext();

  const selectedGroups = inboxState((s) => s.selectedGroups);

  useEffect(() => {
    const sub = combineLatest([
      observeInboxGroups(environment, { userId: currentUserId, inboxSectionId }, { fetchStrategy: "memory" }),
      observeZustandState(inboxState, (s) => pick(s, ["selectedGroups", "toggleGroup"])),
    ])
      .pipe(filter(([[_, { isLoading: areInboxGroupsLoading }]]) => !areInboxGroupsLoading))
      .subscribe(([[inboxGroups], state]) => {
        const removedGroups = state.selectedGroups.filter(
          (groupId) => !inboxGroups.some((group) => group.id === groupId),
        );

        removedGroups.forEach((groupId) => state.toggleGroup(environment, { groupId, inboxSectionId }));
      });

    return () => sub.unsubscribe();
  }, [inboxSectionId, currentUserId, environment]);

  useRegisterCommands({
    commands: () => {
      return combineLatest([
        observeInboxGroups(environment, { userId: currentUserId, inboxSectionId }, { fetchStrategy: "memory" }),
        observeZustandState(inboxState, (s) => s.selectedGroups),
      ]).pipe(
        map(([[inboxGroups, { isLoading: areInboxGroupsLoading }], selectedGroups]) => {
          if (areInboxGroupsLoading || !inboxGroups.length) return [];

          return [
            {
              label: "Clear all filters",
              callback() {
                inboxState.getState().clearSelectedGroups(environment, { inboxSectionId });
              },
            },
            ...inboxGroups.map((group) => {
              const selected = selectedGroups.includes(group.id);

              return {
                label: `${selected ? "Remove filter" : "Filter by"} ${renderGroupName(group)}`,
                callback() {
                  inboxState.getState().toggleGroup(environment, { groupId: group.id, inboxSectionId });
                },
              };
            }),
          ];
        }),
      );
    },
    deps: [inboxSectionId, currentUserId, environment],
  });

  return (
    <>
      <MainLayout.ActionsBar
        listRef={listRef}
        isListRefSet={isListRefSet}
        multiSelectActions={
          <>
            <MarkAllDoneEntryAction />
            <SetReminderForAllEntryAction />
          </>
        }
      >
        <Toggle.Root
          className={cx(
            "inline-flex items-center border group px-2 py-1",
            "rounded",
            "border",
            "border-slate-9",
            showFilters ? "bg-slate-12 text-white" : "hover:bg-slateA-3",
          )}
          onPressedChange={(isPressed) => showGroupFiltersControl.setValue(isPressed)}
        >
          <small>Filters</small>
        </Toggle.Root>

        {selectedGroups.length > 0 && (
          <>
            <div className="flex items-center ml-4">
              {selectedGroups.length} filter{selectedGroups.length === 1 ? "" : "s"} selected
            </div>

            <Tooltip side="bottom" content="Clear filters">
              <button
                type="button"
                tabIndex={-1}
                className={cx("text-slate-9 p-2 rounded-full hover:text-black")}
                onClick={(e) => {
                  e.preventDefault();
                  inboxState.getState().clearSelectedGroups(environment, { inboxSectionId });
                }}
              >
                <IoMdCloseCircle />
              </button>
            </Tooltip>
          </>
        )}
      </MainLayout.ActionsBar>

      {selectedEntryIds.length === 0 && (
        <InboxGroupFilters inboxSectionId={props.inboxSectionId} showFilters={showFilters} />
      )}
    </>
  );
};

/**
 * Shows the group filters for the inbox (if there's at least one group).
 */
const InboxGroupFilters: ParentComponent<{
  inboxSectionId: string;
  showFilters: boolean;
}> = memo((props) => {
  const { showFilters, inboxSectionId } = props;
  const environment = useClientEnvironment();
  const selectedGroups = inboxState((s) => s.selectedGroups);
  const [inboxGroups, { isLoading: areInboxGroupsLoading }] = useInboxGroups(props.inboxSectionId);

  if (!showFilters) return null;

  return (
    <div
      className={cx(
        "flex flex-wrap overflow-y-auto max-h-[120px] px-4 sm-w:px-8 md-w:px-12 pb-2 space-x-2 space-y-2 bg-slate-3",
      )}
    >
      {/*
        Tailwind's "space-x" style has a strange affect on the first element in a list. We add an unstyled, invisble div to catch this effect
        and nullify it.
      */}
      <div />

      {areInboxGroupsLoading && (
        <div className="flex items-center text-sm text-slate-11 mr-4">
          <img src={"/comms-icon.gif"} alt="Loading" className="w-[30px] pr-2" /> Filters loading...
        </div>
      )}

      {inboxGroups.length === 0 && !areInboxGroupsLoading && <span>No groups to filter on</span>}

      {inboxGroups.map((group, index) => (
        <span
          key={index}
          className={cx(
            "text-xs border-slateA-9 px-[10px] py-[3px] border rounded cursor-pointer truncate max-w-[10rem]",
            "hover:border-black hover:text-black shrink-0",
            selectedGroups.includes(group.id) && "bg-slate-12 text-white",
          )}
          onClick={() => {
            inboxState.getState().toggleGroup(environment, { groupId: group.id, inboxSectionId });
          }}
        >
          {renderGroupName(group)}
        </span>
      ))}
    </div>
  );
}, isEqual);

const InboxEntries: ParentComponent<{
  inboxLayout: RecordValue<"user_settings">["settings"]["inbox_layout"];
  inboxSectionId: string;
  headerRef: React.RefObject<HTMLElement>;
  listRef: React.RefObject<IListRef<TInboxEntry>>;
  scrollboxRef: React.RefObject<HTMLDivElement>;
  isThreadOpen: boolean;
}> = withNewCommandContext((props) => {
  const environment = useClientEnvironment();

  const [
    inboxEntries,
    { isLoading: areInboxEntriesLoading, nextId, fetchMore, refetch: refetchInboxEntries, error: inboxEntriesError },
  ] = useInboxEntries({ inboxSectionId: props.inboxSectionId, isThreadOpen: props.isThreadOpen });

  const filteredInboxEntries = useFilteredInboxEntries({
    inboxSectionId: props.inboxSectionId,
    unfilteredInboxEntries: inboxEntries,
  });

  const isWindowMdWidth = useIsWindowMdWidth();

  const hasNextPage = !!nextId;

  const virtualFilteredInbox = useVirtualList({
    scrollboxRef: props.scrollboxRef,
    count: filteredInboxEntries.length,
    getEntryKey: (index) => filteredInboxEntries[index]?.id || "",
    estimateSize: (index) => {
      const entry = filteredInboxEntries[index];

      switch (entry?.type) {
        case "notification":
        case "draft": {
          return isWindowMdWidth ? 48 : 64;
        }
        case "section_header": {
          return 80;
        }
        case "subsection_break": {
          return 20;
        }
        case undefined: {
          return 0;
        }
        default: {
          throw new UnreachableCaseError(entry);
        }
      }
    },
    fetchMore,
    hasNextPage,
    isFetchingNextPage: areInboxEntriesLoading,
  });

  const filteredInboxEntryIds = useFilteredInboxEntryIds(filteredInboxEntries);

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TInboxEntry>();

  const hasInboxNotification = useMemo(() => {
    return inboxEntries.some((p) => p.type === "notification");
  }, [inboxEntries]);

  return (
    <>
      <RegisterInboxViewCommands
        listRef={props.listRef}
        inboxSectionId={props.inboxSectionId}
        useFocusedEntry={useFocusedEntry}
      />

      <ContentList<TInboxEntry>
        listRef={props.listRef}
        mode={props.isThreadOpen ? "active-descendent" : "focus"}
        onEntryFocused={setFocusedEntry}
        onEntryAction={(event) => onInboxEntrySelect(environment, { event, inboxSectionId: props.inboxSectionId })}
        className={cx(props.isThreadOpen && "invisible")}
        autoFocus
        allEntryIdsForVirtualizedList={filteredInboxEntryIds}
        style={virtualFilteredInbox.containerStyles()}
      >
        {virtualFilteredInbox.entries.map((virtualEntry, index) => {
          if (!virtualEntry.key) return null;
          const entry = filteredInboxEntries[virtualEntry.index];
          if (!entry) return null;

          switch (entry.type) {
            case "notification": {
              return (
                <InboxNotificationEntry
                  key={entry.id}
                  notificationId={entry.id}
                  inboxSubsectionId={entry.inbox_subsection_id!}
                  relativeOrder={index}
                  style={virtualFilteredInbox.entryStyles(virtualEntry)}
                />
              );
            }
            case "draft": {
              return (
                <InboxDraftEntry
                  key={entry.id}
                  draftId={entry.id}
                  relativeOrder={index}
                  style={virtualFilteredInbox.entryStyles(virtualEntry)}
                />
              );
            }
            case "section_header": {
              return (
                <SubsectionHeader
                  key={entry.id}
                  sectionId={entry.sectionId}
                  subsectionId={entry.subsectionId}
                  style={virtualFilteredInbox.entryStyles(virtualEntry)}
                />
              );
            }
            case "subsection_break": {
              return <SubsectionBreak key={entry.id} style={virtualFilteredInbox.entryStyles(virtualEntry)} />;
            }
            default: {
              throw new UnreachableCaseError(entry);
            }
          }
        })}
      </ContentList>

      {hasInboxNotification && !hasNextPage && !areInboxEntriesLoading && (
        <EndOfInboxMsg inboxSectionId={props.inboxSectionId} isThreadOpen={props.isThreadOpen} />
      )}

      {(hasNextPage || areInboxEntriesLoading) && <LoadingMoreListEntriesMsg isThreadOpen={props.isThreadOpen} />}

      {inboxEntriesError && (
        <ErrorLoadingInbox refetch={refetchInboxEntries} isLoading={areInboxEntriesLoading} error={inboxEntriesError} />
      )}

      {!inboxEntriesError && !props.isThreadOpen && (
        <EmptyInbox isLoading={areInboxEntriesLoading} hasInboxNotification={hasInboxNotification} />
      )}
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

const EmptyInbox: ParentComponent<{
  isLoading: boolean;
  hasInboxNotification: boolean;
}> = (props) => {
  const { settings } = useCurrentUserSettings();

  const { showConfetti, windowWidth, windowHeight } = useInboxZeroConfetti(props.isLoading, props.hasInboxNotification);

  if (props.isLoading || props.hasInboxNotification) {
    return null;
  }

  return (
    <>
      {showConfetti && !settings?.enable_focus_mode && (
        <div className="pointer-events-none w-screen h-screen absolute top-0 left-0 z-[2000]">
          <Confetti width={windowWidth} height={windowHeight} recycle={false} tweenDuration={5000} gravity={0.1} />
        </div>
      )}

      <EmptyInboxMessage />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ErrorLoadingInbox: ParentComponent<{
  refetch: () => void;
  isLoading: boolean;
  error?: unknown;
}> = (props) => {
  const { refetchFailed } = useAttemptRefetchOnError(props);

  if (!props.error || !refetchFailed) {
    return null;
  }

  return (
    <EmptyListMessage text="Oops, something went wrong.">
      <div className="max-w-[600px] mt-4">
        <p className="text-slate-9 font-medium">We couldn't load your inbox entries.</p>
      </div>

      <div className="h-20" />
    </EmptyListMessage>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useFilteredInboxEntries(props: {
  inboxSectionId: string;
  unfilteredInboxEntries: RecordValue<"inbox_entry">[];
}) {
  const { inboxSectionId, unfilteredInboxEntries } = props;
  const environment = useClientEnvironment();
  const { filteredInboxEntries, setInboxEntries } = inboxState();

  useEffect(() => {
    setInboxEntries(environment, { inboxEntries: unfilteredInboxEntries, inboxSectionId });
  }, [unfilteredInboxEntries, setInboxEntries, inboxSectionId, environment]);

  return filteredInboxEntries;
}

/* -----------------------------------------------------------------------------------------------*/

const SubsectionHeader: ParentComponent<{
  sectionId: string;
  subsectionId: string;
  style?: CSSProperties;
}> = (props) => {
  const elRef = useRef<HTMLDivElement>(null);
  const [subsection] = useInboxSubsection(props.subsectionId);

  useAddDropShadowWhenSticky(elRef, [!!subsection]);

  if (!subsection) return null;

  // const subsectionInboxEntriesCount = subsection.inbox_entries_count;
  const subsectionInboxEntriesCount = 0;

  return (
    <div
      ref={elRef}
      className={`
        px-4 sm-w:px-8 md-w:px-12
        border-l-2 border-white text-black
        font-medium flex items-center py-4 pt-8
      `}
      style={props.style}
    >
      <h2 className="inline-flex shrink-0 items-center text-2xl">
        {subsectionInboxEntriesCount > 0 && (
          <NotificationCountBadge
            count={subsectionInboxEntriesCount}
            className="bg-slate-4 border-blackA-7 text-black"
          />
        )}

        {subsection.name}
      </h2>

      {subsection.description && (
        <>
          <span className="mx-2 text-slate-9">-</span>

          <p className="italic text-slate-9 font-normal">{subsection.description}</p>
        </>
      )}

      <div className="flex-1" />

      <Link to="/inbox/$inboxSectionId/edit" params={{ inboxSectionId: props.sectionId }} className="underline">
        Edit
      </Link>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const SubsectionBreak: ParentComponent<{ style?: CSSProperties }> = (props) => {
  return (
    <div
      className={`
        h-20 px-4 sm-w:px-8 md-w:px-12
        border-l-2 border-transparent flex items-center
        text-slateA-9
      `}
      style={props.style}
    >
      <ThinLineIcon />
    </div>
  );
};

const ThinLineIcon: ParentComponent = () => {
  return (
    <div className="w-8 h-[0.15rem] flex justify-center">
      <div className="w-1/3 rounded-full bg-current" />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const InboxHeader: ParentComponent<{
  currentInboxSectionId: string;
}> = (props) => {
  const [inboxSectionIds] = useInboxSectionIds();

  return (
    <div className="flex mr-4 overflow-x-auto overflow-y-hidden">
      {inboxSectionIds.map((sectionId, index) => {
        return (
          <HeaderInboxSectionEntry
            key={sectionId}
            inboxSectionId={sectionId}
            isCurrentInboxSection={props.currentInboxSectionId === sectionId}
            isFirstEntry={index === 0}
          />
        );
      })}
    </div>
  );
};

const HeaderInboxSectionEntry: ParentComponent<{
  inboxSectionId: string;
  isCurrentInboxSection: boolean;
  isFirstEntry: boolean;
}> = (props) => {
  const [section] = useInboxSection(props.inboxSectionId);

  if (!section) return null;

  return (
    <>
      {!props.isFirstEntry && <span className="text-3xl mx-4 text-slate-7">&bull;</span>}

      <h1 className={cx("text-3xl", !props.isCurrentInboxSection && "opacity-40")}>
        <Link to={`/inbox/$inboxSectionId`} params={{ inboxSectionId: props.inboxSectionId }}>
          {section.name}
        </Link>
      </h1>
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * useRegisterInboxViewCommands
 * -----------------------------------------------------------------------------------------------*/

const RegisterInboxViewCommands: ParentComponent<{
  listRef: RefObject<IListRef<TInboxEntry>>;
  inboxSectionId: string;
  useFocusedEntry: () => TInboxEntry | null;
}> = (props) => {
  const { listRef, inboxSectionId, useFocusedEntry } = props;
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const focusedEntry = useFocusedEntry();

  const [doesFocusedNotificationHaveADraft] = useDoesThreadHaveDraft({
    threadId: focusedEntry?.table === "notification" ? focusedEntry.record.thread_id : null,
    // In the environment we create and maintain a subscription to all of the user's drafts.
    // For this reason, we can use a cache-only fetch strategy here. Note that the environment
    // creates a subscription to the user's drafts after a delay, so if the user is initially
    // loading Comms' inbox they won't see these drafts appear until that subscription is created.
    // But in this case we're deciding that that is ok.
    fetchStrategy: "cache",
  });

  const { isDefaultInboxSection } = useIsDefaultInboxSection(inboxSectionId);

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
  });

  useRegisterCommands({
    commands() {
      return [
        previousThreadCommand({
          callback: () => {
            const inboxList = listRef.current;

            if (!inboxList) {
              environment.logger.error("[RegisterInboxViewCommands] [previousThreadCommand] listRef.current is null");
              return;
            }

            inboxList.focusPrevEntry({ scrollViewInsteadIfAppropriate: true });
          },
        }),
        nextThreadCommand({
          callback: () => {
            const inboxList = listRef.current;

            if (!inboxList) {
              environment.logger.error("[RegisterInboxViewCommands] [nextThreadCommand] listRef.current is null");
              return;
            }

            inboxList.focusNextEntry({ scrollViewInsteadIfAppropriate: true });
          },
        }),
      ];
    },
    deps: [listRef, environment],
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Edit inbox sections",
          altLabels: ["Update inbox sections"],
          callback() {
            environment.router.navigate(`/inbox/${inboxSectionId}/edit`);
          },
        },
      ];

      switch (focusedEntry?.table) {
        case undefined: {
          commands.push(
            markDoneCommand({
              callback: () => {
                toast("vanilla", {
                  subject: "Oops, no message is focused.",
                  description: `
                    You first need to focus a message by hovering your mouse
                    over it or by using the arrow keys on your keyboard.
                  `,
                });
              },
            }),
          );

          return commands;
        }
        case "draft": {
          commands.push(
            deleteDraftCommand({
              callback: () => {
                deleteDraft(environment, {
                  draftId: focusedEntry.id,
                  currentUserId,
                });
              },
            }),
          );

          return commands;
        }
        case "notification": {
          commands.push(
            markDoneCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedEntry.record.thread_id,
                  done: true,
                });
              },
            }),
            markNotDoneCommand({
              showInKBar: false,
              callback: () => {
                toast("vanilla", {
                  subject: "Message already marked not done.",
                  description: `Hint: use "E" to mark as done.`,
                });
              },
            }),
            setThreadReminderCommand({
              callback: () => {
                RemindMeDialogState.open({
                  threadId: focusedEntry.record.thread_id,
                  fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
                });
              },
            }),
            ...threadSubscriptionCommands({
              environment,
              notification: focusedEntry.record,
              threadId: focusedEntry.record.thread_id,
            }),
          );

          if (focusedEntry.record.has_reminder) {
            commands.push(
              removeThreadReminderCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedEntry.record.thread_id,
                    triagedUntil: null,
                  });
                },
              }),
            );
          }

          if (focusedEntry.record.is_starred) {
            commands.push(
              unstarThreadCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedEntry.record.thread_id,
                    isStarred: false,
                  });
                },
              }),
            );
          } else {
            commands.push(
              starThreadCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedEntry.record.thread_id,
                    isStarred: true,
                  });
                },
              }),
            );
          }

          if (doesFocusedNotificationHaveADraft) {
            commands.push(
              deleteDraftCommand({
                callback: async () => {
                  const [drafts] = await environment.recordLoader.getDrafts(
                    { threadId: focusedEntry.record.thread_id, currentUserId },
                    // In the environment we create and maintain a subscription to all of the user's drafts.
                    // For this reason, we can use a cache-only fetch strategy here. Note that the environment
                    // creates a subscription to the user's drafts after a delay, so if the user is initially
                    // loading Comms' inbox they won't see these drafts appear until that subscription is created.
                    // But in this case we're deciding that that is ok and this is also the way that the InboxEntry
                    // component detects if drafts are present for a given notification.
                    { fetchStrategy: "cache" },
                  );

                  await Promise.all(
                    drafts.map((draft) => {
                      return deleteDraft(environment, {
                        draftId: draft.id,
                        currentUserId,
                      });
                    }),
                  );
                },
              }),
            );
          }

          return commands;
        }
        default: {
          throw new UnreachableCaseError(focusedEntry);
        }
      }
    },
    deps: [
      focusedEntry,
      doesFocusedNotificationHaveADraft,
      inboxSectionId,
      isDefaultInboxSection,
      currentUserId,
      environment,
    ],
  });

  useRegisterThreadLabelCommands({
    threadId: focusedEntry?.table === "notification" ? focusedEntry.record.thread_id : null,
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/

async function onInboxEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TInboxEntry>;
    inboxSectionId: string;
  },
) {
  const { event, inboxSectionId } = props;

  const threadRoutePrefix = `/inbox/${inboxSectionId}`;

  switch (event.entry.table) {
    case "notification": {
      return environment.router.navigate(`${threadRoutePrefix}/threads/${event.entry.record.thread_id}`, {
        openInNewTab: isModKeyActive(event.event),
      });
    }
    case "draft": {
      return onDraftSelect(environment, {
        event: event.event,
        draft: event.entry,
        threadRoutePrefix,
      });
    }
    default: {
      throw new UnreachableCaseError(event.entry);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

function useFilteredInboxEntryIds(filteredEntries: TFilteredInboxEntry[]) {
  const [virtualizedIds, setVirtualizedIds] = useState<string[]>([]);

  useEffect(() => {
    const inboxEntryIds = filteredEntries
      .filter((entry): entry is RecordValue<"inbox_entry"> => {
        switch (entry.type) {
          case "draft":
          case "notification": {
            return true;
          }
          case "section_header":
          case "subsection_break": {
            return false;
          }
          default: {
            throw new UnreachableCaseError(entry);
          }
        }
      })
      .map((entry) => entry.id);

    setVirtualizedIds(inboxEntryIds);
  }, [filteredEntries]);

  return virtualizedIds;
}

/* -----------------------------------------------------------------------------------------------*/

function useThreadViewContext(props: {
  isThreadOpen: boolean;
  listRef: React.RefObject<IListRef<TInboxEntry>>;
  inboxSectionId: string | null | undefined;
}) {
  const { isThreadOpen, listRef, inboxSectionId } = props;

  // If the thread is open on mount, then the user has navigated directly to the thread.
  const [navigatedDirectlyToThread, setNavigatedDirectlyToThread] = useState(() => isThreadOpen);

  // If the user escapes back to the inbox after having navigated directly to the thread,
  // then we no longer treat them as having navigated directly to the thread.
  useEffect(() => {
    if (isThreadOpen) return;
    if (!navigatedDirectlyToThread) return;
    setNavigatedDirectlyToThread(false);
  }, [isThreadOpen, navigatedDirectlyToThread]);

  return useMemo((): IThreadViewContext | null => {
    if (!inboxSectionId) return null;
    return { threadList: { type: "inbox", inboxSectionId, ref: navigatedDirectlyToThread ? null : listRef } };
  }, [listRef, inboxSectionId, navigatedDirectlyToThread]);
}

/* -----------------------------------------------------------------------------------------------*/

const EndOfInboxMsg: ParentComponent<{ inboxSectionId: string; isThreadOpen: boolean }> = (props) => {
  const environment = useClientEnvironment();
  const { inboxEntries, filteredInboxEntries, clearSelectedGroups } = inboxState();
  const remainingEntries = inboxEntries.length - filteredInboxEntries.length;

  return (
    <div className={cx("text-slate-9 my-8 flex justify-center", props.isThreadOpen && "invisible")}>
      {remainingEntries > 0 ?
        <>
          <span>{remainingEntries} hidden messages &bull;&nbsp;</span>
          <button className="text-slate-8 hover:text-slate-9" onClick={() => clearSelectedGroups(environment, props)}>
            Clear filters
          </button>
        </>
      : <span className="uppercase">end</span>}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/
