import { createContext, RefObject, useMemo } from "react";
import { Subject } from "rxjs";
import { useMarkThreadSeen } from "~/hooks/useMarkThreadSeen";
import { TThreadTimelineContext, ThreadTimelineContext } from "~/components/thread-timeline-entry/context";
import { useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { ParentComponent } from "~/utils/type-helpers";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { IListRef } from "~/components/list";
import { TInboxEntry } from "../inbox/utils";
import { TTagEntry } from "../tag/utils";
import { TDoneEntry } from "../done/utils";
import { TStarredEntry } from "../starred/utils";
import { TSentEntry } from "../sent/utils";
import { TScheduledEntry } from "../scheduled/utils";
import { TRemindersEntry } from "../reminders/utils";
import { TTrashEntry } from "../trash/utils";
import { TDraftsEntry } from "../drafts/utils";
import { TSearchEntry } from "../search/utils";

/* -------------------------------------------------------------------------------------------------
 * ThreadTimelineContextProvider
 * -----------------------------------------------------------------------------------------------*/

export const ThreadTimelineContextProvider: ParentComponent<{
  threadId: string;
}> = (props) => {
  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TThreadTimelineEntry>();

  const markThreadSeen = useMarkThreadSeen(props.threadId);

  const context = useMemo<TThreadTimelineContext>(() => {
    return {
      isQuoted: false,
      collapseMessageEvents: new Subject<"expand" | "collapse" | string>(),
      onMessageInView: markThreadSeen,
      setFocusedEntry,
      useFocusedEntry,
    };
  }, [markThreadSeen, setFocusedEntry, useFocusedEntry]);

  return <ThreadTimelineContext.Provider value={context}>{props.children}</ThreadTimelineContext.Provider>;
};

export type TThreadList =
  | {
      type: "done";
      ref: RefObject<IListRef<TDoneEntry>>;
    }
  | {
      type: "drafts";
      ref: RefObject<IListRef<TDraftsEntry>>;
    }
  | {
      type: "inbox";
      inboxSectionId: string;
      /**
       * Will only be `null` if the user navigates directly to a thread rather than clicking
       * on a thread in the inbox.
       */
      ref: RefObject<IListRef<TInboxEntry>> | null;
    }
  | {
      type: "reminders";
      ref: RefObject<IListRef<TRemindersEntry>>;
    }
  | {
      type: "search";
      query: string;
      ref: RefObject<IListRef<TSearchEntry>>;
    }
  | {
      type: "scheduled";
      ref: RefObject<IListRef<TScheduledEntry>>;
    }
  | {
      type: "sent";
      ref: RefObject<IListRef<TSentEntry>>;
    }
  | {
      type: "starred";
      ref: RefObject<IListRef<TStarredEntry>>;
    }
  | {
      type: "group";
      groupId: string;
      ref: RefObject<IListRef<TTagEntry>>;
    }
  | {
      type: "label";
      labelId: string;
      ref: RefObject<IListRef<TTagEntry>>;
    }
  | {
      type: "trash";
      ref: RefObject<IListRef<TTrashEntry>>;
    };

export interface IThreadViewContext {
  threadList: TThreadList;
}

const ThreadViewContext = createContext<IThreadViewContext | null>(null);

export const useThreadViewContext = createUseContextHook(ThreadViewContext, "ThreadViewContext");

export const ThreadViewContextProvider: ParentComponent<{ context: IThreadViewContext }> = (props) => {
  return <ThreadViewContext.Provider value={props.context}>{props.children}</ThreadViewContext.Provider>;
};
