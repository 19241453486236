import { PropsWithChildren, Ref, useMemo } from "react";
import { cx } from "@emotion/css";
import { IOption, TAutocompleteSelectRef } from "./AutocompleteSelect";
import { MultiValue, SingleValue } from "react-select";
import { useUsersGroupsWithFolderAncestorRecords } from "~/hooks/useUsersGroupsWithFolderAncestorRecords";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { GroupTagRecord, RecordValue } from "libs/schema";
import { getTagFolderAncestors } from "~/queries/getTagFolderAncestors";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isTagPrivate } from "libs/schema/predicates";
import { renderGroupName } from "~/utils/tag-utils";
import Select from "react-select";

export interface IGroupOption extends IOption<string> {
  folderPaths: RecordValue<"tag">[][];
  isPrivate: boolean;
}

export async function getGroupSelectOption(
  environment: Pick<ClientEnvironment, "recordLoader">,
  groupId: string,
): Promise<IGroupOption | null> {
  const { recordLoader } = environment;

  const [[group], { folderPaths }] = await Promise.all([
    recordLoader.getRecord({ table: "tag", id: groupId }),
    getTagFolderAncestors(environment, { tagId: groupId }),
  ]);

  if (!group) return null;

  return {
    label: renderGroupName(group),
    value: group.id,
    folderPaths,
    isPrivate: isTagPrivate(group),
  };
}

/**
 * Component for selecting a group with autocomplete.
 */
export function GroupSelect<M extends boolean>(
  props: PropsWithChildren<{
    autocompleteRef?: Ref<TAutocompleteSelectRef<IGroupOption, M>>;
    value?: M extends true ? MultiValue<IGroupOption> : SingleValue<IGroupOption>;
    touched?: boolean;
    error?: string;
    onChange?: M extends true ? (newValue: MultiValue<IGroupOption>) => void
    : (newValue: SingleValue<IGroupOption>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    groupFilterFn?: (group: GroupTagRecord) => boolean;
    autoFocus?: boolean;
    autocompleteMenuEl?: HTMLDivElement | null;
    multiple?: M;
    label?: string;
    placeholder?: string;
    errorPlaceholder?: string;
    isOptionDisabledFn?: (props: { group: GroupTagRecord }) => {
      isDisabled: boolean;
      disabledReason: string | undefined;
    };
  }>,
) {
  const { groupFilterFn } = props;
  const { currentUser } = useAuthGuardContext();

  const [groups] = useUsersGroupsWithFolderAncestorRecords({
    userId: currentUser.id,
  });

  const options: IGroupOption[] = useMemo(() => {
    const isOptionDisabledFn = props.isOptionDisabledFn || (() => ({ isDisabled: false, disabledReason: undefined }));

    const filteredGroups =
      groupFilterFn ? groups.filter(({ group }) => groupFilterFn(group as GroupTagRecord)) : groups;

    return filteredGroups.map(({ group, folderPaths }) => {
      const { isDisabled, disabledReason } = isOptionDisabledFn({
        group: group as GroupTagRecord,
      });

      return {
        label: renderGroupName(group),
        value: group.id,
        folderPaths,
        isPrivate: isTagPrivate(group),
        isDisabled,
        disabledReason,
      };
    });
  }, [groups, props.isOptionDisabledFn, groupFilterFn]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none", // Remove border
      boxShadow: "none", // Remove box shadow
      zIndex: 1,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 150,
      pointerEvents: "auto",
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 150,
      pointerEvents: "auto",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "0.95rem",
      backgroundColor: "transparent",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid grey",
      borderRadius: ".25rem",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "lightgray",
    }),
  };

  return (
    <div className={cx("flex flex-1 items-center py-2")}>
      {props.label && (
        <label className={cx("my-1 mr-2", props.touched && props.error ? "text-red-9" : "text-slateDark-11")}>
          {props.label}
        </label>
      )}

      <Select
        name="groups"
        value={props.value}
        onBlur={props.onBlur}
        isClearable={!props.multiple}
        onChange={props.onChange as (newValue: MultiValue<IOption> | SingleValue<IOption>) => void}
        options={options}
        placeholder={props.touched && props.error ? props.errorPlaceholder : props.placeholder}
        autoFocus={props.autoFocus}
        isMulti={props.multiple}
        className="w-full"
        styles={customStyles}
        menuPosition="absolute"
        menuPortalTarget={document.body}
      />
    </div>
  );
}
