import { LogEvent, LoggerOptions, createLogger } from "libs/logger";
import { StatusType, datadogLogs } from "@datadog/browser-logs";
import { config } from "./config";
import { omit } from "lodash-comms";
import { isTransferrable } from "libs/predicates";
import { isUnitTest } from "libs/AppEnvironment";

export function createClientLogger(options: LoggerOptions = {}) {
  let logger = createLogger({
    enabled: !isUnitTest(config.appEnvironment),
    browser: {
      asObject: true,
      disabled: isUnitTest(config.appEnvironment),
      ...options.browser,
    },
    ...omit(options, "browser"),
    mixin: (mergeObject, level, logger) => ({
      ...(options.mixin && options.mixin(mergeObject, level, logger)),
      env: config.appEnvironment,
    }),
  });

  if (options.name) {
    // For whatever reason, Pino seems to be ignoring the name prop otherwise
    logger = logger.child({ name: options.name });
  }

  return logger;
}

export function sendLogToConsole(level: number, logObject: any) {
  if (typeof level !== "number") {
    console.warn("[sendLogObjectToConsole] unexpected level", { level });
    console.info(getFormattedLogMsg(logObject), logObject);
  } else if (level >= 50) {
    console.error(getFormattedLogMsg(logObject), logObject);
  } else if (level >= 40) {
    console.warn(getFormattedLogMsg(logObject), logObject);
  } else if (level >= 30) {
    console.info(getFormattedLogMsg(logObject), logObject);
  } else if (level >= 20) {
    console.debug(getFormattedLogMsg(logObject), logObject);
  } else if (level >= 10) {
    console.trace(getFormattedLogMsg(logObject), logObject);
  } else {
    console.debug(getFormattedLogMsg(logObject), logObject);
  }
}

export function sendLogToDatadog(level: number, logObject: any) {
  let status: StatusType;

  if (level >= 60) {
    status = "critical";
  } else if (level >= 50) {
    status = "error";
  } else if (level >= 40) {
    status = "warn";
  } else if (level >= 35) {
    status = "notice";
  } else if (level >= 30) {
    status = "info";
  } else {
    status = "debug";
  }

  datadogLogs.logger.log(logObject.msg, logObject, status, logObject.error || logObject.err);
}

export function getContextFromLogEvent(logEvent: LogEvent) {
  const data = [...logEvent.bindings, ...logEvent.messages];

  const context = data.reduce(
    (store, data) => (typeof data === "object" ? { ...store, ...data } : { ...store, msg: data }),
    {},
  );

  // If there's a message/msg prop on the context, it might take precedence over the log message
  // in datadog. As such, either we need to remove the props from the context or the context's message
  // prop and the log message need to be the same.
  context.msg = getFormattedLogMsg(context);
  delete context.message;

  return omit(context, "environment");
}

function getFormattedLogMsg(o: any) {
  let message = o.message || o.msg || "";
  const prefix = `[${o.name}]`;

  if (typeof message !== "string" || !message) {
    message = prefix;
  } else if (!message.startsWith(prefix)) {
    message = `${prefix} ${message}`;
  }

  return message as string;
}

export function getLogLevel() {
  if (typeof localStorage === "undefined") return undefined;
  return localStorage.getItem("comms:log-level") || undefined;
}

export function setLogLevel(level: string | null) {
  if (level && !["verbose", "trace", "debug", "info", "warn", "error", "fatal"].includes(level)) {
    throw new Error(`Invalid log level: ${level}. Must be one of: verbose, trace, debug, info, warn, error, fatal`);
  }

  if (level) {
    localStorage.setItem("comms:log-level", level);
  } else {
    localStorage.removeItem("comms:log-level");
  }

  console.warn(`Log level set to ${level || "default"}. You'll need to reload the page for this to take effect.`);
}

export function serializeValue(value: unknown, seen = new WeakMap()) {
  if (typeof value !== "object" || value === null) return value;
  if (isTransferrable(value)) return `[${value.constructor.name}]`;
  if (seen.has(value)) return seen.get(value);

  const obj: Record<string, unknown> = {};
  seen.set(value, obj);

  if (value instanceof Error) {
    obj.type = value.constructor.name;
    obj.msg = value.message;
    obj.stack = value.stack;
    obj.cause = serializeValue(value.cause, seen);
  }

  for (const key in value) {
    if (obj[key] !== undefined) continue;
    obj[key] = serializeValue((value as any)[key], seen);
  }

  return obj;
}

export function serializeLogEvent(logEvent: LogEvent): LogEvent {
  return {
    ...logEvent,
    messages: logEvent.messages.map((message) => serializeValue(message)),
    bindings: logEvent.bindings.map((bindings) => serializeValue(bindings)),
  };
}
