import { useState } from "react";
import { cx } from "@emotion/css";
import { List } from "~/components/list";
import useLocalStorageState from "use-local-storage-state";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { LabelTagRecord } from "libs/schema";
import { FaRegPlusSquare } from "react-icons/fa";
import { MdOutlineMoreVert, MdArchive, MdLabelOutline } from "react-icons/md";
import { DropdownMenu } from "~/components/DropdownMenu";
import { Tooltip } from "~/components/Tooltip";
import { createLabelCommand, openArchiveLabelDialogCommand } from "~/utils/common-commands";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ParentComponent } from "~/utils/type-helpers";
import { useIsRouteActive } from "~/environment/router/components";
import { useSidebarLayoutContext } from "./context";
import { usePersonalLabels } from "~/hooks/usePersonalLabels";
import { TriangleIcon } from "./SidebarGroups";
import { LabelChipEntry, onLabelSelect } from "~/components/content-list/LabelEntry";

export const SidebarLabels: ParentComponent<{}> = () => {
  const [isExpanded, setIsExpanded] = useArePersonalLabelsExpanded();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [labels] = usePersonalLabels();

  return (
    <div className="flex flex-col">
      <List.Entry
        id="labels"
        onEntryAction={({ event }) => {
          if (event.defaultPrevented) return;
          setIsExpanded((v) => !v);
        }}
      >
        <div
          className={cx(
            `group flex items-center pl-[14.4px] pr-4 py-2`,
            `focus:bg-slate-4 outline-none hover:cursor-pointer`,
            isContextMenuOpen && "bg-slate-4",
          )}
        >
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsExpanded((v) => !v);
            }}
          >
            <TriangleIcon className={cx({ "rotate-90": isExpanded })} />
          </button>

          <span className={cx("text-left font-medium")}>Labels</span>

          <span className="flex-1" />

          <LabelContextMenu open={isContextMenuOpen} setOpen={setIsContextMenuOpen} />
        </div>
      </List.Entry>

      {isExpanded && (
        <ul className="list-none flex flex-wrap gap-2 pl-10 pr-4 py-2">
          {labels.length === 0 && <CreateFirstLabelButton />}

          {labels.map((label, index) => (
            <SidebarLabelEntry key={label.id} label={label} relativeOrder={index} />
          ))}
        </ul>
      )}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const SidebarLabelEntry: ParentComponent<{
  label: LabelTagRecord;
  relativeOrder: number;
}> = (props) => {
  const environment = useClientEnvironment();
  const { label } = props;
  const isActive = useIsRouteActive({ path: "/labels/$tagId", params: { tagId: label.id } });

  if (!label) return null;

  return (
    <li className="flex flex-wrap">
      <LabelChipEntry
        labelId={label.id}
        relativeOrder={props.relativeOrder}
        onEntryAction={(props) => onLabelSelect(environment, { event: props.event, label: props.entry })}
        isActive={isActive}
      />
    </li>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const CreateFirstLabelButton: ParentComponent = () => {
  const context = useSidebarLayoutContext();

  return (
    <button
      tabIndex={-1}
      className="text-slate-9 hover:cursor-pointer hover:underline"
      onClick={async (e) => {
        e.preventDefault();

        if (context.sidebarMode() === "over") {
          // When in "over" mode, the sidebar is essentially a modal itself. We need
          // to close it before opening another modal.
          await context.setSidebarOpen(false);
        }

        createLabelCommand.trigger({ location: "SidebarLabels.CreateFirstLabel" });
      }}
    >
      Create first label...
    </button>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const LabelContextMenu: ParentComponent<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const environment = useClientEnvironment();
  const context = useSidebarLayoutContext();

  return (
    <DropdownMenu
      open={props.open}
      setOpen={props.setOpen}
      trigger={
        <div className="relative h-4">
          <div className={cx("absolute right-0 -top-1 flex")}>
            <div className="h-6 w-6" />

            <Tooltip side="bottom" content="Label options">
              <button
                type="button"
                data-testid="label-options-button"
                className="py-1 text-slate-9 hover:text-black"
                onClick={(e) => {
                  e.preventDefault();
                  props.setOpen((v) => !v);
                }}
              >
                <MdOutlineMoreVert />
              </button>
            </Tooltip>
          </div>
        </div>
      }
    >
      <DropdownMenu.Item
        icon={<FaRegPlusSquare />}
        onClick={async (e) => {
          e.preventDefault();
          props.setOpen(false);

          if (context.sidebarMode() === "over") {
            // When in "over" mode, the sidebar is essentially a modal itself. We need
            // to close it before opening another modal.
            await context.setSidebarOpen(false);
          }

          createLabelCommand.trigger({ location: "SidebarLabels.AddLabel" });
        }}
      >
        Create label
      </DropdownMenu.Item>

      <DropdownMenu.Item
        icon={<MdArchive />}
        onClick={async (e) => {
          e.preventDefault();
          props.setOpen(false);

          if (context.sidebarMode() === "over") {
            // When in "over" mode, the sidebar is essentially a modal itself. We need
            // to close it before opening another modal.
            await context.setSidebarOpen(false);
          }

          openArchiveLabelDialogCommand.trigger({ location: "SidebarLabels.ArchiveLabel" });
        }}
      >
        Archive label...
      </DropdownMenu.Item>

      <DropdownMenu.Item
        icon={<MdLabelOutline />}
        onClick={async (e) => {
          e.preventDefault();
          props.setOpen(false);
          environment.router.navigate("/archived-labels");
        }}
      >
        View archived labels
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useArePersonalLabelsExpanded() {
  const { currentUserId } = useAuthGuardContext();

  return useLocalStorageState(`${currentUserId}.sidebar.arePersonalLabelsExpanded`, { defaultValue: true });
}

/* -----------------------------------------------------------------------------------------------*/
