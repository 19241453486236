import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import type { FilterValue, LogicalOperator, ParsedToken, TextToken, Token } from "libs/searchQueryParser";
import { EMAIL_ADDRESS_REGEXP, parseStringToEmailAddress } from "libs/parseEmailAddress";
import {
  getPointer,
  generateRecordId,
  MessageGroupRecipientDoc,
  MessageUserRecipientDoc,
  InboxSectionTagRecord,
  InboxSubsectionTagRecord,
  SpecialTagTypeEnum,
} from "libs/schema";
import { RecordLoaderApi } from "libs/database";
import { promiseRaceEvery, promiseRaceSome } from "./promise-utils";
import { Logger } from "./logger";

export async function findMatchingSubsection(props: {
  currentUserId: string;
  messageId: string;
  threadId: string;
  inboxSection: InboxSectionTagRecord;
  inboxSubsections: InboxSubsectionTagRecord[];
  loader: SearchQueryMatcherRecordLoader;
  logger: Logger;
}) {
  const logger = props.logger.child({ name: "findMatchingSubsection" });

  for (const subsection of props.inboxSubsections) {
    const matchesSubsection = await module.searchQueryMatcher({
      loader: props.loader,
      currentUserId: props.currentUserId,
      messageId: props.messageId,
      threadId: props.threadId,
      parsedQuery: subsection.data.parsed_query,
      allowTopLevelFullTextQuery: true,
      logger,
    });

    if (!matchesSubsection) continue;

    return {
      section: props.inboxSection,
      subsection: subsection,
    };
  }

  return null;
}

export type SearchQueryMatcherRecordLoader = Pick<
  RecordLoaderApi,
  "getRecord" | "getThreadUserPermissions" | "getThreadUserParticipants" | "getThreadGroupPermissions" | "getThreadTags"
>;

export interface ISearchQueryMatcherProps {
  currentUserId: string;
  messageId: string;
  threadId: string;
  parsedQuery: ParsedToken[];
  allowTopLevelFullTextQuery?: boolean;
  loader: SearchQueryMatcherRecordLoader;
  logger: Logger;
}

export interface IFilterProps<T> extends ISearchQueryMatcherProps {
  token: T;
  notificationId: string;
}

async function searchQueryMatcher(props: ISearchQueryMatcherProps): Promise<boolean> {
  if (props.parsedQuery.length === 0) {
    return false;
  }
  const parsedQuery = props.parsedQuery.slice();

  const filterProps = {
    ...props,
    parsedQuery,
    notificationId: generateRecordId("notification", {
      thread_id: props.threadId,
      user_id: props.currentUserId,
    }),
  };

  // Unfortunately, the "trigram-similarity" npm package attempts to mimic
  // "similarity" matching in postgres rather than "word_similarity" matching
  // (which is what Comms users). Additionally, while it produces a pretty
  // similar similarity score, it isn't an exact match. As such, we're temporarily
  // disabling fuzzy matching. In the client, if someone attempts to add
  // a fuzzy text filter we show them an alert and then prevent them.
  //
  // // If the search query contains a plain text search phrase, the first
  // // ParsedToken in the response will be of type "text". See the
  // // searchQueryParser.ts module for more information.
  // if (parsedQuery[0].type === "text" && props.allowTopLevelFullTextQuery) {
  //   const token = parsedQuery.shift() as TextToken;
  //   const postText = props.message.subject + " " + props.message.bodyText;
  //   const similarity = trigramSimilarity(postText, token.value);
  //   if (similarity < FUZZY_MATCH_SIMILARITY_THRESHOLD) return false;
  // }

  return promiseRaceEvery(parsedQuery.map((token) => module.matchQueryToken(token, filterProps)));
}

/* -----------------------------------------------------------------------------------------------*/

function matchQueryToken(
  token: ParsedToken,
  props: ISearchQueryMatcherProps & {
    notificationId: string;
  },
): Promise<boolean> {
  const getFilterProps = <T>(token: T) => ({ ...props, token });

  switch (token.type) {
    case "text": {
      return module.textFilter(getFilterProps(token));
    }
    case "from:": {
      return promiseRaceEvery(token.value.map((value) => module.fromFilter(getFilterProps(value))));
    }
    case "to:": {
      return promiseRaceEvery(token.value.map((value) => module.toFilter(getFilterProps(value))));
    }
    case "is:": {
      return promiseRaceEvery(token.value.map((value) => module.isFilter(getFilterProps(value))));
    }
    case "after:": {
      return promiseRaceEvery(token.value.map((value) => module.afterFilter(getFilterProps(value))));
    }
    case "before:": {
      return promiseRaceEvery(token.value.map((value) => module.beforeFilter(getFilterProps(value))));
    }
    case "viewer:": {
      return promiseRaceEvery(token.value.map((value) => module.viewerFilter(getFilterProps(value))));
    }
    case "participating:": {
      return promiseRaceEvery(token.value.map((value) => module.participatingFilter(getFilterProps(value))));
    }
    case "group:": {
      return promiseRaceEvery(token.value.map((value) => module.groupFilter(getFilterProps(value))));
    }
    case "label:": {
      return promiseRaceEvery(token.value.map((value) => module.labelFilter(getFilterProps(value))));
    }
    case "subject:": {
      return promiseRaceEvery(token.value.map((value) => module.subjectFilter(getFilterProps(value))));
    }
    case "body:": {
      return promiseRaceEvery(token.value.map((value) => module.bodyFilter(getFilterProps(value))));
    }
    case "has:": {
      return promiseRaceEvery(token.value.map((value) => module.hasFilter(getFilterProps(value))));
    }
    case "remind-after:": {
      return promiseRaceEvery(token.value.map((value) => module.remindAfterFilter(getFilterProps(value))));
    }
    case "remind-before:": {
      return promiseRaceEvery(token.value.map((value) => module.remindBeforeFilter(getFilterProps(value))));
    }
    case "mentions:": {
      return promiseRaceEvery(token.value.map((value) => module.mentionsFilter(getFilterProps(value))));
    }
    case "priority:": {
      return promiseRaceEvery(token.value.map(async (value) => module.priorityFilter(getFilterProps(value))));
    }
    case "and()": {
      return module.andOperator({
        ...props,
        token: token as LogicalOperator<"and()">,
      });
    }
    case "or()": {
      return module.orOperator({
        ...props,
        token: token as LogicalOperator<"or()">,
      });
    }
    case "not()": {
      return module.notOperator({
        ...props,
        token: token as LogicalOperator<"not()">,
      });
    }
    default: {
      throw new UnreachableCaseError(token, `matchQueryToken: unreachable case ${token}`);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function textFilter(props: IFilterProps<TextToken>) {
  const lowercaseInput = props.token.value.toLowerCase();
  const [[message], [thread]] = await Promise.all([
    props.loader.getRecord("message", props.messageId),
    props.loader.getRecord("thread", props.threadId),
  ]);

  if (!message || !thread) return false;

  return (
    thread.subject.toLowerCase().includes(lowercaseInput) || message.body_text.toLowerCase().includes(lowercaseInput)
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function fromFilter(props: IFilterProps<FilterValue<"from">>) {
  const { token, currentUserId } = props;

  switch (token.type) {
    case "text": {
      if (token.value === "me") {
        return module.isMessageSentByUser(props, currentUserId);
      } else if (isEmail(token.value)) {
        return module.isMessageSentByEmail(props, token.value);
      } else {
        return module.isMessageSentByName(props, token.value);
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return module.isMessageSentByUser(props, subjectId);
        }
        case "group":
        case "label": {
          // TODO
          // if someone indicates "from" a group or label, they probably mean that
          // they want messages which they received an inbox notification for
          // because the message is associated with that group or label (and, presumably,
          // the current user had a subscription to the group/label at the time).
          // This is something we should try to support.
          props.logger.warn(`[fromFilter] Attempted to filter on "from:" ${subject}, ignoring.`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token, `fromFilter: unreachable case ${token}`);
    }
  }
}

async function isMessageSentByEmail(props: IFilterProps<unknown>, input: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      if (!message.sender_user_id) return false;

      const [user] = await props.loader.getRecord("user_contact_info", message.sender_user_id);

      return user?.email_address.toLowerCase() === lowercaseInput;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("isMessageSentByEmail: email not implemented");
      // const [emailRecipients] = await props.loader.getMessageEmailRecipients({
      //   message_id: message.id,
      //   type: "FROM",
      // });

      // return emailRecipients.some((r) => {
      //   const email = parseStringToEmailAddress(r.email_address);
      //   if (!email) return false;

      //   return typeof email.address === "string"
      //     ? email.address.toLowerCase() === lowercaseInput
      //     : email.addresses
      //     ? email.addresses.some(
      //         (g) => g.address.toLowerCase() === lowercaseInput,
      //       )
      //     : throwUnreachableCaseError(email);
      // });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentByName(props: IFilterProps<unknown>, input: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      if (!message.sender_user_id) return false;
      const [user] = await props.loader.getRecord("user_profile", message.sender_user_id);

      if (!user) return false;

      return user.name.toLowerCase().includes(lowercaseInput);
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("isMessageSentByName: email not implemented");

      // const [emailRecipients] = await props.loader.getMessageEmailRecipients({
      //   message_id: message.id,
      //   type: "FROM",
      // });

      // return emailRecipients.some((r) => {
      //   const email = parseStringToEmailAddress(r.email_address);
      //   if (!email) return false;

      //   return (
      //     email.label?.toLowerCase().includes(lowercaseInput) ||
      //     (typeof email.address === "string"
      //       ? email.address.toLowerCase().includes(lowercaseInput)
      //       : email.addresses
      //       ? email.addresses.some((g) =>
      //           g.address.toLowerCase().includes(lowercaseInput),
      //         )
      //       : throwUnreachableCaseError(email))
      //   );
      // });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentByUser(props: IFilterProps<unknown>, userId: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  switch (message.type) {
    case "COMMS": {
      return message.sender_user_id === userId;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("isMessageSentByUser: email not implemented");

      // if (message.sender_user_id === userId) return true;

      // const [user] = await props.loader.getRecord("user_contact_info", userId);

      // if (!user) return false;

      // const email = parseStringToEmailAddress(user.email_address);

      // if (!email?.address) return false;

      // return module.isMessageSentByEmail(props, email.address);
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function toFilter(props: IFilterProps<FilterValue<"to">>) {
  const { token, currentUserId } = props;

  switch (token.type) {
    case "text": {
      if (token.value === "me") {
        return module.isMessageSentToUser(props, currentUserId);
      } else if (isEmail(token.value)) {
        return module.isMessageSentToEmail(props, token.value);
      } else {
        return module.isMessageSentToName(props, token.value);
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return module.isMessageSentToUser(props, subjectId);
        }
        case "group": {
          return module.isMessageSentToGroup(props, subjectId);
        }
        case "label": {
          props.logger.warn(`[toFilter] Attempted to filter on "to:" label, ignoring.`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

async function isMessageSentToEmail(props: IFilterProps<unknown>, input: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;
  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      const userRecipients = message.to.filter((r): r is MessageUserRecipientDoc => r.type === "USER");

      const users = await Promise.all(
        userRecipients.map((r) => props.loader.getRecord("user_contact_info", r.user_id)),
      );

      return users.some(([user]) => {
        if (!user) return false;
        const email = parseStringToEmailAddress(user.email_address);
        if (!email?.address) return false;
        return email.address.toLowerCase() === lowercaseInput;
      });
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("isMessageSentToEmail: email not implemented");

      // const [recipients] = await props.loader.getMessageEmailRecipients({
      //   message_id: message.id,
      //   type: "TO",
      // });

      // return recipients.some((r) => {
      //   const email = parseStringToEmailAddress(r.email_address);
      //   if (!email) return false;

      //   return typeof email.address === "string"
      //     ? email.address.toLowerCase() === lowercaseInput
      //     : email.addresses
      //     ? email.addresses.some(
      //         (g) => g.address.toLowerCase() === lowercaseInput,
      //       )
      //     : throwUnreachableCaseError(email);
      // });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentToName(props: IFilterProps<unknown>, input: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;
  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      const userRecipients = message.to.filter((r): r is MessageUserRecipientDoc => r.type === "USER");

      const users = await Promise.all(userRecipients.map((r) => props.loader.getRecord("user_profile", r.user_id)));

      return users.some(([user]) => {
        if (!user) return false;
        return user.name.toLowerCase().includes(lowercaseInput);
      });
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("isMessageSentToName: email not implemented");

      // const [recipients] = await props.loader.getMessageEmailRecipients({
      //   message_id: message.id,
      //   type: "TO",
      // });

      // return recipients.some((r) => {
      //   const email = parseStringToEmailAddress(r.email_address);
      //   if (!email) return false;

      //   return (
      //     email.label?.toLowerCase().includes(lowercaseInput) ||
      //     (typeof email.address === "string"
      //       ? email.address.toLowerCase().includes(lowercaseInput)
      //       : email.addresses
      //       ? email.addresses.some((g) =>
      //           g.address.toLowerCase().includes(lowercaseInput),
      //         )
      //       : throwUnreachableCaseError(email))
      //   );
      // });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentToUser(props: IFilterProps<unknown>, userId: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  switch (message.type) {
    case "COMMS": {
      return message.to.some((r) => r.type === "USER" && r.user_id === userId);
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("isMessageSentToUser: email not implemented");

      // if (message.to.some((r) => r.type === "USER" && r.user_id === userId)) {
      //   return true;
      // }

      // const [user] = await props.loader.getRecord("user_contact_info", userId);

      // if (!user) return false;

      // const email = parseStringToEmailAddress(user.email_address);

      // if (!email?.address) return false;

      // return module.isMessageSentToEmail(props, email.address);
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentToGroup(props: IFilterProps<unknown>, groupId: string) {
  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  return message.to.some((r) => r.type === "GROUP" && r.group_id === groupId);
}

/* -----------------------------------------------------------------------------------------------*/

async function isFilter(props: IFilterProps<FilterValue<"is">>) {
  const { token } = props;

  if (token.type === "DocId") {
    props.logger.warn(`[isFilter] Document ID passed to "is:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  switch (token.value) {
    case "done": {
      const [notification] = await props.loader.getRecord("notification", props.notificationId);

      return !!notification?.is_done;
    }
    case "branch": {
      const [thread] = await props.loader.getRecord("thread", props.threadId);
      return !!thread?.is_branch;
    }
    case "private": {
      const [thread] = await props.loader.getRecord("thread", props.threadId);
      return thread?.visibility === "PRIVATE";
    }
    case "shared": {
      const [thread] = await props.loader.getRecord("thread", props.threadId);
      return thread?.visibility === "SHARED";
    }
    case "seen": {
      const [message] = await props.loader.getRecord("message", props.messageId);

      if (!message) return false;
      if (message.type === "EMAIL_BCC") return true;

      const [seen] = await props.loader.getRecord(
        getPointer("thread_seen_receipt", {
          thread_id: props.threadId,
          user_id: props.currentUserId,
        }),
      );

      return !!seen;
    }
    case "email": {
      const [message] = await props.loader.getRecord("message", props.messageId);

      if (!message) return false;
      return message.type === "EMAIL" || message.type === "EMAIL_BCC";
    }
    default: {
      props.logger.warn(`[isFilter] Unknown option passed to "is:".`, token);
      return false;
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function afterFilter(props: IFilterProps<FilterValue<"after">>) {
  const { token } = props;

  if (token.type === "DocId") {
    props.logger.warn(`[afterFilter] Document ID passed to "after:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const timestamp = module.convertStringToTimestamp(token.value);

  if (!timestamp) {
    props.logger.warn(`[afterFilter] Invalid value provided to "after:" filter`);
    return false;
  }

  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  return message.sent_at >= timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function beforeFilter(props: IFilterProps<FilterValue<"before">>) {
  const { token } = props;

  if (token.type === "DocId") {
    props.logger.warn(`[beforeFilter] Document ID passed to "before:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const timestamp = module.convertStringToTimestamp(token.value);

  if (!timestamp) {
    props.logger.warn(`[beforeFilter] Invalid value provided to "after:" filter`);
    return false;
  }

  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  return message.sent_at < timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function viewerFilter(props: IFilterProps<FilterValue<"viewer">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [permittedUsers] = await props.loader.getThreadUserPermissions({
        thread_id: props.threadId,
      });

      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return permittedUsers.length === asNumber;
      } else if (token.value === "me") {
        return permittedUsers.some(({ user_id }) => user_id === props.currentUserId);
      } else if (isEmail(token.value)) {
        const lowercaseEmail = token.value.toLowerCase();

        const users = await Promise.all(
          permittedUsers.map((user) => props.loader.getRecord("user_contact_info", user.id)),
        );

        return users.some(([user]) => {
          if (!user) return false;
          const email = parseStringToEmailAddress(user.email_address);
          if (!email?.address) return false;
          return email.address.toLowerCase() === lowercaseEmail;
        });
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const users = await Promise.all(permittedUsers.map((user) => props.loader.getRecord("user_profile", user.id)));

        return users.some(([user]) => user?.name.toLowerCase().includes(lowercaseInput));
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const [permittedUsers] = await props.loader.getThreadUserPermissions({
            thread_id: props.threadId,
          });

          return permittedUsers.some(({ user_id }) => user_id === subjectId);
        }
        case "group":
        case "label": {
          props.logger.warn(`[viewerFilter] User provided a specified a ${subject} for "viewer:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function participatingFilter(props: IFilterProps<FilterValue<"participating">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [participatingUsers] = await props.loader.getThreadUserParticipants({
        thread_id: props.threadId,
      });

      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return participatingUsers.length === asNumber;
      } else if (token.value === "me") {
        return participatingUsers.some(({ user_id }) => user_id === props.currentUserId);
      } else if (isEmail(token.value)) {
        const lowercaseEmail = token.value.toLowerCase();

        const users = await Promise.all(
          participatingUsers.map((user) => props.loader.getRecord("user_contact_info", user.id)),
        );

        return users.some(([user]) => {
          if (!user) return false;
          const email = parseStringToEmailAddress(user.email_address);
          if (!email?.address) return false;
          return email.address.toLowerCase() === lowercaseEmail;
        });
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const users = await Promise.all(
          participatingUsers.map((user) => props.loader.getRecord("user_profile", user.id)),
        );

        return users.some(([user]) => user?.name.toLowerCase().includes(lowercaseInput));
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const [participatingUsers] = await props.loader.getThreadUserParticipants({
            thread_id: props.threadId,
          });

          return participatingUsers.some(({ user_id }) => user_id === subjectId);
        }
        case "group":
        case "label": {
          props.logger.warn(`[participatingFilter] User provided a ${subject} for "participating:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function groupFilter(props: IFilterProps<FilterValue<"group">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [permittedGroups] = await props.loader.getThreadGroupPermissions({
        thread_id: props.threadId,
      });

      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return permittedGroups.length === asNumber;
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const groups = await Promise.all(
          permittedGroups.map(({ group_id }) => props.loader.getRecord("tag", group_id)),
        );

        return groups.some(([group]) => group?.name.toLowerCase().includes(lowercaseInput));
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          props.logger.warn(`[groupFilter] User provided a user for "group:".`);
          return false;
        }
        case "group": {
          const [permittedGroups] = await props.loader.getThreadGroupPermissions({
            thread_id: props.threadId,
          });

          return permittedGroups.some(({ group_id }) => group_id === subjectId);
        }
        case "label": {
          props.logger.warn(`[groupFilter] User provided a label for "group:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function labelFilter(props: IFilterProps<FilterValue<"label">>) {
  const { token } = props;

  const [threadLabels] = await props.loader.getThreadTags({
    thread_id: props.threadId,
    tag_type: SpecialTagTypeEnum.LABEL,
  });

  // This filters out deleted labels
  const labels = await Promise.all(
    threadLabels.map((threadLabel) => props.loader.getRecord("tag", threadLabel.tag_id)),
  );

  switch (token.type) {
    case "text": {
      props.logger.error(`[labelFilter] text value for "label:".`);
      return false;
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          props.logger.error(`[labelFilter] userId provided for "label:".`);
          return false;
        }
        case "group": {
          props.logger.error(`[labelFilter] groupId provided for "label:".`);
          return false;
        }
        case "label": {
          return labels.some(([tag]) => tag?.id === subjectId);
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function subjectFilter(props: IFilterProps<FilterValue<"subject">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [thread] = await props.loader.getRecord("thread", props.threadId);
      if (!thread) return false;
      return thread.subject.toLowerCase().includes(token.value.toLowerCase());
    }
    case "DocId": {
      props.logger.warn(`[subjectFilter] provided a docId to "subject:".`);
      return false;
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function bodyFilter(props: IFilterProps<FilterValue<"body">>) {
  const { token } = props;

  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  switch (token.type) {
    case "text": {
      return message.body_text.toLowerCase().includes(token.value.toLowerCase());
    }
    case "DocId": {
      props.logger.warn(`[bodyFilter] provided a docId to "body:".`);
      return false;
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function hasFilter(props: IFilterProps<FilterValue<"has">>) {
  const { token } = props;

  if (token.type === "DocId") {
    props.logger.warn(`[hasFilter] Document ID passed to "has:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const [notification] = await props.loader.getRecord("notification", props.notificationId);

  if (!notification) return false;

  switch (token.value) {
    case "reminder": {
      return notification.has_reminder;
    }
    case "notification": {
      return !!notification;
    }
    default: {
      props.logger.warn(`[hasFilter] provided unknown value "${token.value}" to "has:".`);
      return false;
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function remindAfterFilter(props: IFilterProps<FilterValue<"remindAfter">>) {
  const { token } = props;

  if (token.type === "DocId") {
    props.logger.warn(`[remindAfterFilter] Document ID passed to "remind-after:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const [notification] = await props.loader.getRecord("notification", props.notificationId);

  if (!notification?.remind_at) return false;

  const timestamp = module.convertStringToTimestamp(token.value);

  if (!timestamp) {
    props.logger.warn(`[remindAfterFilter] Invalid value provided to "remind-after:" filter`);
    return false;
  }

  return notification.remind_at >= timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function remindBeforeFilter(props: IFilterProps<FilterValue<"remindBefore">>) {
  const { token } = props;

  if (token.type === "DocId") {
    props.logger.warn(`[remindBeforeFilter] Document ID passed to "remind-before:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const [notification] = await props.loader.getRecord("notification", props.notificationId);

  if (!notification?.remind_at) return false;

  const timestamp = module.convertStringToTimestamp(token.value);

  if (!timestamp) {
    props.logger.warn(`[remindBeforeFilter] Invalid value provided to "remind-before:" filter`);
    return false;
  }

  return notification.remind_at < timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function mentionsFilter(props: IFilterProps<FilterValue<"mentions">>) {
  const { token } = props;

  const [message] = await props.loader.getRecord("message", props.messageId);

  if (!message) return false;

  switch (token.type) {
    case "text": {
      const mentionedUserIds = message.to
        .filter((r): r is MessageUserRecipientDoc => r.type === "USER" && r.is_mentioned)
        .map((r) => r.user_id);

      if (token.value === "me") {
        return mentionedUserIds.includes(props.currentUserId);
      } else if (isEmail(token.value)) {
        const users = await Promise.all(
          mentionedUserIds.map((userId) => props.loader.getRecord("user_contact_info", userId)),
        );

        const lowercaseInput = token.value.toLowerCase();

        return users.some(([user]) => {
          if (!user) return false;

          const email = parseStringToEmailAddress(user.email_address);
          if (!email) return false;

          return (
            typeof email.address === "string" ? email.address.toLowerCase() === lowercaseInput
            : email.addresses ? email.addresses.some((g) => g.address.toLowerCase() === lowercaseInput)
            : throwUnreachableCaseError(email)
          );
        });
      } else {
        const users = await Promise.all(
          mentionedUserIds.map((userId) => props.loader.getRecord("user_profile", userId)),
        );

        const lowercaseInput = token.value.toLowerCase();

        return users.some(([user]) => {
          if (!user) return false;
          return user.name.toLowerCase().includes(lowercaseInput);
        });
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const mentionedUsers = message.to.filter(
            (r): r is MessageUserRecipientDoc => r.type === "USER" && r.is_mentioned,
          );

          const isSubjectMentioned = mentionedUsers.some((r) => r.user_id === subjectId);

          return isSubjectMentioned;
        }
        case "group": {
          const mentionedGroups = message.to.filter(
            (r): r is MessageGroupRecipientDoc => r.type === "GROUP" && r.is_mentioned,
          );

          const isSubjectMentioned = mentionedGroups.some((r) => r.group_id === subjectId);

          return isSubjectMentioned;
        }
        case "label": {
          props.logger.warn(`[mentionsFilter] User provided a label for "mentions:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function priorityFilter(props: IFilterProps<FilterValue<"priority">>) {
  const { token } = props;

  if (token.type !== "text") {
    throw new UnreachableCaseError(token.value as never);
  }

  const [notification] = await props.loader.getRecord("notification", props.notificationId);

  if (!notification) return false;

  const hasPriority = (priority: number) => notification.priority === priority;

  if (token.value.includes("@@@") || token.value === "100") {
    return hasPriority(100);
  } else if (token.value.includes("@@") || token.value === "200") {
    return hasPriority(200);
  } else if (token.value.includes("@") || token.value === "300") {
    return hasPriority(300);
  } else if (token.value === "participating" || token.value === "400") {
    return hasPriority(400);
  } else if (token.value === "subscriber" || token.value === "500") {
    return hasPriority(500);
  } else {
    throw new UnreachableCaseError(token.value as never);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function andOperator(props: IFilterProps<LogicalOperator<"and()">>) {
  return promiseRaceEvery(props.token.value.map((token) => module.matchQueryToken(token, props)));
}

/* -----------------------------------------------------------------------------------------------*/

function orOperator(props: IFilterProps<LogicalOperator<"or()">>) {
  return promiseRaceSome(props.token.value.map((token) => module.matchQueryToken(token, props)));
}

/* -----------------------------------------------------------------------------------------------*/

async function notOperator(props: IFilterProps<LogicalOperator<"not()">>) {
  const result = await promiseRaceEvery(props.token.value.map((token) => module.matchQueryToken(token, props)));

  return !result;
}

/* -----------------------------------------------------------------------------------------------*/

function isEmail(input: string) {
  return EMAIL_ADDRESS_REGEXP.test(input);
}

export type ParsedDocId = {
  subject: "user" | "group" | "label";
  mentionLevel: string;
  subjectId: string;
};

export function parseDocId(token: Token<"DocId", string>): ParsedDocId {
  const [subject, mentionLevel, subjectId] = token.value.split("::") as ["user" | "group" | "label", string, string];

  return { subject, mentionLevel, subjectId };
}

/* -----------------------------------------------------------------------------------------------*/

function convertStringToTimestamp(input: string): string | null {
  const dateMatch = input.match(/(\d{4})\/(\d{1,2})\/(\d{1,2})/);

  if (!dateMatch) return null;

  const date = new Date(dateMatch[0]);

  if (isNaN(date.valueOf())) return null;

  return date.toISOString();
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Recursively checks if a parsed token structure matches a predicate.
 * @param token The parsed token to search through
 * @param predicate The predicate to search for
 * @returns True if the predicate is found, false otherwise
 */

export function isInParsedToken(token: ParsedToken, predicate: (token: ParsedToken) => boolean): boolean {
  // Check if the token is a filter with the target type
  if ("type" in token) {
    // Check if it's the exact filter we're looking for
    if (predicate(token)) {
      return true;
    }

    // Check if it's a logical operator with nested tokens
    if (["and()", "or()", "not()"].includes(token.type) && "value" in token && Array.isArray(token.value)) {
      // Recursively search through nested tokens
      for (const nestedToken of token.value) {
        if (isInParsedToken(nestedToken as ParsedToken, predicate)) {
          return true;
        }
      }
    }
  }

  // No match found
  return false;
}

/* -----------------------------------------------------------------------------------------------*/

/** This object exists to support mocking in tests */
export const module = {
  findMatchingSubsection,
  searchQueryMatcher,
  matchQueryToken,
  textFilter,
  fromFilter,
  isMessageSentByEmail,
  isMessageSentByName,
  isMessageSentByUser,
  toFilter,
  isMessageSentToEmail,
  isMessageSentToName,
  isMessageSentToUser,
  isMessageSentToGroup,
  isFilter,
  afterFilter,
  beforeFilter,
  viewerFilter,
  participatingFilter,
  groupFilter,
  labelFilter,
  subjectFilter,
  bodyFilter,
  hasFilter,
  remindAfterFilter,
  remindBeforeFilter,
  mentionsFilter,
  priorityFilter,
  andOperator,
  orOperator,
  notOperator,
  isEmail,
  convertStringToTimestamp,
};

/* -----------------------------------------------------------------------------------------------*/
