import { ComponentType, useCallback, useRef } from "react";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { TextInput } from "~/components/forms/TextInput";
import { OutlineButton } from "~/components/OutlineButtons";
import { withDepsGuard } from "~/route-guards/withDepsGuard";
import { DIALOG_CONTENT_WRAPPER_CSS, DialogState, DialogTitle, withModalDialog } from "../withModalDialog";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useControlState } from "~/components/forms/utils";
import {
  Field,
  QueryBuilder,
  ValueEditorProps,
  ValueEditor,
  OperatorSelectorProps,
  ActionProps,
  NotToggleProps,
  FieldSelectorProps,
  CombinatorSelectorProps,
  RuleGroupType,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { closeDialogCommand } from "~/utils/common-commands";
import { UserSelect } from "~/components/forms/UserSelect";
import { ParentComponent } from "~/utils/type-helpers";
import { cx } from "@emotion/css";
import { GroupSelect } from "~/components/forms/GroupSelect";
import { DialogFooter } from "../DialogLayout";
import { MdOutlineRemoveCircle } from "react-icons/md";
import { Tooltip } from "~/components/Tooltip";
import { SwitchPrimitive } from "~/components/forms/SwitchInput";
import { MdAdd } from "react-icons/md";
import { Merge } from "type-fest";
import { LabelSelect } from "~/components/forms/LabelSelect";

export type EditInboxSubsectionDialogData = {
  id: string | null;
  name: string | null;
  query: RuleGroupType | null;
};

export type EditInboxSubsectionDialogReturnData =
  | EditInboxSubsectionDialogReturnDelete
  | EditInboxSubsectionDialogReturnSubmit
  | void;

type EditInboxSubsectionDialogReturnDelete = { type: "delete" };

export type EditInboxSubsectionDialogReturnSubmit = {
  type: "submit";
  data: {
    id: string | null;
    name: string;
    query: RuleGroupType;
  };
};

export const EditInboxSubsectionDialogState = new DialogState<
  EditInboxSubsectionDialogData,
  EditInboxSubsectionDialogReturnData
>();

export const EditInboxSubsectionDialog = withModalDialog({
  dialogState: EditInboxSubsectionDialogState as any,
  loadData,
  Component: (props) => <DialogComponent {...props} />,
});

type LoadDataResult = Awaited<ReturnType<typeof loadData>>;

async function loadData(props: { data?: EditInboxSubsectionDialogData; environment: ClientEnvironment }) {
  if (!props.data) {
    throw new Error("No data provided to EditInboxSubsectionDialog");
  }

  return props.data;
}

const DialogComponent = withDepsGuard<{ data: LoadDataResult }>()({
  useDepsFactory(props) {
    const { data } = props;

    const control = useControl(() => {
      const query = data.query || {
        combinator: "and",
        not: false,
        rules: [{ field: "from", operator: "=", value: "" }],
      };

      return createFormGroup({
        id: createFormControl<string | null>(data.id || null),
        name: createFormControl(data.name || "", {
          required: true,
        }),
        query: createFormControl<RuleGroupType>(query, {
          required: true,
        }),
      });
    });

    if (!control) return null;

    return { control };
  },
  Component: (props) => {
    const { control: subsectionControl } = props;

    const isNewPage = useControlState(() => subsectionControl.rawValue.id === null, [subsectionControl]);

    const scrollboxRef = useRef<HTMLElement>(document.body);
    const headerRef = useRef<HTMLElement>(null);

    useTopScrollShadow({
      scrollboxRef,
      targetRef: headerRef,
    });

    const onSubmit = useCallback(() => {
      if (!subsectionControl.isValid) {
        subsectionControl.children.markTouched(true);
        return;
      }

      EditInboxSubsectionDialogState.close({
        type: "submit",
        // We're using jsonClone here to remove any proxies that may be present in the rawValue.
        // See the comment below in the QueryInput component for more details.
        data: jsonClone(subsectionControl.rawValue),
      });
    }, [subsectionControl]);

    const onDelete = useCallback(() => {
      const confirmed = confirm("Are you sure you want to delete this subsection?");
      if (!confirmed) return;
      EditInboxSubsectionDialogState.close({ type: "delete" });
    }, []);

    useRegisterCommands({
      commands: () => {
        const commands: ICommandArgs[] = [
          closeDialogCommand({
            callback: () => {
              EditInboxSubsectionDialogState.close();
            },
          }),
          {
            label: "Submit",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: onSubmit,
          },
        ];

        return commands;
      },
      deps: [onSubmit],
    });

    return (
      <div>
        <DialogTitle>
          <h2>{isNewPage ? "Add section" : "Edit section"}</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="flex mt-4 mx-4">
            <h4 className="text-lg mr-3 text-slate-9">Name</h4>

            <TextInput
              control={subsectionControl.controls.name}
              name="name"
              placeholder="text"
              autoFocus
              className="text-lg"
            />
          </div>

          <div className={cx("m-4")}>
            <QueryInput control={subsectionControl.controls.query} />
          </div>
        </div>

        <DialogFooter>
          <OutlineButton onClick={() => closeDialogCommand.trigger()}>
            <small>Cancel</small>
          </OutlineButton>

          <div className="w-2" />

          <OutlineButton onClick={onSubmit}>
            <small>Submit</small>
          </OutlineButton>

          <div className="flex-1" />

          {!isNewPage && (
            <OutlineButton onClick={onDelete}>
              <small>Delete</small>
            </OutlineButton>
          )}
        </DialogFooter>
      </div>
    );
  },
});

function jsonClone<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

const QueryInput: ParentComponent<{ control: IFormControl<RuleGroupType> }> = (props) => {
  // FormControl reactivity is implemented by wrapping internal state in proxies, including
  // the value/rawValue returned by a FormControl. Usually this is transparent and doesn't affect
  // consumption, but for whatever reason the proxies seem to cause react query builder to throw
  // an error. By jsonCloning the value here, we can remove the proxies and avoid the error.
  const query = useControlState(() => jsonClone(props.control.rawValue), [props.control]);

  return (
    <QueryBuilder
      query={query}
      onQueryChange={(query) => props.control.setValue(query)}
      fields={fields as any}
      addRuleToNewGroups
      showNotToggle
      controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
      controlElements={{
        valueEditor: CustomValueEditor,
        operatorSelector: CustomOperatorSelector,
        removeGroupAction: CustomRemoveGroupAction,
        removeRuleAction: CustomRemoveRuleAction,
        notToggle: CustomNotToggle,
        fieldSelector: CustomSelect,
        combinatorSelector: CustomCombinatorSelector,
        addRuleAction: CustomAddRuleAction,
        addGroupAction: CustomAddGroupAction,
      }}
    />
  );
};

const CustomValueEditor: ParentComponent<ValueEditorProps> = (props) => {
  const { testID, type, inputType, value, handleOnChange } = props;

  switch (type as CommsField["valueEditorType"]) {
    case "userselect": {
      return <UserSelect multiple={false} value={value} onChange={(newValue) => handleOnChange(newValue)} />;
    }
    case "groupselect": {
      return <GroupSelect multiple={false} value={value} onChange={(newValue) => handleOnChange(newValue)} />;
    }
    case "labelselect": {
      return <LabelSelect multiple={false} value={value} onChange={(newValue) => handleOnChange(newValue)} />;
    }
    case "text": {
      switch (inputType) {
        case "date": {
          return (
            <input
              type="date"
              data-testid={testID}
              value={value}
              onChange={(e) => handleOnChange(e.target.value)}
              className="rounded h-[38px]"
              style={{ padding: "calc(-1px + 0.5rem) 8px" }}
            />
          );
        }
      }
    }
    default: {
      return (
        <ValueEditor
          {...props}
          className={cx(
            props.className,
            (props.fieldData.hideValueEditor as boolean | undefined) && "invisible absolute",
          )}
        />
      );
    }
  }
};

const CustomSelect: ParentComponent<FieldSelectorProps> = (props) => {
  // This color is chosen to match the chevron color of react-select, which
  // is also used in the query builder.
  const controlChevronColor = "rgb(204, 204, 204)";

  return (
    <div className="relative py-2">
      <select
        data-testid={props.testID}
        className="relative rounded appearance-none"
        style={{ padding: "calc(0.5rem - 1px)", paddingLeft: "8px", paddingRight: "51px" }}
        value={props.value}
        onChange={(e) => props.handleOnChange(e.target.value)}
      >
        {props.options.map((option: any) => (
          <option key={option.name} value={option.name} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </select>

      <div
        className={cx(
          `absolute w-[38px] h-[24px] top-[15px] right-0 border-l-[1px]`,
          `flex items-center justify-center pointer-events-none`,
        )}
        style={{ borderColor: controlChevronColor }}
      >
        <ChevronDownIcon style={{ fill: controlChevronColor }} />
      </div>
    </div>
  );
};

const ChevronDownIcon: ComponentType<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" {...props}>
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
  </svg>
);

const CustomCombinatorSelector: ParentComponent<CombinatorSelectorProps> = (props) => {
  return (
    <select
      data-testid={props.testID}
      className="relative rounded"
      style={{ padding: "0.15rem 2px" }}
      value={props.value}
      onChange={(e) => props.handleOnChange(e.target.value)}
    >
      {props.options.map((option: any) => (
        <option key={option.name} value={option.name}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const CustomOperatorSelector: ParentComponent<OperatorSelectorProps> = (props) => {
  return (
    <select
      data-testid={props.testID}
      data-operator-type={props.value}
      title={props.title}
      value={props.value}
      onChange={(e) => props.handleOnChange(e.target.value)}
      className={cx((props.fieldData.hideOperators as boolean | undefined) && "invisible absolute", props.className)}
    >
      {props.options.map((op: any) => (
        <option key={op.name} value={op.name}>
          {op.label}
        </option>
      ))}
    </select>
  );
};

const CustomRemoveGroupAction: ParentComponent<ActionProps> = (props) => {
  return (
    <Tooltip side="bottom" content="Remove group">
      <OutlineButton
        data-testid={props.testID}
        className={cx(props.className, "uppercase px-2 py-1 text-[60%] font-medium rounded")}
        onClick={props.handleOnClick}
        disabled={props.disabled}
      >
        Remove
      </OutlineButton>
    </Tooltip>
  );
};

const CustomRemoveRuleAction: ParentComponent<ActionProps> = (props) => {
  if (props.path.at(-1) === 0) return null;

  return (
    <Tooltip side="bottom" content="Remove rule">
      <button
        type="button"
        data-testid={props.testID}
        className={cx(props.className, "p-1 rounded-full text-slateA-9 hover:bg-slateA-5 hover:text-black")}
        onClick={props.handleOnClick}
        disabled={props.disabled}
      >
        <MdOutlineRemoveCircle className="h-5 w-5" />
      </button>
    </Tooltip>
  );
};

const CustomNotToggle: ParentComponent<NotToggleProps> = (props) => {
  const id = `notToggle-${props.path.join("-")}`;

  return (
    <Tooltip side="bottom" content="Only match messages that do not meet these rules">
      <div className="flex mr-auto items-center mx-2">
        <SwitchPrimitive
          id={id}
          checked={props.checked}
          onCheckedChange={props.handleOnChange}
          disabled={props.disabled}
          className={props.className}
        />

        <label htmlFor={id} className="ml-2">
          Not
        </label>
      </div>
    </Tooltip>
  );
};

const CustomAddRuleAction: ParentComponent<ActionProps> = (props) => {
  return (
    <Tooltip side="bottom" content="Add rule">
      <OutlineButton
        data-testid={props.testID}
        className={cx(props.className, "uppercase px-2 py-1 text-[60%] font-medium rounded")}
        onClick={props.handleOnClick}
        disabled={props.disabled}
      >
        <MdAdd className="w-3 h-3 mr-1" /> Rule
      </OutlineButton>
    </Tooltip>
  );
};

const CustomAddGroupAction: ParentComponent<ActionProps> = (props) => {
  return (
    <Tooltip side="bottom" content="Add group">
      <OutlineButton
        data-testid={props.testID}
        className={cx(props.className, "uppercase px-2 py-1 text-[60%] font-medium rounded")}
        onClick={props.handleOnClick}
        disabled={props.disabled}
      >
        <MdAdd className="w-3 h-3 mr-1" /> Group
      </OutlineButton>
    </Tooltip>
  );
};

type CommsField = Merge<
  Field,
  {
    valueEditorType?: Field["valueEditorType"] | "userselect" | "groupselect" | "labelselect";
    hideOperators?: boolean;
    hideValueEditor?: boolean;
  }
>;

const fields: CommsField[] = [
  {
    name: "from",
    label: "From",
    operators: [{ name: "=", label: "=" }],
    inputType: "userId",
    valueEditorType: "userselect",
    hideOperators: true,
  },
  {
    name: "to",
    label: "To",
    operators: [{ name: "=", label: "=" }],
    inputType: "userId",
    valueEditorType: "userselect",
    hideOperators: true,
  },
  {
    name: "group",
    label: "In group",
    operators: [{ name: "=", label: "=" }],
    inputType: "groupId",
    valueEditorType: "groupselect",
    hideOperators: true,
  },
  {
    name: "label",
    label: "Has label",
    operators: [{ name: "=", label: "=" }],
    inputType: "labelId",
    valueEditorType: "labelselect",
    hideOperators: true,
  },
  {
    name: "is:private",
    label: "Is private",
    valueEditorType: "checkbox",
    defaultValue: true,
    hideOperators: true,
    hideValueEditor: true,
  },
  {
    name: "-is:private",
    label: "Is not private",
    valueEditorType: "checkbox",
    defaultValue: true,
    hideOperators: true,
    hideValueEditor: true,
  },
  {
    name: "is:branch",
    label: "Is branch",
    valueEditorType: "checkbox",
    defaultValue: true,
    hideOperators: true,
    hideValueEditor: true,
  },
  {
    name: "-is:branch",
    label: "Is not branch",
    valueEditorType: "checkbox",
    defaultValue: true,
    hideOperators: true,
    hideValueEditor: true,
  },
  // {
  //   name: "is:reply",
  //   label: "Is reply",
  //   valueEditorType: "checkbox",
  //   defaultValue: true,
  //   hideOperators: true,
  //   hideValueEditor: true,
  // },
  // {
  //   name: "-is:reply",
  //   label: "Is not reply",
  //   valueEditorType: "checkbox",
  //   defaultValue: true,
  //   hideOperators: true,
  //   hideValueEditor: true,
  // },
  //
  // TODO: @john
  // We're disabling the after/before rules because, as currently implemented, they are
  // basically useless. By disabling these options we prevent them from being added to
  // new inbox subsections but don't prevent existing subsections using these rules if
  // any happen to exist. After deploying this change, we can look at fully removing the
  // logic for these rules. -- 2025/03/19
  {
    name: "after",
    label: "Sent on or after",
    operators: [{ name: ">=", label: ">=" }],
    hideOperators: true,
    inputType: "date",
    valueEditorType: "text",
    disabled: true,
  },
  {
    name: "before",
    label: "Sent before",
    operators: [{ name: "<", label: "<" }],
    hideOperators: true,
    inputType: "date",
    valueEditorType: "text",
    disabled: true,
  },
  {
    name: "has:attachment",
    label: "Has attachment",
    valueEditorType: "checkbox",
    defaultValue: true,
    hideOperators: true,
    hideValueEditor: true,
  },
  {
    name: "-has:attachment",
    label: "Has no attachment",
    valueEditorType: "checkbox",
    defaultValue: true,
    hideOperators: true,
    hideValueEditor: true,
  },
];
