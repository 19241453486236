import { RefObject, useEffect } from "react";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { observable } from "~/components/forms/utils";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ISearchEditorRef } from "~/components/forms/search-editor/SearchEditorBase";
import { useGetSearchQueryFromURL } from "./useGetSearchQueryFromURL";
import { onlySearchSeenPostsControl } from "./state";
import { distinctUntilChanged, skip } from "rxjs";
import { performSearchCommand } from "../useRegisterSearchViewCommands";
import { useAsRef } from "~/hooks/useAsRef";

/* -------------------------------------------------------------------------------------------------
 * useAndInitializeSearch
 * -----------------------------------------------------------------------------------------------*/

export function useAndInitializeSearch(args: { editorRef: RefObject<ISearchEditorRef> }) {
  const { editorRef } = args;

  const searchControl = useControl(() =>
    createFormGroup({
      queryHTML: createFormControl(""),
      queryText: createFormControl(""),
    }),
  );

  const { queryAsHTML, queryAsPlainText } = useGetSearchQueryFromURL();

  // Important to run this hook before we focus and select the input
  // which happens in useFocusSearchInputIfNoResults.
  useSetSearchInputOnInit({
    editorRef,
    isEditorRefMounted: !!(searchControl && onlySearchSeenPostsControl),
    queryAsHTML,
  });

  useInitializeControl_OnlySearchSeenPosts();

  return {
    searchControl,
    queryAsPlainText,
    onlySearchSeenPostsControl,
  };
}

/* -----------------------------------------------------------------------------------------------*/

/** Important to run this hook before we focus and select the input. */
function useSetSearchInputOnInit(args: {
  editorRef: RefObject<ISearchEditorRef>;
  isEditorRefMounted: boolean;
  queryAsHTML: string | null;
}) {
  const { editorRef, isEditorRefMounted, queryAsHTML } = args;

  // Note that we're intentionally not including queryAsHTML in the dependency array
  // because we only want to run this effect on mount, not when the queryAsHTML changes.
  const queryAsHTMLRef = useAsRef(queryAsHTML);

  useEffect(() => {
    if (!editorRef.current || !isEditorRefMounted) {
      return;
    }

    const sub = editorRef.current.onCreate$.subscribe((editor) => {
      const queryHTML = queryAsHTMLRef.current;

      if (!queryHTML) return;

      // Set the initial value of the editor
      editor.commands.setContent(queryHTML, true, {
        preserveWhitespace: true,
      });

      // If we have a query on load, perform a search to load the initial results
      performSearchCommand.trigger();
    });

    return () => sub.unsubscribe();
  }, [isEditorRefMounted, queryAsHTMLRef, editorRef]);
}

/* -----------------------------------------------------------------------------------------------*/

function useInitializeControl_OnlySearchSeenPosts() {
  const { currentUser } = useAuthGuardContext();

  useEffect(() => {
    const localStorageKey = `${currentUser.id}.search.filterOnOnlySeenPosts`;

    const onlySeenPosts = localStorage.getItem(localStorageKey);

    onlySearchSeenPostsControl.setValue(onlySeenPosts !== "false");

    const sub = observable(() => onlySearchSeenPostsControl.value).subscribe((val) => {
      localStorage.setItem(localStorageKey, JSON.stringify(val));
    });

    return () => sub.unsubscribe();
  }, [currentUser.id]);

  // Perform a new search on control changes
  useEffect(() => {
    const sub = observable(() => onlySearchSeenPostsControl.value)
      .pipe(
        distinctUntilChanged(),
        // Skip the first emission since `useSetSearchInputOnInit` will execute the initializing
        // search query
        skip(1),
      )
      .subscribe(() => {
        performSearchCommand.trigger();
      });

    return () => sub.unsubscribe();
  }, []);

  return onlySearchSeenPostsControl;
}

/* -----------------------------------------------------------------------------------------------*/
