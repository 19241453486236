import { PointerWithRecord } from "libs/schema";
import { IListOnEntryActionEvent, IListRef } from "~/components/list";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isModKeyActive } from "~/environment/command.service";
import { UnreachableCaseError } from "libs/errors";
import { IThreadViewContext } from "../thread/context";
import { useMemo } from "react";

export type TSearchEntry = PointerWithRecord<"message">;

/* -----------------------------------------------------------------------------------------------*/

export async function onSearchEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TSearchEntry>;
    query: string;
  },
) {
  const { event, query } = props;

  switch (event.entry.table) {
    case "message": {
      return environment.router.navigate(
        `/search/${encodeURIComponent(query)}/threads/${event.entry.record.thread_id}?messageId=${event.entry.record.id}`,
        { openInNewTab: isModKeyActive(event.event) },
      );
    }
    default: {
      throw new UnreachableCaseError(event.entry.table);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function useCreateSearchViewThreadContext(props: {
  listRef: React.RefObject<IListRef<TSearchEntry>>;
  query: string;
}) {
  const { listRef, query } = props;

  return useMemo((): IThreadViewContext => {
    return { threadList: { type: "search", query, ref: listRef } };
  }, [query, listRef]);
}

/* -----------------------------------------------------------------------------------------------*/
