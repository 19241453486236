import { applyLabelCommand, openRemoveLabelDialogCommand, removeLabelCommand } from "~/utils/common-commands";

import { combineLatest, map, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useRegisterCommands } from "~/environment/command.service";
import { openApplyLabelDialogCommand } from "~/utils/common-commands";
import { applyLabelToThread, removeLabelFromThread } from "~/actions/thread";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { SpecialTagTypeEnum } from "libs/schema";
import { useObservable } from "observable-hooks";

export function useRegisterThreadLabelCommands(props: { threadId: string | null | undefined }) {
  const { threadId } = props;
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  const labels$ = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([threadId, currentUserId, environment]) => {
          const labelTags$ =
            !threadId ?
              environment.recordLoader.createObserveQueryResult<"thread_tag">()
            : environment.recordLoader.observeGetThreadTags({
                thread_id: threadId,
                tag_type: SpecialTagTypeEnum.LABEL,
              });

          return combineLatest([labelTags$, environment.recordLoader.observeGetPersonalLabels({ currentUserId })]);
        }),
        map(([[threadTags], [personalLabels]]) => {
          const threadLabelIds = new Set(threadTags.map((t) => t.tag_id));
          return { threadLabelIds, personalLabels };
        }),
      ),
    [threadId, currentUserId, environment],
  );

  // Register apply label commands
  useRegisterCommands({
    commands() {
      if (!threadId) return [];

      return labels$.pipe(
        map(({ threadLabelIds, personalLabels }) => {
          return personalLabels.filter((l) => !threadLabelIds.has(l.id));
        }),
        map((labelsNotAppliedToThread) => {
          return [
            openApplyLabelDialogCommand({}),
            ...labelsNotAppliedToThread.map((label) => {
              return applyLabelCommand(
                {
                  callback: () => {
                    applyLabelToThread(environment, {
                      labelId: label.id,
                      threadId,
                    });
                  },
                },
                label,
              );
            }),
          ];
        }),
      );
    },
    deps: [threadId, labels$, environment],
  });

  // Register remove label commands
  useRegisterCommands({
    commands() {
      if (!threadId) return [];

      return labels$.pipe(
        map(({ threadLabelIds, personalLabels }) => {
          return personalLabels.filter((l) => threadLabelIds.has(l.id));
        }),
        map((labelsAppliedToThread) => {
          return [
            openRemoveLabelDialogCommand({}),
            ...labelsAppliedToThread.map((label) => {
              return removeLabelCommand(
                {
                  callback: () => {
                    removeLabelFromThread(environment, {
                      labelId: label.id,
                      threadId,
                    });
                  },
                },
                label,
              );
            }),
          ];
        }),
      );
    },
    deps: [threadId, labels$, environment],
  });
}
