import { useRef, useMemo } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import * as MainLayout from "~/page-layouts/main-layout";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useArchivedPersonalLabels } from "~/hooks/useArchivedPersonalLabels";
import { LabelEntry, onLabelSelect } from "~/components/content-list/LabelEntry";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { PointerWithRecord } from "libs/schema/schema";

export const ArchivedPersonalLabelsView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  const [archivedLabels] = useArchivedPersonalLabels();

  const archivedLabelIds = useMemo(() => archivedLabels.map((label) => label.id), [archivedLabels]);

  const virtualArchivedLabelEntries = useVirtualList({
    scrollboxRef,
    count: archivedLabelIds.length,
    getEntryKey: (index) => archivedLabelIds[index] ?? "",
  });

  const archivedLabelCount = virtualArchivedLabelEntries.entries.length;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <Helmet>
          <title>Archived labels | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} className="flex-col">
          <h1 className="text-3xl">Archived labels</h1>
        </MainLayout.Header>

        {archivedLabelCount === 0 ?
          <EmptyListMessage text="None." />
        : <ContentList<PointerWithRecord<"tag">>
            onEntryAction={({ event, entry }) => {
              onLabelSelect(environment, {
                label: entry,
                event,
              });
            }}
            autoFocus
            allEntryIdsForVirtualizedList={archivedLabelIds}
            style={virtualArchivedLabelEntries.containerStyles()}
          >
            {virtualArchivedLabelEntries.entries.map((virtualEntry, index) => {
              const labelId = virtualEntry.key as string;

              return (
                <LabelEntry
                  key={labelId}
                  labelId={labelId}
                  relativeOrder={index}
                  style={virtualArchivedLabelEntries.entryStyles(virtualEntry)}
                />
              );
            })}
          </ContentList>
        }

        {archivedLabelCount > 0 && <EndOfListMsg />}
      </div>
    </ListScrollbox>
  );
};
