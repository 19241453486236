import React, { createContext, Fragment, memo, ReactNode, useMemo, useState } from "react";
import { isEqual } from "libs/predicates";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { cx, css } from "@emotion/css";
import { DisplayDate, EntryTimestamp } from "~/components/content-list/layout";
import { Tooltip } from "~/components/Tooltip";
import { getEmbeddableVideoLink, shouldURLBeUnfurled } from "../util";
import { FaPlayCircle } from "react-icons/fa";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { BsLockFill } from "react-icons/bs";
import { UnreachableCaseError } from "libs/errors";
import { getPointer, RecordValue } from "libs/schema";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useCurrentUserTagSubscription } from "~/hooks/useCurrentUserTagSubscription";
import { useGroup } from "~/hooks/useGroup";
import { calculateImageDimensions } from "~/utils/dom-helpers";
import { useUserProfile } from "~/hooks/useUserProfile";
import { onGroupSelectNavigateToGroup } from "~/components/content-list/GroupEntry";
import { AttachmentsContainer, MessageAttachment } from "~/components/Attachment";
import { ThreadResolvedLabel } from "~/components/LabelChip";
import { renderGroupName } from "~/utils/tag-utils";
import { useConvertHTMLToReact } from "~/hooks/useConvertHTMLToReact";
import { useRecords } from "~/hooks/useRecords";
import { stringComparer } from "libs/comparers";
import { uniqBy } from "lodash-comms";
import { ParentComponent } from "~/utils/type-helpers";
import { Link } from "@tanstack/react-router";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useOrganizationUserMember } from "~/hooks/useOrganizationUserMember";

export const ExpandedMessage: ParentComponent<{
  message: RecordValue<"message">;
  messageActions?: ReactNode;
  onHeaderClick?: () => void;
}> = memo((props) => {
  const { message } = props;

  switch (message.type) {
    case "COMMS": {
      return (
        <CommsMessage message={message} messageActions={props.messageActions} onHeaderClick={props.onHeaderClick} />
      );
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      throw new Error("not implemented");
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

const CommsMessage: ParentComponent<{
  message: RecordValue<"message">;
  messageActions?: ReactNode;
  onHeaderClick?: () => void;
}> = (props) => {
  const { message } = props;
  const { currentUser } = useAuthGuardContext();
  const [showRecipients, setShowRecipients] = useState(false);

  const sender = useMessageSender(message.id);

  const senderName = currentUser.id === message.sender_user_id ? "Me" : sender?.name || "unknown";

  return (
    <>
      <div
        className={cx("MessageHeader flex pt-4 px-4 sm-w:px-8 sm-w:pt-4 hover:cursor-pointer")}
        onClick={(e) => {
          if (e.defaultPrevented) return;
          props.onHeaderClick?.();
        }}
      >
        <div className="MessageSender flex items-baseline">
          <ThreadResolvedLabel
            threadId={message.thread_id}
            messageId={message.id}
            className="mr-3 shrink-0"
            showRemoveButton
          />

          <strong
            onClick={(e) => {
              e.preventDefault();
              setShowRecipients((r) => !r);
            }}
          >
            {senderName}
          </strong>

          {message.last_edited_at && (
            <Tooltip
              side="bottom"
              content={
                <span className="flex flex-wrap">
                  This message last edited <DisplayDate date={message.last_edited_at} size="sm" className="ml-1" />
                </span>
              }
            >
              <div className="text-sm text-slate-8 ml-4">(edited)</div>
            </Tooltip>
          )}
        </div>

        <div className="flex-1" />

        <EntryTimestamp datetime={message.sent_at} size="md" alwaysShowTime />
      </div>

      {showRecipients && (
        <div className="MessageRecipients pt-2 px-4 sm-w:px-8 text-slate-9 space-x-1">
          <MessageRecipients message={props.message} />
        </div>
      )}

      <div className="MessageBody p-4 sm-w:px-8 sm-w:py-4">
        <MessageContent message={props.message} />
        <MessageAttachments message={props.message} />
      </div>

      {props.messageActions && (
        <div className="flex items-center p-4 sm-w:px-8 min-h-[60px]">{props.messageActions}</div>
      )}
    </>
  );
};

const MessageRecipients: ParentComponent<{ message: RecordValue<"message"> }> = (props) => {
  const recipientPointers = useMemo(
    () =>
      props.message.to.map((r) => {
        switch (r.type) {
          case "GROUP": {
            return getPointer("tag", r.group_id);
          }
          case "USER": {
            return getPointer("user_profile", r.user_id);
          }
          default: {
            throw new UnreachableCaseError(r);
          }
        }
      }),
    [props.message.to],
  );

  const [recipients] = useRecords(recipientPointers);

  const sortedRecipients = useMemo(() => {
    return uniqBy(
      recipients.toSorted((a, b) => {
        if (a.table === "user_profile") {
          if (b.table === "tag") return -1;
          return stringComparer(a.record.name, b.record.name);
        } else if (b.table === "user_profile") {
          return 1;
        } else if (a.record.data?.is_organization_group) {
          if (b.record.data?.is_organization_group) {
            return stringComparer(a.record.name, b.record.name);
          }

          return 1;
        } else if (b.record.data?.is_organization_group) {
          return -1;
        }

        return stringComparer(a.record.name, b.record.name);
      }),
      (r) => r.table + r.id,
    );
  }, [recipients]);

  return (
    <>
      <span>To:</span>

      {sortedRecipients.map((r, index) => {
        switch (r.table) {
          case "user_profile": {
            return (
              <span key={r.id}>
                {r.record.name}
                {index < sortedRecipients.length - 1 && ", "}
              </span>
            );
          }
          case "tag": {
            return (
              <Fragment key={r.id}>
                <Link to="/groups/$tagId" params={{ tagId: r.id }} className="hover:underline">
                  {renderGroupName(r.record)}
                </Link>

                {index < sortedRecipients.length - 1 && ", "}
              </Fragment>
            );
          }
          default: {
            throw new UnreachableCaseError(r);
          }
        }
      })}
    </>
  );
};

export const MessageContent: ParentComponent<{ message: RecordValue<"message"> }> = memo((props) => {
  const messageType = props.message.type;

  const componentsToUseForHTML = useMemo(() => {
    switch (messageType) {
      case "COMMS": {
        return {
          a: Hyperlink,
          span: Span,
          img: ImageTag,
        };
      }
      case "EMAIL":
      case "EMAIL_BCC": {
        return {
          a: Hyperlink,
          div: DivTag,
          style: StyleTag,
          img: ImageTag,
        };
      }
      default: {
        throw new UnreachableCaseError(messageType);
      }
    }
  }, [messageType]);

  const bodyNode = useConvertHTMLToReact(props.message.body_html, componentsToUseForHTML);

  return (
    <div className="prose">
      <ExpandedMessageContext.Provider value={props}>{bodyNode}</ExpandedMessageContext.Provider>
    </div>
  );
});

export const MessageAttachments: ParentComponent<{
  message: RecordValue<"message">;
}> = (props) => {
  const attachments = props.message.attachments.filter((a) => a.contentDisposition !== "inline");

  if (attachments.length === 0) return null;

  return (
    <AttachmentsContainer>
      {attachments.map((attachment) => (
        <MessageAttachment key={attachment.id} messageId={props.message.id} attachment={attachment} />
      ))}
    </AttachmentsContainer>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ExpandedMessageContext = createContext<{
  message: RecordValue<"message">;
} | null>(null);

const useExpandedMessageContext = createUseContextHook(ExpandedMessageContext, "ExpandedMessageContext");

const Span: ParentComponent<
  Omit<JSX.IntrinsicElements["span"], "ref"> & {
    "data-type"?: string;
    "data-id"?: string;
    "data-subject"?: string;
    "data-priority"?: string;
  }
> = memo((props) => {
  const isMention = props["data-type"] === "mention";

  if (isMention) return <MentionSpan {...props} />;

  return <span {...props} />;
});

// Spans are used to render mentions in the message body.
const MentionSpan: ParentComponent<
  Omit<JSX.IntrinsicElements["span"], "ref"> & {
    "data-type"?: string;
    "data-id"?: string;
    "data-subject"?: string;
    "data-priority"?: string;
  }
> = memo((props) => {
  const isMention = props["data-type"] === "mention";

  if (!isMention) {
    throw new Error("MentionSpan: invalid data-type");
  }

  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const { message } = useExpandedMessageContext();

  const mentionType = (props["data-type"] === "mention" && props["data-subject"]) || null;

  const mentionPriority = (props["data-type"] === "mention" && props["data-priority"]) || null;

  const isUserMention = mentionType === "user";

  const mentionedUserId = isUserMention ? props["data-id"] : null;

  const [userProfile] = useUserProfile(mentionedUserId);

  const [organizationUserMember] = useOrganizationUserMember({
    userId: userProfile?.id,
    organizationId: userProfile?.owner_organization_id,
    includeSoftDeletes: true,
  });

  const isGroupMention = mentionType === "group";

  const mentionedGroupId = isGroupMention ? props["data-id"] : null;

  const [group] = useGroup(mentionedGroupId);

  const [subscription] = useCurrentUserTagSubscription({
    tagId: mentionedGroupId,
  });

  const isCurrentUserSender = message.sender_user_id === currentUserId;

  if (isUserMention) {
    const isCurrentUserMentioned = mentionedUserId === currentUserId;

    const mentionPrefix =
      mentionPriority === "100" ? "@@@"
      : mentionPriority === "200" ? "@@"
      : "@";

    let content = props.children;

    if (userProfile) {
      content = `${mentionPrefix}${userProfile.name}`;

      if (organizationUserMember?.deleted_at) {
        content += ` (deactivated)`;
      }
    }

    return (
      <Tooltip side="bottom" content={userProfile?.name || ""}>
        <span
          className={cx("inline-flex items-center", !isCurrentUserSender && isCurrentUserMentioned && "subscribed")}
          {...props}
        >
          {content}
        </span>
      </Tooltip>
    );
  }

  if (isGroupMention) {
    const isGroupPrivate =
      !!group && !group?.data?.organization_group_member_ids?.length && !group?.data?.is_organization_group;

    // If the mentioned group is an organization, then the user is considered subscribed
    // if they are a member of that organization. If a user has any kind of subscription
    // to an organization group, that indicates they are a member of that group.
    const isCurrentUserSubscribedToGroup =
      group?.data?.is_organization_group ?
        !!subscription
      : subscription?.preference === "all" || subscription?.preference === "all-new";

    const mentionPrefix =
      mentionPriority === "100" ? "@@@"
      : mentionPriority === "200" ? "@@"
      : "@";

    return (
      <Tooltip side="bottom" content={group?.name || ""}>
        <span
          className={cx(
            "inline-flex items-center cursor-pointer",
            !isCurrentUserSender && isCurrentUserSubscribedToGroup && "subscribed",
          )}
          // We're using an onclick handler rather than an anchor tag because we don't
          // want to transform the html output. If we did, and if someone copy and pasted
          // this from one message into a new draft, the pasted content would be different
          // than expected. A better solution might be to update the logic surrounding
          // mentions in the draft editor to support anchor tags as mentions, but this is
          // easier for now.
          onClick={(e) => {
            if (!mentionedGroupId) return;
            return onGroupSelectNavigateToGroup(environment, {
              id: mentionedGroupId,
              entry: { id: mentionedGroupId },
              event: e.nativeEvent,
            });
          }}
          {...props}
        >
          {group ? `${mentionPrefix}${renderGroupName(group)}` : props.children}{" "}
          {isGroupPrivate && <BsLockFill className="inline ml-1 scale-75" />}
        </span>
      </Tooltip>
    );
  }

  return <span {...props} />;
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

/**
 * This component renders a hyperlink from HTML text. The original motivation
 * for creating it was to render internal links using react-router's Link
 * component. It also handles setting a link's `target` prop.
 */
const Hyperlink: ParentComponent<Omit<JSX.IntrinsicElements["a"], "ref">> = memo(
  ({ href, target, children, ...otherProps }) => {
    const { message } = useExpandedMessageContext();

    const isExternalLink = useMemo(() => {
      if (!href) return true;

      const commsHost = new URL(window.location.href).host;

      try {
        const linkHost = new URL(href, window.location.href).host;
        return linkHost !== commsHost;
      } catch (e) {
        console.warn("Hyperlink: invalid href passed to <a> tag", href, e);
        return true;
      }
    }, [href]);

    const targetProp = target || (isExternalLink ? "_blank" : "_self");

    const shouldUnfurl = message.type === "COMMS" && shouldURLBeUnfurled(href, children);

    const embeddableLink = shouldUnfurl && getEmbeddableVideoLink(href);

    if (embeddableLink) {
      return (
        <>
          <VideoEmbed embedUrl={embeddableLink} />
          <a {...otherProps} href={href} target={targetProp}>
            {children}
          </a>
        </>
      );
    }

    if (!href || isExternalLink) {
      return (
        <a {...otherProps} href={href} target={targetProp}>
          {children}
        </a>
      );
    }

    // React router's Link component expects the "to" value to
    // not include the URL origin.
    const to = href.replace(new URL(window.location.href).origin, "");

    return (
      <Link {...otherProps} to={to} target={targetProp}>
        {children}
      </Link>
    );
  },
  isEqual,
);

/* -----------------------------------------------------------------------------------------------*/

/**
 * Renders a video embed within an iframe. A loading state is shown until the
 * iframe has loaded.
 *
 * Note: This component will show the following warning in the console, which
 * we're ignoring for now as it will take some further attention to fix:
 * `Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>.`
 */
const VideoEmbed: ParentComponent<{ embedUrl: string }> = memo(({ embedUrl }) => {
  const [showIFrameLoading, setShowIFrameLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);

  return (
    <div className={videoEmbedCSS}>
      {showOverlay && (
        <div
          // This element serves as a transparent overlay for the iFrame and prevents the iFrame from receiving
          // mouse events. The user needs to click on the overlay (i.e. interact with the iFrame) in order to make
          // the overlay disappear and to allow the iFrame to receive mouse events.
          className="absolute top-0 left-0 w-full h-full bg-transparent z-10"
          onClick={() => setShowOverlay(false)}
        />
      )}

      <iframe
        className={cx(
          videoEmbedIFrameCSS,
          "transition-opacity duration-300",
          showIFrameLoading ? "opacity-0" : "opacity-100",
        )}
        frameBorder="0"
        allowFullScreen
        src={embedUrl}
        onLoad={() => {
          setShowIFrameLoading(false);
        }}
      />
      <div
        className={cx(
          videoEmbedIFrameCSS,
          "bg-slate-3 transition-opacity duration-300 pointer-events-none",
          showIFrameLoading ? "opacity-100" : "opacity-0",
        )}
      >
        <div className="size-full flex items-center justify-center">
          <FaPlayCircle className="text-slate-7 self-center animate-pulse" size={64} />
        </div>
      </div>
    </div>
  );
}, isEqual);

const videoEmbedCSS = css`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 10px;
  height: 400px;
  overflow: hidden;
  border-radius: 0.25rem;
`;

const videoEmbedIFrameCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
`;

/* -----------------------------------------------------------------------------------------------*/

/**
 * This component renders a `<div>` tag. If the element has the "gmail_quote" class,
 * then it wraps that `<div>` in a `<details> element.
 */
const DivTag: ParentComponent<Omit<JSX.IntrinsicElements["div"], "ref">> = memo(({ className, ...otherProps }) => {
  const isAlreadyQuoted = useQuotedTextContext();
  const isQuotedText = className?.includes("gmail_quote");

  if (!isAlreadyQuoted && isQuotedText) {
    return (
      <QuotedTextContext.Provider value={true}>
        <details>
          <summary style={{ cursor: "pointer" }}>Show more</summary>

          <div className={className} {...otherProps} />
        </details>
      </QuotedTextContext.Provider>
    );
  }

  return <div className={className} {...otherProps} />;
}, isEqual);

const QuotedTextContext = createContext(false);

const useQuotedTextContext = createUseContextHook(QuotedTextContext, "QuotedTextContext");

/* -----------------------------------------------------------------------------------------------*/

/**
 * This component renders a `<style>` tag but strips "!important" from the styles.
 * The reason this component was added was because Gmail was styling emails based on
 * users system light/dark theme and forcing those styles with "!important". Since
 * Comms doesn't currently support a dark theme, it could make text very difficult to
 * read if the email was rendered expecting a dark background. The user of !important
 * prevented us from overriding the behavior. This could inadvertently break the
 * styling of other emails. If we add a dark theme to Comms, we should reevaluate
 * the need for this component.
 */
// TODO: reevaluate after Comms supports a dark theme
const StyleTag: ParentComponent<Omit<JSX.IntrinsicElements["style"], "ref">> = memo(({ children, ...otherProps }) => {
  const mappedChildren = React.Children.map(children, (child) => {
    if (typeof child === "string") {
      return child.replaceAll("!important", "");
    }

    return child;
  });

  return <style {...otherProps}>{mappedChildren}</style>;
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

const ImageTag: ParentComponent<
  Omit<JSX.IntrinsicElements["img"], "ref"> & {
    "data-imageid"?: string;
    "data-content-type"?: string;
    "data-file-size"?: string;
  }
> = memo((props) => {
  const width = Number(props.width);
  const height = Number(props.height);

  const values = !isNaN(width) && !isNaN(height) ? calculateImageDimensions({ width, height, maxHeight: 600 }) : null;

  return <img {...props} {...values} />;
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/
