import { useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { useRegisterCommands } from "~/environment/command.service";
import {
  deleteDraftCommand,
  ESCAPE_TO_INBOX_COMMAND,
  setThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useDrafts } from "~/hooks/useDrafts";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { DraftEntry } from "~/components/content-list/DraftEntry";
import { deleteDraft } from "~/actions/draft";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import { useVirtualList } from "~/hooks/useVirtualList";
import { onDraftsEntrySelect, TDraftsEntry, useDraftsViewThreadContext } from "./utils";
import { EndOfListMsg, LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";

/* -------------------------------------------------------------------------------------------------
 * DraftsView
 * -----------------------------------------------------------------------------------------------*/

export const DraftsView = withOfflineFirstIfSynced(() => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const listRef = useRef<IListRef<TDraftsEntry>>(null);
  const threadViewContext = useDraftsViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/drafts/threads/$threadId" });

  const [draftIds, { nextId, isLoading, fetchMore }] = useDrafts();

  const hasNextPage = !!nextId;

  const virtualDraftIds = useVirtualList({
    scrollboxRef,
    count: draftIds.length,
    getEntryKey: (index) => draftIds[index] || "",
    fetchMore,
    hasNextPage,
    isFetchingNextPage: isLoading,
  });

  const draftCount = virtualDraftIds.entries.length;

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TDraftsEntry>();

  useRegisterDraftViewCommands(useFocusedEntry);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <Helmet>
          <title>Drafts | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
          <h1 className="text-3xl">Drafts</h1>
        </MainLayout.Header>

        {draftCount === 0 && !isLoading ?
          <EmptyListMessage text="No drafts." className={cx(isThreadOpen && "invisible")} />
        : <ContentList<TDraftsEntry>
            listRef={listRef}
            mode={isThreadOpen ? "active-descendent" : "focus"}
            onEntryFocused={setFocusedEntry}
            onEntryAction={(event) => onDraftsEntrySelect(environment, { event })}
            className={cx(isThreadOpen && "invisible")}
            autoFocus
            allEntryIdsForVirtualizedList={draftIds}
            style={virtualDraftIds.containerStyles()}
          >
            {virtualDraftIds.entries.map((virtualEntry) => {
              const draftId = virtualEntry.key as string;
              if (!draftId) return null;

              return (
                <DraftEntry
                  key={draftId}
                  draftId={draftId}
                  relativeOrder={virtualEntry.index}
                  style={virtualDraftIds.entryStyles(virtualEntry)}
                />
              );
            })}
          </ContentList>
        }

        {draftCount > 0 && !hasNextPage && !isLoading && <EndOfListMsg className={cx(isThreadOpen && "invisible")} />}

        {(hasNextPage || isLoading) && <LoadingMoreListEntriesMsg isThreadOpen={isThreadOpen} />}

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
});

/* -------------------------------------------------------------------------------------------------
 * useRegisterDraftViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterDraftViewCommands(useFocusedDraft: () => TDraftsEntry | null) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const focusedDraft = useFocusedDraft();

  useRegisterCommands({
    commands: () => {
      return [
        ESCAPE_TO_INBOX_COMMAND,
        deleteDraftCommand({
          callback: () => {
            if (!focusedDraft) {
              // if the user has no draft focused, but try to delete,
              // just tell them what they need to do to delete.
              toast("vanilla", {
                subject: "Oops, no draft is focused.",
                description: `
                    You first need to focus a draft by hovering your mouse  
                    over it or by using the arrow keys on your keyboard.
                  `,
              });
            } else {
              deleteDraft(environment, {
                draftId: focusedDraft.id,
                currentUserId,
              });
            }
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the drafts page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star drafts.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star drafts.
            `);
          },
        }),
      ];
    },
    deps: [focusedDraft, currentUserId, environment],
  });
}

/* -----------------------------------------------------------------------------------------------*/
