import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { RecordValue, getPointer } from "libs/schema";
import { Observable, of, switchMap } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { uniqBy, uniq } from "lodash-comms";
import { observeInboxEntries } from "./observeInboxEntries";
import { ObserveOptions } from "~/environment/RecordLoader";
import { isEqual } from "libs/predicates";
import { cacheReplayForTime } from "libs/rxjs-operators";

export type ObserveInboxGroupsResult = [RecordValue<"tag">[], { isLoading: boolean }];

export function observeInboxGroups(
  environment: ClientEnvironment,
  props: { userId: string; inboxSectionId: string },
  options: ObserveOptions = {},
) {
  const { userId, inboxSectionId } = props;
  const { fetchStrategy } = options;

  const cacheKey = props.userId + props.inboxSectionId + options?.fetchStrategy + options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = observeInboxEntries(environment, { userId, inboxSectionId }, { ...options, fetchStrategy }).pipe(
    debounceTime(200),
    map(([entries, { isLoading }]) => [uniq(entries.map((e) => e.thread_id)), { isLoading }] as const),
    distinctUntilChanged(isEqual),
    switchMap(([threadIds, meta]) => {
      if (!threadIds.length) {
        return of([[] as RecordValue<"thread_group_permission">[], meta] as const);
      }

      return environment.recordLoader.observeGetThreadsGroupPermissions({ threadIds }, { ...meta, fetchStrategy });
    }),
    switchMap(([permissionsResult, meta]) => {
      const pointers = permissionsResult.map((p) => getPointer("tag", p.group_id));

      return environment.recordLoader.observeGetRecords(pointers, { ...meta, fetchStrategy });
    }),
    map(([groups, { isLoading }]): ObserveInboxGroupsResult => {
      const groupsMinusOrgs = groups
        .map(({ record }) => record)
        .filter((record) => !record.data?.is_organization_group);

      const uniqueGroups = uniqBy(groupsMinusOrgs, (r) => r.id);

      return [uniqueGroups, { isLoading }];
    }),
    cacheReplayForTime({
      timeMs: 100,
      onInit: () => {
        queryCache.set(cacheKey, observable);
      },
      onCleanup: () => {
        queryCache.delete(cacheKey);
      },
    }),
  );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveInboxGroupsResult>>();
