import { Operation, op } from "libs/transaction";
import { RecordValue } from "libs/schema";
import { createDefaultInboxSection } from "./inboxSection";
import { createOrganizationMembership } from "./organization";
import { linkOAuthToUser } from "./userOAuth";
import { createAuthToken } from "./authToken";
import { defaultUserSettings } from "libs/constants/defaultUserSettings";
import { applyOperationsToTransaction } from "./utils";

export type CreateUserProps = {
  userId: string;
  email: string;
  firebaseAuthId: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  phoneNumber?: string | null;
  receiveTextNotifications?: boolean;
  ownerOrganization: {
    organizationId: string;
    organizationName: string;
    isAdmin: boolean;
    invitedByUserId?: string;
    subscribeToGroups?: string[];
  };
  existingUserOAuths: RecordValue<"user_oauth">[];
  existingAuthTokens: RecordValue<"auth_token">[];
};

/**
 * These operations will create the user records and add the user as a member
 * of the owner organization. If this user should also be added as a member of
 * other organizations, use the `createOrganizationMembership` function
 * separately to add the user to those organizations.
 */
export function createUser(props: CreateUserProps): Operation[] {
  const ownerOrganizationId = props.ownerOrganization.organizationId;

  const operations: Operation[] = [
    op.set("user", {
      id: props.userId,
      email: props.email,
      email_verified: false,
      email_verified_at: null,
      firebase_auth_id: props.firebaseAuthId,
      is_disabled: false,
      disabled_at: null,
      owner_organization_id: ownerOrganizationId,
    }),

    op.set("user_profile", {
      id: props.userId,
      first_name: props.firstName,
      middle_name: props.middleName || null,
      last_name: props.lastName,
      name: [props.firstName, props.middleName, props.lastName].filter(Boolean).join(" "),
      photo_url: null,
      owner_organization_id: ownerOrganizationId,
    }),

    op.set("user_contact_info", {
      id: props.userId,
      email_address: props.email,
      phone_number: props.phoneNumber ?? null,
      owner_organization_id: ownerOrganizationId,
    }),

    op.set("user_settings", {
      id: props.userId,
      settings: { ...defaultUserSettings(), interrupt_message_text: props.receiveTextNotifications ?? false },
      mention_frequency: {},
      owner_organization_id: ownerOrganizationId,
    }),
  ];

  applyOperationsToTransaction(
    operations,
    createOrganizationMembership({
      userId: props.userId,
      organizationId: ownerOrganizationId,
      isAdmin: props.ownerOrganization.isAdmin,
      invitedByUserId: props.ownerOrganization.invitedByUserId,
      subscribeToGroups: props.ownerOrganization.subscribeToGroups,
      userOwnerOrganizationId: ownerOrganizationId,
    }),
    createDefaultInboxSection({
      userId: props.userId,
      ownerOrganizationId: ownerOrganizationId,
      organizationName: props.ownerOrganization.organizationName,
    }),
    // The user oauth records were originally created when the user first logged in
    // and before they created their account. When we did this, we just assigned a
    // dummy owner_organization_id value to the user_oauth records. Now that they've
    // created their account and we've assigned an owner_organization_id to their
    // user account, we need to update the user_oauth records so that the
    // owner_organization_id value of these records match that of the user account.
    // We also update the user_oauth records to to reflect that they are now linked
    // to a user account.
    props.existingUserOAuths.flatMap((userOauth) => {
      return linkOAuthToUser({
        userOAuthId: userOauth.id,
        userId: props.userId,
        ownerOrganizationId: ownerOrganizationId,
      });
    }),
    // Any existing auth_token records associated with this userId have placeholder
    // owner_organization_id values. Now that the user has created their account and
    // we've assigned an owner_organization_id to their user account, we need to update
    // the auth_token records so that the owner_organization_id value of these records
    // match that of the user account and also delete these old auth_token records.
    // Deleting them ensures that existing clients will need to re-authenticate with
    // the new auth token.
    props.existingAuthTokens.flatMap((auth) => [
      op.update(
        { table: "auth_token", id: auth.id },
        {
          owner_organization_id: ownerOrganizationId,
          user_id: props.userId,
        },
      ),
      op.delete("auth_token", auth),
    ]),
    // Create a new auth token for the user
    createAuthToken({ userId: props.userId, ownerOrganizationId }),
  );

  return operations;
}
/* -----------------------------------------------------------------------------------------------*/

export type UpdateUserNameProps = {
  userId: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  name?: string;
};

export function updateUserName(props: UpdateUserNameProps): Operation[] {
  const name =
    props.name ? props.name
    : props.middleName ? `${props.firstName} ${props.middleName} ${props.lastName}`
    : `${props.firstName} ${props.lastName}`;

  return [
    op.update(
      { table: "user_profile", id: props.userId },
      {
        first_name: props.firstName,
        middle_name: props.middleName,
        last_name: props.lastName,
        name,
      },
    ),
  ];
}

/* -----------------------------------------------------------------------------------------------*/

export type UpdateUserContactInfoProps = {
  userId: string;
  phoneNumber: string | null;
};

export function updateUserContactInfo(props: UpdateUserContactInfoProps): Operation[] {
  return [
    op.update(
      { table: "user_contact_info", id: props.userId },
      {
        phone_number: props.phoneNumber,
      },
    ),
  ];
}

/* -----------------------------------------------------------------------------------------------*/
