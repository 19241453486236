import { CreateRecord, Operation, op } from "libs/transaction";
import { LabelTagRecord, SpecialTagTypeEnum, generateRecordId } from "libs/schema";
import { ADMIN_USER_ID } from "libs/shared-constants";

export type CreateLabelProps = {
  labelId?: string;
  icon: string | null;
  name: string;
  description?: string | null;
  isPrivateLabel: boolean;
  ownerOrganizationId: string;
  creatorUserId: string;
};

export function createLabel(props: CreateLabelProps) {
  const labelId = props.labelId ?? generateRecordId("tag");

  const operations: Operation[] = [];

  const label = op.set("tag", {
    id: labelId,
    type: SpecialTagTypeEnum.LABEL,
    name: props.name,
    icon: props.icon,
    description: props.description ?? null,
    // The presence of a property with an undefined value will cause the
    // database to throw an error.
    data: null,
    owner_organization_id: props.ownerOrganizationId,
    archived_at: null,
  } satisfies CreateRecord<LabelTagRecord>);

  operations.push(label);

  if (!props.isPrivateLabel) {
    const groupMember = op.set("tag_group_member", {
      id: generateRecordId("tag_group_member", {
        tag_id: labelId,
        group_id: props.ownerOrganizationId,
      }),
      creator_user_id: props.creatorUserId,
      group_id: props.ownerOrganizationId,
      tag_id: labelId,
      tag_type: SpecialTagTypeEnum.LABEL,
      is_organization_group: true,
      owner_organization_id: props.ownerOrganizationId,
    });

    operations.push(groupMember);
  } else if (props.creatorUserId !== ADMIN_USER_ID) {
    // If the label is private, than we add the current user as the first member
    // of the label.
    const userMember = op.set("tag_user_member", {
      id: generateRecordId("tag_user_member", {
        tag_id: labelId,
        user_id: props.creatorUserId,
      }),
      tag_id: labelId,
      tag_type: SpecialTagTypeEnum.LABEL,
      user_id: props.creatorUserId,
      creator_user_id: props.creatorUserId,
      owner_organization_id: props.ownerOrganizationId,
    });

    operations.push(userMember);
  }

  return operations;
}
