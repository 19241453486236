import { useRecordLoader } from "./useRecordLoader";
import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { observeFilteredNotificationEntriesForVirtualList } from "~/components/content-list/NotificationEntry";

export function useReminderNotifications() {
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useReminderNotifications",
    load({ loader, limit, currentUserId }) {
      const observable = loader.observeGetNotifications({
        user_id: currentUserId,
        has_reminder: true,
        orderBy: "remind_at",
        limit,
      });

      return observeFilteredNotificationEntriesForVirtualList({ recordLoader: loader }, observable);
    },
    initialLimit,
    limitStep: initialLimit,
  });
}
