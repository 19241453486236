import { useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { useTrashViewThreadIds } from "~/hooks/useTrashViewThreadIds";
import { ThreadEntry } from "~/components/content-list/ThreadEntry";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { onTrashEntrySelect, TTrashEntry, useTrashViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";
import { EndOfListMsg, LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";

export const TrashView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const [threadIds, { fetchMore, nextId, isLoading }] = useTrashViewThreadIds();
  const listRef = useRef<IListRef<TTrashEntry>>(null);
  const threadViewContext = useTrashViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/trash/threads/$threadId" });

  const hasNextPage = !!nextId;

  const virtualThreadIds = useVirtualList({
    scrollboxRef,
    count: threadIds.length,
    getEntryKey: (index) => threadIds[index] ?? "",
    fetchMore,
    hasNextPage,
    isFetchingNextPage: isLoading,
  });

  const threadCount = virtualThreadIds.entries.length;

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't mark threads Done from the Trash.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't mark threads not Done from the Trash.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't edit reminders from the Trash.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't edit reminders from the Trash.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't star threads from the Trash.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't unstar threads from the Trash.
            `);
          },
        }),
      ];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <Helmet>
          <title>Trash | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} className={cx(isThreadOpen && "invisible")}>
          <h1 className="text-3xl">Trash</h1>
        </MainLayout.Header>

        {threadCount === 0 ?
          <EmptyListMessage text="Nothing in trash." className={cx(isThreadOpen && "invisible")} />
        : <ContentList<TTrashEntry>
            listRef={listRef}
            mode={isThreadOpen ? "active-descendent" : "focus"}
            onEntryAction={(event) => onTrashEntrySelect(environment, { event })}
            className={cx(isThreadOpen && "invisible")}
            autoFocus
            allEntryIdsForVirtualizedList={threadIds}
            style={virtualThreadIds.containerStyles()}
          >
            {virtualThreadIds.entries.map((virtualEntry) => {
              const threadId = virtualEntry.key as string;
              if (!threadId) return null;

              return (
                <ThreadEntry
                  key={threadId}
                  threadId={threadId}
                  relativeOrder={virtualEntry.index}
                  style={virtualThreadIds.entryStyles(virtualEntry)}
                />
              );
            })}
          </ContentList>
        }

        {threadCount > 0 && !hasNextPage && !isLoading && <EndOfListMsg className={cx(isThreadOpen && "invisible")} />}

        {(hasNextPage || isLoading) && <LoadingMoreListEntriesMsg isThreadOpen={isThreadOpen} />}

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
};
