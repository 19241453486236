import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import * as MainLayout from "~/page-layouts/main-layout";
import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable } from "react-beautiful-dnd";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { isEqual } from "libs/predicates";
import { OutlineButton } from "~/components/OutlineButtons";
import { cx } from "@emotion/css";
import { Tooltip } from "~/components/Tooltip";
import { removeOneFromArray } from "libs/array-utils";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { useInboxSection } from "~/hooks/useInboxSection";
import { InboxSectionTagRecord, InboxSubsectionTagRecord, generateRecordId } from "libs/schema";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { updateInboxSection } from "~/actions/inboxSection";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { CreateRecord } from "libs/transaction";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { ParsedToken } from "libs/searchQueryParser";
import { isUuid } from "libs/uuid";
import { entryCSSClasses } from "~/components/content-list/layout";
import { toast } from "~/environment/toast-service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ParentComponent } from "~/utils/type-helpers";
import {
  EditInboxSubsectionDialog,
  EditInboxSubsectionDialogReturnSubmit,
  EditInboxSubsectionDialogState,
} from "~/dialogs/edit-inbox-subsections/EditInboxSubsectionDialog";
import { useAsync } from "react-use";
import { List, ListScrollbox } from "~/components/list";
import { RuleGroupType } from "react-querybuilder";
import { ESCAPE_TO_BACK_COMMAND, getCommandFactory, submitFormCommand } from "~/utils/common-commands";
import { SetOptional } from "type-fest";
import {
  mapParsedTokenToReactQueryBuilderRule,
  mapReactQueryBuilderRuleToParsedToken,
} from "~/utils/reactQueryBuilder-utils";
import { partition } from "lodash-comms";
import { map, Observable } from "rxjs";
import { AlertDialogState } from "~/dialogs/alert/AlertDialog";
import { useIsOnline } from "~/hooks/useIsOnline";
import { useParams } from "@tanstack/react-router";

export const EditInboxSectionView: ParentComponent = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const inboxSectionId = useInboxSectionId();
  const [inboxSection, { isLoading: isInboxSectionLoading }] = useInboxSection(inboxSectionId);

  const {
    hasPendingChanges,
    areSubsectionsLoading,
    defaultSubsections: [firstDefaultSubsection, ...otherDefaultSubsections],
    customSubsections,
    addSubsection,
    updateSubsection,
    removeSubsection,
    reorderSubsections,
  } = useOrderedSubsections(inboxSectionId);

  const onEdit = useCallback(
    async (subsection: SubsectionData) => {
      const queryPromise = environment.isLoading.add(
        mapParsedTokenToReactQueryBuilderRule(environment, subsection.query[0]!),
      );

      const query = (await queryPromise) as RuleGroupType;

      const result = await EditInboxSubsectionDialogState.open({
        id: subsection.id,
        name: subsection.name,
        query,
      });

      if (result?.type !== "submit") return;

      updateSubsection(subsection.id, result.data);
    },
    [updateSubsection, environment],
  );

  const onDelete = useCallback(
    (subsectionId: string) => {
      removeSubsection(subsectionId);
    },
    [removeSubsection],
  );

  const { setFocusedEntry, focusedEntry$ } = useKBarAwareFocusedEntry$<SubsectionData>();

  useRegisterEditInboxSectionCommands({
    inboxSectionId,
    inboxSection,
    firstDefaultSubsection,
    otherDefaultSubsections,
    customSubsections,
    addSubsection,
    focusedEntry$,
    onEdit,
    onDelete,
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const isOnline = useIsOnline();

  if (!inboxSection) {
    if (!inboxSectionId) {
      return <EmptyListMessage text="Invalid URL" />;
    } else if (isInboxSectionLoading) {
      return <EmptyListMessage text="Loading..." loading={true} />;
    }

    return <EmptyListMessage text="Cannot load inbox" />;
  } else if (areSubsectionsLoading) {
    return <EmptyListMessage text="Loading..." loading={true} />;
  } else if (!firstDefaultSubsection) {
    return <EmptyListMessage text="Cannot load inbox sections" />;
  }

  return (
    <>
      <Helmet>
        <title>Edit inbox | Comms</title>
      </Helmet>

      <EditInboxSubsectionDialog />

      <MainLayout.Header ref={headerRef} className="flex-col">
        <h1 className="text-3xl">Edit inbox</h1>

        <MainLayout.HeaderMenu>
          {!isOnline ?
            <div className="text-red-9 font-medium">You must be online to edit your inbox</div>
          : <>
              <Tooltip side="bottom" content={hasPendingChanges ? "Save changes" : "Nothing to save"}>
                <li>
                  <OutlineButton
                    disabled={!hasPendingChanges}
                    borderColorCss={
                      hasPendingChanges ?
                        "bg-blue-9 border-blue-9 text-white"
                      : "text-slate-9 border-slate-7 cursor-not-allowed"
                    }
                    onClick={() => {
                      submitFormCommand.trigger();
                    }}
                  >
                    <small>{hasPendingChanges ? "Save changes" : "No changes"}</small>
                  </OutlineButton>
                </li>
              </Tooltip>

              <li>
                <OutlineButton
                  onClick={() => {
                    addSubsectionCommand.trigger();
                  }}
                >
                  {/* We're intentionally referring to this as an inbox section since we no longer support
              multiple custom inbox sections and calling this a section is more intuitive I think */}
                  <small>Add section</small>
                </OutlineButton>
              </li>
            </>
          }
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox>
        <div>
          <ContentList<SubsectionData>
            onEntryFocused={setFocusedEntry}
            onEntryAction={(event) => onEdit(event.entry)}
            className="mb-20"
            autoFocus
          >
            <InboxSubsectionEntry
              key={firstDefaultSubsection.id}
              subsection={firstDefaultSubsection}
              relativeOrder={0}
            />

            <DragDropContext onDragEnd={reorderSubsections}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {customSubsections.map((subsection, index) => (
                      <Draggable key={subsection.id} draggableId={subsection.id} index={index}>
                        {(provided) => (
                          <InboxSubsectionEntry
                            subsection={subsection}
                            provided={provided}
                            relativeOrder={index}
                            onEdit={() => onEdit(subsection)}
                            onDelete={() => onDelete(subsection.id)}
                          />
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {otherDefaultSubsections.map((subsection, index) => (
              <InboxSubsectionEntry key={subsection.id} subsection={subsection} relativeOrder={index} />
            ))}
          </ContentList>
        </div>
      </ListScrollbox>

      <div className="mb-20" />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useInboxSectionId() {
  const params = useParams({ strict: false });

  return useMemo(() => {
    if (!isUuid(params.inboxSectionId || "")) {
      return null;
    }

    return params.inboxSectionId;
  }, [params.inboxSectionId]);
}

/* -----------------------------------------------------------------------------------------------*/

function useRegisterEditInboxSectionCommands(props: {
  inboxSectionId: string | null | undefined;
  inboxSection: InboxSectionTagRecord | null;
  addSubsection: (value: EditInboxSubsectionDialogReturnSubmit["data"]) => void;
  firstDefaultSubsection: SubsectionData | undefined;
  otherDefaultSubsections: SubsectionData[];
  customSubsections: SubsectionData[];
  focusedEntry$: Observable<SubsectionData | null>;
  onEdit: (subsection: SubsectionData) => void;
  onDelete: (subsectionId: string) => void;
}) {
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_BACK_COMMAND,
        addSubsectionCommand({
          callback: async () => {
            const result = await EditInboxSubsectionDialogState.open({
              id: null,
              name: null,
              query: null,
            });

            if (result?.type !== "submit") return;

            props.addSubsection(result.data);
          },
        }),
      ];

      const inboxSectionId = props.inboxSectionId;

      if (inboxSectionId) {
        commands.push(
          submitFormCommand({
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              if (!props.firstDefaultSubsection) {
                // In general this should not happen, but it could happen due to a loading issue.
                return;
              }

              if (!environment.network.isOnline()) {
                AlertDialogState.open({
                  content: "You must be online to edit your inbox sections",
                });

                return;
              }

              const subsections = [
                props.firstDefaultSubsection,
                ...props.customSubsections,
                ...props.otherDefaultSubsections,
              ];

              submit(environment, {
                id: inboxSectionId,
                subsections,
              });
            },
          }),
        );
      }

      return commands;
    },
    deps: [props, environment],
  });

  useRegisterCommands({
    commands: () => {
      return props.focusedEntry$.pipe(
        map((focusedEntry): ICommandArgs[] => {
          if (!focusedEntry) return [];

          // We're intentionally referring to these as inbox sections since we no longer support
          // multiple custom inbox sections and calling subsections "sections" is more intuitive
          // I think
          return [
            {
              label: "Edit section",
              callback: () => props.onEdit(focusedEntry),
            },
            {
              label: "Delete section",
              callback: () => props.onDelete(focusedEntry.id),
            },
          ];
        }),
      );
    },
    deps: [props.focusedEntry$, props.onEdit, props.onDelete],
  });
}

/* -----------------------------------------------------------------------------------------------*/

type SubsectionData = {
  id: string;
  name: string;
  query: ParsedToken[];
  isDefault: boolean;
};

function useOrderedSubsections(inboxSectionId?: string | null) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const [defaultSubsections, setDefaultSubsections] = useState<SubsectionData[]>([]);
  const [customSubsections, setCustomSubsections] = useState<SubsectionData[]>([]);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const inboxSubsectionsResult = useAsync(async () => {
    if (!inboxSectionId) return [] as CreateRecord<InboxSubsectionTagRecord>[];

    const [inboxSubsections] = await environment.recordLoader.getInboxSubsections({
      currentUserId,
      inboxSectionId,
    });

    return inboxSubsections as unknown as CreateRecord<InboxSubsectionTagRecord>[];
  }, [inboxSectionId, currentUserId, environment]);

  useEffect(() => {
    if (inboxSubsectionsResult.loading || !inboxSubsectionsResult.value) return;

    const [defaultSubsections, customSubsections] = partition(
      inboxSubsectionsResult.value.map((subsection) => ({
        id: subsection.id,
        name: subsection.name,
        query: subsection.data.parsed_query,
        isDefault: subsection.data.is_default || false,
      })),
      (subsection) => subsection.isDefault,
    );

    setDefaultSubsections(defaultSubsections);
    setCustomSubsections(customSubsections);
  }, [inboxSubsectionsResult]);

  const addSubsection = useCallback(
    (value: EditInboxSubsectionDialogReturnSubmit["data"]) => {
      setHasPendingChanges(true);

      setCustomSubsections((prev) => {
        return [
          ...prev,
          {
            id: value.id || generateRecordId("tag"),
            name: value.name,
            query: [mapReactQueryBuilderRuleToParsedToken(value.query)],
            isDefault: false,
          },
        ];
      });
    },
    [setCustomSubsections, environment],
  );

  const updateSubsection = useCallback(
    (subsectionId: string, value: EditInboxSubsectionDialogReturnSubmit["data"]) => {
      setHasPendingChanges(true);

      setCustomSubsections((prev) => {
        const subsections = prev.map((subsection) => {
          if (subsection.id !== subsectionId) return subsection;

          return {
            id: subsectionId,
            name: value.name,
            query: [mapReactQueryBuilderRuleToParsedToken(value.query)],
            isDefault: subsection.isDefault,
          };
        });

        return subsections;
      });
    },
    [currentUserId],
  );

  const removeSubsection = useCallback(
    (subsectionId: string) => {
      setHasPendingChanges(true);

      setCustomSubsections((prev) => {
        const newOrderedSubsections = removeOneFromArray(prev, (el) => el.id === subsectionId);
        return newOrderedSubsections;
      });
    },
    [setCustomSubsections, setHasPendingChanges],
  );

  const reorderSubsections = useCallback(
    (dropResult: DropResult) => {
      if (!dropResult.destination) return;

      const sourceIndex = dropResult.source.index;
      const destinationIndex = dropResult.destination.index;

      if (destinationIndex === sourceIndex) return;

      setHasPendingChanges(true);
      setCustomSubsections((prev) => {
        const newOrderedSubsections = reorder(prev, sourceIndex, destinationIndex);
        return newOrderedSubsections;
      });
    },
    [setCustomSubsections],
  );

  return {
    hasPendingChanges,
    areSubsectionsLoading: inboxSubsectionsResult.loading,
    defaultSubsections,
    customSubsections,
    addSubsection,
    updateSubsection,
    removeSubsection,
    reorderSubsections,
  };
}

/* -----------------------------------------------------------------------------------------------*/

const reorder = (subsections: SubsectionData[], startIndex: number, endIndex: number) => {
  const newList = Array.from(subsections);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const removed = newList.splice(startIndex, 1)[0]!;
  newList.splice(endIndex, 0, removed);

  return newList;
};

/* -----------------------------------------------------------------------------------------------*/

const addSubsectionCommand = getCommandFactory(
  "ADD_SUBSECTION",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    // We're intentionally referring to this as an inbox section since we no longer support
    // multiple custom inbox sections and calling this a section is more intuitive I think
    label: "Add section",
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

const InboxSubsectionEntry: ParentComponent<{
  subsection: SubsectionData;
  relativeOrder: number;
  provided?: DraggableProvided;
  onEdit?: () => void;
  onDelete?: () => void;
}> = memo((props) => {
  const isOnline = useIsOnline();

  let isDisabled: boolean;
  let tooltipContent: string;

  if (props.subsection.isDefault) {
    isDisabled = true;
    tooltipContent = "Default sections cannot be edited";
  } else if (!isOnline) {
    isDisabled = true;
    tooltipContent = "You must be online to edit your inbox";
  } else {
    isDisabled = false;
    tooltipContent = "";
  }

  return (
    <List.Entry
      id={props.subsection.id}
      data={props.subsection}
      disabled={isDisabled}
      relativeOrder={props.relativeOrder}
    >
      <div
        ref={props.provided?.innerRef}
        {...props.provided?.draggableProps}
        className={cx("InboxSectionEntry", entryCSSClasses)}
      >
        <div
          {...props.provided?.dragHandleProps}
          onClick={(e) => e.preventDefault()}
          className={cx("mr-4", isDisabled && "cursor-not-allowed")}
        >
          <Tooltip side="right" content={tooltipContent}>
            <span>
              <RxDragHandleHorizontal size={30} className={cx(isDisabled ? "text-slate-7" : "text-slate-9")} />
            </span>
          </Tooltip>
        </div>

        <div className="font-medium">{props.subsection.name}</div>

        <div className="flex-1" />

        <Tooltip side="left" content={tooltipContent}>
          <span>
            <OutlineButton
              onClick={(e) => {
                e.preventDefault();
                props.onEdit?.();
              }}
              className={cx("mr-2", isDisabled && "text-slate-9 cursor-not-allowed")}
              disabled={isDisabled}
            >
              <small>Edit</small>
            </OutlineButton>
          </span>
        </Tooltip>

        <Tooltip side="left" content={tooltipContent}>
          <span>
            <OutlineButton
              className={cx("mr-2", isDisabled && "text-slate-9 cursor-not-allowed")}
              disabled={isDisabled}
              onClick={(e) => {
                e.preventDefault();
                props.onDelete?.();
              }}
            >
              <small>Delete</small>
            </OutlineButton>
          </span>
        </Tooltip>
      </div>
    </List.Entry>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    environment: ClientEnvironment,
    values: {
      id: string;
      subsections: SubsectionData[];
    },
  ) => {
    console.log("submitting...", values);

    using disposable = environment.isLoading.add();

    toast("vanilla", {
      subject: "Saving inbox updates...",
    });

    await updateInboxSection(environment, {
      inboxSectionId: values.id,
      subsections: values.subsections,
    });

    console.log("submitted successfully!");

    toast("vanilla", {
      subject: "Inbox updated.",
    });

    environment.router.navigate(`/inbox/${values.id}`);
  },
);

/* -----------------------------------------------------------------------------------------------*/
