import { CreateRecord, op } from "libs/transaction";
import { omit } from "lodash-es";
import {
  InboxSectionTagRecord,
  InboxSubsectionTagRecord,
  SpecialTagTypeEnum,
  generateRecordId,
  getPointer,
} from "libs/schema";
import { withTransaction, write } from "./write";
import { AlertDialogState } from "~/dialogs/alert/AlertDialog";
import { ParsedToken } from "libs/searchQueryParser";

/* -------------------------------------------------------------------------------------------------
 *  deleteInboxSection
 * -------------------------------------------------------------------------------------------------
 */

export const deleteInboxSection = withTransaction(
  "deleteInboxSection",
  async (
    environment,
    transaction,
    props: {
      inboxSectionId: string;
      afterUndo?: () => Promise<void> | void;
    },
  ) => {
    transaction.operations.push(op.delete("tag", props.inboxSectionId));

    await write(environment, { transaction, onOptimisticUndo: props.afterUndo });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  createInboxSection
 * -------------------------------------------------------------------------------------------------
 */

export const createInboxSection = withTransaction(
  "createInboxSection",
  async (
    environment,
    transaction,
    props: {
      inboxSection: CreateRecord<InboxSectionTagRecord>;
      inboxSubsections: CreateRecord<InboxSubsectionTagRecord>[];
      afterUndo?: () => Promise<void> | void;
    },
  ) => {
    if (!environment.network.isOnline()) {
      AlertDialogState.open({
        content: "You must be online to create/update an inbox section",
      });

      return;
    }

    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const pointer = getPointer("tag", props.inboxSection.id);

    transaction.operations.push(
      op.upsert(pointer, {
        onCreate: [
          op.set("tag", {
            ...props.inboxSection,
            data: {
              ...props.inboxSection.data,
              is_reindexing: true,
            },
          }),
          op.set("tag_user_member", {
            id: generateRecordId("tag_user_member", {
              tag_id: props.inboxSection.id,
              user_id: currentUserId,
            }),
            tag_id: props.inboxSection.id,
            tag_type: SpecialTagTypeEnum.INBOX_SECTION,
            user_id: currentUserId,
            creator_user_id: currentUserId,
            owner_organization_id: props.inboxSection.owner_organization_id,
          }),
          ...props.inboxSubsections.flatMap((subsection) => [
            op.set("tag", subsection),
            op.set("tag_user_member", {
              id: generateRecordId("tag_user_member", {
                tag_id: subsection.id,
                user_id: currentUserId,
              }),
              tag_id: subsection.id,
              tag_type: SpecialTagTypeEnum.INBOX_SUBSECTION,
              user_id: currentUserId,
              creator_user_id: currentUserId,
              owner_organization_id: subsection.owner_organization_id,
            }),
          ]),
        ],
      }),
    );

    await write(environment, { transaction, onOptimisticUndo: props.afterUndo });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  updateInboxSection
 * -------------------------------------------------------------------------------------------------
 */

export type UpdateInboxSectionProps = {
  inboxSectionId: string;
  subsections: Array<{
    id: string;
    name: string;
    query: ParsedToken[];
  }>;
};

export const updateInboxSection = withTransaction(
  "updateInboxSection",
  async (environment, transaction, props: UpdateInboxSectionProps) => {
    if (!environment.network.isOnline()) {
      AlertDialogState.open({
        content: "You must be online to edit your inbox sections",
      });

      return;
    }

    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const currentUserOwnerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();
    const pointer = getPointer("tag", props.inboxSectionId);

    const [_prevSubsections] = await environment.recordLoader.getInboxSubsections({
      currentUserId,
      inboxSectionId: props.inboxSectionId,
    });

    const prevSubsections = _prevSubsections as InboxSubsectionTagRecord[];

    const deleteSubsections = prevSubsections.filter(
      (prevSubsection) => !props.subsections.some((subsection) => prevSubsection.id === subsection.id),
    );

    transaction.operations.push(
      op.update(pointer, "data", { is_reindexing: true }),
      ...props.subsections.map((subsection, index) => {
        const subsectionPointer = getPointer("tag", subsection.id);

        return op.upsert(subsectionPointer, {
          onCreate: [
            op.set("tag", {
              id: subsection.id,
              type: SpecialTagTypeEnum.INBOX_SUBSECTION,
              name: subsection.name,
              description: null,
              icon: null,
              owner_organization_id: currentUserOwnerOrganizationId,
              archived_at: null,
              data: {
                user_id: currentUserId,
                inbox_section_id: props.inboxSectionId,
                order: index,
                parsed_query: subsection.query,
              } satisfies InboxSubsectionTagRecord["data"],
            }),
            op.set("tag_user_member", {
              id: generateRecordId("tag_user_member", {
                tag_id: subsection.id,
                user_id: currentUserId,
              }),
              tag_id: subsection.id,
              tag_type: SpecialTagTypeEnum.INBOX_SUBSECTION,
              user_id: currentUserId,
              creator_user_id: currentUserId,
              owner_organization_id: currentUserOwnerOrganizationId,
            }),
          ],
          onUpdate: [
            op.update(subsectionPointer, { name: subsection.name }),
            op.update(subsectionPointer, "data", {
              user_id: currentUserId,
              inbox_section_id: props.inboxSectionId,
              order: index,
              parsed_query: subsection.query,
            } satisfies InboxSubsectionTagRecord["data"]),
          ],
        });
      }),
      // Note that we're intetionally not deleting the tag_user_member records which
      // link the current user to this subsection tag. This is to preserve our ability
      // to authorize the current user to read/modify this subsection tag (e.g. if, in
      // the future, the user wanted to undelete the subsection tag--which we don't
      // currently support)..
      ...deleteSubsections.map((subsection) => op.delete("tag", subsection)),
    );

    await write(environment, {
      transaction,
      canUndo: false,
    });

    await environment.api.reindexInboxSections({
      notificationDeliveredAt: {
        onOrBefore: new Date().toISOString(),
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  reorderInboxSections
 * -------------------------------------------------------------------------------------------------
 */

export const reorderInboxSections = withTransaction(
  "reorderInboxSections",
  async (
    environment,
    transaction,
    props: {
      newOrder: Array<{ id: string }>;
    },
  ) => {
    if (!environment.network.isOnline()) {
      AlertDialogState.open({
        content: "You must be online to reorder inbox sections",
      });

      return;
    }

    props.newOrder.forEach(({ id }, index) => {
      transaction.operations.push(op.update({ table: "tag", id }, "data", { order: index }));
    });

    await write(environment, {
      transaction,
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/
