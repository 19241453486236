import { UnreachableCaseError } from "libs/errors";
import { wait } from "libs/promise-utils";
import { useObservableEagerState } from "observable-hooks";
import { createContext, PropsWithChildren, useEffect } from "react";
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, Subject } from "rxjs";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { ParentComponent } from "~/utils/type-helpers";

export type TSidebarLayoutFocusEvent = "Sidebar" | "Outlet";
export type TSidebarLayoutMode = "push" | "over";

export interface ISidebarLayoutContext {
  focusEvent$: Observable<TSidebarLayoutFocusEvent>;
  isSidebarOpen(): boolean;
  sidebarOpen$: Observable<boolean>;
  sidebarMode(): TSidebarLayoutMode;
  sidebarMode$: Observable<TSidebarLayoutMode>;
  /** @returns A promise that resolves after react has processed any changes */
  focus(event: TSidebarLayoutFocusEvent): Promise<void>;
  /** @returns A promise that resolves after react has processed any changes */
  setSidebarOpen(isOpen: boolean): Promise<void>;
  /**
   * @returns A promise that resolves after react has processed any changes
   */
  setSidebarMode(value: TSidebarLayoutMode): Promise<void>;
  /**
   * This overrides the natural sidebar mode with a new one. You can then clear this override
   * by passing "clear override" as the value. This is useful for temporarily changing the
   * sidebar mode for a specific page and then reverting to the natural mode afterwards.
   *
   * @returns A promise that resolves after react has processed any changes
   */
  overrideSidebarMode(value: TSidebarLayoutMode | "clear override"): Promise<void>;
  useSidebarMode(): TSidebarLayoutMode;
  useIsSidebarOpen(): boolean;
}

export const SidebarLayoutContext = createContext<ISidebarLayoutContext | null>(null);

export const useSidebarLayoutContext = createUseContextHook(SidebarLayoutContext, "SidebarLayoutContext");

export function useForceSidebarIntoMode(mode: TSidebarLayoutMode) {
  const { overrideSidebarMode } = useSidebarLayoutContext();

  useEffect(() => {
    overrideSidebarMode(mode);

    return () => {
      overrideSidebarMode("clear override");
    };
  }, [mode, overrideSidebarMode]);
}

export function withProvideSidebarLayoutContext<P extends PropsWithChildren<unknown>>(Component: ParentComponent<P>) {
  return function ComponentWithContext(props: P) {
    const context = useConstant(() => {
      const sidebarMode$ = new BehaviorSubject<TSidebarLayoutMode>("over");
      const sidebarForceMode$ = new BehaviorSubject<TSidebarLayoutMode | null>(null);
      const sidebarOpen$ = new BehaviorSubject<boolean>(false);
      const focusEvent$ = new Subject<TSidebarLayoutFocusEvent>();

      const SIDEBAR_LAYOUT_CONTEXT: ISidebarLayoutContext = {
        focusEvent$,
        async focus(event: TSidebarLayoutFocusEvent) {
          switch (event) {
            case "Sidebar":
              if (!this.isSidebarOpen()) {
                this.setSidebarOpen(true);
              }

              break;
            case "Outlet": {
              if (this.sidebarMode() === "over") {
                this.setSidebarOpen(false);
              }

              break;
            }
            default: {
              throw new UnreachableCaseError(event);
            }
          }

          // Wait a tick for react to re-render and open the sidebar
          await wait(1);
          focusEvent$.next(event);
        },
        isSidebarOpen() {
          return sidebarOpen$.getValue();
        },
        sidebarOpen$: sidebarOpen$.pipe(distinctUntilChanged()),
        async setSidebarOpen(open: boolean) {
          if (open === this.isSidebarOpen()) return;
          sidebarOpen$.next(open);
          // Wait a tick for react to re-render and open the sidebar
          await wait(1);
          const mode = this.sidebarMode();

          switch (mode) {
            case "over": {
              if (open) focusEvent$.next("Sidebar");
              else focusEvent$.next("Outlet");
              break;
            }
            case "push": {
              break;
            }
            default: {
              throw new UnreachableCaseError(mode);
            }
          }
        },
        sidebarMode() {
          return sidebarForceMode$.getValue() || sidebarMode$.getValue();
        },
        sidebarMode$: combineLatest([sidebarMode$, sidebarForceMode$]).pipe(
          map(([sidebarMode, sidebarForceMode]) => sidebarForceMode || sidebarMode),
          distinctUntilChanged(),
        ),
        async setSidebarMode(value) {
          sidebarMode$.next(value);
        },
        async overrideSidebarMode(value) {
          if (value === "clear override") {
            sidebarForceMode$.next(null);
          } else {
            sidebarForceMode$.next(value);
          }
        },
        useSidebarMode() {
          return useObservableEagerState(this.sidebarMode$);
        },
        useIsSidebarOpen() {
          return useObservableEagerState(this.sidebarOpen$);
        },
      };

      return SIDEBAR_LAYOUT_CONTEXT;
    });

    // Respond to sidebar mode changes by automatically opening or closing the sidebar
    useEffect(() => {
      const sub = context.sidebarMode$.subscribe((mode) => {
        switch (mode) {
          case "over": {
            context.setSidebarOpen(false);
            break;
          }
          case "push": {
            context.setSidebarOpen(true);
            break;
          }
          default: {
            throw new UnreachableCaseError(mode);
          }
        }
      });

      return () => sub.unsubscribe();
    }, [context.sidebarMode$]);

    return (
      <SidebarLayoutContext.Provider value={context}>
        <Component {...props} />
      </SidebarLayoutContext.Provider>
    );
  };
}
