import { getPointer } from "libs/schema";
import { combineLatest, map, of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useLoadingObservable } from "./useLoadingObservable";
import { ClientRecordLoaderObserveGetRecordsResult, ObserveOptions } from "~/environment/RecordLoader";
import { UnreachableCaseError } from "libs/errors";
import { renderGroupName } from "~/utils/tag-utils";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export type UseMessageRecipientNamesResult = [names: string[], meta: { isLoading: boolean }];

export function useMessageRecipientNames(messageId: string | null | undefined): UseMessageRecipientNamesResult {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment.recordLoader, messageId, currentUserId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, messageId, currentUserId]) => {
          if (!messageId) {
            return of(getDefaultResult(false));
          }

          const options: ObserveOptions = {};

          return recordLoader
            .observeGetRecord(
              {
                table: "message",
                id: messageId,
              },
              options,
            )
            .pipe(
              switchMap(([message, { isLoading: isMessageLoading }]) => {
                if (!message?.to) return of(getDefaultResult(isMessageLoading));

                const thread$ =
                  !message || !message.is_reply || message.is_delivered ?
                    recordLoader.createObserveGetResult<"thread">([null, { isLoading: isMessageLoading }])
                  : recordLoader.observeGetRecord(
                      {
                        table: "thread",
                        id: message.thread_id,
                      },
                      {
                        isLoading: isMessageLoading,
                        fetchStrategy: options.fetchStrategy,
                      },
                    );

                // If the sent message is a reply and if it hasn't been delivered yet, then the only
                // message recipients are recipients that were added to the thread by this message.
                // I.e. when drafting a reply, we only add recipients to the draft if they are not
                // already participating in the thread. On the server when we deliver the message,
                // we add all the thread recipients that exist at that time to the message recipients.
                // But when the message hasn't yet been delivered, we need to do that manually here
                // in order to render the expected results.
                const threadRecipient$ = thread$.pipe(
                  switchMap(([thread, { isLoading: isThreadLoading }]) => {
                    if (!thread) {
                      return of([
                        [[], { isLoading: isThreadLoading }],
                        [[], { isLoading: isThreadLoading }],
                      ] as [
                        ClientRecordLoaderObserveGetRecordsResult<"tag">,
                        ClientRecordLoaderObserveGetRecordsResult<"user_profile">,
                      ]);
                    }

                    const threadGroups$ = recordLoader
                      .observeGetThreadGroupPermissions(
                        { thread_id: thread.id },
                        {
                          isLoading: isThreadLoading,
                          fetchStrategy: options.fetchStrategy,
                        },
                      )
                      .pipe(
                        switchMap(([records, { isLoading }]) => {
                          return recordLoader.observeGetRecords(
                            records.map((r) => getPointer("tag", r.group_id)),
                            { isLoading, fetchStrategy: options.fetchStrategy },
                          );
                        }),
                      );

                    const threadUsers$ = recordLoader
                      .observeGetThreadUserParticipants(
                        { thread_id: thread.id },
                        {
                          isLoading: isThreadLoading,
                          fetchStrategy: options.fetchStrategy,
                        },
                      )
                      .pipe(
                        switchMap(([records, { isLoading }]) => {
                          return recordLoader.observeGetRecords(
                            records.map((r) => getPointer("user_profile", r.user_id)),
                            { isLoading, fetchStrategy: options.fetchStrategy },
                          );
                        }),
                      );

                    return combineLatest([threadGroups$, threadUsers$]);
                  }),
                  map(([threadGroups, threadUsers]) => {
                    const threadRecipients = [...threadGroups[0], ...threadUsers[0]];
                    const isLoading = threadGroups[1].isLoading || threadUsers[1].isLoading;
                    return [threadRecipients, { isLoading }] as const;
                  }),
                );

                const messageRecipients$ = recordLoader.observeGetRecords(
                  message.to.map((recipient) => {
                    switch (recipient.type) {
                      case "USER": {
                        return getPointer("user_profile", recipient.user_id);
                      }
                      case "GROUP": {
                        return getPointer("tag", recipient.group_id);
                      }
                      default: {
                        throw new UnreachableCaseError(recipient);
                      }
                    }
                  }),
                  { fetchStrategy: options.fetchStrategy },
                );

                return combineLatest([messageRecipients$, threadRecipient$]);
              }),
            );
        }),
        map(([[messageRecipients, messageMeta], [threadRecipients, threadMeta]]): UseMessageRecipientNamesResult => {
          const isLoading = messageMeta.isLoading || threadMeta.isLoading;

          const userNames: string[] = [];
          const groupNames: string[] = [];

          for (const { table, record } of messageRecipients) {
            switch (table) {
              case "user_profile": {
                if (record.id === currentUserId) continue;
                userNames.push(`@${record.name}`);
                break;
              }
              case "tag": {
                if (record.data?.is_organization_group) continue;
                groupNames.push(`${renderGroupName(record)}`);
                break;
              }
              default: {
                throw new UnreachableCaseError(table);
              }
            }
          }

          for (const { table, record } of threadRecipients) {
            const hasRecipientAlready = messageRecipients.some((r) => r.table === table && r.id === record.id);

            if (hasRecipientAlready) continue;

            switch (table) {
              case "user_profile": {
                if (record.id === currentUserId) continue;
                userNames.push(`@${record.name}`);
                break;
              }
              case "tag": {
                if (record.data?.is_organization_group) continue;
                groupNames.push(`${renderGroupName(record)}`);
                break;
              }
              default: {
                throw new UnreachableCaseError(table);
              }
            }
          }

          return [[...userNames, ...groupNames], { isLoading }];
        }),
      );
    },
  });
}

const DEFAULT_VALUE: UseMessageRecipientNamesResult = Object.freeze([
  Object.freeze([]),
  {
    isLoading: true,
  },
]) as unknown as UseMessageRecipientNamesResult;

function getDefaultResult(isLoading: boolean) {
  return [
    [[], { isLoading }],
    [[], { isLoading }],
  ] as const;
}
