import {
  createRootRouteWithContext,
  createRoute,
  createRouteMask,
  createRouter,
  Outlet,
  redirect,
} from "@tanstack/react-router";
import type { ClientEnvironment } from "./environment/ClientEnvironment";
import { PlansView } from "./pages/plans";
import { DeactivatedView } from "./pages/deactivated";
import { SignupView } from "./pages/signup/SignupView";
import { LoginView } from "./pages/login/LoginView";
import { SidebarLayout } from "./page-layouts/sidebar-layout/SidebarLayout";
import { TrashView } from "./pages/trash/TrashView";
import { StarredView } from "./pages/starred/StarredView";
import { SettingsView } from "./pages/settings/SettingsView";
import { SentView } from "./pages/sent/SentView";
import { SearchView } from "./pages/search/SearchView";
import { ScheduledView } from "./pages/scheduled/ScheduledView";
import { RemindersView } from "./pages/reminders/RemindersView";
import { ReferralsView } from "./pages/referrals/ReferralsView";
import { DraftsView } from "./pages/drafts/DraftsView";
import { ThreadView } from "./pages/thread/ThreadView";
import { DoneView } from "./pages/done/DoneView";
import { InboxView } from "./pages/inbox/InboxView";
import { TagView } from "./pages/tag/TagView";
import { TagSubscribersView } from "./pages/tag/TagSubscribersView";
import { MembersView as OrganizationMembersView } from "./pages/organization/MembersView";
import { ExploreGroupsView as OrganizationExploreGroupsView } from "./pages/organization/explore-groups/ExploreGroupsView";
import { lazy, Suspense, useEffect } from "react";
import { config } from "./environment/config";
import { isDevelopment } from "libs/AppEnvironment";
import { ParentComponent } from "./utils/type-helpers";
import useConstant from "use-constant";
import { useSessionStorage } from "react-use";
import { NotFound } from "~/components/NotFound";
import { FromPathOption } from "@tanstack/react-router/dist/esm/link";
import { EditInboxSectionView } from "./pages/inbox/EditInboxSectionView";
import { ArchivedPersonalLabelsView } from "./pages/tag/ArchivedPersonalLabelsView";

/* -------------------------------------------------------------------------------------------------
 * routeTree
 * -----------------------------------------------------------------------------------------------*/

export interface RouterContext {
  environment: ClientEnvironment;
}

const rootLayout = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
});

const rootRoute = createRoute({
  getParentRoute: () => rootLayout,
  path: "/",
  beforeLoad: redirectToDefaultInbox,
});

const plansRoute = createRoute({
  getParentRoute: () => rootLayout,
  path: "/plans",
  component: PlansView,
});

const deactivatedRoute = createRoute({
  getParentRoute: () => rootLayout,
  path: "/deactivated",
  component: DeactivatedView,
});

const signupRoute = createRoute({
  getParentRoute: () => rootLayout,
  path: "/signup",
  component: () => <SignupView />,
});

const loginRoute = createRoute({
  getParentRoute: () => rootLayout,
  path: "/login",
  component: LoginView,
});

const sidebarLayoutRoute = createRoute({
  id: "/_SidebarLayout",
  getParentRoute: () => rootLayout,
  component: () => <SidebarLayout />,
});

const trashRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/trash",
  component: () => <TrashView />,
});

const trashThreadRoute = createRoute({
  getParentRoute: () => trashRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const starredRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/starred",
  component: () => <StarredView />,
});

const starredThreadRoute = createRoute({
  getParentRoute: () => starredRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const settingsRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/settings",
  component: () => <SettingsView />,
});

const sentRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/sent",
  component: () => <SentView />,
});

const sentThreadRoute = createRoute({
  getParentRoute: () => sentRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const searchRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/search",
  component: () => <SearchView />,
});

const searchQueryRoute = createRoute({
  getParentRoute: () => searchRoute,
  path: "/$query",
});

const searchThreadRoute = createRoute({
  getParentRoute: () => searchQueryRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const scheduledRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/scheduled",
  component: () => <ScheduledView />,
});

const scheduledThreadRoute = createRoute({
  getParentRoute: () => scheduledRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const remindersRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/reminders",
  component: () => <RemindersView />,
});

const remindersThreadRoute = createRoute({
  getParentRoute: () => remindersRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const referralsRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/referrals",
  component: () => <ReferralsView />,
});

const draftsRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/drafts",
  component: () => <DraftsView />,
});

const draftsThreadRoute = createRoute({
  getParentRoute: () => draftsRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const doneRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/done",
  component: () => <DoneView />,
});

const doneThreadRoute = createRoute({
  getParentRoute: () => doneRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const threadRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/threads/$threadId",
  beforeLoad: async (props) => {
    const { context, params } = props;
    const currentUserId = context.environment.auth.getAndAssertCurrentUserId();
    const [[defaultInboxSection]] = await context.environment.recordLoader.getInboxSections({ currentUserId });

    if (defaultInboxSection) {
      throw redirect({
        href: `/inbox/${defaultInboxSection.id}/threads/${params.threadId}`,
        replace: true,
      });
    }

    throw new Error("No default inbox section found");
  },
});

const inboxRootRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  // The trailing slash indicates that we only want to match on exactly `"/inbox"`
  path: "/inbox/",
  beforeLoad: redirectToDefaultInbox,
});

const inboxRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/inbox/$inboxSectionId",
  component: () => <InboxView />,
});

const inboxThreadRoute = createRoute({
  getParentRoute: () => inboxRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const editInboxSectionRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/inbox/$inboxSectionId/edit",
  component: () => <EditInboxSectionView />,
});

const groupRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/groups/$tagId",
  component: () => <TagView />,
});

const groupThreadRoute = createRoute({
  getParentRoute: () => groupRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const groupSubscribersRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/groups/$tagId/subscribers",
  component: () => <TagSubscribersView />,
});

const archivedPersonalLabelsRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/archived-labels",
  component: () => <ArchivedPersonalLabelsView />,
});

const labelRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/labels/$tagId",
  component: () => <TagView />,
});

const labelThreadRoute = createRoute({
  getParentRoute: () => labelRoute,
  path: "threads/$threadId",
  component: () => <ThreadView />,
});

const organizationMembersRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/organizations/$organizationId/members",
  component: () => <OrganizationMembersView />,
});

const organizationExploreGroupsRoute = createRoute({
  getParentRoute: () => sidebarLayoutRoute,
  path: "/organizations/$organizationId/explore-groups",
  component: () => <OrganizationExploreGroupsView />,
});

/* -----------------------------------------------------------------------------------------------*/

const routeTree = rootLayout.addChildren([
  rootRoute,
  plansRoute,
  deactivatedRoute,
  signupRoute,
  loginRoute,
  sidebarLayoutRoute.addChildren([
    trashRoute.addChildren([trashThreadRoute]),
    starredRoute.addChildren([starredThreadRoute]),
    settingsRoute,
    sentRoute.addChildren([sentThreadRoute]),
    searchRoute.addChildren([searchQueryRoute.addChildren([searchThreadRoute])]),
    scheduledRoute.addChildren([scheduledThreadRoute]),
    remindersRoute.addChildren([remindersThreadRoute]),
    referralsRoute,
    draftsRoute.addChildren([draftsThreadRoute]),
    doneRoute.addChildren([doneThreadRoute]),
    threadRoute,
    inboxRootRoute,
    inboxRoute.addChildren([inboxThreadRoute]),
    editInboxSectionRoute,
    groupRoute.addChildren([groupThreadRoute]),
    groupSubscribersRoute,
    archivedPersonalLabelsRoute,
    labelRoute.addChildren([labelThreadRoute]),
    organizationMembersRoute,
    organizationExploreGroupsRoute,
  ]),
]);

/* -------------------------------------------------------------------------------------------------
 * routeMasks
 * -----------------------------------------------------------------------------------------------*/

const pathsForThreadViewRouteMasks = [
  "/trash/threads/$threadId",
  "/starred/threads/$threadId",
  "/sent/threads/$threadId",
  "/search/$query/threads/$threadId",
  "/scheduled/threads/$threadId",
  "/reminders/threads/$threadId",
  "/done/threads/$threadId",
  "/drafts/threads/$threadId",
  "/inbox/$inboxSectionId/threads/$threadId",
  "/groups/$tagId/threads/$threadId",
  "/labels/$tagId/threads/$threadId",
] as const;

const routeMasks = pathsForThreadViewRouteMasks.map((from) =>
  createRouteMask({
    routeTree,
    from,
    to: "/threads/$threadId",
    search: true,
    params: true,
  }),
);

/* -------------------------------------------------------------------------------------------------
 * tanstackRouter
 * -----------------------------------------------------------------------------------------------*/

// The "real" context is provided when using the tanstack RouterProvider in React. Tanstack just
// requires providing a dummy context object when creating the router. It might only be for
// typing.
const routerContext: RouterContext = {
  environment: null!,
};

// Creating the router involves importing all of the routes (via the routeTree.gen file).
// In order to avoid circular dependencies we do this in a separate file.
export const tanstackRouter = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <NotFound />,
  routeMasks,
  context: routerContext,
});

export type TanstackRouter = typeof tanstackRouter;

export type RoutePath = FromPathOption<TanstackRouter, string>;

declare module "@tanstack/react-router" {
  interface Register {
    router: TanstackRouter;
  }
}

/* -------------------------------------------------------------------------------------------------
 * utilities
 * -----------------------------------------------------------------------------------------------*/

const TanStackRouterDevtools: ParentComponent = () => {
  if (!isDevelopment(config.appEnvironment)) {
    return null;
  }

  const [show, setShow] = useSessionStorage("tanstackDevtools", false);

  const Component = useConstant(() =>
    lazy(() =>
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    ),
  );

  useEffect(() => {
    console.log(
      `%c[TanStackRouterDevtools] toggle the devtools via tanstackDevtools.show() in the console`,
      "font-weight: bold; color: yellow",
    );

    (globalThis as any).tanstackDevtools = { show: () => setShow(true), hide: () => setShow(false) };
  }, []);

  if (!show) return null;

  return (
    <Suspense fallback={null}>
      <Component initialIsOpen={false} position="bottom-right" />
    </Suspense>
  );
};

/* -----------------------------------------------------------------------------------------------*/

async function redirectToDefaultInbox(props: { context: RouterContext }) {
  const currentUserId = props.context.environment.auth.getCurrentUserId();

  if (!currentUserId) {
    throw redirect({ href: `/login`, replace: true });
  }

  const [[defaultInboxSection]] = await props.context.environment.recordLoader.getInboxSections({ currentUserId });

  if (defaultInboxSection) {
    throw redirect({ href: `/inbox/${defaultInboxSection.id}`, replace: true });
  }

  throw new Error("No default inbox section found");
}

/* -----------------------------------------------------------------------------------------------*/
