import {
  GroupTagRecord,
  InboxSectionTagRecord,
  InboxSubsectionTagRecord,
  LabelTagRecord,
  RecordValue,
  SingletonTagRecord,
  SpecialTagTypeEnum,
} from "./schema";

export function isTagSubscribed(record: Pick<RecordValue<"tag_subscription">, "preference">) {
  return record.preference === "all-new" || record.preference === "all";
}

export function isGroupTagRecord(
  record: Pick<RecordValue<"tag">, "type"> | null | undefined,
): record is GroupTagRecord {
  return record?.type === SpecialTagTypeEnum.GROUP;
}

export function isOrganizationGroupTagRecord(
  record: Pick<RecordValue<"tag">, "type" | "data"> | null | undefined,
): record is GroupTagRecord {
  return record?.type === SpecialTagTypeEnum.GROUP && !!record.data?.is_organization_group;
}

export function isInboxSectionTagRecord(
  record: Pick<RecordValue<"tag">, "type"> | null | undefined,
): record is InboxSectionTagRecord {
  return record?.type === SpecialTagTypeEnum.INBOX_SECTION;
}

export function isInboxSubsectionTagRecord(
  record: Pick<RecordValue<"tag">, "type"> | null | undefined,
): record is InboxSubsectionTagRecord {
  return record?.type === SpecialTagTypeEnum.INBOX_SUBSECTION;
}

export function isLabelTagRecord(
  record: Pick<RecordValue<"tag">, "type"> | null | undefined,
): record is LabelTagRecord {
  return record?.type === SpecialTagTypeEnum.LABEL;
}

export function isSingletonTagRecord(
  record: Pick<RecordValue<"tag">, "type"> | null | undefined,
): record is SingletonTagRecord {
  return record?.type === SpecialTagTypeEnum.SINGLETON;
}

export function isTagPrivate(record: Pick<RecordValue<"tag">, "type" | "data">) {
  return (
    !isSingletonTagRecord(record) &&
    !record.data?.organization_group_member_ids?.length &&
    !(isGroupTagRecord(record) && record.data?.is_organization_group)
  );
}
