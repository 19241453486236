import { RuleGroupArray, RuleGroupType, RuleType } from "react-querybuilder";
import { ParsedToken, LogicalOperator, Token, Primatives } from "libs/searchQueryParser";
import { getUserSelectOption, IUserOption } from "~/components/forms/UserSelect";
import { getGroupSelectOption, IGroupOption } from "~/components/forms/GroupSelect";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { parseDocId } from "libs/searchQueryMatcher";
import { getLabelSelectOption, ILabelOption } from "~/components/forms/LabelSelect";

export function mapReactQueryBuilderRuleToParsedToken(rule: RuleType | RuleGroupType): ParsedToken {
  if ("combinator" in rule && rule.combinator) {
    switch (rule.combinator) {
      case "and": {
        return mapCombinatorRuleToParsedToken({ type: "and", invert: rule.not || false, rules: rule.rules });
      }
      case "or": {
        return mapCombinatorRuleToParsedToken({ type: "or", invert: rule.not || false, rules: rule.rules });
      }
      default: {
        throw new Error(`Unexpected combinator: ${rule.combinator}`);
      }
    }
  } else if ("field" in rule && rule.field) {
    switch (rule.field) {
      case "from": {
        return {
          type: "from:",
          value: [createDocIdToken("user", (rule.value as IUserOption).value)],
        } satisfies ParsedToken;
      }
      case "to": {
        return {
          type: "to:",
          value: [createDocIdToken("user", (rule.value as IUserOption).value)],
        } satisfies ParsedToken;
      }
      case "group": {
        return {
          type: "group:",
          value: [createDocIdToken("group", (rule.value as IGroupOption).value)],
        } satisfies ParsedToken;
      }
      case "label": {
        return {
          type: "label:",
          value: [createDocIdToken("label", (rule.value as ILabelOption).value)],
        } satisfies ParsedToken;
      }
      case "is:private": {
        return isPrivateFilter;
      }
      case "-is:private": {
        return invert(isPrivateFilter);
      }
      case "is:branch": {
        return isBranchFilter;
      }
      case "-is:branch": {
        return invert(isBranchFilter);
      }
      case "after": {
        return {
          type: "after:",
          value: [
            {
              type: "text",
              value: rule.value.replaceAll("-", "/"),
            },
          ],
        } satisfies ParsedToken;
      }
      case "before": {
        return {
          type: "before:",
          value: [
            {
              type: "text",
              value: rule.value.replaceAll("-", "/"),
            },
          ],
        } satisfies ParsedToken;
      }
      case "has:attachment": {
        return hasAttachmentFilter;
      }
      case "-has:attachment": {
        return invert(hasAttachmentFilter);
      }
      default: {
        throw new Error(`Unexpected rule: ${rule.field}`);
      }
    }
  } else {
    throw new Error(`Unexpected rule: ${JSON.stringify(rule)}`);
  }
}

export async function mapParsedTokenToReactQueryBuilderRule(
  environment: ClientEnvironment,
  token: ParsedToken,
): Promise<RuleType | RuleGroupType> {
  switch (token.type) {
    case "from:": {
      const { subjectId } = parseDocId(token.value[0] as Token<"DocId", string>);

      return createSimpleRuleType("from", await getUserSelectOption(environment, subjectId));
    }
    case "to:": {
      const { subjectId } = parseDocId(token.value[0] as Token<"DocId", string>);

      return createSimpleRuleType("to", await getUserSelectOption(environment, subjectId));
    }
    case "is:": {
      switch (getTokenStringValue(token)) {
        case "private": {
          return createSimpleRuleType("is:private");
        }
        case "branch": {
          return createSimpleRuleType("is:branch");
        }
        default: {
          throw new Error(`Unexpected is: value: ${getTokenStringValue(token)}`);
        }
      }
    }
    case "after:": {
      return {
        field: "after",
        operator: ">=",
        value: getTokenStringValue(token).replaceAll("/", "-"),
      } satisfies RuleType;
    }
    case "before:": {
      return {
        field: "before",
        operator: "<",
        value: getTokenStringValue(token).replaceAll("/", "-"),
      } satisfies RuleType;
    }
    case "group:": {
      const { subjectId } = parseDocId(token.value[0] as Token<"DocId", string>);

      return createSimpleRuleType("group", await getGroupSelectOption(environment, subjectId));
    }
    case "label:": {
      const { subjectId } = parseDocId(token.value[0] as Token<"DocId", string>);

      return createSimpleRuleType("label", await getLabelSelectOption(environment, subjectId));
    }
    case "has:": {
      return createSimpleRuleType("has:attachment");
    }
    case "and()": {
      return {
        combinator: "and",
        rules: await Promise.all(token.value.map((token) => mapParsedTokenToReactQueryBuilderRule(environment, token))),
      } satisfies RuleGroupType;
    }
    case "or()": {
      return {
        combinator: "or",
        rules: await Promise.all(token.value.map((token) => mapParsedTokenToReactQueryBuilderRule(environment, token))),
      } satisfies RuleGroupType;
    }
    case "not()": {
      if (token.value.length !== 1) {
        throw new Error("Unexpected not() token length");
      }

      const innerToken = token.value[0]!;

      innerTokenTypeSwitch: switch (innerToken.type) {
        case "is:": {
          switch (getTokenStringValue(innerToken)) {
            case "private": {
              return createSimpleRuleType("-is:private");
            }
            case "branch": {
              return createSimpleRuleType("-is:branch");
            }
            default: {
              break innerTokenTypeSwitch;
            }
          }
        }
        case "has:": {
          switch (getTokenStringValue(innerToken)) {
            case "attachment": {
              return createSimpleRuleType("-has:attachment");
            }
            default: {
              break innerTokenTypeSwitch;
            }
          }
        }
        case "and()":
        case "or()": {
          const combinator = innerToken.type.replace("()", "");

          return {
            combinator,
            not: true,
            rules: await Promise.all(
              innerToken.value.map((token) => mapParsedTokenToReactQueryBuilderRule(environment, token)),
            ),
          } satisfies RuleGroupType;
        }
        default: {
          throw new Error(`Unexpected not() token value: ${JSON.stringify(token.value)}`);
        }
      }
    }
    default: {
      throw new Error(`Unexpected token: ${token.type}`);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

function mapCombinatorRuleToParsedToken(props: {
  type: "and" | "or";
  invert: boolean;
  rules: RuleGroupArray;
}): ParsedToken {
  const baseOperator: LogicalOperator<"and()" | "or()"> = {
    type: `${props.type}()`,
    value: props.rules.map((rule) => mapReactQueryBuilderRuleToParsedToken(rule)),
  };

  if (props.invert) {
    return invert(baseOperator);
  } else {
    return baseOperator;
  }
}

function invert(token: ParsedToken): ParsedToken {
  return { type: "not()", value: [token] } satisfies LogicalOperator<"not()">;
}

function createDocIdToken(type: "user" | "group" | "label", subjectId: string): Token<"DocId", string> {
  return {
    type: "DocId",
    value: `${type}::@::${subjectId}`,
  };
}

function createSimpleRuleType(field: string, value: unknown = true): RuleType {
  return {
    field: field,
    operator: "=",
    value,
  };
}

/** Gets the primative value of a token with a single primative. */
function getTokenStringValue(token: Token<string, Primatives[]>) {
  const value = token.value[0]!.value;

  if (typeof value !== "string") {
    throw new Error(`Unexpected token value: ${value}`);
  }

  return value;
}

const isPrivateFilter = createSimpleTextFilter({ type: "is", value: "private" });
const isBranchFilter = createSimpleTextFilter({ type: "is", value: "branch" });
const hasAttachmentFilter = createSimpleTextFilter({ type: "has", value: "attachment" });

function createSimpleTextFilter(props: {
  type: "is" | "has";
  value: "private" | "branch" | "reply" | "attachment";
}): ParsedToken {
  return {
    type: `${props.type}:`,
    value: [
      {
        type: "text",
        value: `${props.value}`,
      },
    ],
  };
}

/* -----------------------------------------------------------------------------------------------*/
