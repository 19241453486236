import { Editor } from "@tiptap/core";
import { oneLine } from "common-tags";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { uuid } from "libs/uuid";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { subscribeWithSelector } from "zustand/middleware";
import { DeferredPromise } from "libs/promise-utils";
import { SetNonNullable } from "libs/type-helpers";
import { IImageExtentionAttrs } from "./extensions/image/context";
import { IMessageEditorControl } from ".";
import { AttachmentContentDisposition } from "libs/schema";
import { useEffect } from "react";
import { observable } from "../utils";
import { produce } from "immer";

export type TPendingUpload = {
  id: string;
  /**
   * May be null if the image is hosted on a 3rd party server and we
   * haven't finished downloading the file yet.
   */
  file: File | Blob | null;
  /** Object URL for rendering a preview of the file during uploads*/
  previewUrl: string;
  /** Upload progress between 0 and 100 */
  progress: number;
  cancelled?: boolean;
  error?: string;
};

export type TPendingUploadState = {
  pendingUploads: Record<string, TPendingUpload>;
  setUploadError: (id: string, error: any) => void;
  setUploadProgress: (id: string, progress: number) => void;
  setPendingUpload: (id: string, pendingUpload: TPendingUpload) => void;
  cancelPendingUpload: (id: string) => void;
  removePendingUpload: (id: string) => void;
};

/**
 * React hook for storing and accessing global pending upload state.
 * We store this state globally because, once started, an upload is not associated
 * with any particular form. E.g. if the user starts uploading an attachment
 * for a draft and then navigates away, that upload will continue in the
 * background. If the user then navigates back to the draft, they should see the
 * current upload progress.
 */
export const usePendingUploadState = create(
  subscribeWithSelector(
    immer<TPendingUploadState>((set) => ({
      pendingUploads: {},
      setUploadError: (id: string, error: any) =>
        set((state) => {
          const pendingUpload = state.pendingUploads[id];
          if (!pendingUpload) return;
          pendingUpload.error = error;
        }),
      setUploadProgress: (id: string, progress: number) =>
        set((state) => {
          const pendingUpload = state.pendingUploads[id];
          if (!pendingUpload) return;
          pendingUpload.progress = progress;
        }),
      setPendingUpload: (id: string, pendingUpload: TPendingUpload) =>
        set((state) => {
          state.pendingUploads[id] = pendingUpload;
        }),
      cancelPendingUpload: (id: string) =>
        set((state) => {
          const pendingUpload = state.pendingUploads[id];
          if (!pendingUpload) return;
          pendingUpload.cancelled = true;
        }),
      removePendingUpload: (id: string) =>
        set((state) => {
          delete state.pendingUploads[id];
        }),
    })),
  ),
);

export function uploadAndInsertImagesToEditor(
  environment: Pick<ClientEnvironment, "api" | "logger" | "isPendingUpdate">,
  props: {
    messageId: string;
    editor: Editor;
    files: FileList;
    position: number;
    saveDraftFn: () => Promise<void>;
  },
): boolean {
  const { editor, files, position, messageId, saveDraftFn } = props;

  const imageFiles = Array.from(files).filter((file) =>
    ["image/jpeg", "image/gif", "image/png", "image/webp"].includes(file.type),
  );

  if (imageFiles.length !== files.length) {
    alert(oneLine`
      Comms currently only supports embedding jpeg, gif, png, and webp
      images in messages. You appear to have dragged in file(s) that aren't
      an image or aren't a supported image type. Those will be ignored.
    `);
  }

  if (imageFiles.length === 0) return false;

  // Note that we're not actually awaiting these promises before returning. The
  // use of `Promise.allSettled` is just to catch any errors that occur during
  // the upload process.
  Promise.allSettled(
    imageFiles.map(async (imageFile) => {
      const { width, height } = await getImageDimensions(imageFile);

      const pendingUpload: SetNonNullable<TPendingUpload, "file"> = {
        id: uuid(),
        file: imageFile,
        previewUrl: URL.createObjectURL(imageFile),
        progress: 0,
      };

      const pendingUploadState = usePendingUploadState.getState();
      pendingUploadState.setPendingUpload(pendingUpload.id, pendingUpload);

      const img = document.createElement("img");
      // tiptap requires that the image have a src attribute
      img.src = "";
      img.alt = imageFile.name;
      img.title = imageFile.name;
      img.width = width;
      img.height = height;
      img.dataset.imageid = pendingUpload.id;
      img.dataset.contentType = imageFile.type;
      img.dataset.fileSize = String(imageFile.size);
      editor.commands.insertContentAt(position, img.outerHTML);

      const src = await uploadFile(environment, {
        pendingUpload,
        saveDraftFn: () => saveDraftFn(),
      });

      if (!src) return;

      updateDraftImage({
        editor,
        imageId: pendingUpload.id,
        imageAttributes: {
          src: `/uploads/${pendingUpload.id}?messageId=${messageId}`,
        },
      });
    }),
  ).then((results) => {
    results.forEach((result) => {
      if (result.status === "rejected") {
        console.error("uploadAndInsertImagesToEditor: ", result.reason);
      }
    });
  });

  return true;
}

export function insertHtmlToEditorAndUploadImages(
  environment: Pick<ClientEnvironment, "api" | "logger" | "isPendingUpdate">,
  props: {
    messageId: string;
    editor: Editor;
    html: string;
    position: number;
    saveDraftFn: () => Promise<void>;
  },
): boolean {
  const { editor, html, position, messageId, saveDraftFn } = props;

  const parser = new DOMParser();

  const doc = parser.parseFromString(html, "text/html");

  // Extract image elements from the parsed HTML
  const imgElements = doc.querySelectorAll("img");

  if (imgElements.length === 0) {
    return false; // Allow default onPaste handling to occur
  }

  const pendingUploadState = usePendingUploadState.getState();

  // Note that this mapping is also mutating the image elements
  const pendingUploads = Array.from(imgElements).map((el) => {
    const pendingUpload: TPendingUpload = {
      id: uuid(),
      file: null,
      previewUrl: el.src,
      progress: 0,
    };

    pendingUploadState.setPendingUpload(pendingUpload.id, pendingUpload);

    el.setAttribute("src", "");
    el.setAttribute("data-imageid", pendingUpload.id);

    return pendingUpload;
  });

  editorInsertElementAt({ editor, element: doc.body, position });

  Promise.allSettled(
    pendingUploads.map(async (pendingUpload) => {
      const image = await environment.isPendingUpdate.add(download3rdPartyImage(pendingUpload));

      if (!image) return; // pendingUpload already updated by download3rdPartyImage

      const { width, height } = await getImageDimensions(image);

      updateDraftImage({
        editor,
        imageId: pendingUpload.id,
        imageAttributes: {
          width,
          height,
          fileSize: image.size,
        },
      });

      const src = await uploadFile(environment, {
        pendingUpload: {
          ...pendingUpload,
          file: image,
        },
        saveDraftFn: () => saveDraftFn(),
      });

      if (!src) return;

      updateDraftImage({
        editor,
        imageId: pendingUpload.id,
        imageAttributes: {
          src: `/uploads/${pendingUpload.id}?messageId=${messageId}`,
        },
      });
    }),
  );

  return true;
}

export async function addAndUploadAttachment(
  environment: Pick<ClientEnvironment, "api" | "logger" | "isPendingUpdate">,
  props: {
    control: IMessageEditorControl;
    files: FileList | null;
  },
) {
  const { control, files } = props;

  if (!files) return;

  const pendingUploadState = usePendingUploadState.getState();

  await Promise.allSettled(
    Array.from(files).map(async (file) => {
      const upload: TPendingUpload & { file: File } = {
        id: uuid(),
        file,
        previewUrl: "",
        progress: 0,
      };

      const ONE_MB = 1024 * 1024;

      if (file.size > 30 * ONE_MB) {
        upload.error = "Attachments must be less than 30MB.";
      }

      pendingUploadState.setPendingUpload(upload.id, upload);

      const prevAttachments = control.controls.attachments.rawValue;

      control.patchValue({
        attachments: prevAttachments.concat({
          id: upload.id,
          fileName: upload.file.name,
          fileSize: upload.file.size,
          contentType: upload.file.type,
          contentDisposition: "attachment",
          errorMsg: upload.error,
        }),
      });

      if (upload.error) return;

      const src = await uploadFile(environment, {
        pendingUpload: upload as SetNonNullable<TPendingUpload, "file">,
        saveDraftFn: async () => {},
        contentDisposition: "attachment",
      });

      environment.logger.debug({ src }, "Attachment uploaded");
    }),
  );
}

const download3rdPartyImage = (pendingUpload: TPendingUpload): Promise<Blob | null> => {
  const deferred = new DeferredPromise<Blob | null>();
  const pendingUploadState = usePendingUploadState.getState();

  if (isBase64Image(pendingUpload.previewUrl)) {
    const contentType = getContentTypeFromBase64String(pendingUpload.previewUrl);

    if (!contentType) {
      pendingUploadState.setUploadError(pendingUpload.id, `Cannot determine image type.`);

      deferred.resolve(null);
      return deferred.promise;
    }

    const validContentType = ["image/jpeg", "image/gif", "image/png", "image/webp"].includes(contentType);

    if (!validContentType) {
      pendingUploadState.setUploadError(pendingUpload.id, `Unsupported image type: must be jpeg, gif, png, or webp.`);

      deferred.resolve(null);
      return deferred.promise;
    }

    // Removing the 'data:image/png;base64,' part
    const base64Data = pendingUpload.previewUrl.split(",")[1]!;
    const blob = base64ToBlob(base64Data, contentType);
    deferred.resolve(blob);

    return deferred.promise;
  }

  const xhr = new XMLHttpRequest();
  xhr.open("GET", pendingUpload.previewUrl);
  xhr.responseType = "blob";
  // In order to use withCredentials true, the other server must explicitely include `comms.day`
  // in the 'Access-Control-Allow-Origin' header. Since this will never the be the case for 3rd
  // party websites, there isn't any point in setting this to true. Indeed, it will cause the
  // request to error.
  xhr.withCredentials = false;

  // Note
  // We currently don't show download progress. This is in part to simplify this code
  // and also because the user will already see the image preview loading in the editor
  // and I expect that the preview loading time to be almost identical to this download
  // time (so the user already has an indication of that progress is being made).

  xhr.onerror = () => {
    console.warn("An error occurred during the file download.", xhr.status, xhr.statusText);

    pendingUploadState.setUploadError(pendingUpload.id, "An error occurred while downloading this file.");

    deferred.resolve(null);
  };

  xhr.onabort = () => {
    console.log("File download aborted.");

    pendingUploadState.setUploadError(pendingUpload.id, "File download cancelled.");

    deferred.resolve(null);
  };

  xhr.ontimeout = () => {
    console.warn("File download timed out", xhr.status, xhr.statusText);

    pendingUploadState.setUploadError(pendingUpload.id, "File download timed out");

    deferred.resolve(null);
  };

  xhr.onload = () => {
    if (xhr.status < 300) {
      const blob = xhr.response;
      deferred.resolve(blob);
    } else {
      console.warn("Image download failed:", xhr.status, xhr.statusText);
      pendingUploadState.setUploadError(pendingUpload.id, xhr.statusText);
      deferred.resolve(null);
    }
  };

  xhr.send();

  return deferred.promise;
};

/**
 * This function accepts a TPendingUpload object and handles uploading it to our object
 * storage. It does this by first getting an upload URL from our API server and then
 * uploading the file to that URL while periodically syncing the upload progress to
 * the pendingUploadState. This function returns immediately after starting the upload
 * and resolves with a URL that can be used to read the file after it is uploaded.
 *
 * If the client is unable to acquire an upload URL for some reason, than this function
 * will return null.
 */
async function uploadFile(
  environment: Pick<ClientEnvironment, "api" | "logger" | "isPendingUpdate">,
  props: {
    pendingUpload: SetNonNullable<TPendingUpload, "file">;
    saveDraftFn: () => Promise<void>;
    contentDisposition?: AttachmentContentDisposition;
  },
): Promise<string | null> {
  const onPendingCompleteDisposable = environment.isPendingUpdate.add();

  const { pendingUpload, saveDraftFn } = props;

  const fileType = pendingUpload.file.type;
  const pendingUploadState = usePendingUploadState.getState();

  const unsubscribeFromPendingUploadCancelledSub = usePendingUploadState.subscribe(
    (state) => state.pendingUploads[pendingUpload.id],
    (pendingUpload) => {
      if (!pendingUpload?.cancelled) return;
      xhr.abort();
    },
  );

  const onComplete = () => {
    onPendingCompleteDisposable[Symbol.dispose]();
    unsubscribeFromPendingUploadCancelledSub();
    URL.revokeObjectURL(pendingUpload.previewUrl);
  };

  const result = await environment.api
    .uploadFile({
      fileId: pendingUpload.id,
      fileType,
      contentDisposition: props.contentDisposition ?? "inline",
    })
    .catch((error) => {
      onComplete();
      throw error;
    });

  if (result.status !== 200) {
    environment.logger.error({ response: result }, "uploadFile: unexpected response");

    pendingUploadState.setUploadError(pendingUpload.id, "An error occurred during the file upload.");

    onComplete();

    return null;
  }

  const { uploadUrl, readUrl } = result.body;

  // At time of creation the fetch API doesn't support upload progress
  // indicators.
  const xhr = new XMLHttpRequest();

  xhr.open("PUT", uploadUrl);

  xhr.setRequestHeader("Content-Type", fileType);
  xhr.setRequestHeader("Content-Disposition", props.contentDisposition ?? "inline");

  xhr.upload.onprogress = (event) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    pendingUploadState.setUploadProgress(pendingUpload.id, progress);

    environment.logger.debug(`uploadFile:Received ${event.loaded} of ${event.total} for ${pendingUpload.id}`);
  };

  xhr.onerror = () => {
    environment.logger.warn(
      { status: xhr.status, statusText: xhr.statusText },
      "An error occurred during the file upload.",
    );

    pendingUploadState.setUploadError(pendingUpload.id, "An error occurred during the file upload.");

    onComplete();
  };

  xhr.onabort = () => {
    environment.logger.info("File upload aborted.");
    pendingUploadState.removePendingUpload(pendingUpload.id);
    onComplete();
  };

  xhr.ontimeout = () => {
    environment.logger.warn({ status: xhr.status, statusText: xhr.statusText }, "File upload timed out");

    pendingUploadState.setUploadError(pendingUpload.id, "File upload timed out");

    onComplete();
  };

  xhr.onload = () => {
    saveDraftFn().then(() => {
      onComplete();
      pendingUploadState.removePendingUpload(pendingUpload.id);
    });
  };

  xhr.send(pendingUpload.file);

  return readUrl;
}

/** Mark control as pending until the upload has completed. */
export function useMarkControlPendingDuringAttachmentUpload(props: {
  control: IMessageEditorControl;
  pendingUpload: TPendingUpload | null | undefined;
}) {
  const { control, pendingUpload } = props;

  const uploadId = pendingUpload?.id;
  const hasError = !!pendingUpload?.error;
  const isPending = !!pendingUpload;

  useEffect(() => {
    if (!uploadId) return;
    if (hasError) return;

    control.controls.body.markPending(isPending, {
      source: uploadId,
    });

    return () => {
      control.controls.body.markPending(false, {
        source: uploadId,
      });
    };
  }, [isPending, hasError, control, uploadId]);
}

/** Mark control as errored if the upload has an error. */
export function useSyncAttachmentUploadErrorToControl(props: {
  control: IMessageEditorControl;
  uploadId: string | null | undefined;
  pendingUpload: TPendingUpload | null | undefined;
}) {
  const { control, pendingUpload, uploadId } = props;

  const errorMsg = pendingUpload?.error;

  // Sync upload error message to the attachment document
  useEffect(() => {
    if (!uploadId) return;
    if (!errorMsg) return;

    const updatedAttachments = produce(control.controls.attachments.rawValue, (attachments) => {
      const attachment = attachments.find((a) => a.id === uploadId);
      if (!attachment) return;
      attachment.errorMsg = errorMsg;
    });

    control.controls.attachments.setValue(updatedAttachments);
  }, [control, uploadId, errorMsg]);

  // Sync attachment errorMsg to control as a form error
  useEffect(() => {
    if (!uploadId) return;

    const options = { source: uploadId };

    const sub = observable(() => control.controls.attachments.rawValue).subscribe((attachments) => {
      const attachment = attachments.find((a) => a.id === uploadId);

      if (!attachment) {
        control.controls.attachments.setErrors(null, options);
      } else if (attachment.errorMsg) {
        control.controls.attachments.setErrors({ uploadError: attachment.errorMsg }, options);
      }
    });

    return () => sub.unsubscribe();
  }, [control, uploadId]);
}

/**
 * If uploading an image, we can't simply count on updating the image's src
 * via the draft editor because the user might navigate away from the page
 * while the image is still being uploaded. We also can't just update the
 * image source in the draft record body_html because, while the draft is
 * being edited, we ignore changes to the draft's body_html for performance
 * reasons. So, we need to update the draft's body_html and the image's src
 * in the draft editor.
 */
function updateDraftImage(props: {
  editor: Editor;
  imageId: string;
  imageAttributes: Partial<IImageExtentionAttrs>;
  // environment: Pick<ClientEnvironment, "recordLoader">;
}) {
  const {
    editor,
    imageId,
    imageAttributes,
    // environment: { recordLoader },
  } = props;

  if (editor.isDestroyed) {
    console.warn("updateDraftImage: cannot update image metadata since editor is destroyed.");
  } else {
    // @ts-expect-error we manually add this command to the image extension but it's not
    //                  in the type definition.
    editor.commands.updateImage(imageId, imageAttributes);
  }
}

function editorInsertElementAt(props: { editor: Editor; element: HTMLElement; position: number }) {
  const { editor, element, position } = props;

  editor.chain().deleteSelection().insertContentAt(position, element.innerHTML).run();
}

// Written by ChatGPT
function isBase64Image(imgSrc: string) {
  // Regular expression for checking if the src is a base64 encoded image
  const regex = /^data:image\/[a-zA-Z]+;base64,[\w+/=]+$/;
  return regex.test(imgSrc);
}

// Written by ChatGPT
function getContentTypeFromBase64String(src: string) {
  const result = src.match(/^data:([^;]+);base64,/);

  if (result && result.length > 1) {
    return result[1]; // Returns the content type
  } else {
    return null; // No content type found
  }
}

// Written by ChatGPT
function base64ToBlob(base64: string, contentType: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64); // Decode base64
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function getImageDimensions(file: Blob) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
      URL.revokeObjectURL(img.src);
    };

    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject(new Error("There was an error loading the image"));
    };

    // Create a URL for the blob and set it as the image source
    img.src = URL.createObjectURL(file);
  });
}

// async function getImageDimensions(file: Blob) {
//   const imgContainer = document.createElement("div");
//   imgContainer.style.position = "absolute";
//   imgContainer.style.zIndex = "-1000";
//   imgContainer.style.opacity = "0";
//   imgContainer.style.width = "9999px";
//   imgContainer.style.height = "9999px";
//   imgContainer.style.pointerEvents = "none";

//   document.body.appendChild(imgContainer);

//   const img = new Image();

//   img.src = URL.createObjectURL(file);

//   img.style.position = "absolute";

//   const deferred = new DeferredPromise<Event>();

//   img.onload = deferred.resolve;
//   img.onerror = deferred.reject;

//   imgContainer.appendChild(img);

//   try {
//     await deferred.promise;

//     const result = {
//       width: img.width,
//       height: img.height,
//     };

//     return result;
//   } finally {
//     document.body.removeChild(imgContainer);
//     URL.revokeObjectURL(img.src);
//   }
// }
