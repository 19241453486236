import { PropsWithChildren, Ref, useMemo } from "react";
import { cx } from "@emotion/css";
import { IOption, TAutocompleteSelectRef } from "./AutocompleteSelect";
import { MultiValue, SingleValue } from "react-select";
import { LabelTagRecord } from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import Select from "react-select";
import { usePersonalLabels } from "~/hooks/usePersonalLabels";

export interface ILabelOption extends IOption<string> {}

export async function getLabelSelectOption(
  environment: Pick<ClientEnvironment, "recordLoader">,
  labelId: string,
): Promise<ILabelOption | null> {
  const { recordLoader } = environment;

  const [label] = await recordLoader.getRecord("tag", labelId);

  if (!label) return null;

  return {
    label: label.name,
    value: label.id,
  };
}

/**
 * Component for selecting a label with autocomplete.
 */
export function LabelSelect<M extends boolean>(
  props: PropsWithChildren<{
    autocompleteRef?: Ref<TAutocompleteSelectRef<ILabelOption, M>>;
    value?: M extends true ? MultiValue<ILabelOption> : SingleValue<ILabelOption>;
    touched?: boolean;
    error?: string;
    onChange?: M extends true ? (newValue: MultiValue<ILabelOption>) => void
    : (newValue: SingleValue<ILabelOption>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    labelFilterFn?: (label: LabelTagRecord) => boolean;
    autoFocus?: boolean;
    autocompleteMenuEl?: HTMLDivElement | null;
    multiple?: M;
    label?: string;
    placeholder?: string;
    errorPlaceholder?: string;
    isOptionDisabledFn?: (props: { label: LabelTagRecord }) => {
      isDisabled: boolean;
      disabledReason: string | undefined;
    };
  }>,
) {
  const { labelFilterFn } = props;

  const [labels] = usePersonalLabels();

  const options: ILabelOption[] = useMemo(() => {
    const isOptionDisabledFn = props.isOptionDisabledFn || (() => ({ isDisabled: false, disabledReason: undefined }));

    const filteredLabels =
      labelFilterFn ?
        labels.filter((label): label is LabelTagRecord => labelFilterFn(label as LabelTagRecord))
      : labels;

    return filteredLabels.map((label) => {
      const { isDisabled, disabledReason } = isOptionDisabledFn({ label });

      return {
        label: label.name,
        value: label.id,
        isDisabled,
        disabledReason,
      };
    });
  }, [labels, props.isOptionDisabledFn, labelFilterFn]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none", // Remove border
      boxShadow: "none", // Remove box shadow
      zIndex: 1,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 150,
      pointerEvents: "auto",
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 150,
      pointerEvents: "auto",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "0.95rem",
      backgroundColor: "transparent",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid grey",
      borderRadius: ".25rem",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "lightgray",
    }),
  };

  return (
    <div className={cx("flex flex-1 items-center py-2")}>
      {props.label && (
        <label className={cx("my-1 mr-2", props.touched && props.error ? "text-red-9" : "text-slateDark-11")}>
          {props.label}
        </label>
      )}

      <Select
        name="labels"
        value={props.value}
        onBlur={props.onBlur}
        isClearable={!props.multiple}
        onChange={props.onChange as (newValue: MultiValue<IOption> | SingleValue<IOption>) => void}
        options={options}
        placeholder={props.touched && props.error ? props.errorPlaceholder : props.placeholder}
        autoFocus={props.autoFocus}
        isMulti={props.multiple}
        className="w-full"
        styles={customStyles}
        menuPosition="absolute"
        menuPortalTarget={document.body}
      />
    </div>
  );
}
