import { memo, useState } from "react";
import { List, useListContext } from "../list";
import {
  EntryActions,
  entryCSSClasses,
  EntryTimestamp,
  MarkDoneEntryAction,
  OtherCommandEntryAction,
  PrivateEntryIcon,
  Recipients,
  SetReminderEntryAction,
  StarredEntryIcon,
  Summary,
  useShouldShowChannelLabels,
} from "./layout";
import { Avatar } from "../Avatar";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { LabelContainer, PermittedGroupChips, ThreadLabelsCountChip, ThreadResolvedLabel } from "../LabelChip";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { cx } from "@emotion/css";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { useNotification } from "~/hooks/useNotification";
import { NotificationTimestamp } from "./NotificationEntry";
import { ParentComponent } from "~/utils/type-helpers";
import { isEqual } from "libs/predicates";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { onMessageSelectNavigateToThread } from "./MessageEntry";
import { getThreadRoutePrefix } from "~/environment/router";
import { ClientRecordLoaderObserveGetRecordResult, ObserveOptions } from "~/environment/RecordLoader";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ClientRecordLoaderObserveQueryResult } from "~/environment/RecordLoader";

/**
 * Because we're using a virtual list, entries are positioned based on their index.
 * If one of the list entry components renders `null`, then it will create a gap in
 * the list since the next entry will have a higher index. For this reason, we need
 * to filter out any threads that will not be rendered from the list.
 */
export function observeFilteredThreadEntriesForVirtualList(
  environment: Pick<ClientEnvironment, "recordLoader">,
  observable: Observable<ClientRecordLoaderObserveQueryResult<"thread">>,
  options?: ObserveOptions,
) {
  const loader = environment.recordLoader;

  return observable.pipe(
    switchMap(([threads, meta]) => {
      const observable: Observable<
        Array<[RecordValue<"thread">, ClientRecordLoaderObserveGetRecordResult<"message">]>
      > =
        threads.length === 0 ?
          of([])
        : combineLatest(
            threads.map((t) => combineLatest([of(t), loader.observeGetRecord("message", t.last_message_id, options)])),
          );

      return observable.pipe(
        map((results) => {
          const threads = results.filter(([_, [message]]) => !!message).map(([thread]) => thread);

          return [threads, meta] as const;
        }),
      );
    }),
  );
}

export const ThreadEntry: ParentComponent<{
  threadId: string;
  relativeOrder: number;
  style?: React.CSSProperties;
}> = memo((props) => {
  const environment = useClientEnvironment();
  const [thread] = useThread(props.threadId, { includeSoftDeletes: true });
  const [message] = useMessage(thread?.last_message_id);
  const [notification] = useNotification({ threadId: props.threadId });
  const sender = useMessageSender(thread?.last_message_id);
  const showLabels = useShouldShowChannelLabels();
  const [isChecked, setIsChecked] = useState(false);
  const listContext = useListContext();
  const entryData = useAsPointerWithRecord("thread", thread);

  if (!thread || !message || !entryData) return null;

  const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");
  const isPrivateThread = thread.visibility === "PRIVATE";

  return (
    <List.Entry<PointerWithRecord<"thread">>
      id={props.threadId}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntrySelectionChange={(event) => {
        setIsChecked(event.isSelected);
      }}
    >
      <div
        role="listitem"
        className={cx(entryCSSClasses, isChecked && "is-checked", "ThreadEntry")}
        style={props.style}
      >
        <div className="pr-2 sm-w:pr-3">
          {isChecked ?
            <MdOutlineCheckBox
              size={30}
              className="p-1"
              onClick={(e) => {
                e.stopPropagation();
                listContext.deselect(thread.id);
              }}
            />
          : <>
              <Avatar label={senderLabel} photoURL={sender?.photoUrl} width="30px" className="group-hover:hidden" />

              <MdOutlineCheckBoxOutlineBlank
                size={30}
                className="hidden group-hover:block p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  listContext.select(thread.id);
                }}
              />
            </>
          }
        </div>

        <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
          <Recipients
            nonTruncatedSuffix={
              <>
                {isPrivateThread && <PrivateEntryIcon />}
                {notification?.is_starred && <StarredEntryIcon />}
              </>
            }
          >
            <span className="truncate">{senderLabel}</span>
          </Recipients>

          <Summary
            subject={thread.subject}
            formatAsReply={message.is_reply && message.type === "COMMS"}
            details={isPrivateThread ? "private message" : message.body_text}
          />
        </div>

        <LabelContainer>
          {showLabels && <PermittedGroupChips threadId={props.threadId} />}
          {showLabels && <ThreadLabelsCountChip threadId={props.threadId} />}

          <ThreadResolvedLabel
            threadId={thread.id}
            onClick={({ event, threadTag }) => {
              event.preventDefault();

              const location = environment.router.location();

              onMessageSelectNavigateToThread(environment, {
                event,
                threadRoutePrefix: getThreadRoutePrefix(location),
                threadId: threadTag.thread_id,
                messageId: threadTag.data.message_id,
              });
            }}
          />
        </LabelContainer>

        <EntryActions
          defaultComponent={
            notification?.has_reminder ?
              <div className="ml-2">
                <NotificationTimestamp notification={notification} sentAt={message.sent_at} showReminder />
              </div>
            : <EntryTimestamp datetime={message.sent_at} />
          }
        >
          <MarkDoneEntryAction />
          <SetReminderEntryAction />
          <OtherCommandEntryAction />
        </EntryActions>
      </div>
    </List.Entry>
  );
}, isEqual);
