import * as ThreadLayout from "~/page-layouts/thread-layout";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";
import { Tooltip } from "~/components/Tooltip";
import { ParentComponent } from "~/utils/type-helpers";
import { Avatar } from "~/components/Avatar";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { useUsersWhoWillReceiveNotificationForNewThreadDraft } from "~/hooks/useUsersWhoWillReceiveNotificationForNewThreadDraft";
import { useControlState } from "~/components/forms/utils";
import { useUsersWhoWillBePermittedToViewNewThread } from "~/hooks/useUsersWhoWillBePermittedToViewNewThread";
import { useOrganizationMembersOfDraft } from "~/hooks/useOrganizationMembersOfDraft";
import { hideComposeInfoPanelCommand, openApplyLabelDialogCommand, removeLabelCommand } from "~/utils/common-commands";
import { FaRegPlusSquare } from "react-icons/fa";
import { ThreadLabelChip } from "~/components/LabelChip";
import { useRecords } from "~/hooks/useRecords";
import { getPointer } from "libs/schema";

/* -------------------------------------------------------------------------------------------------
 *  ThreadInfoPanel
 * -------------------------------------------------------------------------------------------------
 */

export const ComposeInfoPanel: ParentComponent<{
  control: IComposeMessageForm;
}> = (props) => {
  return (
    <ThreadLayout.InfoPanel onBackdropClick={() => hideComposeInfoPanelCommand.trigger()}>
      <div className="p-4">
        <h2 className="text-lg font-medium text-slate-9">Draft details</h2>

        <RecipientsSection control={props.control} />

        <PersonalLabelsSection control={props.control} />

        <ViewPermissionsSection control={props.control} />

        <ClosePanelButton />
      </div>
    </ThreadLayout.InfoPanel>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const RecipientsSection: ParentComponent<{
  control: IComposeMessageForm;
}> = ({ control }) => {
  const draftId = useControlState(() => control.rawValue.messageId, [control]);
  const [audienceProfiles, { isLoading: areAudienceProfilesLoading }] =
    useUsersWhoWillReceiveNotificationForNewThreadDraft({
      draftId,
    });

  return (
    <div>
      <div className="mt-4 flex items-center">
        <Tooltip side="bottom" content="Notifications will be delivered to the inbox of these users.">
          <p className="text-slate-9 cursor-help">Recipients (?)</p>
        </Tooltip>
      </div>

      <div className="mt-2 flex flex-wrap">
        {audienceProfiles.length === 0 && areAudienceProfilesLoading ?
          "Loading..."
        : audienceProfiles.length === 0 ?
          <div className="mt-2 prose">
            <p>
              No recipients.
              <br />
              <br />
              <strong>Hint:</strong> <code>@mention</code> someone to loop them into this conversation.{" "}
              <code>@@request-response</code> someone to loop them into the conversation and also request a response
              from them. Including someone in the message's recipient field is the same as <code>@mentioning</code> them
              in the message's body.
            </p>
          </div>
        : audienceProfiles.map((profile) => (
            <Tooltip key={profile.id} side="bottom" content={profile.name}>
              <div className="p-1">
                <Avatar label={profile.name} photoURL={profile.photo_url} />
              </div>
            </Tooltip>
          ))
        }
      </div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const PersonalLabelsSection: ParentComponent<{
  control: IComposeMessageForm;
}> = ({ control }) => {
  const labelDocs = useControlState(() => control.rawValue.labels, [control]);

  // Filter out deleted labels
  const [labels] = useRecords(labelDocs.map((l) => getPointer("tag", l.label_id)));

  return (
    <>
      <div className="mt-4 flex items-center">
        <Tooltip side="bottom" content="Only you will be able to see these labels">
          <p className="text-slate-9 cursor-help">Labels (?)</p>
        </Tooltip>

        <div className="flex-1" />

        <Tooltip side="bottom" content="Apply label...">
          <button
            type="button"
            className="text-slate-9 hover:text-black"
            onClick={() => {
              openApplyLabelDialogCommand.trigger({ location: "ComposeInfoPanel" });
            }}
          >
            <FaRegPlusSquare />
          </button>
        </Tooltip>
      </div>

      <div className="mt-2 flex flex-wrap gap-2">
        {labels.length === 0 && "No labels"}

        {labels.map((label) => (
          <ThreadLabelChip
            key={label.id}
            labelId={label.id}
            color="primary"
            showArchived
            onRemoveButtonClick={() => {
              removeLabelCommand.trigger(label.id, {
                location: "ComposeInfoPanel",
              });
            }}
          />
        ))}
      </div>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ViewPermissionsSection: ParentComponent<{
  control: IComposeMessageForm;
}> = ({ control }) => {
  const draftId = useControlState(() => control.rawValue.messageId, [control]);
  const visibility = useControlState(() => control.rawValue.visibility, [control]);
  const to = useControlState(() => control.rawValue.recipients.to, [control]);

  const [permissions, { isLoading: arePermissionsLoading }] = useUsersWhoWillBePermittedToViewNewThread({
    draftId: visibility === "PRIVATE" ? draftId : null,
  });

  const [organizationMembers, { isLoading: areOrgMembersLoading }] = useOrganizationMembersOfDraft({
    draftId: visibility === "SHARED" ? draftId : null,
  });

  if (to.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mt-4 flex items-center">
        <Tooltip side="bottom" content={`These users will have permission to view this thread.`}>
          <p className="text-slate-9 cursor-help">Will be able to view (?)</p>
        </Tooltip>
      </div>

      <div className="mt-2 flex flex-wrap">
        {(() => {
          if (visibility === "SHARED") {
            return (
              organizationMembers.length === 0 && areOrgMembersLoading ? "Loading..."
              : organizationMembers.length === 0 ? <span className="text-sm font-medium">Error.</span>
              : organizationMembers.map((m) => `Everyone in the ${m.name}.`).join(" ")
            );
          } else {
            return (
              permissions.length === 0 && arePermissionsLoading ? "Loading..."
              : permissions.length === 0 ? <span className="text-sm font-medium">No recipients.</span>
              : permissions.map((profile) => (
                  <Tooltip key={profile.id} side="bottom" content={profile.name}>
                    <div className="p-1">
                      <Avatar label={profile.name} photoURL={profile.photo_url} />
                    </div>
                  </Tooltip>
                ))
            );
          }
        })()}
      </div>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ClosePanelButton: ParentComponent = () => {
  return (
    <Tooltip side="top" content="Close details panel">
      <button
        type="button"
        className="absolute bottom-4 right-4 text-slate-9 hover:text-black"
        onClick={(e) => {
          e.preventDefault();
          hideComposeInfoPanelCommand.trigger();
        }}
      >
        <IoMdClose className="hidden text-4xl md-w:block" />
        <IoMdCloseCircle className="text-5xl md-w:hidden" />
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/
