import { memo, RefObject, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { cx } from "@emotion/css";
import { ICommandArgs, useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { KBarState } from "~/dialogs/kbar";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { Tooltip } from "~/components/Tooltip";
import { IoMdEye } from "react-icons/io";
import { MdEdit, MdLabelOutline } from "react-icons/md";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import {
  tagSubscriptionCommands,
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
  composeMessageCommand,
  archiveTagCommand,
  unArchiveTagCommand,
  editGroupCommand,
  editTagCommand,
  unArchiveGroupCommand,
  archiveGroupCommand,
} from "~/utils/common-commands";
import { BsLockFill } from "react-icons/bs";
import { UnreachableCaseError } from "libs/errors";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineButton, OutlineDropdownButton } from "~/components/OutlineButtons";
import { useTag } from "~/hooks/useTag";
import { useTagViewThreads } from "~/hooks/useTagViewThreads";
import { useCurrentUserTagSubscription } from "~/hooks/useCurrentUserTagSubscription";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import {
  DEFAULT_SUBSCRIPTION_PREFERENCE,
  generateRecordId,
  PointerWithRecord,
  RecordValue,
  SpecialTagTypeEnum,
  TagSubscriptionPreference,
} from "libs/schema";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { EditGroupDialogState } from "~/dialogs/group-edit/EditGroupDialog";
import { triageThread } from "~/actions/notification";
import { useIsTagPrivate } from "~/hooks/useIsTagPrivate";
import { ThreadEntry } from "~/components/content-list/ThreadEntry";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isGroupTagRecord, isLabelTagRecord, isSingletonTagRecord, isTagPrivate } from "libs/schema/predicates";
import { createNewThreadDraft } from "~/actions/draft";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { useIsCurrentRouteForAGroup } from "~/hooks/useIsCurrentRouteForAGroup";
import { archiveTag, unarchiveTag } from "~/actions/tag";
import { renderGroupName } from "~/utils/tag-utils";
import {
  MarkAllDoneEntryAction,
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { Outlet, useMatches, useParams } from "@tanstack/react-router";
import { onTagEntrySelect, TTagEntry, useTagViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { EndOfListMsg, LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";
import { TTagViewType, useTagViewType } from "~/hooks/useTagViewType";
import { capitalize } from "lodash-es";
import { isEqual } from "libs/predicates";
import { EditLabelDialogState } from "~/dialogs/label-edit/EditLabelDialog";
import { useRegisterThreadLabelCommands } from "~/hooks/useRegisterThreadLabelCommands";

/* -------------------------------------------------------------------------------------------------
 * TagView
 * -----------------------------------------------------------------------------------------------*/

export const TagView = withNewCommandContext(() => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const params = useParams({ strict: false });
  const [tag, { isLoading: isTagLoading }] = useTag(params.tagId);
  const viewType = useTagViewType();
  const isSingletonTag = isSingletonTagRecord(tag);
  const [threadIds, { fetchMore, isLoading, nextId }] = useTagViewThreads({
    tagId: params.tagId,
    type: viewType,
  });

  const listRef = useRef<IListRef<TTagEntry>>(null);
  const canEdit = !isSingletonTag && !(isGroupTagRecord(tag) && tag.data?.is_organization_group);

  const isThreadOpen = useMatches({
    select(matches) {
      return matches.some(
        (m) => m.fullPath === "/groups/$tagId/threads/$threadId" || m.fullPath === "/labels/$tagId/threads/$threadId",
      );
    },
  });

  const threadViewContext = useTagViewThreadContext({
    listRef,
    type: viewType,
    tagId: params.tagId,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<PointerWithRecord<"thread">>();

  const { isTagPrivate } = useIsTagPrivate(params.tagId);

  useApplyScrollShadowToHeader({
    tag,
    scrollboxRef,
    headerRef,
  });

  const hasNextPage = !!nextId;

  const virtualThreadIds = useVirtualList({
    scrollboxRef,
    count: threadIds.length,
    getEntryKey: (index) => threadIds[index] || "",
    fetchMore,
    hasNextPage,
    isFetchingNextPage: isLoading,
  });

  const threadIdCount = virtualThreadIds.entries.length;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [tag, threadViewContext],
  });

  // Not yet implemented. The challenge is that the easy implementation is just a
  // `select count()` on the tag subscribers but in order for this result to be
  // accurate we will have needed to load ALL the tag subscriber records into the
  // local sqlite database. So instead we need to maintain a subscriber count seperately.
  // The easy way here would be to store the information in the tag `data` json prop,
  // but this would cause the tag to be updated every time a new subscriber is added
  // which would cause unnecessary query re-rendering an (I expect) jank in the UI.
  // So we really need to create a separate record to store this information--"tag_metadata"
  // table was added for this purpose, but it hasn't been used yet.
  const subscribersCount: number = 0;

  const doesTagMatchViewType =
    viewType === "group" ? isGroupTagRecord(tag)
    : viewType === "label" ? isLabelTagRecord(tag)
    : false;

  if (tag === null || !viewType || !doesTagMatchViewType) {
    if (isTagLoading) {
      return <div>Loading...</div>;
    }

    return <NotFound title={`${viewType ? capitalize(viewType) : "Page"} Not Found`} />;
  }

  if (!threadViewContext) return null;

  const theme = isTagPrivate ? "dark" : "light";
  const showNoThreadsMessage = threadIdCount === 0 && !isLoading;

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <RegisterTagViewCommands
          listRef={listRef}
          isListRefSet={!showNoThreadsMessage}
          tag={tag}
          isSingletonTag={isSingletonTag}
          canEdit={canEdit}
          useFocusedThread={useFocusedEntry}
        />

        <Helmet>
          <title>
            {tag.name} | {capitalize(viewType)} | Comms
          </title>
        </Helmet>

        <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
          <MainLayout.Header theme={theme} className={cx("flex-col")}>
            <div
              className={cx("flex items-center", {
                ["mb-1"]: !!tag.description,
              })}
            >
              <h1 className="text-3xl text-slate-8 truncate">
                <span className={theme === "dark" ? "text-white mr-2" : "text-black mr-2"}>
                  <TagName tag={tag} />
                </span>

                {canEdit && <EditTagButton tag={tag} viewType={viewType} />}

                {isTagPrivate && (
                  <Tooltip
                    side="bottom"
                    content={
                      viewType === "label" ?
                        `Labels are private and only visible to you`
                      : `This ${viewType} is private and only visible to invited members`
                    }
                  >
                    <span className="text-2xl inline-flex ml-2 hover:cursor-help mt-1">
                      <small>
                        <BsLockFill />
                      </small>
                    </span>
                  </Tooltip>
                )}
              </h1>

              <div className="flex-1" />
            </div>

            {tag.description && <p className="text-xl">{tag.description}</p>}

            {!tag.data?.is_organization_group && !isSingletonTag && (
              <MainLayout.HeaderMenu>
                {viewType === "group" && (
                  <>
                    <li>
                      {typeof isTagPrivate === "boolean" && (
                        <SubscriptionLevel tagId={tag.id} isTagPrivate={isTagPrivate} />
                      )}
                    </li>

                    <li>
                      <OutlineDropdownButton
                        theme={theme}
                        onClick={(e) => {
                          e.preventDefault();
                          environment.router.navigate(`/groups/${tag.id}/subscribers`);
                        }}
                      >
                        <small>Subscriber{subscribersCount === 1 ? "" : "s"}</small>
                      </OutlineDropdownButton>
                    </li>
                  </>
                )}

                <li>
                  {tag.archived_at ?
                    <OutlineButton theme={theme} onClick={() => unArchiveTagCommand.trigger()}>
                      <span className="text-[12.8px]">Unarchive</span>
                    </OutlineButton>
                  : <OutlineButton theme={theme} onClick={() => archiveTagCommand.trigger()}>
                      <span className="text-[12.8px]">Archive</span>
                    </OutlineButton>
                  }
                </li>
              </MainLayout.HeaderMenu>
            )}
          </MainLayout.Header>

          <MainLayout.ActionsBar
            listRef={listRef}
            isListRefSet={threadIdCount > 0}
            multiSelectActions={
              <>
                <MarkAllDoneEntryAction />
                <MarkAllNotDoneEntryAction />
                <SetReminderForAllEntryAction />
                <OtherCommandEntryAction />
              </>
            }
            className={cx(isThreadOpen && "invisible")}
          />
        </div>

        {showNoThreadsMessage ?
          <EmptyListMessage text="No threads yet." className={cx(isThreadOpen && "invisible")} />
        : <ContentList<PointerWithRecord<"thread">>
            listRef={listRef}
            mode={isThreadOpen ? "active-descendent" : "focus"}
            onEntryFocused={setFocusedEntry}
            onEntryAction={(event) =>
              onTagEntrySelect(environment, {
                event,
                type: viewType,
                tagId: tag.id,
              })
            }
            className={cx(isThreadOpen && "invisible")}
            autoFocus
            allEntryIdsForVirtualizedList={threadIds}
            style={virtualThreadIds.containerStyles()}
          >
            {virtualThreadIds.entries.map((item) => {
              if (!item.key) return null;

              return (
                <ThreadEntry
                  key={item.key as string}
                  threadId={item.key as string}
                  relativeOrder={item.index}
                  style={virtualThreadIds.entryStyles(item)}
                />
              );
            })}
          </ContentList>
        }

        {threadIdCount > 0 && !hasNextPage && !isLoading && (
          <EndOfListMsg className={cx(isThreadOpen && "invisible")} />
        )}

        {(hasNextPage || isLoading) && <LoadingMoreListEntriesMsg isThreadOpen={isThreadOpen} />}

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
});

/* -----------------------------------------------------------------------------------------------*/

const TagName: ParentComponent<{
  tag: RecordValue<"tag">;
}> = (props) => {
  switch (props.tag.type) {
    case SpecialTagTypeEnum.GROUP: {
      return <span>{renderGroupName(props.tag)}</span>;
    }
    case SpecialTagTypeEnum.LABEL: {
      return (
        <div className="inline-flex items-center">
          <MdLabelOutline />
          <span className="ml-2">{props.tag.name}</span>
        </div>
      );
    }
    default: {
      return <span>{props.tag.name}</span>;
    }
  }
};

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader(args: {
  tag: unknown;
  scrollboxRef: RefObject<HTMLElement>;
  headerRef: RefObject<HTMLElement>;
}) {
  const { tag, scrollboxRef, headerRef } = args;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!tag],
  });
}

/* -----------------------------------------------------------------------------------------------*/

const EditTagButton: ParentComponent<{
  tag: RecordValue<"tag">;
  viewType: TTagViewType;
}> = (props) => {
  return (
    <Tooltip side="bottom" content={`Edit ${props.viewType}`}>
      <button
        className="hover:text-black text-2xl"
        onClick={() => {
          switch (props.viewType) {
            case "group": {
              EditGroupDialogState.open({
                prefill: {
                  id: props.tag.id,
                  icon: props.tag.icon,
                  name: props.tag.name,
                  description: props.tag.description,
                },
              });

              break;
            }
            case "label": {
              EditLabelDialogState.open({
                prefill: {
                  id: props.tag.id,
                  name: props.tag.name,
                  description: props.tag.description,
                },
              });

              break;
            }
            default: {
              throw new UnreachableCaseError(props.viewType);
            }
          }
        }}
      >
        <MdEdit />
      </button>
    </Tooltip>
  );
};

/* -------------------------------------------------------------------------------------------------
 * SubscriptionLevel
 * -----------------------------------------------------------------------------------------------*/

const SubscriptionLevel: ParentComponent<{
  tagId: string;
  isTagPrivate: boolean;
}> = memo((props) => {
  const [subscription, { isLoading: isSubscriptionLoading }] = useCurrentUserTagSubscription({ tagId: props.tagId });

  const isGroupView = useIsCurrentRouteForAGroup();

  const { label, hintText } = getSubscriptionText({
    preference: subscription?.preference,
    isLoading: isSubscriptionLoading,
    isGroupView,
  });

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          <p className="text-center">{hintText}</p>
          <p className="mt-1">
            <em>
              (press <kbd>S</kbd> to update subscription )
            </em>
          </p>
        </>
      }
    >
      <span>
        <OutlineDropdownButton
          theme={props.isTagPrivate ? "dark" : "light"}
          onClick={(e) => {
            if (isSubscriptionLoading) return;
            e.preventDefault();
            KBarState.open({
              path: ["Update subscription"],
              mode: "hotkey",
            });
          }}
        >
          <IoMdEye className="mr-1 text-slate-11" /> <small>{label}</small>
        </OutlineDropdownButton>
      </span>
    </Tooltip>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

function getSubscriptionText(args: {
  preference?: TagSubscriptionPreference | null;
  isLoading: boolean;
  isGroupView: boolean;
}) {
  const { isLoading, preference, isGroupView } = args;

  if (isLoading) {
    return {
      label: "",
      hintText: `loading`,
    };
  }

  const tagType = isGroupView ? "group" : "tag";

  const normalizedPreference = !preference ? DEFAULT_SUBSCRIPTION_PREFERENCE : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        label: "Subscribed All",
        hintText: `You will receive all notifications for this ${tagType}.`,
      };
    }
    case "all-new": {
      return {
        label: "Subscribed",
        hintText: `
          You will receive a notification for every new
          thread added to this ${tagType}.
        `,
      };
    }
    case "involved": {
      return {
        label: "Unsubscribed",
        hintText: `
          You will only receive notifications for
          threads you are participating or @mentioned in.
        `,
      };
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 * RegisterTagViewCommands
 * -----------------------------------------------------------------------------------------------*/

const RegisterTagViewCommands: ParentComponent<{
  listRef: RefObject<IListRef<TTagEntry>>;
  isListRefSet: boolean;
  tag: RecordValue<"tag"> | null | undefined;
  isSingletonTag: boolean;
  canEdit: boolean;
  useFocusedThread: () => PointerWithRecord<"thread"> | null;
}> = (props) => {
  const { listRef, isListRefSet, tag, isSingletonTag, canEdit, useFocusedThread } = props;
  const environment = useClientEnvironment();
  const { currentUserId, ownerOrganizationId } = useAuthGuardContext();
  const focusedThread = useFocusedThread();

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet,
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedThread) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedThread.id,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedThread.id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            callback: () => setThreadReminder(environment, focusedThread.id),
          }),
          removeThreadReminderCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedThread.id,
                triagedUntil: null,
              });
            },
          }),
          starThreadCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedThread.id,
                isStarred: true,
              });
            },
          }),
          unstarThreadCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedThread.id,
                isStarred: false,
              });
            },
          }),
        );
      }

      if (tag && !tag.data?.is_organization_group && !isSingletonTag) {
        commands.push(...tagSubscriptionCommands({ environment, tagId: tag.id }));

        if (isGroupTagRecord(tag)) {
          // The default compose command in SidebarLayout will be overridden to include
          // the channel as a recipient.
          commands.push(
            composeMessageCommand({
              callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
                const draftId = generateRecordId("draft");
                const threadId = generateRecordId("thread");

                // We're intentionally not awaiting this response since it results in
                // this command taking too long in the UI
                createNewThreadDraft(environment, {
                  type: "COMMS",
                  currentUserId,
                  draftId,
                  threadId,
                  ownerOrganizationId,
                  visibility: isTagPrivate(tag) ? "PRIVATE" : "SHARED",
                  to: [
                    {
                      type: "group",
                      id: tag.id,
                    },
                  ],
                });

                openComposeNewThreadDialog(environment, draftId);
              }),
            }),
          );
        }
      }

      if (tag && canEdit) {
        if (isGroupTagRecord(tag)) {
          commands.push(
            editGroupCommand({
              callback: () => {
                if (!environment.network.isOnline()) {
                  toast("vanilla", {
                    subject: "Not supported in offline mode",
                    description: "Can't update groups when offline.",
                  });

                  return;
                }

                EditGroupDialogState.open({
                  prefill: {
                    id: tag.id,
                    name: tag.name,
                    description: tag.description,
                  },
                });
              },
            }),
          );

          if (tag.archived_at) {
            commands.push(
              unArchiveGroupCommand({
                callback: () => {
                  unarchiveTag(environment, { tagId: tag.id });
                },
              }),
            );
          } else {
            commands.push(
              archiveGroupCommand({
                callback: () => {
                  archiveTag(environment, { tagId: tag.id, tagType: tag.type });
                },
              }),
            );
          }
        } else {
          commands.push(
            editTagCommand({
              callback: () => {
                showNotImplementedToastMsg();
              },
            }),
          );

          if (tag.archived_at) {
            commands.push(
              unArchiveTagCommand({
                callback: () => {
                  unarchiveTag(environment, { tagId: tag.id });
                },
              }),
            );
          } else {
            commands.push(
              archiveTagCommand({
                callback: () => {
                  archiveTag(environment, { tagId: tag.id, tagType: tag.type });
                },
              }),
            );
          }
        }
      }

      return commands;
    },
    deps: [tag, canEdit, isSingletonTag, focusedThread, currentUserId, ownerOrganizationId, environment],
  });

  useRegisterThreadLabelCommands({
    threadId: focusedThread?.id,
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/

const setThreadReminder = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: Pick<ClientEnvironment, "recordLoader">, threadId: string) => {
    RemindMeDialogState.open({
      threadId: threadId,
      fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/
