import { useMatches } from "@tanstack/react-router";

export type TTagViewType = "group" | "label";

export function useTagViewType(): TTagViewType | null {
  return useMatches({
    select(matches) {
      for (const match of matches) {
        if (match.fullPath.startsWith("/groups/")) {
          return "group";
        } else if (match.fullPath.startsWith("/labels/")) {
          return "label";
        }
      }

      return null;
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/
