import { UnreachableCaseError } from "libs/errors";
import { PointerWithRecord } from "libs/schema";
import { onDraftSelect } from "~/components/content-list/DraftEntry";
import { IListOnEntryActionEvent, IListRef } from "~/components/list";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { IThreadViewContext } from "../thread/context";
import { useMemo } from "react";

export type TDraftsEntry = PointerWithRecord<"draft">;

/* -----------------------------------------------------------------------------------------------*/

export async function onDraftsEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TDraftsEntry>;
  },
) {
  const { event } = props;

  switch (event.entry.table) {
    case "draft": {
      return onDraftSelect(environment, {
        event: event.event,
        draft: event.entry,
        threadRoutePrefix: "/drafts/threads/",
      });
    }
    default: {
      throw new UnreachableCaseError(event.entry.table);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function useDraftsViewThreadContext(props: { listRef: React.RefObject<IListRef<TDraftsEntry>> }) {
  const { listRef } = props;

  return useMemo((): IThreadViewContext => {
    return { threadList: { type: "drafts", ref: listRef } };
  }, [listRef]);
}

/* -----------------------------------------------------------------------------------------------*/
