import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { TextInput } from "~/components/forms/TextInput";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { handleSubmit, onSubmitFn } from "~/components/forms/utils";
import { toast } from "~/environment/toast-service";
import { useRegisterCommands } from "~/environment/command.service";
import { Tooltip } from "~/components/Tooltip";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { OutlineButton } from "~/components/OutlineButtons";
import { closeDialogCommand, submitFormCommand } from "~/utils/common-commands";
import { updateThreadSubject } from "~/actions/thread";

export type IEditThreadSubjectDialogData = { threadId: string } | undefined;

export type IEditThreadSubjectDialogReturnData = { success: boolean } | void;

export const EditThreadSubjectDialogState = new DialogState<
  IEditThreadSubjectDialogData,
  IEditThreadSubjectDialogReturnData
>();

interface IFormValue {
  id: string;
  subject: string;
}

export const EditThreadSubjectDialog = withModalDialog({
  dialogState: EditThreadSubjectDialogState,
  async loadData({ environment, data }) {
    if (!data?.threadId) {
      throw new Error("[EditThreadSubjectDialog] threadId is required");
    }

    const [thread] = await environment.recordLoader.getRecord("thread", data.threadId);

    if (!thread) {
      toast("vanilla", {
        subject: "Thread not found",
      });

      throw new Error("[EditThreadSubjectDialog] thread not found");
    }

    return {
      thread,
    };
  },
  Component: (props) => {
    const environment = useClientEnvironment();

    const control = useControl(() => {
      return createFormGroup({
        id: createFormControl(props.data.thread.id),
        subject: createFormControl(props.data.thread.subject, {
          required: true,
        }),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              EditThreadSubjectDialogState.close();
            },
          }),
          submitFormCommand({
            callback: () => {
              if (!control) return;
              environment.logger.debug("[EditThreadSubjectDialog] attempting submit");
              handleSubmit({ control, environment, submit });
            },
          }),
        ];
      },
      deps: [control, environment],
    });

    if (!control) return null;

    return (
      <div>
        <DialogTitle>
          <h2>Edit thread subject</h2>
        </DialogTitle>

        <form onSubmit={onSubmitFn({ control, environment, submit })} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="flex px-4">
            <div className="flex flex-1 py-2 border-b border-mauve-5">
              <TextInput control={control.controls.subject} name="subject" id="subject" />
            </div>
          </div>

          <div className="w-fit ml-auto m-2">
            <Tooltip side="left" content="Cmd + Enter">
              <OutlineButton type="submit" className="flex-col text-sm px-3">
                Update thread
              </OutlineButton>
            </Tooltip>
          </div>
        </form>
      </div>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, values: IFormValue) => {
  console.log("submitting...", values);

  using disposable = environment.isLoading.add();

  EditThreadSubjectDialogState.close({ success: true });

  await updateThreadSubject(environment, {
    threadId: values.id,
    subject: values.subject,
  });
});
