import { DraftGroupRecipientDoc, DraftUserRecipientDoc, generateRecordId, RecordValue } from "libs/schema";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { IEditorMention } from "~/components/forms/message-editor";
import { createRecipientOptionFromPointer, IRecipientOption } from "~/components/forms/ThreadRecipients";
import { useDraft } from "~/hooks/useDraft";
import { closeComposeNewThreadDialog, openComposeNewThreadDialog } from "../page-dialog-state";
import { ComposeNewThread } from "./new-thread/ComposeNewThread";
import { ComposeBranchedThread } from "./branched-thread/ComposeBranchedThread";
import { SetNonNullable } from "type-fest";
import { waitForCacheToContainRecord } from "~/utils/database-utils";
import { UnreachableCaseError } from "libs/errors";
import { isNonNullable } from "libs/predicates";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { createNewThreadDraft } from "~/actions/draft";
import { ParentComponent } from "~/utils/type-helpers";
import { useSearchParams } from "~/hooks/useSearchParams";

/* -------------------------------------------------------------------------------------------------
 * ComposeMessageView
 * -----------------------------------------------------------------------------------------------*/

export const ComposeMessageView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.compose as string | undefined;

  const isCreatingNewDraft = draftId === "new" || draftId === "new-email";

  const [draft, { isLoading: isDraftLoading }] = useDraft(isCreatingNewDraft ? undefined : draftId, {
    fetchStrategy: "cache-first",
  });

  const keepComposeMessageViewOpen = draft !== null || isCreatingNewDraft || isDraftLoading;

  useEffect(() => {
    if (keepComposeMessageViewOpen) return;
    closeComposeNewThreadDialog(environment);
  }, [keepComposeMessageViewOpen, environment]);

  const initialFormValues = useInitialFormValues({
    type: isCreatingNewDraft ? draftId : "existing",
    draft,
  });

  if (!initialFormValues) {
    return (
      <>
        <Helmet>
          <title>Loading... | Comms</title>
        </Helmet>
      </>
    );
  } else if (initialFormValues.branchedFrom) {
    return (
      <>
        <Helmet>
          <title>New Branched Message | Comms</title>
        </Helmet>

        <ComposeBranchedThread
          initialFormValues={initialFormValues as SetNonNullable<IComposeMessageFormValue, "branchedFrom">}
        />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>New Message | Comms</title>
        </Helmet>

        <ComposeNewThread initialFormValues={initialFormValues} />
      </>
    );
  }
};

/* -----------------------------------------------------------------------------------------------*/

function useInitialFormValues(args: { type: "new" | "new-email" | "existing"; draft?: RecordValue<"draft"> | null }) {
  const environment = useClientEnvironment();
  const [initialFormValues, setInitialFormValues] = useState<IComposeMessageFormValue | undefined>();

  /**
   * Creates a new post draft if an existing draft wasn't provided
   * before setting the form values.
   * Otherwise directly sets the initial form values to the existing draft.
   */
  useEffect(() => {
    if (args.type === "new") {
      createAndOpenNewDraft(environment, "COMMS").catch(console.error);
    } else if (args.type === "new-email") {
      createAndOpenNewDraft(environment, "EMAIL").catch(console.error);
    } else if (!args.draft) {
      return;
    } else if (args.draft.is_reply === false) {
      // We clear the form to ensure we rebuild the form's FormControls
      // setInitialFormValues(undefined);
      getInitialFormValues(environment, args.draft).then(setInitialFormValues).catch(console.error);
    } else {
      console.warn(args);
      throw new Error(`Expected a draft for a new thread`);
    }
    // Since this is an initialization fn, we only want to run it
    // once per draftId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.type, args.draft?.id, environment]);

  return initialFormValues;
}

/* -----------------------------------------------------------------------------------------------*/

async function createAndOpenNewDraft(environment: ClientEnvironment, type: "COMMS" | "EMAIL") {
  const draftId = generateRecordId("draft");
  const threadId = generateRecordId("thread");
  const currentUserId = environment.auth.getAndAssertCurrentUserId();
  const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

  const promise = createNewThreadDraft(environment, {
    type,
    draftId,
    threadId,
    currentUserId,
    ownerOrganizationId,
  });

  await waitForCacheToContainRecord(environment, { table: "draft", id: draftId }, { relatedPromise: promise });

  await openComposeNewThreadDialog(environment, draftId);
}

/* -----------------------------------------------------------------------------------------------*/

async function getInitialFormValues(environment: ClientEnvironment, draft: RecordValue<"draft">) {
  const isThreadPrivate = draft.new_thread_visibility === "PRIVATE";

  const [branchedFromMessageResult, ...recipientOptions] = await Promise.all([
    draft.branched_from_message_id && environment.recordLoader.getRecord("message", draft.branched_from_message_id),
    ...draft.to.map((r) => {
      switch (r.type) {
        case "GROUP": {
          return createRecipientOptionFromPointer(environment, {
            pointer: { table: "tag", id: r.group_id },
            isThreadPrivate,
          });
        }
        case "USER": {
          return createRecipientOptionFromPointer(environment, {
            pointer: { table: "user_profile", id: r.user_id },
            isThreadPrivate,
          });
        }
        default: {
          throw new UnreachableCaseError(r);
        }
      }
    }),
  ]);

  const [branchedFromMessage] = branchedFromMessageResult || [null];

  const to: IRecipientOption[] = recipientOptions.filter(isNonNullable);

  const userMentions = draft.to
    .filter((r): r is DraftUserRecipientDoc => r.is_mentioned && r.type === "USER")
    .map<IEditorMention>((r) => {
      return {
        id: r.user_id,
        priority: r.priority,
        type: "user",
      };
    });

  const groupMentions = draft.to
    .filter((r): r is DraftGroupRecipientDoc => r.is_mentioned && r.type === "GROUP")
    .map<IEditorMention>((r) => {
      return {
        id: r.group_id,
        priority: r.priority,
        type: "group",
      };
    });

  const branchedFrom =
    draft.branched_from_thread_id && draft.branched_from_message_id && branchedFromMessage ?
      {
        threadId: draft.branched_from_thread_id,
        messageId: draft.branched_from_message_id,
      }
    : null;

  return {
    type: draft.type,
    messageId: draft.id,
    threadId: draft.thread_id,
    isEdit: draft.is_edit,
    isReply: draft.is_reply,
    branchedFrom,
    visibility: draft.new_thread_visibility!,
    recipients: {
      to,
      cc: [],
      bcc: [],
    },
    subject: draft.new_thread_subject!,
    labels: draft.labels || [],
    attachments: draft.attachments,
    body: {
      content: draft.body_html,
      groupMentions,
      userMentions,
    },
  } satisfies IComposeMessageFormValue;
}

/* -----------------------------------------------------------------------------------------------*/
