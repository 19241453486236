import { useEffect, useRef, useState } from "react";
import {
  DialogState,
  DIALOG_CONTAINER_CSS,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { css, cx } from "@emotion/css";
import { ACTIVE_COMMANDS$, COMMAND_EVENTS$, ICommand, useRegisterCommands } from "~/environment/command.service";
import { combineLatest, distinctUntilChanged, map, throttleTime } from "rxjs";
import { IListRef, List, ListScrollbox } from "~/components/list";
import commandScore from "command-score";
import { CommandEntry, NoMatchingEntry } from "./CommandEntry";
import { FilterCommandsInput, KBarHeader, type IKBarHeaderRef } from "./KBarHeader";
import { IKBarDialogData, KBarState } from "./KBarState";
import { isEqual } from "libs/predicates";
import { useObservable, useObservableState } from "observable-hooks";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { CheckboxInput } from "~/components/forms/CheckboxInput";
import { createFormControl, useControl } from "solid-forms-react";
import { getNormalizedUserSettings } from "~/queries/getNormalizedUserSettings";
import { updateUserSettings } from "~/actions/updateUserSettings";
import { observable } from "~/components/forms/utils";
import { observeNormalizedUserSettings } from "~/observables/observeNormalizedUserSettings";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import * as commonCommandFactories from "~/utils/common-commands";
import { useUnmount } from "react-use";
import { ParentComponent } from "~/utils/type-helpers";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { withDepsGuard } from "~/route-guards/withDepsGuard";
import { useBottomScrollShadow } from "~/hooks/useScrollShadow";
import { hint, ShortcutHint } from "~/environment/hint-service";

/* -------------------------------------------------------------------------------------------------
 * KBarDialog
 * -----------------------------------------------------------------------------------------------*/

export const KBarDialog = withModalDialog({
  dialogState: KBarState as unknown as DialogState<IKBarDialogData | undefined, undefined>,
  containerCSS: cx(
    DIALOG_CONTAINER_CSS,
    css`
      max-height: 472px;
    `,
  ),
  useOnDialogContainerRendered: () => {
    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          commonCommandFactories.openCommandBarCommand({}),
          commonCommandFactories.signoutCommand({
            callback: () => environment.auth.signout(),
          }),
        ];
      },
      deps: [environment],
    });
  },
  // The CommandBar dialog is unusual in that it it's a dialog which doesn't
  // replace the current hotkey context when opened, but rather merges it's
  // context in with the existing context (which is how we grab all the
  // currently active commands and display them). Because of this, we need
  // to ensure that the kbar's commands take precidence over any existing
  // ones with similar names.
  //
  // Note, another approach would be to grab a snapshot of the active commands
  // right before opening the command bar and use those. For the time
  // being though, this current approach seems simpler.
  commandContextOptions: {
    updateStrategy: "merge",
  },
  loadData,
  Component: (props) => <DialogComponent {...props} />,
});

/* -----------------------------------------------------------------------------------------------*/

const dialogCSS = cx(
  DIALOG_CONTENT_WRAPPER_CSS,
  "overflow-hidden rounded",
  css`
    background-color: transparent;
    box-shadow:
      0px 2px 12px 2px rgba(0, 0, 0, 0.2),
      0px 15px 50px 10px rgba(0, 0, 0, 0.3);
  `,
);

type LoadDataResult = Awaited<ReturnType<typeof loadData>>;

async function loadData(props: { environment: ClientEnvironment }) {
  const { environment } = props;
  const settings = await getNormalizedUserSettings(environment);

  return {
    settings,
  };
}

/* -----------------------------------------------------------------------------------------------*/

const DialogComponent = withDepsGuard<{ data: LoadDataResult }>()({
  useDepsFactory: (props) => {
    const control = useControl(() => createFormControl(props.data.settings?.show_archived_groups ?? false));
    if (!control) return null;
    return { control };
  },
  Component: ({ control }) => {
    const listRef = useRef<IListRef<ICommand>>(null);
    const headerRef = useRef<IKBarHeaderRef>(null);
    const scrollboxRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const environment = useClientEnvironment();

    const mode = useObservableState(KBarState.mode$);
    const currentPath = useObservableState(KBarState.path$);

    // Switch focus between search-input and not-search-input
    // when the mode changes.
    useEffect(() => {
      headerRef.current?.focusInput(mode === "search");
    }, [mode]);

    useUnmount(() => KBarState.reset());

    useEffect(() => {
      const sub = observable(() => control.value).subscribe((value) => {
        updateUserSettings(environment, {
          show_archived_groups: value,
        });
      });

      return () => sub.unsubscribe();
    }, [control, environment]);

    useBottomScrollShadow({
      scrollboxRef: scrollboxRef,
      targetRef: footerRef,
    });

    const { commands, enableEntryFocusOnMouseover } = useCommands(listRef);

    useRegisterCommandBarCommands(listRef);

    const showArchivedGroupsToggle = useShowArchivedGroupsToggle();

    return (
      <List ref={listRef} mode="active-descendent" focusEntryOnMouseOver={enableEntryFocusOnMouseover}>
        <div className={cx(dialogCSS, mode === "hotkey" && "hotkey-mode")}>
          <KBarHeader ref={headerRef} scrollboxRef={scrollboxRef} currentPath={currentPath} mode={mode}>
            <KbarFilterInput />
          </KBarHeader>

          <ListScrollbox>
            <div ref={scrollboxRef} role="listbox" className="flex flex-col overflow-y-auto bg-white">
              {commands.map((command, index) => {
                return (
                  <CommandEntry
                    key={command.id}
                    index={index}
                    currentPath={currentPath}
                    command={command}
                    mode={mode}
                    onClick={() => {
                      callCommand(command);

                      if (command.hotkeys[0]) {
                        hint("quiet", { content: <ShortcutHint hint={command.hotkeys[0]} /> });
                      }
                    }}
                  />
                );
              })}

              {commands.length === 0 && <NoMatchingEntry />}

              <div
                ref={footerRef}
                className="flex items-center justify-end px-8 py-4 space-x-2 sticky bottom-0 bg-white"
              >
                {showArchivedGroupsToggle && (
                  <>
                    <label htmlFor="show-archived-groups-checkbox mr-2" className="ml-2 text-sm">
                      Show archived groups?
                    </label>

                    <CheckboxInput
                      id="ml-2 show-archived-groups-checkbox"
                      control={control}
                      checkedValue={true}
                      uncheckedValue={false}
                    />
                  </>
                )}
              </div>
            </div>
          </ListScrollbox>
        </div>
      </List>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

// This command is needed to ensure that a COMMAND_EVENT is triggered when the command is called.
function callCommand(command: ICommand) {
  COMMAND_EVENTS$.next({ command });
  command.callback();
}

/* -----------------------------------------------------------------------------------------------*/

const KbarFilterInput: ParentComponent<{}> = () => {
  const value = useObservableState(KBarState.query$);

  return (
    <FilterCommandsInput
      value={value}
      onChange={(e) => KBarState.query$.next(e.target.value)}
      onFocus={() => KBarState.mode$.next("search")}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useRegisterCommandBarCommands(listRef: React.RefObject<IListRef<ICommand>>) {
  useRegisterCommands({
    priority: 99999,
    commands: () => {
      return [
        {
          label: "Escape",
          path: ["global"],
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          showInKBar: false,
          callback: () => {
            if (KBarState.mode$.getValue() === "search" && KBarState.query$.getValue().length > 0) {
              KBarState.query$.update(() => "");
              return;
            }

            KBarState.close();
          },
        },
        {
          label: "Back",
          path: ["global"],
          hotkeys: ["Backspace"],
          triggerHotkeysWhenInputFocused: true,
          showInKBar: false,
          callback: () => {
            if (KBarState.path$.getValue().length === 0) return false;
            if (KBarState.mode$.getValue() === "search" && KBarState.query$.getValue().length > 0) {
              return false;
            }

            KBarState.query$.next("");

            if (KBarState.path$.getValue().length === 1) {
              KBarState.mode$.next("search");
            }

            KBarState.query$.update(() => "");
            KBarState.path$.update((path) => path.slice(0, -1));
          },
        },
        {
          label: "Select",
          path: ["global"],
          hotkeys: ["Enter"],
          triggerHotkeysWhenInputFocused: true,
          showInKBar: false,
          callback: () => {
            const command = listRef.current?.focusableOrActiveEntry()?.data;
            if (!command) return;
            callCommand(command);
          },
        },
      ];
    },
    deps: [listRef],
  });
}

/* -----------------------------------------------------------------------------------------------*/

function useCommands(listRef: React.RefObject<IListRef<ICommand>>) {
  const [commands, setCommands] = useState<Array<ICommand>>([]);
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const [enableEntryFocusOnMouseover, setEnableEntryFocusOnMouseover] = useState(true);

  // Update the kbar commands in response to change in the
  // command service state and in response to user searches.
  useEffect(() => {
    const sub = combineLatest([
      KBarState.query$.pipe(throttleTime(100, undefined, { leading: true, trailing: true })),
      ACTIVE_COMMANDS$.pipe(map((commands) => commands.filter((command) => command.showInKBar))),
      observeNormalizedUserSettings(environment, { userId: currentUserId }),
    ]).subscribe(([query, commands, { settings }]) => {
      const currentPath = KBarState.path$.getValue();

      const getScore = (command: ICommand) => {
        let text = command.keywords.join(" ");

        if (typeof command.label === "string") {
          text = command.label.concat(" ", text);
        }

        return commandScore(text, query);
      };

      if (!settings?.show_archived_groups) {
        commands = commands.filter((command) => !command.path.includes("Archived"));
      }

      const results = commands
        .filter((command) => {
          if (isEqual(command.path, ["global"] as const)) return true;

          if (query.length === 0) {
            return (
              command.path.length === currentPath.length &&
              currentPath.every((segment, i) => command.path[i] === segment)
            );
          }

          return currentPath.every((segment, i) => command.path[i] === segment);
        })
        .flatMap((command) => {
          if (command.altLabels.length > 0) {
            const allLabels: ICommand["altLabels"] = [
              command.keywords ? { render: command.label, keywords: command.keywords } : (command.label as string),
              ...command.altLabels,
            ];

            const expandedCommands = allLabels
              .map((label) => {
                const labelText =
                  typeof label === "string" ? label
                  : typeof label.render === "string" ? label.render
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  : label.keywords[0]!;

                const keywords = typeof label === "object" ? label.keywords : [];

                return {
                  ...command,
                  __local: {
                    originalCommand: command,
                  },
                  label: labelText,
                  keywords,
                } as ICommand;
              })
              .filter((command) => getScore(command) > 0);

            if (expandedCommands.length === 0) return [];

            if (query.length > 0) {
              expandedCommands.sort((a, b) => getScore(b) - getScore(a));
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return [expandedCommands[0]!];
          }

          if (getScore(command) <= 0) return [];

          return [command];
        })
        // Place highest scores first, archived groups last
        .sort((a, b) => {
          if (a.path.includes("Archived") && !b.path.includes("Archived")) {
            return 1;
          } else if (!a.path.includes("Archived") && b.path.includes("Archived")) {
            return -1;
          }

          const aText =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            typeof a.label === "string" ? a.label : a.keywords[0]!;

          const bText =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            typeof b.label === "string" ? b.label : b.keywords[0]!;

          return commandScore(bText, query) - commandScore(aText, query);
        });

      // If the mouse is overing over the list as entries move around
      // beneith it, mouseover events will be triggered which will cause
      // the entry beneith the mouse to be focused as someone types. We
      // want the top entry to be focused while someone types, so we
      // disable mouseover events while they type.
      setEnableEntryFocusOnMouseover(false);
      setCommands(results);

      // After performing a search, we want to focus the first command
      // in the list.
      setTimeout(() => {
        const firstEntry = listRef.current?.entries[0];
        listRef.current?.focus(firstEntry?.id);
        setEnableEntryFocusOnMouseover(true);
      }, 20);
    });

    return () => sub.unsubscribe();
  }, [listRef, environment, currentUserId]);

  return { commands, enableEntryFocusOnMouseover };
}

/* -----------------------------------------------------------------------------------------------*/

function useShowArchivedGroupsToggle() {
  const observable = useObservable(() =>
    KBarState.path$.pipe(
      map((path) => path.length === 0 || path[0] === "Groups"),
      distinctUntilChanged(),
    ),
  );

  return useObservableState(observable);
}

/* -----------------------------------------------------------------------------------------------*/
