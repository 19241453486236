import { LabelTagRecord } from "libs/schema";
import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

export type UsePersonalLabelsResult = UseRecordLoaderResult<LabelTagRecord[]>;

export function usePersonalLabels(): UsePersonalLabelsResult {
  return useRecordLoader({
    name: "usePersonalLabels",
    load({ loader, currentUserId }) {
      return loader.observeGetPersonalLabels({
        currentUserId,
      });
    },
  });
}
