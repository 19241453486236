import { switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ObserveMentionableUserRecordsResult, observeMentionableUsers } from "~/observables/observeMentionableUsers";

export type UseMentionableUsersResult = ObserveMentionableUserRecordsResult;

export function useMentionableUsers(): UseMentionableUsersResult {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: defaultValue,
    deps: [environment, currentUserId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, currentUserId]) => {
          return observeMentionableUsers(environment, { currentUserId });
        }),
      );
    },
  });
}

const defaultValue = Object.freeze([[], { isLoading: true }]) as unknown as UseMentionableUsersResult;
