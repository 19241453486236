import { groupBy, uniqWith } from "lodash-comms";
import { isPointerEqual, RecordPointer, RecordTable, TABLE_NAMES } from "libs/schema";
import { ValidationError } from "libs/errors";

export function groupPointersByTable(pointers: RecordPointer<any>[]) {
  const distinctPointers = uniqWith(pointers, isPointerEqual) as RecordPointer[];

  for (const pointer of distinctPointers) {
    if (TABLE_NAMES.includes(pointer.table)) continue;
    throw new ValidationError(`[groupPointersByTable] invalid record table "${pointer.table}"`);
  }

  const groupedPointersMap = groupBy(distinctPointers, (p) => p.table);

  return {
    distinctPointers,
    groupedPointers: Object.entries(groupedPointersMap) as Array<[RecordTable, RecordPointer[]]>,
  };
}
