import { observeInboxEntries } from "../observables/observeInboxEntries";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useRecordLoader } from "./useRecordLoader";
import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { tapObservable } from "libs/rxjs-operators";
import { NEVER, switchMap, throttleTime } from "rxjs";
import { observeFilteredInboxEntriesForVirtualList } from "~/pages/inbox/InboxEntry";

export function useInboxEntries(props: { inboxSectionId: string | null; isThreadOpen: boolean }) {
  const { inboxSectionId, isThreadOpen } = props;
  const environment = useClientEnvironment();
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useInboxEntries",
    deps: [inboxSectionId, isThreadOpen, environment],
    load({ loader, deps: [inboxSectionId, isThreadOpen, environment], limit, currentUserId }) {
      if (!inboxSectionId) {
        return loader.createObserveQueryResult<"inbox_entry">([undefined, { limit }]);
      }

      let observable = observeInboxEntries(environment, {
        userId: currentUserId,
        inboxSectionId,
        limit,
      }).pipe(
        // We need to fetch the thread group permissions for each inbox entry in order to properly generate the
        // inbox group filters. This is because the inbox group filter logic just runs off data which is already in
        // memory.
        tapObservable((source) =>
          source.pipe(
            switchMap(([entries, { isLoading }]) => {
              if (entries.length === 0) return NEVER;

              return loader.observeGetThreadsGroupPermissions(
                { threadIds: entries.map((e) => e.thread_id) },
                { isLoading },
              );
            }),
          ),
        ),
      );

      // If a user is replying to a thread with the inbox open in the background, the inbox query will
      // be updating on every keystroke. This quickly causes poor performance so we throttle the updates.
      if (isThreadOpen) {
        observable = observable.pipe(throttleTime(1500, undefined, { leading: false, trailing: true }));
      }

      return observeFilteredInboxEntriesForVirtualList(observable);
    },
    initialLimit,
    limitStep: initialLimit,
  });
}
