import { PointerWithRecord } from "libs/schema/schema";
import { IListOnEntryActionEvent, IListRef } from "~/components/list";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isModKeyActive } from "~/environment/command.service";
import { UnreachableCaseError } from "libs/errors";
import { IThreadViewContext } from "../thread/context";
import { useMemo } from "react";
import { TTagViewType } from "~/hooks/useTagViewType";

export type TTagEntry = PointerWithRecord<"thread">;

/* -----------------------------------------------------------------------------------------------*/

export async function onTagEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TTagEntry>;
    type: TTagViewType;
    tagId: string;
  },
) {
  const { event, type, tagId } = props;

  switch (event.entry.table) {
    case "thread": {
      return environment.router.navigate(`/${type}s/${tagId}/threads/${event.entry.record.id}`, {
        openInNewTab: isModKeyActive(event.event),
      });
    }
    default: {
      throw new UnreachableCaseError(event.entry.table);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function useTagViewThreadContext(props: {
  listRef: React.RefObject<IListRef<TTagEntry>>;
  type: TTagViewType | null;
  tagId: string | undefined;
}) {
  const { listRef, type, tagId } = props;

  return useMemo((): IThreadViewContext | null => {
    if (!tagId || !type) return null;

    switch (type) {
      case "group": {
        return {
          threadList: {
            type: "group",
            groupId: tagId,
            ref: listRef,
          },
        };
      }
      case "label": {
        return {
          threadList: {
            type: "label",
            labelId: tagId,
            ref: listRef,
          },
        };
      }
      default: {
        throw new UnreachableCaseError(type);
      }
    }
  }, [listRef, type, tagId]);
}

/* -----------------------------------------------------------------------------------------------*/
