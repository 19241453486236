import { BehaviorSubject } from "rxjs";
import { useEffect } from "react";
import { useObservableState } from "observable-hooks";
import { wait } from "libs/promise-utils";
import { ParentComponent } from "~/utils/type-helpers";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useSearchParams } from "~/hooks/useSearchParams";

interface IPageDialogDataMap {
  ComposeMessage: null | undefined;
}

interface IPageDialogDataStruct<T extends keyof IPageDialogDataMap> {
  type: T;
  data: IPageDialogDataMap[T] | undefined;
}

export type IPageDialogData = IPageDialogDataStruct<keyof IPageDialogDataMap>;

function openPageDialog<T extends keyof IPageDialogDataMap>(type: T, data?: IPageDialogDataMap[T]) {
  PAGE_DIALOG_STATE$.next({ type, data });
}

function closePageDialog() {
  PAGE_DIALOG_STATE$.next(null);
}

export const PAGE_DIALOG_STATE$ = new BehaviorSubject<IPageDialogData | null>(null);

export function usePageDialogState() {
  return useObservableState(PAGE_DIALOG_STATE$);
}

/**
 * @param draftId id of an existing draft or "new" to create a new draft
 */
export async function openComposeNewThreadDialog(
  environment: Pick<ClientEnvironment, "router">,
  draftId: string,
  options: {
    inNewWindow?: boolean;
  } = {},
) {
  const isComposeNewThreadDialogAlreadyOpen = PAGE_DIALOG_STATE$.getValue()?.type === "ComposeMessage";

  const url = environment.router.url();

  const existingDraftId = url.searchParams.get("compose");

  if (isComposeNewThreadDialogAlreadyOpen && existingDraftId !== "new" && existingDraftId !== "new-email") {
    // If we're already composing a new draft, the ComposeNewThreadDialog
    // doesn't expect the draftId to change so we close and reopen the
    // dialog.
    await closeComposeNewThreadDialog(environment);
  }

  if (options.inNewWindow) {
    url.searchParams.set("compose", draftId);
    return environment.router.navigate(url, { openInNewTab: true });
  } else if (isMaskedRoute(environment)) {
    const threadIdRegexp = /^\/threads\/(.+)/;
    const threadId = location.pathname.match(threadIdRegexp)?.[1];

    if (!threadId) {
      throw new Error("[openComposeNewThreadDialog] cannot find threadId");
    }

    // A bug in tanstack's "declarative route masking" functionality causes undefined behavior
    // when navigating to a masked route with search params (which we do if we're viewing a thread
    // and attempt to compose a new draft). To work around this, we need to
    // be explicit and manually specify the masking behavior.
    // See https://github.com/TanStack/router/issues/2070
    return environment.router.tanstack.navigate({
      search: (search) => ({ ...search, compose: draftId }) as never,
      mask: {
        to: "/threads/$threadId",
        params: { threadId },
        search: (search) => ({ ...search, compose: draftId }) as never,
      },
      replace: true,
    });
  } else {
    return environment.router.updateSearchParams((searchParams) => searchParams.set("compose", draftId), {
      replace: !!(isComposeNewThreadDialogAlreadyOpen && existingDraftId),
    });
  }
}

export async function closeComposeNewThreadDialog(environment: Pick<ClientEnvironment, "router">) {
  const hasExistingDraftId = !!environment.router.url().searchParams.get("compose");

  if (!hasExistingDraftId) return;

  if (isMaskedRoute(environment)) {
    const threadIdRegexp = /^\/threads\/(.+)/;
    const threadId = location.pathname.match(threadIdRegexp)?.[1];

    if (!threadId) {
      throw new Error("[closeComposeNewThreadDialog] cannot find threadId");
    }

    // A bug in tanstack's "declarative route masking" functionality causes undefined behavior
    // when navigating to a masked route with search params (which we do if we're viewing a thread
    // and attempt to compose a new draft). To work around this, we need to
    // be explicit and manually specify the masking behavior.
    // See https://github.com/TanStack/router/issues/2070
    return environment.router.tanstack.navigate({
      search: (search) => ({ ...search, compose: undefined }) as never,
      mask: {
        to: "/threads/$threadId",
        params: { threadId },
        search: (search) => ({ ...search, compose: undefined }) as never,
      },
      replace: true,
    });
  }

  return environment.router.updateSearchParams((searchParams) => searchParams.delete("compose"), {
    replace: true,
  });
}

export const OpenComposeMessageService: ParentComponent<{}> = () => {
  const [searchParams] = useSearchParams();
  const draftId = searchParams.compose as string | undefined;

  useEffect(() => {
    if (draftId) {
      openPageDialog("ComposeMessage");
    } else {
      closePageDialog();
    }
  }, [!!draftId]);

  return null;
};

function isMaskedRoute(environment: Pick<ClientEnvironment, "router">) {
  const url = environment.router.url();
  const isMaskedRoute = url.pathname !== location.pathname;

  if (isMaskedRoute) {
    if (!location.pathname.startsWith("/threads/")) {
      // At time of writing, the only masked route is /threads/$threadId.
      // We'll need to update this logic if we add more masked routes.
      throw new Error("[isMaskedRoute] Unexpected masked route");
    }
  }

  return isMaskedRoute;
}
