import { UnreachableCaseError } from "libs/errors";
import { RecordValue } from "libs/schema";
import { create } from "zustand";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type TFilteredInboxEntry =
  | RecordValue<"inbox_entry">
  | { type: "section_header"; id: string; sectionId: string; subsectionId: string }
  | { type: "subsection_break"; id: string; sectionId: string; subsectionId: string; priorityIndicator: string };

/**
 * State of the inbox page.
 */

export const inboxState = create<{
  inboxEntries: RecordValue<"inbox_entry">[];
  filteredInboxEntries: TFilteredInboxEntry[];
  selectedGroups: string[];
  setInboxEntries: (
    environment: ClientEnvironment,
    props: {
      inboxEntries: RecordValue<"inbox_entry">[];
      inboxSectionId: string;
    },
  ) => void;
  toggleGroup: (
    environment: ClientEnvironment,
    props: {
      groupId: string;
      inboxSectionId: string;
    },
  ) => void;
  reIndexEntries: (
    environment: ClientEnvironment,
    props: {
      inboxSectionId: string;
    },
  ) => void;
  clearSelectedGroups: (
    environment: ClientEnvironment,
    props: {
      inboxSectionId: string;
    },
  ) => void;
}>((set) => ({
  inboxEntries: [],
  filteredInboxEntries: [],
  selectedGroups: [],
  setInboxEntries(
    environment: ClientEnvironment,
    props: {
      inboxEntries: RecordValue<"inbox_entry">[];
      inboxSectionId: string;
    },
  ) {
    set((state) => ({
      inboxEntries: props.inboxEntries,
      filteredInboxEntries: filterInboxEntries(environment, {
        selectedGroups: state.selectedGroups,
        inboxEntries: props.inboxEntries,
        inboxSectionId: props.inboxSectionId,
      }),
    }));
  },
  toggleGroup: (
    environment: ClientEnvironment,
    props: {
      groupId: string;
      inboxSectionId: string;
    },
  ) => {
    set((state) => {
      let selectedGroups = state.selectedGroups;

      selectedGroups =
        selectedGroups.includes(props.groupId) ?
          selectedGroups.filter((g) => g !== props.groupId)
        : [...selectedGroups, props.groupId];

      return {
        selectedGroups,
        filteredInboxEntries: filterInboxEntries(environment, {
          selectedGroups,
          inboxEntries: state.inboxEntries,
          inboxSectionId: props.inboxSectionId,
        }),
      };
    });
  },
  reIndexEntries: (
    environment: ClientEnvironment,
    props: {
      inboxSectionId: string;
    },
  ) => {
    set((state) => ({
      filteredInboxEntries: filterInboxEntries(environment, {
        selectedGroups: state.selectedGroups,
        inboxEntries: state.inboxEntries,
        inboxSectionId: props.inboxSectionId,
      }),
    }));
  },
  clearSelectedGroups: (
    environment: ClientEnvironment,
    props: {
      inboxSectionId: string;
    },
  ) => {
    set((state) => ({
      selectedGroups: [],
      filteredInboxEntries: filterInboxEntries(environment, {
        selectedGroups: [],
        inboxEntries: state.inboxEntries,
        inboxSectionId: props.inboxSectionId,
      }),
    }));
  },
}));

function filterInboxEntries(
  environment: ClientEnvironment,
  props: {
    selectedGroups: string[];
    inboxSectionId: string;
    inboxEntries: RecordValue<"inbox_entry">[];
  },
) {
  const { selectedGroups, inboxSectionId, inboxEntries } = props;
  const currentUserId = environment.auth.getAndAssertCurrentUserId();

  let previousInboxSubsectionId: string | undefined;
  let previousPriorityIndicator: string | undefined;
  const inboxSubsections = environment.db.getInboxSubsections({ inboxSectionId, currentUserId });
  const inboxSubsectionCount = inboxSubsections.length;

  return inboxEntries.reduce((store: TFilteredInboxEntry[], entry) => {
    switch (entry.type) {
      case "notification": {
        const isMatch = doesEntryMatchGroupFilters(environment, selectedGroups, entry);

        if (!isMatch) return store;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const inboxSubsectionId = entry.inbox_subsection_id!;
        const isNewInboxSection = previousInboxSubsectionId !== inboxSubsectionId;
        previousInboxSubsectionId = inboxSubsectionId;

        if (isNewInboxSection) {
          previousPriorityIndicator = undefined;

          if (inboxSubsectionCount > 1) {
            store.push({
              type: "section_header",
              id: `${inboxSectionId}:${inboxSubsectionId}`,
              sectionId: inboxSectionId,
              subsectionId: inboxSubsectionId,
            });
          }
        }

        const priorityIndicator = mapPriorityToPriorityIndicator(entry.priority);
        const isNewPriorityIndicator = !!previousPriorityIndicator && previousPriorityIndicator !== priorityIndicator;
        previousPriorityIndicator = priorityIndicator;

        if (isNewPriorityIndicator) {
          store.push({
            type: "subsection_break",
            id: `${inboxSectionId}:${inboxSubsectionId}:${priorityIndicator}`,
            sectionId: inboxSectionId,
            subsectionId: inboxSubsectionId,
            priorityIndicator,
          });
        }

        store.push(entry);

        return store;
      }
      case "draft": {
        store.push(entry);

        return store;
      }
      default: {
        throw new UnreachableCaseError(entry.type);
      }
    }
  }, []);
}

function doesEntryMatchGroupFilters(
  environment: ClientEnvironment,
  selectedGroups: string[],
  entry: RecordValue<"inbox_entry">,
) {
  if (selectedGroups.length === 0) {
    return true;
  }

  if (entry.type === "notification") {
    const [permissions] = environment.db.getThreadGroupPermissions({
      thread_id: entry.thread_id,
    });

    return permissions.some((p) => selectedGroups.includes(p.group_id));
  }

  return true;
}

export function mapPriorityToPriorityIndicator(priority: number | null) {
  if (priority === null) {
    return "-";
  } else if (priority <= 100) {
    return "@@@";
  } else if (priority <= 200) {
    return "@@";
  } else if (priority <= 300) {
    return "@";
  } else {
    return "-";
  }
}
