import { withNewCommandContext } from "~/environment/command.service";
import { RefObject, useEffect, useMemo, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useIsOnline } from "~/hooks/useIsOnline";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { ISearchEditorRef, SearchEditor } from "~/components/forms/search-editor";
import { MessageEntry } from "~/components/content-list/MessageEntry";
import { useAndInitializeSearch } from "./useSearch";
import { usePerformSearch, useRegisterSearchViewCommands } from "./useRegisterSearchViewCommands";
import { filterSuggestions } from "~/components/forms/search-editor/suggest/SuggestionsDropdownProvider";
import { buildFilterSuggestionString, getTextSelectionRange } from "~/components/forms/search-editor/suggest/utils";
import { useTips } from "./useTips";
import { withDepsGuard } from "~/route-guards/withDepsGuard";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { onSearchEntrySelect, TSearchEntry, useCreateSearchViewThreadContext } from "./utils";
import { useVirtualList } from "~/hooks/useVirtualList";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";
import { LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useRouteParams } from "~/hooks/useRouteParams";
import { useSearchParams } from "~/hooks/useSearchParams";

/* -------------------------------------------------------------------------------------------------
 * SearchView
 * -----------------------------------------------------------------------------------------------*/

export const SearchView = withNewCommandContext({
  priority: { delta: 2 },
  Component: () => <SearchComponent />,
});

const SearchComponent = withDepsGuard()({
  useDepsFactory() {
    const editorRef = useRef<ISearchEditorRef>(null);

    const { searchControl, queryAsPlainText, onlySearchSeenPostsControl } = useAndInitializeSearch({
      editorRef,
    });

    if (!searchControl || !onlySearchSeenPostsControl) return null;

    return {
      searchControl,
      onlySearchSeenPostsControl,
      editorRef,
      queryAsPlainText,
    };
  },
  Component: (props) => {
    const { searchControl, onlySearchSeenPostsControl, editorRef, queryAsPlainText } = props;
    const environment = useClientEnvironment();
    const resultsListRef = useRef<IListRef<TSearchEntry>>(null);
    const searchEditorRef = useRef<HTMLDivElement>(null);
    const scrollboxRef = useRef<HTMLElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const [params] = useRouteParams();
    const query = params.query || "";
    const [searchParams] = useSearchParams();
    const threadViewContext = useCreateSearchViewThreadContext({ listRef: resultsListRef, query });
    const isThreadOpen = useIsRouteActive({ path: "/search/$query/threads/$threadId" });

    const isAppOnline = useIsOnline();

    const { search, fetchMore, searchResults, isEndOfSearch, isSearchPending } = usePerformSearch({
      searchControl,
      resultsListRef,
      onlySearchSeenPostsControl,
    });

    const processedSearchResults = useMemo(() => {
      const store = new Map(searchResults.map((r) => [r.id, r]));
      return { messageIds: Array.from(store.keys()), messageMap: store };
    }, [searchResults]);

    const searchResultCount = searchResults.length;

    useFocusSearchInputIfNoResults({
      editorRef,
      queryAsPlainText,
      areSearchResults: searchResultCount > 0,
      isSearchPending,
    });

    useRegisterSearchViewCommands({
      editorRef,
      searchEditorRef,
      searchControl,
      search,
      onlySearchSeenPostsControl,
    });

    useTopScrollShadow({
      scrollboxRef,
      targetRef: headerRef,
    });

    const virtualSearchResultIds = useVirtualList({
      scrollboxRef,
      count: searchResultCount,
      getEntryKey: (index) => searchResults[index]?.id || "",
      fetchMore,
      hasNextPage: !isEndOfSearch,
      isFetchingNextPage: isSearchPending,
    });

    const { tip, nextTip } = useTips();

    return (
      <ListScrollbox ref={scrollboxRef} offsetHeaderEl={headerRef}>
        <div className="h-screen overflow-auto">
          <Helmet>
            <title>Search | Comms</title>
          </Helmet>

          <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
            <MainLayout.Header className="MainHeader pt-4">
              <h1 className="text-3xl mr-3 mt-2 font-medium">Search</h1>
            </MainLayout.Header>

            <div className="flex justify-center px-4 md-w:px-12 bg-white">
              <div className="flex flex-col w-full">
                <SearchEditor ref={searchEditorRef} editorRef={editorRef} control={searchControl} />
              </div>
            </div>

            <div className="flex flex-wrap px-4 md-w:px-12 pt-5 bg-white">
              {filterSuggestions.map((o, index) => (
                <div
                  key={index}
                  className="bg-slate-2 py-1 mb-2 px-3 mr-2 rounded text-sm text-slate-11 border border-slate-5 cursor-pointer hover:bg-slate-3 hover:border-slate-4"
                  title={o.description}
                  onClick={() => {
                    const editor = editorRef.current?.editor;
                    if (!editor) return;

                    editor.commands.insertContent(buildFilterSuggestionString(o));

                    editorRef.current?.editor?.view.focus();
                    if (o.hint && o.selectHint) {
                      const range = getTextSelectionRange(o, editor);
                      editorRef.current?.editor?.commands.setTextSelection(range);
                    }
                  }}
                >
                  {o.name}
                </div>
              ))}
            </div>
          </div>

          {searchResultCount === 0 && !isSearchPending && (
            <>
              {tip && (
                <div className="flex align-center justify-center my-6 md-w:mt-12 text-slate-10">
                  <div className="w-screen max-w-[600px] mx-4 md-w:mx-12 py-3 px-5 bg-slate-1 rounded">
                    <div className="text-base mb-4">{tip.title}</div>
                    <div className="text-sm mb-2">{tip.description}</div>
                    <div className="text-center mt-3">
                      <code>{tip?.example}</code>
                    </div>
                    <div className="flex justify-end mt-4">
                      <button className="px-4 py-1 hover:bg-slate-4 text-sm text-slate-10 rounded" onClick={nextTip}>
                        Next Tip
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {!isAppOnline ?
            <EmptyListMessage
              text="Currently offline. Go online to search."
              className={cx(isThreadOpen && "invisible")}
            />
          : !queryAsPlainText || (searchResultCount === 0 && isSearchPending) ?
            <div />
          : searchResultCount === 0 ?
            <EmptyListMessage
              text={`No results for "${queryAsPlainText}"`}
              className={cx(isThreadOpen && "invisible")}
            />
          : <ContentList<TSearchEntry>
              listRef={resultsListRef}
              mode={isThreadOpen ? "active-descendent" : "focus"}
              onEntryAction={(event) => onSearchEntrySelect(environment, { event, query })}
              onArrowUpOverflow={(e) => {
                e.preventDefault();
                editorRef.current?.focus("all");
              }}
              initiallyFocusEntryId={isThreadOpen ? searchParams.messageId : undefined}
              allEntryIdsForVirtualizedList={processedSearchResults.messageIds}
              className={cx(isThreadOpen && "invisible")}
              style={virtualSearchResultIds.containerStyles()}
              autoFocus
            >
              {virtualSearchResultIds.entries.map((virtualEntry, index) => {
                const messageId = virtualEntry.key as string;
                if (!messageId) return null;

                const message = processedSearchResults.messageMap.get(messageId);
                if (!message) return null;

                return (
                  <MessageEntry
                    key={messageId}
                    messageId={messageId}
                    relativeOrder={index}
                    overrideSubject={
                      message.subject && (
                        <span
                          className="[&>em]:bg-mint-4 [&>em]:not-italic"
                          dangerouslySetInnerHTML={{
                            __html: message.subject,
                          }}
                        />
                      )
                    }
                    overrideBody={
                      message.body && (
                        <span
                          className="[&>em]:bg-mint-4 [&>em]:not-italic"
                          dangerouslySetInnerHTML={{
                            __html: message.body,
                          }}
                        />
                      )
                    }
                    showScheduledToBeSentIconFor="threadId"
                    style={virtualSearchResultIds.entryStyles(virtualEntry)}
                  />
                );
              })}
            </ContentList>
          }

          {searchResultCount > 0 && isEndOfSearch && !isSearchPending && (
            <EndOfListMsg className={cx(isThreadOpen && "invisible")} />
          )}

          {(!isEndOfSearch || isSearchPending) && <LoadingMoreListEntriesMsg isThreadOpen={isThreadOpen} />}

          <ThreadViewContextProvider context={threadViewContext}>
            <Outlet />
          </ThreadViewContextProvider>
        </div>
      </ListScrollbox>
    );
  },
});

function useFocusSearchInputIfNoResults(props: {
  editorRef: RefObject<ISearchEditorRef>;
  isSearchPending: boolean;
  queryAsPlainText: string | null;
  areSearchResults: boolean;
}) {
  useEffect(() => {
    if (props.isSearchPending) return;
    if (props.areSearchResults) return;
    props.editorRef.current?.onCreate$.subscribe((observer) => {
      observer.view.focus();
      props.editorRef.current?.focus("end");
    });
    props.editorRef.current?.focus("all");
  }, [
    props.editorRef,
    // We wish to refocus the input every time the query changes
    props.queryAsPlainText,
    props.areSearchResults,
    props.isSearchPending,
  ]);
}
