import { AppEnvironment } from "libs/AppEnvironment";

/** Static configuration values for the client application. */
export type ClientConfig = typeof config;

/** Static configuration values for the client application. */
export const config = {
  appEnvironment: (import.meta.env.VITE_APP_ENV || import.meta.env.MODE || "development") as AppEnvironment,
  version: import.meta.env.VITE_RELEASE_VERSION,
  apiServerOrigin: import.meta.env.VITE_API_SERVER_ORIGIN || location.origin,
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || location.host,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
  },
  datadog: {
    appId: import.meta.env.VITE_DD_APP_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
  },
  stripe: {
    publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    pricingTableId: import.meta.env.VITE_STRIPE_PRICING_TABLE_ID,
    billingPortalUrl: import.meta.env.VITE_STRIPE_BILLING_PORTAL_URL,
  },
  dev: {
    adminApiToken: import.meta.env.VITE_COMMS_ADMIN_API_TOKEN,
    adminLoginAsUserId: import.meta.env.VITE_ADMIN_LOGIN_AS_USER_ID,
  },
} as const;
