import { RefObject, useMemo, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useStarredNotifications } from "~/hooks/useStarredNotifications";
import { NotificationEntry } from "~/components/content-list/NotificationEntry";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { PointerWithRecord } from "libs/schema";
import { triageThread } from "~/actions/notification";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  MarkAllDoneEntryAction,
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { onStarredEntrySelect, TStarredEntry, useStarredViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";
import { EndOfListMsg, LoadingMoreListEntriesMsg } from "~/components/EndOfListMsg";
import { useRegisterThreadLabelCommands } from "~/hooks/useRegisterThreadLabelCommands";

export const StarredView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<IListRef<TStarredEntry>>(null);
  const threadViewContext = useStarredViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/starred/threads/$threadId" });

  const [notifications, { nextId, isLoading, fetchMore }] = useStarredNotifications();

  const containsDoneNotification = notifications.some((n) => n.is_done);
  const containsNotDoneNotification = notifications.some((n) => !n.is_done);

  const hasNextPage = !!nextId;

  const virtualNotificationIds = useVirtualList({
    scrollboxRef,
    count: notifications.length,
    getEntryKey: (index) => notifications[index]?.id ?? "",
    fetchMore,
    hasNextPage,
    isFetchingNextPage: isLoading,
  });

  const notificationIds = useMemo(() => notifications.map((n) => n.id), [notifications]);

  const notificationCount = virtualNotificationIds.entries.length;
  const showNoStarredNotificationsMessage = notificationCount === 0 && !isLoading;

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TStarredEntry>();

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <RegisterStarredNotificationEntryCommands
          listRef={listRef}
          isListRefSet={!showNoStarredNotificationsMessage}
          useFocusedNotification={useFocusedEntry}
        />

        <Helmet>
          <title>Starred | Comms</title>
        </Helmet>

        <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
          <MainLayout.Header>
            <h1 className="text-3xl">Starred</h1>
          </MainLayout.Header>

          <MainLayout.ActionsBar
            listRef={listRef}
            isListRefSet={notificationCount > 0}
            multiSelectActions={
              <>
                {containsNotDoneNotification && <MarkAllDoneEntryAction />}
                {containsDoneNotification && <MarkAllNotDoneEntryAction />}
                <SetReminderForAllEntryAction />
                <OtherCommandEntryAction />
              </>
            }
            className={cx(isThreadOpen && "invisible")}
          />
        </div>

        {showNoStarredNotificationsMessage ?
          <EmptyListMessage text="No starred notifications." className={cx(isThreadOpen && "invisible")} />
        : <ContentList<TStarredEntry>
            listRef={listRef}
            mode={isThreadOpen ? "active-descendent" : "focus"}
            onEntryFocused={setFocusedEntry}
            onEntryAction={(event) => onStarredEntrySelect(environment, { event })}
            className={cx(isThreadOpen && "invisible")}
            autoFocus
            allEntryIdsForVirtualizedList={notificationIds}
            style={virtualNotificationIds.containerStyles()}
          >
            {virtualNotificationIds.entries.map((virtualEntry, index) => {
              const notificationId = virtualEntry.key as string;
              if (!notificationId) return null;

              return (
                <NotificationEntry
                  key={notificationId}
                  notificationId={notificationId}
                  relativeOrder={index}
                  style={virtualNotificationIds.entryStyles(virtualEntry)}
                />
              );
            })}
          </ContentList>
        }

        {notificationCount > 0 && !hasNextPage && !isLoading && (
          <EndOfListMsg className={cx(isThreadOpen && "invisible")} />
        )}

        {(hasNextPage || isLoading) && <LoadingMoreListEntriesMsg isThreadOpen={isThreadOpen} />}

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
};

const RegisterStarredNotificationEntryCommands: ParentComponent<{
  listRef: RefObject<IListRef<TStarredEntry>>;
  isListRefSet: boolean;
  useFocusedNotification: () => PointerWithRecord<"notification"> | null;
}> = (props) => {
  const { listRef, isListRefSet, useFocusedNotification } = props;
  const focusedNotification = useFocusedNotification();
  const environment = useClientEnvironment();

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet,
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            callback: () => {
              RemindMeDialogState.open({
                threadId: focusedNotification.record.thread_id,
                fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
              });
            },
          }),
        );

        if (focusedNotification.record.has_reminder) {
          commands.push(
            removeThreadReminderCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  triagedUntil: null,
                });
              },
            }),
          );
        }

        if (focusedNotification.record.is_starred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification, environment],
  });

  useRegisterThreadLabelCommands({
    threadId: focusedNotification?.record.thread_id,
  });

  return null;
};
