import { htmlToText } from "libs/htmlToText";
import {
  DraftAttachmentDoc,
  DraftLabelDoc,
  DraftRecipientDoc,
  generateRecordId,
  getPointer,
  MessageRecipientDoc,
  RecordValue,
  SpecialTagTypeEnum,
  ThreadTimelineTypeEnum,
  ThreadVisibility,
} from "libs/schema";
import * as d from "ts-decoders/decoders";
import { areDecoderErrors, DecoderSuccess, DecoderError, DecoderReturnType, Decoder } from "ts-decoders";
import { op, Operation, WithSentinelValues } from "libs/transaction";
import { isUuid } from "libs/uuid";
import { UnreachableCaseError, ValidationError } from "libs/errors";

/* -------------------------------------------------------------------------------------------------
 *  createDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface CreateDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  userId: string;
  ownerOrganizationId: string;
  isReply: boolean;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
  };
  subject?: string | null;
  bodyHTML?: string;
  /**
   * Note that drafts for replies only add recipients that are *new* to the thread to the `to`
   * field. If someone is mentioned in a reply who is already participating in the thread, they
   * will not be added to the `to` field. Because of this, we need to ensure that all mentioned
   * users and groups are included as recipients when constructing the message.
   */
  to?: DraftRecipientDoc[];
  labels?: DraftLabelDoc[];
  visibility?: ThreadVisibility | null;
  attachments?: DraftAttachmentDoc[];
}

export function createDraft(props: CreateDraftProps) {
  switch (props.type) {
    case "COMMS": {
      const onCreate = [
        op.set("draft", {
          id: props.draftId,
          type: props.type,
          user_id: props.userId,
          thread_id: props.threadId,
          is_reply: props.isReply,
          is_edit: false,
          branched_from_thread_id: props.branchedFrom?.threadId ?? null,
          branched_from_message_id: props.branchedFrom?.messageId ?? null,
          new_thread_subject: props.isReply ? null : (props.subject ?? ""),
          new_thread_visibility: props.isReply ? null : (props.visibility ?? null),
          body_html: props.bodyHTML ?? "",
          attachments: props.attachments ?? [],
          to: props.to ?? [],
          labels: props.labels ?? [],
          owner_organization_id: props.ownerOrganizationId,
        }),
      ];

      if (props.branchedFrom) {
        onCreate.push(
          op.set("thread_timeline", {
            id: generateRecordId("thread_timeline", {
              entry_id: props.draftId,
              thread_id: props.branchedFrom.threadId,
            }),
            entry_id: props.draftId,
            thread_id: props.branchedFrom.threadId,
            type: ThreadTimelineTypeEnum.BRANCHED_DRAFT,
            data: null,
            order: op.fieldValue.BRANCHED_DRAFT_TIMELINE_ORDER({
              branchedFromMessageId: props.branchedFrom.messageId,
              draftCreatedAt: op.fieldValue.SERVER_TIMESTAMP(),
            }),
            creator_user_id: props.userId,
            owner_organization_id: props.ownerOrganizationId,
          }),
        );
      }

      const draftPointer = getPointer("draft", props.draftId);

      const operations: Operation[] = [
        op.upsert(draftPointer, {
          onCreate,
        }),
      ];

      return operations;
    }
    case "EMAIL": {
      throw new Error("Email drafts are not yet supported.");
    }
    default: {
      throw new UnreachableCaseError(props.type);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 *  mergeDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface MergeDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  userId: string;
  ownerOrganizationId: string;
  is_reply: boolean;
  is_edit: boolean;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
  };
  subject?: string | null;
  bodyHTML?: string;
  /**
   * Note that drafts for replies only add recipients that are *new* to the thread to the `to`
   * field. If someone is mentioned in a reply who is already participating in the thread, they
   * will not be added to the `to` field. Because of this, we need to ensure that all mentioned
   * users and groups are included as recipients when constructing the message.
   */
  to?: DraftRecipientDoc[];
  labels?: DraftLabelDoc[];
  visibility?: ThreadVisibility | null;
  attachments?: DraftAttachmentDoc[];
}

export function mergeDraft(props: MergeDraftProps) {
  switch (props.type) {
    case "COMMS": {
      const operations = [
        op.set("draft", {
          id: props.draftId,
          type: props.type,
          user_id: props.userId,
          thread_id: props.threadId,
          is_reply: props.is_reply,
          is_edit: props.is_edit,
          branched_from_thread_id: props.branchedFrom?.threadId ?? null,
          branched_from_message_id: props.branchedFrom?.messageId ?? null,
          new_thread_subject: props.is_reply ? null : (props.subject ?? ""),
          new_thread_visibility: props.is_reply ? null : (props.visibility ?? null),
          body_html: props.bodyHTML ?? "",
          attachments: props.attachments ?? [],
          to: props.to ?? [],
          labels: props.labels ?? [],
          owner_organization_id: props.ownerOrganizationId,
        }),
      ];

      if (props.branchedFrom) {
        operations.push(
          op.set("thread_timeline", {
            id: generateRecordId("thread_timeline", {
              entry_id: props.draftId,
              thread_id: props.branchedFrom.threadId,
            }),
            entry_id: props.draftId,
            thread_id: props.branchedFrom.threadId,
            type: ThreadTimelineTypeEnum.BRANCHED_DRAFT,
            data: null,
            order: op.fieldValue.SET_IF_NULL(
              op.fieldValue.BRANCHED_DRAFT_TIMELINE_ORDER({
                branchedFromMessageId: props.branchedFrom.messageId,
                draftCreatedAt: op.fieldValue.SERVER_TIMESTAMP(),
              }),
            ),
            creator_user_id: props.userId,
            owner_organization_id: props.ownerOrganizationId,
          }),
        );
      }

      return operations;
    }
    case "EMAIL": {
      throw new Error("Email drafts are not yet supported.");
    }
    default: {
      throw new UnreachableCaseError(props.type);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 *  sendNewThreadDraft
 * -------------------------------------------------------------------------------------------------
 */

export type SendNewThreadDraftProps = {
  scheduledToBeSentAt: Date;
  draft: ValidDraft;
  // Note that, even if the draft specifies a branched_from_thread_id,
  // it's possible that the user no longer has access to that thread so
  // branchedFromMessage will be undefined. We choose to pretend like
  // the thread wasn't branched in this case and we still send the message.
  branchedFromMessage:
    | Pick<WithSentinelValues<RecordValue<"message">, "timeline_order">, "id" | "thread_id" | "timeline_order">
    | undefined;
};

/**
 * Doesn't delete the draft records. That should be done separately.
 */
export function sendNewThreadDraft(props: SendNewThreadDraftProps): Operation[] {
  const draft = validateNewThreadDraft(props.draft);

  if (!draft) {
    throw new ValidationError("sendNewThreadDraft: invalid draft");
  }

  const messageScheduledToBeSentAt = props.scheduledToBeSentAt.toISOString();

  const messageSentAt = op.fieldValue.FUTURE_TIME_OR_SERVER_TIMESTAMP({
    futureTimestamp: messageScheduledToBeSentAt,
  });

  const messageTimelineOrder = op.fieldValue.MESSAGE_TIMELINE_ORDER({
    scheduledToBeSentAt: messageScheduledToBeSentAt,
  });

  const operations: Operation[] = [
    op.set("thread", {
      id: draft.thread_id,
      type: draft.type,
      visibility: draft.new_thread_visibility,
      subject: draft.new_thread_subject,
      first_message_sender_user_id: draft.user_id,
      first_message_id: draft.id,
      first_message_timeline_order: messageTimelineOrder,
      first_message_sent_at: messageSentAt,
      last_message_id: draft.id,
      last_message_sent_at: messageSentAt,
      last_message_timeline_order: messageTimelineOrder,
      is_branch: !!draft.branched_from_thread_id,
      branched_from_thread_id: props.branchedFromMessage?.thread_id || null,
      branched_from_message_id: props.branchedFromMessage?.id || null,
      owner_organization_id: draft.owner_organization_id,
    }),
    op.set("message", {
      id: draft.id,
      type: draft.type,
      thread_id: draft.thread_id,
      sender_user_id: draft.user_id,
      is_reply: draft.is_reply,
      to: draft.to.map((recipient): MessageRecipientDoc => {
        switch (recipient.type) {
          case "GROUP": {
            return {
              id: generateRecordId("message_group_recipient_doc", {
                type: "GROUP",
                group_id: recipient.group_id,
              }),
              type: "GROUP",
              group_id: recipient.group_id,
              priority: recipient.priority,
              is_implicit: recipient.is_implicit,
              is_mentioned: recipient.is_mentioned,
            };
          }
          case "USER": {
            return {
              id: generateRecordId("message_user_recipient_doc", {
                type: "USER",
                user_id: recipient.user_id,
              }),
              type: "USER",
              user_id: recipient.user_id,
              priority: recipient.priority,
              is_implicit: recipient.is_implicit,
              is_mentioned: recipient.is_mentioned,
            };
          }
        }
      }),
      body_text: htmlToText(draft.body_html),
      body_html: draft.body_html,
      attachments: draft.attachments,
      timeline_order: messageTimelineOrder,
      sent_at: messageSentAt,
      scheduled_to_be_sent_at: messageScheduledToBeSentAt,
      was_edited: false,
      last_edited_at: null,
      email_message_id: null,
      email_sender: null,
      is_delivered: false,
      delivered_at: null,
      data: {
        // This is initially set to true to ensure that the notifications are sent even if the user
        // immediately edits the message. This property should be renamed to "send_notifications".
        resend_notifications: true,
      },
      owner_organization_id: draft.owner_organization_id,
    }),
    op.set("message_reactions", {
      id: draft.id,
      thread_id: draft.thread_id,
      reactions: {},
      message_sender_user_id: draft.user_id,
      message_sent_at: messageSentAt,
      message_timeline_order: messageTimelineOrder,
      owner_organization_id: draft.owner_organization_id,
    }),
    op.set("thread_timeline", {
      id: generateRecordId("thread_timeline", {
        entry_id: draft.id,
        thread_id: draft.thread_id,
      }),
      entry_id: draft.id,
      thread_id: draft.thread_id,
      type: "MESSAGE",
      data: null,
      order: messageTimelineOrder,
      creator_user_id: draft.user_id,
      owner_organization_id: draft.owner_organization_id,
    }),
    ...draft.to.flatMap((recipient) => {
      const operations: Operation[] = [];

      switch (recipient.type) {
        case "GROUP": {
          operations.push(
            op.set("thread_group_permission", {
              id: generateRecordId("thread_group_permission", {
                group_id: recipient.group_id,
                thread_id: draft.thread_id,
              }),
              group_id: recipient.group_id,
              thread_id: draft.thread_id,
              start_at: messageTimelineOrder,
              thread_sent_at: messageSentAt,
              creator_user_id: draft.user_id,
              owner_organization_id: draft.owner_organization_id,
            }),
          );

          return operations;
        }
        case "USER": {
          operations.push(
            op.set("thread_user_permission", {
              id: generateRecordId("thread_user_permission", {
                user_id: recipient.user_id,
                thread_id: draft.thread_id,
              }),
              user_id: recipient.user_id,
              thread_id: draft.thread_id,
              start_at: messageTimelineOrder,
              thread_sent_at: messageSentAt,
              creator_user_id: draft.user_id,
              owner_organization_id: draft.owner_organization_id,
            }),
            op.set("thread_user_participant", {
              id: generateRecordId("thread_user_participant", {
                user_id: recipient.user_id,
                thread_id: draft.thread_id,
              }),
              user_id: recipient.user_id,
              thread_id: draft.thread_id,
              owner_organization_id: draft.owner_organization_id,
            }),
          );

          return operations;
        }
        default: {
          throw new UnreachableCaseError(recipient);
        }
      }
    }),
    op.set("thread_user_permission", {
      id: generateRecordId("thread_user_permission", {
        user_id: draft.user_id,
        thread_id: draft.thread_id,
      }),
      user_id: draft.user_id,
      thread_id: draft.thread_id,
      start_at: messageTimelineOrder,
      thread_sent_at: messageSentAt,
      creator_user_id: draft.user_id,
      owner_organization_id: draft.owner_organization_id,
    }),
    op.set("thread_user_participant", {
      id: generateRecordId("thread_user_participant", {
        user_id: draft.user_id,
        thread_id: draft.thread_id,
      }),
      user_id: draft.user_id,
      thread_id: draft.thread_id,
      owner_organization_id: draft.owner_organization_id,
    }),
    ...draft.labels.map((label) => {
      return op.set("thread_tag", {
        id: generateRecordId("thread_tag", {
          thread_id: draft.thread_id,
          tag_id: label.label_id,
        }),
        thread_id: draft.thread_id,
        tag_id: label.label_id,
        tag_type: SpecialTagTypeEnum.LABEL,
        creator_user_id: draft.user_id,
        owner_organization_id: draft.owner_organization_id,
        data: null,
      });
    }),
  ];

  if (props.branchedFromMessage) {
    operations.push(
      op.set("thread_timeline", {
        id: generateRecordId("thread_timeline", {
          entry_id: draft.thread_id,
          thread_id: props.branchedFromMessage.thread_id,
        }),
        entry_id: draft.thread_id,
        thread_id: props.branchedFromMessage.thread_id,
        type: "BRANCHED_THREAD",
        order: op.fieldValue.BRANCHED_THREAD_TIMELINE_ORDER({
          branchedFromMessageId: props.branchedFromMessage.id,
          newMessageTimelineOrder: messageTimelineOrder,
        }),
        data: null,
        // Todo: I'm not sure if this timeline entry should be owned
        // by the organization that owns the thread or the organization
        // that owns the draft.
        creator_user_id: draft.user_id,
        owner_organization_id: draft.owner_organization_id,
      }),
    );
  }

  return operations;
}

/* -------------------------------------------------------------------------------------------------
 *  sendDraftReply
 * -------------------------------------------------------------------------------------------------
 */

export type SendDraftReplyProps = {
  scheduledToBeSentAt: Date;
  draft: ValidDraft;
  thread: Pick<RecordValue<"thread">, "subject">;
};

/**
 * Doesn't delete the draft records. That should be done separately.
 */
export function sendDraftReply(props: SendDraftReplyProps): Operation[] {
  const draft = validateDraftReply(props.draft);

  if (!draft) {
    throw new ValidationError("sendDraftReply: invalid draft");
  }

  const messageScheduledToBeSentAt = props.scheduledToBeSentAt.toISOString();

  const messageSentAt = op.fieldValue.FUTURE_TIME_OR_SERVER_TIMESTAMP({
    futureTimestamp: messageScheduledToBeSentAt,
  });

  const messageTimelineOrder = op.fieldValue.MESSAGE_TIMELINE_ORDER({
    scheduledToBeSentAt: messageScheduledToBeSentAt,
  });

  const operations: Operation[] = [
    op.set("message", {
      id: draft.id,
      type: draft.type,
      thread_id: draft.thread_id,
      sender_user_id: draft.user_id,
      is_reply: draft.is_reply,
      to: draft.to.map((recipient): MessageRecipientDoc => {
        switch (recipient.type) {
          case "GROUP": {
            return {
              id: generateRecordId("message_group_recipient_doc", {
                type: "GROUP",
                group_id: recipient.group_id,
              }),
              type: "GROUP",
              group_id: recipient.group_id,
              priority: recipient.priority,
              is_implicit: recipient.is_implicit,
              is_mentioned: recipient.is_mentioned,
            };
          }
          case "USER": {
            return {
              id: generateRecordId("message_user_recipient_doc", {
                type: "USER",
                user_id: recipient.user_id,
              }),
              type: "USER",
              user_id: recipient.user_id,
              priority: recipient.priority,
              is_implicit: recipient.is_implicit,
              is_mentioned: recipient.is_mentioned,
            };
          }
          default: {
            throw new UnreachableCaseError(recipient);
          }
        }
      }),
      body_text: htmlToText(draft.body_html),
      body_html: draft.body_html,
      attachments: draft.attachments,
      timeline_order: messageTimelineOrder,
      sent_at: messageSentAt,
      scheduled_to_be_sent_at: messageScheduledToBeSentAt,
      was_edited: false,
      last_edited_at: null,
      email_message_id: null,
      email_sender: null,
      is_delivered: false,
      delivered_at: null,
      data: {
        // This is initially set to true to ensure that the notifications are sent even if the user
        // immediately edits the message. This property should be renamed to "send_notifications".
        resend_notifications: true,
      },
      owner_organization_id: draft.owner_organization_id,
    }),
    op.set("message_reactions", {
      id: draft.id,
      thread_id: draft.thread_id,
      reactions: {},
      message_sender_user_id: draft.user_id,
      message_sent_at: messageSentAt,
      message_timeline_order: messageTimelineOrder,
      owner_organization_id: draft.owner_organization_id,
    }),
    op.set("thread_timeline", {
      id: generateRecordId("thread_timeline", {
        entry_id: draft.id,
        thread_id: draft.thread_id,
      }),
      entry_id: draft.id,
      thread_id: draft.thread_id,
      type: "MESSAGE",
      data: null,
      order: messageTimelineOrder,
      creator_user_id: draft.user_id,
      owner_organization_id: draft.owner_organization_id,
    }),
  ];

  return operations;
}

/* -------------------------------------------------------------------------------------------------
 *  helpers
 * -------------------------------------------------------------------------------------------------
 */

type ValidDraft = Omit<
  RecordValue<"draft">,
  "created_at" | "updated_at" | "server_updated_at" | "deleted_at" | "deleted_by_user_id" | "version"
>;

function validateNewThreadDraft(draft: ValidDraft): ScheduledNewThreadDraft | null {
  const result = newThreadDraftDecoder.decode(draft);

  if (areDecoderErrors(result)) {
    console.debug("Draft validation errors:", result);
    return null;
  }

  return result.value;
}

function validateDraftReply(draft: ValidDraft): ScheduledDraftReply | null {
  const result = draftReplyDecoder.decode(draft);

  if (areDecoderErrors(result)) {
    console.debug("Draft validation errors:", result);
    return null;
  }

  return result.value;
}

type ScheduledNewThreadDraft = DecoderReturnType<typeof newThreadDraftDecoder>;

const uuidD = new Decoder((input) => {
  if (typeof input !== "string") {
    return new DecoderError(input, "invalid-type", "must be a uuid string");
  } else if (!isUuid(input)) {
    return new DecoderError(input, "invalid-uuid", "must be a uuid string");
  }

  return new DecoderSuccess(input);
});

const newThreadDraftDecoder = d
  .objectD({
    id: uuidD,
    thread_id: uuidD,
    type: d.exactlyD("COMMS"),
    user_id: uuidD,
    new_thread_subject: d.stringD(),
    new_thread_visibility: d.anyOfD([d.exactlyD("SHARED"), d.exactlyD("PRIVATE")]),
    body_html: d.stringD(),
    attachments: d.anyD<DraftAttachmentDoc[]>(),
    is_reply: d.exactlyD(false),
    is_edit: d.exactlyD(false),
    branched_from_thread_id: d.nullableD(uuidD),
    branched_from_message_id: d.nullableD(uuidD),
    to: d.arrayD<DraftRecipientDoc>(),
    labels: d.arrayD<DraftLabelDoc>(),
    owner_organization_id: uuidD,
  } satisfies {
    [P in keyof ValidDraft]: Decoder<RecordValue<"draft">[P]>;
  })
  .chain((input) => {
    const hasBranchedProp = Boolean(input.branched_from_thread_id || input.branched_from_message_id);

    if (hasBranchedProp) {
      const result = newThreadBranchedFromDecoder.decode(input);

      if (areDecoderErrors(result)) return result;
    } else {
      const result = newThreadNotBranchedFromDecoder.decode(input);

      if (areDecoderErrors(result)) return result;
    }

    return new DecoderSuccess(input);
  });

const newThreadBranchedFromDecoder = d.objectD({
  branched_from_thread_id: uuidD,
  branched_from_message_id: uuidD,
});

const newThreadNotBranchedFromDecoder = d.objectD({
  branched_from_thread_id: d.exactlyD(null),
  branched_from_message_id: d.exactlyD(null),
});

type ScheduledDraftReply = DecoderReturnType<typeof draftReplyDecoder>;

const draftReplyDecoder = d.objectD({
  id: uuidD,
  thread_id: uuidD,
  type: d.exactlyD("COMMS"),
  user_id: uuidD,
  new_thread_subject: d.constantD(null),
  new_thread_visibility: d.constantD(null),
  body_html: d.stringD(),
  attachments: d.anyD<DraftAttachmentDoc[]>(),
  is_reply: d.exactlyD(true),
  is_edit: d.exactlyD(false),
  branched_from_thread_id: d.constantD(null),
  branched_from_message_id: d.constantD(null),
  to: d.arrayD<DraftRecipientDoc>(),
  labels: d.arrayD<DraftLabelDoc>(),
  owner_organization_id: uuidD,
} satisfies {
  [P in keyof ValidDraft]: Decoder<RecordValue<"draft">[P]>;
});

/* -----------------------------------------------------------------------------------------------*/
