import { interval, switchMap } from "rxjs";
import { useRecordLoader } from "./useRecordLoader";
import { startWith } from "libs/rxjs-operators";
import { useMemo } from "react";
import { stringComparer } from "libs/comparers";
import { useCurrentTimestamp } from "./useCurrentTimestamp";
import { uniq } from "lodash-es";
import { observeFilteredMessageEntriesForVirtualList } from "~/components/content-list/MessageEntry";

export type UseScheduledMessageIdsResult = [string[], { isLoading: boolean }];

export function useScheduledMessageIds(): UseScheduledMessageIdsResult {
  const [pendingMessages, pendingMeta] = useRecordLoader({
    name: "useScheduledMessageIds-pending",
    load({ loader, currentUserId }) {
      return loader.observeGetPendingSentMessages({ currentUserId }, { fetchStrategy: "cache" });
    },
  });

  const [scheduledMessages, scheduledMeta] = useRecordLoader({
    name: "useScheduledMessageIds-scheduled",
    load({ loader, currentUserId }) {
      return interval(120_000).pipe(
        startWith(() => null),
        switchMap(() => {
          const observable = loader.observeGetSentMessages({
            userId: currentUserId,
            sentAfter: new Date().toISOString(),
          });

          return observeFilteredMessageEntriesForVirtualList({ recordLoader: loader }, observable);
        }),
      );
    },
  });

  const timestamp = useCurrentTimestamp({ reevaluateMs: 20_000 });

  return useMemo(() => {
    const messageIds = uniq(
      [...pendingMessages, ...scheduledMessages]
        .toSorted(
          (a, b) =>
            stringComparer(a.sent_at, b.sent_at) ||
            stringComparer(a.scheduled_to_be_sent_at, b.scheduled_to_be_sent_at) ||
            stringComparer(a.id, b.id),
        )
        .filter(
          (m) =>
            m.sent_at > timestamp ||
            !m.server_updated_at ||
            (!!m.last_edited_at && m.last_edited_at > m.server_updated_at),
        )
        .map((m) => m.id),
    );

    const isLoading = pendingMeta.isLoading || scheduledMeta.isLoading;

    return [messageIds, { isLoading }];
  }, [timestamp, pendingMessages, pendingMeta, scheduledMessages, scheduledMeta]);
}
