import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { TextInput } from "~/components/forms/TextInput";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { handleSubmit, onSubmitFn } from "~/components/forms/utils";
import { useRegisterCommands } from "~/environment/command.service";
import { Tooltip } from "~/components/Tooltip";
import { generateRecordId } from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { createLabel, updateLabel } from "~/actions/tag";
import { OutlineButton } from "~/components/OutlineButtons";
import { closeDialogCommand, createLabelCommand, submitFormCommand } from "~/utils/common-commands";

export type IEditLabelDialogData =
  | {
      prefill?: {
        id?: string | null;
        name?: string;
        description?: string | null;
      };
    }
  | undefined;

export type IEditLabelDialogReturnData = { success: boolean } | void;

export const EditLabelDialogState = new DialogState<IEditLabelDialogData, IEditLabelDialogReturnData>();

interface IFormValue {
  id: string | null;
  name: string;
  description: string;
}

export const EditLabelDialog = withModalDialog({
  dialogState: EditLabelDialogState,
  useOnDialogContainerRendered: () => {
    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          createLabelCommand({
            callback: () => {
              EditLabelDialogState.open();
            },
          }),
        ];
      },
      deps: [environment],
    });
  },
  async loadData({ data }) {
    const prefill = data?.prefill || {};

    return {
      id: prefill.id || null,
      name: prefill.name || "",
      description: prefill.description || "",
    };
  },
  Component: (props) => {
    const environment = useClientEnvironment();

    const control = useControl(() => {
      return createFormGroup({
        id: createFormControl(props.data.id || null),
        name: createFormControl(props.data.name, {
          required: true,
        }),
        description: createFormControl(props.data.description),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              EditLabelDialogState.close();
            },
          }),
          submitFormCommand({
            callback: () => {
              if (!control) return;
              environment.logger.debug("[EditLabelDialog] attempting submit");
              handleSubmit({ control, environment, submit });
            },
          }),
        ];
      },
      deps: [control, environment],
    });

    if (!control) return null;

    return (
      <div>
        <DialogTitle>
          <h2>{props.data.name ? `Update "${props.data.name}" Label` : `Create Label`}</h2>
        </DialogTitle>

        <form onSubmit={onSubmitFn({ control, environment, submit })} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="flex px-4">
            <div className="flex flex-1 py-2 border-b border-mauve-5">
              <TextInput control={control.controls.name} name="name" id="name" autoFocus />
            </div>
          </div>

          <div className="flex flex-1 overflow-y-auto p-4 text-slate-9">
            <p>
              <em>Note: Labels are private and only visible to you</em>
            </p>
          </div>

          <div className="w-fit ml-auto m-2">
            <Tooltip side="left" content="Cmd + Enter">
              <OutlineButton type="submit" className="flex-col text-sm px-3">
                {props.data.name ? "Update" : "Create"} Label
              </OutlineButton>
            </Tooltip>
          </div>
        </form>
      </div>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, values: IFormValue) => {
  console.log("submitting...", values);

  using disposable = environment.isLoading.add();

  EditLabelDialogState.close({ success: true });

  if (values.id) {
    await updateLabel(environment, {
      labelId: values.id,
      name: values.name,
      icon: null,
      description: values.description || null,
    });
  } else {
    const labelId = generateRecordId("tag");

    await createLabel(environment, {
      labelId,
      icon: null,
      name: values.name,
      description: values.description || null,
    });

    environment.router.navigate(`/labels/${labelId}`);
  }
});
