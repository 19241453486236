import { UnreachableCaseError } from "./errors";

export type AppEnvironment = "production" | "staging" | "development" | "test-unit" | "test-e2e";

export const isProduction = (appEnvironment: AppEnvironment) => appEnvironment === "production";

export const isStaging = (appEnvironment: AppEnvironment) => appEnvironment === "staging";

export const isDevelopment = (appEnvironment: AppEnvironment) => appEnvironment === "development";

export const isUnitTest = (appEnvironment: AppEnvironment) => appEnvironment === "test-unit";

export const isE2ETest = (appEnvironment: AppEnvironment) => appEnvironment === "test-e2e";

export const isTest = (appEnvironment: AppEnvironment) => isUnitTest(appEnvironment) || isE2ETest(appEnvironment);

export const isProductionOrStaging = (appEnvironment: AppEnvironment) =>
  isProduction(appEnvironment) || isStaging(appEnvironment);

export const isProductionOrE2ETest = (appEnvironment: AppEnvironment) =>
  isProduction(appEnvironment) || isE2ETest(appEnvironment);

export const isStagingOrDevelopment = (appEnvironment: AppEnvironment) =>
  isStaging(appEnvironment) || isDevelopment(appEnvironment);

export const isDevelopmentOrTest = (appEnvironment: AppEnvironment) =>
  isDevelopment(appEnvironment) || isTest(appEnvironment);

export const isValidAppEnvironment = (appEnvironment: any) =>
  isProduction(appEnvironment) ||
  isStaging(appEnvironment) ||
  isDevelopment(appEnvironment) ||
  isUnitTest(appEnvironment) ||
  isE2ETest(appEnvironment);

export const formatAppEnvironment = (appEnvironment: AppEnvironment) => {
  switch (appEnvironment) {
    case "production":
      return "Production";
    case "staging":
      return "Staging";
    case "development":
      return "Development";
    case "test-unit":
      return "Unit Test";
    case "test-e2e":
      return "E2E Test";
    default: {
      throw new UnreachableCaseError(appEnvironment);
    }
  }
};
