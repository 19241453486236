import { useRef, useMemo, CSSProperties } from "react";
import { List, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { ESCAPE_TO_BACK_COMMAND } from "~/utils/common-commands";
import { Tooltip } from "~/components/Tooltip";
import { BsLockFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineButton } from "~/components/OutlineButtons";
import { useRegisterCommands } from "~/environment/command.service";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useTag } from "~/hooks/useTag";
import { TagSubscriberDoc, useTagSubscribers } from "~/hooks/useTagSubscriberUsers";
import { isTagPrivate } from "libs/schema/predicates";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { PointerWithRecord, TagSubscriptionPreference } from "libs/schema";
import { useUserProfile } from "~/hooks/useUserProfile";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { entryCSSClasses } from "~/components/content-list/layout";
import { useUserContactInfo } from "~/hooks/useUserContactInfo";
import { useIsCurrentRouteForAGroup } from "~/hooks/useIsCurrentRouteForAGroup";
import { TagInviteDialogState } from "~/dialogs/tag-invite/TagInviteDialog";
import { renderGroupName } from "~/utils/tag-utils";
import { ParentComponent } from "~/utils/type-helpers";
import { Simplify } from "type-fest";
import { useVirtualList } from "~/hooks/useVirtualList";
import { UnreachableCaseError } from "libs/errors";
import { useParams } from "@tanstack/react-router";
import { EndOfListMsg } from "~/components/EndOfListMsg";

export const TagSubscribersView: ParentComponent<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  const params = useParams({ strict: false });

  const isGroupView = useIsCurrentRouteForAGroup();

  const [tag, { isLoading: isTagLoading }] = useTag(params.tagId);

  const subscriberEntries = useSubscriberEntries(params.tagId);

  const virtualSubscriberEntries = useVirtualList({
    scrollboxRef,
    count: subscriberEntries.length,
    getEntryKey: (index) => subscriberEntries[index]?.id ?? "",
    estimateSize: (index) => {
      const entry = subscriberEntries[index];

      switch (entry?.type) {
        case "subscriber": {
          return 48;
        }
        case "section-header": {
          return 80;
        }
        case undefined: {
          return 0;
        }
        default: {
          throw new UnreachableCaseError(entry);
        }
      }
    },
  });

  const subscriberIds = useMemo(
    () => subscriberEntries.filter((entry) => entry.type === "subscriber").map((entry) => entry.id),
    [subscriberEntries],
  );

  const subscriberCount = virtualSubscriberEntries.entries.length;

  useRegisterCommands({
    commands() {
      const commands = [ESCAPE_TO_BACK_COMMAND];

      if (tag) {
        commands.push({
          label: "Add subscribers...",
          altLabels: ["Update subscribers...", "Add group members...", "Invite to group...", "Invite subscribers..."],
          callback: () => {
            TagInviteDialogState.open({
              tagId: tag.id,
            });
          },
        });
      }

      return commands;
    },
    deps: [tag],
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [tag],
  });

  if (!tag) {
    if (isTagLoading) {
      return <div>Loading...</div>;
    }

    const title = isGroupView ? "Group Not Found" : "Tag Not Found";

    return <NotFound title={title} />;
  }

  const isPrivate = isTagPrivate(tag);

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen overflow-auto">
        <Helmet>
          <title>{renderGroupName(tag)} subscribers | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} theme={isPrivate ? "dark" : "light"} className="flex-col">
          <h1 className="text-3xl">
            <span># {tag.name} subscribers</span>
            {isPrivate && (
              <Tooltip side="bottom" content="This channel is private and only visible to invited members">
                <span className="text-2xl inline-flex mx-2 hover:cursor-help mt-1 text-slate-8">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>
            )}
          </h1>

          <MainLayout.HeaderMenu>
            <li>
              <OutlineButton
                theme={isPrivate ? "dark" : "light"}
                onClick={(e) => {
                  e.preventDefault();

                  TagInviteDialogState.open({
                    tagId: tag.id,
                  });
                }}
              >
                <FaPlus size={16} className="mr-1 text-slate-11" /> <small>Update subscribers</small>
              </OutlineButton>
            </li>
          </MainLayout.HeaderMenu>
        </MainLayout.Header>

        {subscriberCount === 0 ?
          <EmptyListMessage text="None." />
        : <ContentList
            onEntryAction={() => {
              showNotImplementedToastMsg(`
                  Unfortunately, you can't currently view user profiles. 
                  Annoying, I know. I want this feature too...
                `);
            }}
            autoFocus
            allEntryIdsForVirtualizedList={subscriberIds}
            style={virtualSubscriberEntries.containerStyles()}
          >
            {virtualSubscriberEntries.entries.map((virtualEntry, index) => {
              const entry = subscriberEntries[virtualEntry.index];
              if (!entry) return null;

              switch (entry.type) {
                case "subscriber": {
                  return (
                    <SubscriberEntry
                      key={entry.id}
                      tagId={tag.id}
                      userId={entry.userProfile.id}
                      relativeOrder={index}
                      style={virtualSubscriberEntries.entryStyles(virtualEntry)}
                    />
                  );
                }
                case "section-header": {
                  return (
                    <SectionHeader
                      key={entry.id}
                      preference={entry.preference}
                      style={virtualSubscriberEntries.entryStyles(virtualEntry)}
                    />
                  );
                }
                default: {
                  throw new UnreachableCaseError(entry);
                }
              }
            })}
          </ContentList>
        }

        {subscriberCount > 0 && <EndOfListMsg />}
      </div>
    </ListScrollbox>
  );
};

const SectionHeader: ParentComponent<{
  preference: TagSubscriptionPreference;
  style: CSSProperties;
}> = (props) => {
  const label =
    props.preference === "all" ? "Subscribed to all"
    : props.preference === "all-new" ? "Subscribed to new threads"
    : props.preference === "involved" ? "Mentions only"
    : "Unknown subscription preference";

  return (
    <div className="pt-4 mx-12 border-l-2 border-white text-black font-medium flex items-center" style={props.style}>
      <h2 className="text-2xl">{label}</h2>
    </div>
  );
};

const SubscriberEntry: ParentComponent<{
  tagId: string;
  userId: string;
  relativeOrder: number;
  style: CSSProperties;
}> = (props) => {
  const [userProfile] = useUserProfile(props.userId);
  const [userContactInfo] = useUserContactInfo(props.userId);
  const entryData = useAsPointerWithRecord("user_profile", userProfile);

  if (!userProfile || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"user_profile">>
      id={userProfile.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses} style={props.style}>
        <div className="w-full flex items-center">
          <span className="flex-1">{userProfile.name}</span>

          <span className="flex-[4] text-slate-9">{userContactInfo?.email_address}</span>
        </div>
      </div>
    </List.Entry>
  );
};

type SubscriberEntry =
  | Simplify<TagSubscriberDoc & { type: "subscriber"; id: string }>
  | { type: "section-header"; id: string; preference: TagSubscriptionPreference };

function useSubscriberEntries(tagId: string | undefined) {
  const [subscribers] = useTagSubscribers({
    tagId,
  });

  return useMemo(() => {
    let previousSubscriptionPreference: string | undefined;

    return subscribers.reduce((store, entry) => {
      const isNewSubscriptionPreference = entry.subscriptionPreference !== previousSubscriptionPreference;

      previousSubscriptionPreference = entry.subscriptionPreference;

      if (isNewSubscriptionPreference) {
        store.push({
          type: "section-header",
          id: entry.subscriptionPreference,
          preference: entry.subscriptionPreference,
        });
      }

      store.push({ type: "subscriber", id: entry.userProfile.id, ...entry });

      return store;
    }, [] as SubscriberEntry[]);
  }, [subscribers]);
}
