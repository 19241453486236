import React, { ReactElement } from "react";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Slot } from "@radix-ui/react-slot";
import { ParentComponent } from "~/utils/type-helpers";

export function DropdownMenu(
  props: React.PropsWithChildren<{
    open: boolean;
    setOpen: (value: boolean) => void;
    trigger: ReactElement;
  }>,
) {
  const anchorElRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Slot ref={anchorElRef}>{props.trigger}</Slot>

      <Menu
        anchorEl={props.open ? anchorElRef.current : null}
        open={props.open}
        onClose={(e: Event) => {
          e.preventDefault();
          props.setOpen(false);
        }}
      >
        <MenuList dense disablePadding data-testid="dropdown-menu-list">
          {props.children}
        </MenuList>
      </Menu>
    </>
  );
}

const DropdownMenuItem: ParentComponent<{ icon?: ReactElement; onClick: React.MouseEventHandler<HTMLLIElement> }> = (
  props,
) => {
  return (
    <MenuItem onClick={props.onClick}>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText>{props.children}</ListItemText>
    </MenuItem>
  );
};

DropdownMenu.Item = DropdownMenuItem;
