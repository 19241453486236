import { FcGoogle } from "react-icons/fc";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NewUserDialog } from "~/dialogs/user-new/NewUserDialog";
import { LoadingText } from "~/components/LoadingText";
import { signInWithSwiftGoogle, signinWithGoogle, signinWithGoogleGetRedirectResult } from "~/actions/signinWithGoogle";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useIsNativeIOS } from "~/hooks/useIsNativeIos";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { AlertDialog } from "~/dialogs/alert/AlertDialog";
import { useTriggerIsLoading } from "~/hooks/useTriggerIsLoading";
import { Navigate, useLocation } from "@tanstack/react-router";
import { useSearchParam } from "react-use";
import { attemptToGetAuthGuardRedirectLocation } from "~/route-guards/withAuthGuard";

/**
 * Shows the login view, but also handles the redirect from Google after the user signs in.
 */
export function LoginView() {
  const environment = useClientEnvironment();
  const [currentUser, { isLoading: isCurrentUserLoading }] = useCurrentUser();
  const location = useLocation();
  const invitationToken = useSearchParam("invitationToken");
  const isNativeIOS = useIsNativeIOS();
  const isLoadingComplete = useIsLoadingComplete(isCurrentUserLoading);

  useTriggerIsLoading(isCurrentUserLoading);

  const handleExternalSocialAuth = (
    event: CustomEvent<{
      accessToken: string;
      providerId: string;
      email: string;
      signInResult: any;
    }>,
  ) => {
    const { accessToken, providerId, email } = event.detail;
    signInWithSwiftGoogle(environment, { accessToken, providerId, email });
  };

  useCompleteSigninAfterRedirect({
    isCurrentUser: !!currentUser,
    isCurrentUserLoading,
  });

  useEffect(() => {
    window.addEventListener("external-social-auth", handleExternalSocialAuth as EventListener);
    return () => window.removeEventListener("external-social-auth", handleExternalSocialAuth as EventListener);
  }, []);

  if (currentUser) {
    return <Navigate to="/inbox" replace />;
  }

  if (!isLoadingComplete) {
    return <LoadingText />;
  }

  const onSocialInAppAuth = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.webkit.messageHandlers.socialAuth.postMessage("google");
  };

  return (
    <div className="h-dynamic-screen w-screen flex flex-col justify-center items-center space-y-10">
      <Helmet>
        <title>Login | Comms</title>
      </Helmet>

      <NewUserDialog />
      <AlertDialog />

      <h1 className="text-4xl font-bold">Log in to Comms</h1>

      {isNativeIOS ?
        <button
          className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
          onClick={async () => {
            onSocialInAppAuth();
          }}
        >
          <FcGoogle /> <span>Log in with Google</span>
        </button>
      : <button
          className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
          onClick={async () => {
            const redirectTo = attemptToGetAuthGuardRedirectLocation(location);

            if (invitationToken) {
              environment.sessionStorage.setItem("invitationToken", invitationToken);
            }

            await signinWithGoogle(environment, redirectTo);
          }}
        >
          <FcGoogle /> <span>Log in with Google</span>
        </button>
      }
    </div>
  );
}

function useCompleteSigninAfterRedirect(props: { isCurrentUser: boolean; isCurrentUserLoading: boolean }) {
  const { isCurrentUser, isCurrentUserLoading } = props;
  const environment = useClientEnvironment();
  const attemptToGetRedirectResult = !isCurrentUser && !isCurrentUserLoading;

  useEffect(() => {
    if (!attemptToGetRedirectResult) return;
    signinWithGoogleGetRedirectResult(environment);
  }, [attemptToGetRedirectResult, environment]);
}

/**
 * When the user "Logs in with Google" for the first time, they'll receive a userId but they
 * will not have a user profile yet. Because they have a userId, we'll connect to pubsub. In
 * response to connecting to pubsub the `onStart` method of the `WebsocketPubsubClient` will
 * run which will trigger reloading all active queries. This will flip `isCurrentUserLoading`
 * from false to true again. This flicker can cause the `NewUserDialog` to mount, then unmount,
 * then mount again. If someone starts to type in the `NewUserDialog`'s form, the form will
 * briefly clear the typed characters.
 *
 * To avoid this, we only consider this component "loading" until isCurrentUserLoading is set
 * as false the first time.
 */
function useIsLoadingComplete(isCurrentUserLoading: boolean) {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    if (isCurrentUserLoading || isLoadingComplete) return;
    setIsLoadingComplete(true);
  }, [isCurrentUserLoading, isLoadingComplete]);

  return isLoadingComplete;
}
