import { LabelTagRecord } from "libs/schema";
import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

export type UseArchivedPersonalLabelsResult = UseRecordLoaderResult<LabelTagRecord[]>;

export function useArchivedPersonalLabels(): UseArchivedPersonalLabelsResult {
  return useRecordLoader({
    name: "useArchivedPersonalLabels",
    load({ loader, currentUserId }) {
      return loader.observeGetPersonalLabels({
        currentUserId,
        isArchived: true,
      });
    },
  });
}
